import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { SessionStorageService } from "../../../services/session-storage.service";
import { debounceTime, distinctUntilChanged, forkJoin, of, Subject, switchMap, tap } from "rxjs";
import { Customer } from "../../../customer/customer.model";
import { CustomerService } from "../../../customer/customer.service";
import { Company } from "../../../company/company.model";
import { ContractsService } from "../../../contracts/contracts.service";
import { AccountsService } from "../../accounts.service";
import { CreditNote } from "../credit-note.model";
import { CreditNoteLine } from "../credit-note-line.model";
import { Invoice } from '../../invoice/invoice.model';
import {Bill} from "../../payables/generated-bills/bill.model";

@Component({
    selector: 'app-add-edit-credit-note',
    templateUrl: './add-edit-credit-note.component.html',
    styleUrls: ['./add-edit-credit-note.component.css']
})
export class AddEditCreditNoteComponent implements OnInit {
    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};
    creditNote!: CreditNote;
    creditNoteForm!: FormGroup;
    editMode = false;
    creditNoteId!: number;
    isLoading: boolean = false;

    companies: SelectItem<Company>[] = [];
    customers: SelectItem<Customer>[] = [];
    filteredBills: Bill[] = [];
    filteredInvoices: Invoice[] = [];
    private billSearchTerms = new Subject<string>();
    private invoiceSearchTerms = new Subject<string>();


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private creditNoteService: AccountsService,
        private customerService: CustomerService,
        private contractService: ContractsService,
        private accountsService: AccountsService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Credit Note', routerLink: '/accounts/credit-note' },
            { label: this.editMode ? 'Edit Credit Note' : 'Add Credit Note' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.creditNoteId = params['id'];
            this.editMode = !!this.creditNoteId;
            this.loadData();
        });

        this.initForm();
        this.initFilterLogic();
    }

    loadData() {
        this.isLoading = true;

        const loadObservables = {
            companies: this.loadCompanies(),
        };

        forkJoin(loadObservables)
            .pipe(
                tap(() => {
                    if (this.editMode) {
                        this.loadCreditNote().subscribe();
                    } else {
                        if (this.companies.length > 0) {
                            this.onCompanyChange(this.companies[0].value);
                            this.creditNoteForm.patchValue({ company: this.companies[0].value });
                        }
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while loading data.' + error.message
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    loadCompanies() {
        return this.contractService.getContractRelatedCompanies(this.sessionStorageService.getCompany()!.id).pipe(
            tap(value => {
                this.companies = value.map(company => ({
                    value: company,
                    label: company.name
                }));
            }),
        );
    }

    onCompanyChange(company: Company) {
        if (company) {
            this.loadCustomersByCompany(company).subscribe();
            //this.billSearchTerms.next('');
            //this.invoiceSearchTerms.next('');
        } else {
            this.customers = [];
            this.creditNoteForm.patchValue({ customer: null });
            this.filteredBills = [];
            this.filteredInvoices = [];
            this.creditNoteForm.patchValue({ bill: null, invoice: null });
        }
    }

    loadCustomersByCompany(company: Company) {
        return this.customerService.getCustomersByCompany(company.id).pipe(
            tap(customers => {
                this.customers = customers.map(customer => ({
                    value: customer,
                    label: customer.name
                }));
            })
        );
    }

    loadCreditNote() {
        return this.creditNoteService.getCreditNoteById(this.creditNoteId).pipe(
            tap(creditNote => {
                this.creditNote = creditNote;
                this.creditNoteForm.patchValue(creditNote);
                this.onCompanyChange(creditNote.company!);
                this.creditNoteForm.setControl('creditNoteLines', this.fb.array(creditNote.creditNoteLines!
                    .map(line => this.createCreditNoteLineFormGroup(line))));
            })
        );
    }

    initForm() {
        this.creditNoteForm = this.fb.group({
            creditNoteID: [null],
            creditNoteNumber: [''],
            company: [null, Validators.required],
            customer: [null, Validators.required],
            bill: [null],
            invoice: [null],
            creditNoteDate: [new Date(), Validators.required],
            reason: [''],
            createdAt: [null],
            createdBy: [null],
            updatedAt: [null],
            updatedBy: [null],
            creditNoteLines: this.fb.array([this.createCreditNoteLineFormGroup()]),
        });
    }

    createCreditNoteLineFormGroup(creditNoteLine?: CreditNoteLine): FormGroup {
        return this.fb.group({
            creditNoteLineID: [creditNoteLine?.creditNoteLineID ?? null],
            description: [creditNoteLine?.description ?? ''],
            amount: [creditNoteLine?.amount ?? 0, [Validators.required, Validators.min(0)]],
        });
    }

    get getCreditNoteLineControls(): FormArray {
        return this.creditNoteForm.get('creditNoteLines') as FormArray;
    }

    addCreditNoteLine() {
        this.getCreditNoteLineControls.push(this.createCreditNoteLineFormGroup());
    }

    removeCreditNoteLine(index: number) {
        this.getCreditNoteLineControls.removeAt(index);
    }

    saveCreditNote() {
        if (this.creditNoteForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const modifiedCreditNote = this.creditNoteForm.value as CreditNote;
        modifiedCreditNote.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        const userId = this.sessionStorageService.getUser()!.id

        if (this.editMode) {
            modifiedCreditNote.createdAt = this.creditNote.createdAt;
            modifiedCreditNote.createdBy = this.creditNote.createdBy;
            modifiedCreditNote.updatedAt = new Date();
            modifiedCreditNote.updatedBy = userId;
        } else {
            modifiedCreditNote.createdAt = new Date();
            modifiedCreditNote.createdBy = userId;
            modifiedCreditNote.updatedAt = new Date();
            modifiedCreditNote.updatedBy = userId;
        }
        modifiedCreditNote.creditNoteLines?.forEach(line => {
            line.creditNoteID = modifiedCreditNote.creditNoteID;
            line.updatedBy = userId;
        })
        this.creditNoteService.createCreditNote(modifiedCreditNote).subscribe({
            next: (newCreditNote: CreditNote) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Credit Note# ' + newCreditNote.creditNoteNumber + ' saved successfully.'
                });
                setTimeout(() => {
                    this.router.navigate(['/accounts/enterprise/payables/credit-note']);
                }, 3000);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving credit note'
                });
            }
        });
    }

    filterBills(event: any) {
        this.billSearchTerms.next(event.query);
    }

    filterInvoices(event: any) {
        this.invoiceSearchTerms.next(event.query);
    }

    private initFilterLogic() {
        this.billSearchTerms
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((searchText: string) => {
                    const companyId = this.creditNoteForm.get('company')?.value?.id;
                    const owningCompanyId = this.sessionStorageService.getCompany()!!.id;
                    if(companyId) {
                        return this.accountsService.getBills(0, 5, companyId, undefined, owningCompanyId, searchText);
                    } else {
                        return of({items:[]});
                    }
                }),
                tap(res => this.filteredBills = res.items)
            )
            .subscribe();

        this.invoiceSearchTerms
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((searchText: string) => {
                    const companyId = this.creditNoteForm.get('company')?.value?.id;
                    const owningCompanyId = this.sessionStorageService.getCompany()!!.id;
                    if(companyId) {
                        return this.accountsService.getInvoices(0, 5, companyId, owningCompanyId, searchText);
                    } else {
                        return of({items: []});
                    }
                }),
                tap(res => this.filteredInvoices = res.items)
            )
            .subscribe();
    }

    cancel() {
        this.location.back();
    }
}
