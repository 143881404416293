import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractsService } from '../contracts.service';
import { Contracts } from '../models/contracts.model';
import {MenuItem, MessageService} from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {Company} from "../../company/company.model";
import {Customer} from "../../customer/customer.model";
import {CustomerService} from "../../customer/customer.service";
import {CompanyService} from "../../company/company.service";
import {SessionStorageService} from "../../services/session-storage.service";

@Component({
    selector: 'app-add-edit-contract',
    templateUrl: './add-edit-contract.component.html',
    styleUrls: ['./add-edit-contract.component.css']
})
export class AddEditContractComponent implements OnInit {

    createEditContractForm!: FormGroup;
    createEditContract: Contracts = new Contracts();
    contractCreationInProgress = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    parties: Company[] = []; // Replace with your actual data
    firstPartyReps: Customer[] = []; //
    secondPartyReps: Customer[] = []; // Replace with your actual data
    showCustomerForm: boolean = false;
    customerFormHeader: string;
    customerFormSubmitLabel: string;
    selectedCompany: Company = new Company();
    showCompanyForm: boolean = false;
    companyFormHeader: string;
    companyFormSubmitLabel: string;
    selectedCustomer: Customer = new Customer();
    currentParty: string;

    constructor(
        private fb: FormBuilder,
        private contractsService: ContractsService,
        private customerService: CustomerService,
        private companyService: CompanyService,
        private sessionStorageService: SessionStorageService,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        // Load existing contract for editing
        const contractId = this.route.snapshot.paramMap.get('id');
        if (contractId) {
            this.loadContract(Number(contractId));
        }

        let countryId = this.sessionStorageService.getCountryList()!.find(country => country.countryCode == 'SA')!.id;
        this.companyService.getCompaniesByCountry(countryId).subscribe(companies => {
            this.parties = companies;
            if (!contractId) {
                this.createEditContract.firstParty = this.sessionStorageService.getCompany()!!;
                this.onFirstPartyChanged();
            }
        });

        this.breadcrumbItems = [
            { label: 'Contracts', routerLink: '/contracts' },
            { label: contractId ? 'Edit Contract' : 'Add Contract' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.createEditContractForm = this.fb.group({
            contractNumberInput: [''],
            firstPartyDropdown: [''],
            firstPartyRepDropdown: [''],
            secondPartyDropdown: [''],
            secondPartyRepDropdown: [''],
            statusInput: [''],
            startDateInput: [''],
            endDateInput: ['']
        });
    }

    loadContract(contractId: number) {
        this.contractsService.getContractById(contractId).subscribe(contract => {
            this.createEditContract = contract;
            this.updateFormValues();
        });
    }

    updateFormValues() {
        this.createEditContractForm.patchValue({
            contractNumberInput: this.createEditContract.contractNumber,
            firstPartyDropdown: this.createEditContract.firstParty,
            firstPartyRepDropdown: this.createEditContract.firstPartyRep,
            secondPartyDropdown: this.createEditContract.secondParty,
            secondPartyRepDropdown: this.createEditContract.secondPartyRep,
            statusInput: this.createEditContract.revisions
                .sort((a, b) => a.revisionNumber - b.revisionNumber)
                .map(value => value.status).pop() || '',
            startDateInput: this.createEditContract.createdDate,
            endDateInput: this.createEditContract.updatedDate
        });
    }

    createUpdateContract() {
        this.contractCreationInProgress = true;
        if (this.createEditContract.contractID) {
            // Update existing contract
            this.contractsService.updateContract(this.createEditContract.contractID, this.createEditContract)
                .subscribe(() => {
                    this.contractCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                }, error => {
                    console.error(error);
                    this.contractCreationInProgress = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating contract:' + JSON.stringify(error) });
                });
        } else {
            // Create new contract
            this.contractsService.createContract(this.createEditContract)
                .subscribe(() => {
                    this.contractCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                }, error => {
                    console.error(error);
                    this.contractCreationInProgress = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating contract:' + JSON.stringify(error) });
                });
        }
    }

    onContractFileUpload(event: any) {
        // Handle file upload logic here
        console.log(event.files);
    }

    onFirstPartyChanged() {
        this.customerService.getCustomersByCompany(this.createEditContract.firstParty.id).subscribe(value => {
           this.firstPartyReps = value;
           if (!this.route.snapshot.paramMap.get('id'))
               this.createEditContract.firstPartyRep = this.sessionStorageService.getCustomer()!!;
        });
    }

    onSecondPartyChanged() {
        this.customerService.getCustomersByCompany(this.createEditContract.secondParty.id).subscribe(value => {
            this.secondPartyReps = value;
        });
    }

    registerParty(party: string) {
        this.currentParty = party;
        this.companyFormHeader = 'Create New Company';
        this.companyFormSubmitLabel = 'Create';
        this.selectedCustomer = new Customer(); // Clear for new customer
        this.selectedCompany = new Company(); // Clear for new company
        this.showCompanyForm = true;
    }

    registerPartyRep(party: string) {
        this.currentParty = party;
        this.customerFormHeader = 'Create New Representative';
        this.customerFormSubmitLabel = 'Create';
        this.selectedCustomer = new Customer(); // Clear for new customer
        this.selectedCompany = party === 'firstParty' ? this.createEditContract.firstParty : this.createEditContract.secondParty; // Populate with customer data for editing
        this.showCustomerForm = true;
    }

    onCustomerFormClosed() {
        this.showCustomerForm = false;
        if (this.currentParty === 'firstParty') {
            this.customerService.getCustomersByCompany(this.createEditContract.firstParty.id).subscribe(value => {
                this.firstPartyReps = value;
                this.createEditContract.firstPartyRep = this.selectedCustomer;
            });
        } else if (this.currentParty === 'secondParty') {
            this.customerService.getCustomersByCompany(this.createEditContract.secondParty.id).subscribe(value => {
                this.secondPartyReps = value;
                this.createEditContract.secondPartyRep = this.selectedCustomer;
            });
        }
    }

    onCompanyFormClosed() {
        this.showCompanyForm = false;
        let countryId = this.sessionStorageService.getCountryList()!.find(country => country.countryCode == 'SA')!.id;
        if (this.currentParty === 'firstParty') {
            this.companyService.getCompaniesByCountry(countryId).subscribe(companies => {
                this.parties = companies;
                this.createEditContract.firstParty = this.selectedCompany;
                this.onFirstPartyChanged();
            });
        } else if (this.currentParty === 'secondParty') {
            this.companyService.getCompaniesByCountry(countryId).subscribe(companies => {
                this.parties = companies;
                this.createEditContract.secondParty = this.selectedCompany;
                this.onSecondPartyChanged();
            });
        }
    }
}
