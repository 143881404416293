import { Component } from '@angular/core';
import {ClientReceivablesSummary} from "../client-receivables-summary.model";
import {MenuItem, MessageService} from "primeng/api";
import {AccountsService} from "../../accounts.service";
import {SessionStorageService} from "../../../services/session-storage.service";
import * as XLSX from "xlsx";
import {AccountReceivables} from "../receivables.model";
import {TabViewChangeEvent} from "primeng/tabview";
import {AccountReceivablesLine} from "../receivables-line.model";
import {GeneratedInvoicesSummary} from "./generated-invoices-summary.model";
import {Invoice} from "../../invoice/invoice.model";
import {InvoiceLine} from "../../invoice/invoice-line.model";

@Component({
    selector: 'app-generated-invoices',
    templateUrl: './generated-invoices.component.html',
    styleUrls: ['./generated-invoices.component.css']
})
export class GeneratedInvoicesComponent {

    generatedInvoicesSummaryList: GeneratedInvoicesSummary[] =[];
    isLoading = false;
    first = 0;
    rows = 20;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    constructor(private accountsService: AccountsService,
                private messageService: MessageService,
                private sessionStorageService: SessionStorageService) {
    }


    ngOnInit(): void {
        this.breadcrumbItems = [{label: 'Accounts'}, {label: 'Enterprise'}, {label: 'Receivables', routerLink: '/accounts/receivables'}, {label: 'Generated Invoices'}];
        this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};
        this.loadSummaryData() // Initial load
    }

    loadSummaryData() {
        this.isLoading = true;
        this.accountsService.getGeneratedInvoicesSummary(this.sessionStorageService.getCompany()!!.id).subscribe(value => {
            this.generatedInvoicesSummaryList = value;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        })
    }


    loadData(event: any, generatedInvoicesSummary: GeneratedInvoicesSummary) {
        this.isLoading = true;
        this.first = event.first;
        this.rows = event.rows;
        // Call your service to fetch receivables data with pagination
        // Example (replace with your actual service call):
        this.accountsService.getInvoices(this.first, this.rows,
            generatedInvoicesSummary.client.id, this.sessionStorageService.getCompany()!!.id).subscribe((dataWrapper) => {
            console.log(dataWrapper);
            generatedInvoicesSummary.invoices = dataWrapper.items// Simulate fetching data
            generatedInvoicesSummary.totalInvoices = dataWrapper.count; // Set total records for pagination
            this.isLoading = false;
        });
    }



    showDate(date: Date): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString(); // Example
    }

    downloadInvoice(invoice: Invoice) {
        if (invoice.invoiceFileUrl) {
            const a = document.createElement('a');
            a.href = invoice.invoiceFileUrl;
            a.download = 'cargomate_invoice_' + invoice.odooInvoiceNumber?.replace('/', '_') + '.pdf';
            a.target = '_blank';
            a.click();
        }
    }

    exportToExcel(generatedInvoicesSummary: GeneratedInvoicesSummary) {
        const worksheet = XLSX.utils.json_to_sheet(generatedInvoicesSummary.invoices);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'cargomate_generated_invoices_export_' + new Date().toISOString() + '.xlsx';
        // Create a blob from the workbook
        const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target = '_blank';
        a.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
    }



    viewDetails(invoice: Invoice) {
        // Implement logic to navigate to invoice details page or show a dialog, etc.
        console.log('View details for Invoice:', invoice);
    }

    onClientReceivablesTabChange($event: TabViewChangeEvent) {

    }

    editInvoiceLine(invoiceLine: InvoiceLine) {

    }

    generateReceipt(generatedInvoicesSummary: GeneratedInvoicesSummary) {
        if (!generatedInvoicesSummary.selectedInvoices || generatedInvoicesSummary.selectedInvoices.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one invoice' });
            return;
        }
        if (generatedInvoicesSummary.selectedInvoices.some(value => value.receipts.length > 0)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One or more invoices are already paid' });
            return;
        }
        this.isLoading = true;
        this.accountsService.generateReceiptFromInvoices(generatedInvoicesSummary.selectedInvoices).subscribe(receipt => {
                console.log(receipt);
                this.isLoading = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Receipt# ' + receipt.odooReceiptNumber + ' generated successfully' });
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
               /* this.accountsService.generateInvoicePDF(receipt.owningCompany!.id, receipt.invoiceId!, receipt.odooInvoiceNumber!,
                    receipt.invoiceLines.map(invoiceLine => invoiceLine.headerReferenceNumber!)).subscribe(success => {
                    generatedInvoicesSummary.selectedInvoices.forEach(value => {
                        value.status = 'INVOICED';
                        value.odooInvoiceNumber = receipt.odooInvoiceNumber;
                        value.invoiceCreationDate = receipt.invoiceCreationDate!;
                        value.odooInvoiceId = receipt.odooInvoiceId;
                    })
                    this.isLoading = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invoice generated successfully' });
                }, error => {
                    this.isLoading = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error generating receipt PDF:' + JSON.stringify(error) });
                })*/
            },
            error => {
                console.log(error);
                this.isLoading = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating invoice:' + JSON.stringify(error) });
            });
    }

}
