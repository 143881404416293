<p-toast [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="productForm" (ngSubmit)="saveProduct()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Product' : 'Add Product' }}
            </div>
            <!-- Product ID, Created At, Created By, Updated At, Updated By (Disabled) -->
            <div class="p-fluid grid" *ngIf="editMode">
                <div class="col-12 md:col-3" [hidden]="true">
                    <label for="productId">Product ID</label>
                    <input type="text" pInputText id="productId" formControlName="productID"/>
                </div>
                <div class="col-12 md:col-3" [hidden]="true">
                    <label for="createdAt">Created At</label>
                    <input type="text" pInputText id="createdAt" [value]="productForm.get('createdAt')?.value | date:'yyyy-MM-dd HH:mm:ss'"/>
                </div>
                <div class="col-12 md:col-3" [hidden]="true">
                    <label for="createdBy">Created By</label>
                    <input type="text" pInputText id="createdBy" formControlName="createdBy"/>
                </div>
                <div class="col-12 md:col-3" [hidden]="true">
                    <label for="updatedAt">Updated At</label>
                    <input type="text" pInputText id="updatedAt" [value]="productForm.get('updatedAt')?.value | date:'yyyy-MM-dd HH:mm:ss'"/>
                </div>
                <div class="col-12 md:col-3" [hidden]="true">
                    <label for="updatedBy">Updated By</label>
                    <input type="text" pInputText id="updatedBy" formControlName="updatedBy"/>
                </div>
            </div>

            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="name">Name</label>
                    <input type="text" pInputText id="name" formControlName="name" required/>
                    <small class="p-error" *ngIf="productForm.get('name')?.hasError('required') && productForm.get('name')?.touched">Name is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="productCode">Code</label>
                    <input type="text" pInputText id="productCode" formControlName="productCode" required/>
                    <small class="p-error" *ngIf="productForm.get('productCode')?.hasError('required') && productForm.get('productCode')?.touched">Code is required.</small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="productType">Type</label>
                    <p-dropdown id="productType" [options]="productTypes" formControlName="productType"
                                placeholder="Select a Product Type" optionLabel="label" optionValue="value" [required]="true"></p-dropdown>
                    <small class="p-error" *ngIf="productForm.get('productType')?.hasError('required') && productForm.get('productType')?.touched">Type is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="standardRate">Standard Product Rate</label>
                    <p-inputNumber id="standardRate" formControlName="standardRate"
                                   mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="en-US"/>
                </div>
                <div class="col-12 md:col-6">
                    <label for="taxRate">Tax Rate</label>
                    <p-inputNumber id="taxRate" formControlName="taxRate"
                                   mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="en-US"/>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-12">
                    <label for="description">Description</label>
                    <textarea id="description" rows="3" pInputTextarea formControlName="description" ></textarea>
                </div>
            </div>

            <p-divider/>

            <div formArrayName="productVariants" class="mt-6">
                <div class="text-xl font-semibold mb-4">
                    {{ editMode ? 'Edit Product Variants' : 'Add Product Variants' }}
                </div>
                <div class="mt-2"
                     *ngFor="let variantGroup of getProductVariantControls.controls; let i = index">
                    <div [formGroupName]="i" class="grid p-fluid">
                        <!-- Variant ID, Created At, Created By, Updated At, Updated By (Disabled) -->
                        <div class="col-12 md:col-2" *ngIf="editMode" [hidden]="true">
                            <label for="variantId{{i}}">Variant ID</label>
                            <input type="text" pInputText id="variantId{{i}}" formControlName="variantID"/>
                        </div>
                        <div class="col-12 md:col-2" *ngIf="editMode" [hidden]="true">
                            <label for="variantCreatedAt{{i}}">Created At</label>
                            <input type="text" pInputText id="variantCreatedAt{{i}}" [value]="variantGroup.get('createdAt')?.value | date:'yyyy-MM-dd HH:mm:ss'"/>
                        </div>
                        <div class="col-12 md:col-2" *ngIf="editMode" [hidden]="true">
                            <label for="variantCreatedBy{{i}}">Created By</label>
                            <input type="text" pInputText id="variantCreatedBy{{i}}" formControlName="createdBy"/>
                        </div>
                        <div class="col-12 md:col-2" *ngIf="editMode" [hidden]="true">
                            <label for="variantUpdatedAt{{i}}">Updated At</label>
                            <input type="text" pInputText id="variantUpdatedAt{{i}}" [value]="variantGroup.get('updatedAt')?.value | date:'yyyy-MM-dd HH:mm:ss'"/>
                        </div>
                        <div class="col-12 md:col-2" *ngIf="editMode" [hidden]="true">
                            <label for="variantUpdatedBy{{i}}">Updated By</label>
                            <input type="text" pInputText id="variantUpdatedBy{{i}}" formControlName="updatedBy"/>
                        </div>
                        <div class="col-12 md:col-4">
                            <label for="variantName{{ i }}">Name</label>
                            <input type="text" pInputText id="variantName{{ i }}" formControlName="name"/>
                        </div>
                        <div class="col-12 md:col-4">
                            <label for="variantCode{{ i }}">Code</label>
                            <input type="text" pInputText id="variantCode{{ i }}"
                                   formControlName="variantCode"/>
                        </div>
                        <div class="col-12 md:col-3">
                            <label for="rateAdjustment{{ i }}">Rate Adjustment</label>
                            <p-inputNumber inputId="rateAdjustment{{ i }}" formControlName="rateAdjustment"
                                           mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="en-US"></p-inputNumber>
                        </div>
                        <div class="col-12 md:col-1 flex justify-content-end">
                            <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-text p-button-danger"
                                    (click)="removeProductVariant(i)"></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-12">
                    <button pButton pRipple type="button" label="Add Variant" icon="fa-solid fa-add"
                            (click)="addProductVariant()" class="p-button-rounded p-button-text p-button-info mt-2"></button>
                </div>
            </div>
            <p-divider/>
            <div class="col-12 flex justify-content-end">
                <button pButton pRipple type="button" label="Cancel" (click)="cancel()"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton pRipple type="submit" label="Save" class="p-button-rounded p-button-text p-button-info"></button>
            </div>
        </div>
    </form>
</div>
