import { Routes } from '@angular/router';
import {ContractsComponent} from "./contracts.component";
import {AddEditContractComponent} from "./add-edit-contract/add-edit-contract.component";
import {ViewContractComponent} from "./view-contract/view-contract.component";
import {AddEditRateCardComponent} from "./add-edit-rate-card/add-edit-rate-card.component";
import {AddEditContractRevisionComponent} from "./add-edit-contract-revision/add-edit-contract-revision.component";

export const contractRoutes: Routes = [
    {
        path: 'contracts',
        children: [
            { path: '', component: ContractsComponent, },
            { path: 'create', component: AddEditContractComponent },
            { path: 'edit/:id', component: AddEditContractComponent },
            { path: 'create-revision/contract/:contractId/revision', component: AddEditContractRevisionComponent },
            { path: 'edit-revision/contract/:contractId/revision/:contractRevisionId', component: AddEditContractRevisionComponent },
            { path: 'create-rate-card/contract/:contractId/revision/:contractRevisionId/rate', component: AddEditRateCardComponent },
            { path: 'edit-rate-card/contract/:contractId/revision/:contractRevisionId/rate/:rateId', component: AddEditRateCardComponent },
            { path: 'view/:id', component: ViewContractComponent }
        ]
    },
];
