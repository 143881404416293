import {Component, OnInit, ViewChild, ElementRef, AfterViewChecked} from '@angular/core';
import {ChatMessage} from "./ai-chat.model";
import {MenuItem, MessageService} from "primeng/api";
import {AiChatService} from "./ai-chat.service";
import {SessionStorageService} from "../services/session-storage.service";

@Component({
    selector: 'app-ai-chat',
    templateUrl: './ai-chat.component.html',
    styleUrls: ['./ai-chat.component.css']
})
export class AiChatComponent implements OnInit, AfterViewChecked {

    @ViewChild('chatMessages') private chatMessages: ElementRef;

    messages: ChatMessage[] = [];
    newMessage: string = '';
    isLoading: boolean = false;
    customerId: number;
    companyId: number;


    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(private aiChatService: AiChatService, private messageService: MessageService,
                private sessionStorageService: SessionStorageService) {
    }

    ngOnInit(): void {
        this.customerId = this.sessionStorageService.getCustomer()!.id;
        this.companyId = this.sessionStorageService.getCompany()!.id;
        this.messages = [
            {
                sender: 'bot', content: 'Hello! How can I assist you today?', timestamp: new Date(),
                customerId: this.customerId, companyId: this.companyId
            },
        ];

        this.breadcrumbItems = [{label: 'AI Chat', routerLink: '/ai-chat'}];
        this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};
    }

    sendMessage(): void {
        if (this.newMessage.trim() !== '') {
            let chatMessage: ChatMessage = {
                sender: 'user',
                content: this.newMessage,
                timestamp: new Date(),
                customerId: this.customerId,
                companyId: this.companyId
            };
            this.messages.push(chatMessage);
            this.isLoading = true;

            this.aiChatService.sendMessage(chatMessage).subscribe({
                next: (response) => {
                    console.log(response);
                    this.messages.push(response);
                    this.isLoading = false;
                    this.scrollToBottom();
                    this.newMessage = '';
                },
                error: (error) => {
                    this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to send message.'});
                    console.error(error);
                    this.isLoading = false;
                }
            });
        }
    }

    ngAfterViewChecked(): void {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

}
