<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="salesOrders" dataKey="orderID" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRecords" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[20, 50, 100]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Sales Orders</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Sales Order" icon="fa-solid fa-plus" (onClick)="addSalesOrder()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Order #</th>
            <th>Company</th>
            <th>Customer</th>
            <th>Salesperson</th>
            <th>Order Date</th>
            <th>Expected Date</th>
            <th>Total Amount</th>
            <th>State</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-salesOrder let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="salesOrder"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ salesOrder.orderNumber }}</td>
            <td>{{ salesOrder.company.name }}</td>
            <td>{{ salesOrder.customer.name }}</td>
            <td>{{ salesOrder.salesperson.name }}</td>
            <td>{{ salesOrder.orderDate | date:'yyyy-MM-dd' }}</td>
            <td>{{ salesOrder.expectedDate  | date:'yyyy-MM-dd' }}</td>
            <td>{{ getSalesOrderTotalAmount(salesOrder) }}</td>
            <td>
                <p-badge *ngIf="salesOrder.state === 'draft'" severity="danger" [value]="salesOrder.state.toUpperCase()"></p-badge>
                <p-badge *ngIf="salesOrder.state === 'confirm'" severity="warning" [value]="salesOrder.state.toUpperCase()"></p-badge>
                <p-badge *ngIf="salesOrder.state === 'invoices'" severity="success" [value]="salesOrder.state.toUpperCase()"></p-badge>
            </td>
            <td>
                <button pButton type="button" icon="pi pi-check" (click)="confirmSalesOrder(salesOrder)" pTooltip="Confirm Order"
                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                <button pButton type="button" icon="pi pi-pencil" (click)="editSalesOrder(salesOrder)" pTooltip="Edit Order"
                        class="p-button-rounded p-button-text p-button-info mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-salesOrder>
        <tr>
            <td colspan="10">
                <div class="p-3">
                    <p-table [value]="salesOrder.salesOrderLines" dataKey="lineID" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-10">
                                    <h2>Sales Order Lines</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>S#</th>
                                <th>Product</th>
                                <th>Variant</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total Line Amount</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-line>
                            <tr>
                                <td>{{ line.lineNumber }}</td>
                                <td>{{ line.product?.name }}</td>
                                <td>{{ line.variant?.name }}</td>
                                <td>{{ line.description }}</td>
                                <td>{{ line.quantity }}</td>
                                <td>SAR&nbsp;{{ line.unitPrice }}</td>
                                <td>SAR&nbsp;{{ line.totalLineAmount }}</td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} Sales Orders.
        </div>
    </ng-template>
</p-table>
