<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="invoices" dataKey="invoiceId" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRequests" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[20, 50, 100]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Invoices</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel()" class="p-2" severity="help"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Type</th>
            <th>From</th>
            <th>Invoice#</th>
            <th>Invoice Date</th>
            <th>Amount Excluding VAT</th>
            <th>Tax Amount</th>
            <th>Total Amount</th>
            <th>Status</th>
            <th>Paid With</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice let-expandedHeader="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="invoice"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ invoice.invoiceType }}</td>
            <td>{{ invoice.owningCompany != undefined ? invoice.owningCompany.name : 'CargoMate' }}</td>
            <td>{{ invoice.odooInvoiceNumber }}</td>
            <td>{{ showDate(invoice.invoiceCreationDate) }}</td>
            <td>{{ invoice.country.currencyCode + ' ' + invoice.totalAmountExclTax }}</td>
            <td>{{ invoice.country.currencyCode + ' ' + invoice.totalTax }}</td>
            <td>{{ invoice.country.currencyCode + ' ' + invoice.totalAmount }}</td>
            <td>
                <p-tag *ngIf="showInvoiceStatus(invoice)" icon="fa-solid fa-check" severity="success" value="PAID"></p-tag>
                <p-tag *ngIf="!showInvoiceStatus(invoice)" icon="fa-solid fa-close" severity="danger" value="UNPAID"></p-tag>
            </td>
            <td>{{ showAllReceiptNumberWithComma(invoice.receipts) }}</td>
            <td>
        <span class="p-buttonset">
            <p-button pTooltip="Download Invoice" icon="pi pi-download" (onClick)="downloadInvoice(invoice)" class="p-1" severity="info"></p-button>
        </span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-invoice>
        <tr>
            <td colspan="12">
                <div class="p-3">
                    <p-table [value]="invoice.invoiceLines" dataKey="invoiceLineId" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-10">
                                    <h2>Invoice Lines</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>S#</th>
                                <th>Order#</th>
                                <th>Delivery#</th>
                                <th>Service Type</th>
                                <th>Charge Amount</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-invoiceLine>
                            <tr>
                                <td>{{ invoiceLine.invoiceLineId }}</td>
                                <td>{{ invoiceLine.headerReferenceNumber }}</td>
                                <td>{{ invoiceLine.detailReferenceNumber }}</td>
                                <td>{{ invoiceLine.itemNumber }}</td>
                                <td>{{ invoice.country.currencyCode + ' ' + invoiceLine.chargeAmount }}</td>
                                <td>
                  <span class="p-buttonset">
                      <p-button pTooltip="Show Order Details" icon="pi pi-eye" (onClick)="showOrderDetails(invoiceLine)" class="p-1" severity="help"></p-button>
                  </span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRequests}} Invoices.
        </div>
    </ng-template>
</p-table>
