import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {PaymentTerm} from "./models/payment-term.model";
import {SalesOrder} from "./sales-order/sales-order.model";
import {Products} from "./models/product.model";
import {APIListWrapper} from "../models/common/api-list-wrapper.model";
import {environment} from "../../environments/environment";
import {PurchaseOrder} from "./purchase-order/purchase-order.model";


@Injectable({
    providedIn: 'root'
})
export class ProductsService {

    private baseUrl = `${environment.restApiUrl}orders`; // Your base URL for the SalesOrdersEndpoint

    constructor(private http: HttpClient) { }

    /**
     * Retrieves all products.
     * @returns An Observable of a list of Products objects.
     */
    getAllProductsByOwningCompany(owningCompanyId: number): Observable<Products[]> {
        const url = `${this.baseUrl}/products`;
        let params = new HttpParams()
            .set('owningCompanyId', owningCompanyId.toString());
        return this.http.get<Products[]>(url, { params });
    }

    /**
     * Retrieves a product by its ID.
     * @param id The ID of the product.
     * @returns An Observable of a Products object.
     */
    getProductById(id: number): Observable<Products> {
        const url = `${this.baseUrl}/products/${id}`;
        return this.http.get<Products>(url);
    }

    /**
     * Creates a new product.
     * @param product The Products object to create.
     * @returns An Observable of the created Products object.
     */
    createProduct(product: Products): Observable<Products> {
        const url = `${this.baseUrl}/products`;
        return this.http.post<Products>(url, product);
    }

    /**
     * Deletes a product by its ID.
     * @param id The ID of the product to delete.
     * @returns An Observable of void.
     */
    deleteProduct(id: number): Observable<void> {
        const url = `${this.baseUrl}/products/${id}`;
        return this.http.delete<void>(url);
    }

    /**
     * Retrieves all payment terms.
     * @returns An Observable of a list of PaymentTerm objects.
     */
    getAllPaymentTerms(): Observable<PaymentTerm[]> {
        const url = `${this.baseUrl}/payment-terms`;
        return this.http.get<PaymentTerm[]>(url);
    }

    /**
     * Retrieves a payment term by its ID.
     * @param id The ID of the payment term.
     * @returns An Observable of a PaymentTerm object.
     */
    getPaymentTermById(id: number): Observable<PaymentTerm> {
        const url = `${this.baseUrl}/payment-terms/${id}`;
        return this.http.get<PaymentTerm>(url);
    }
}
