import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {PaymentTerm} from "./models/payment-term.model";
import {SalesOrder} from "./sales-order/sales-order.model";
import {Products} from "./models/product.model";
import {APIListWrapper} from "../models/common/api-list-wrapper.model";
import {environment} from "../../environments/environment";
import {PurchaseOrder} from "./purchase-order/purchase-order.model";


@Injectable({
    providedIn: 'root'
})
export class OrdersService {

    private baseUrl = `${environment.restApiUrl}orders`; // Your base URL for the SalesOrdersEndpoint

    constructor(private http: HttpClient) { }

    /**
     * Retrieves all sales orders.
     * @param owningCompanyId The ID of the owning company.
     * @param page The page number.
     * @param pageSize The number of items per page.
     * @returns An Observable of APIListWrapper<SalesOrder> object.
     */
    getAllSalesOrdersByOwningCompany(owningCompanyId: number, page: number, pageSize: number): Observable<APIListWrapper<SalesOrder>> {
        const url = `${this.baseUrl}/sales-orders`;
        let params = new HttpParams()
            .set('owningCompanyId', owningCompanyId.toString())
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        return this.http.get<APIListWrapper<SalesOrder>>(url, { params });
    }

    /**
     * Retrieves a sales order by its ID.
     * @param id The ID of the sales order.
     * @returns An Observable of a SalesOrder object.
     */
    getSalesOrderById(id: number): Observable<SalesOrder> {
        const url = `${this.baseUrl}/sales-orders/${id}`;
        return this.http.get<SalesOrder>(url);
    }

    /**
     * Creates a new sales order.
     * @param salesOrder The SalesOrder object to create.
     * @returns An Observable of the created SalesOrder object.
     */
    createSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
        const url = `${this.baseUrl}/sales-orders`;
        return this.http.post<SalesOrder>(url, salesOrder);
    }

    /**
     * Confirms a sales order.
     * @param salesOrder The SalesOrder object to create.
     * @returns An Observable of the created SalesOrder object.
     */
    confirmSalesOrder(salesOrder: SalesOrder): Observable<Boolean> {
        const url = `${this.baseUrl}/sales-orders/confirm`;
        return this.http.patch<Boolean>(url, salesOrder);
    }

    /**
     * Deletes a sales order by its ID.
     * @param id The ID of the sales order to delete.
     * @returns An Observable of void.
     */
    deleteSalesOrder(id: number): Observable<void> {
        const url = `${this.baseUrl}/sales-orders/${id}`;
        return this.http.delete<void>(url);
    }

    /**
     * Retrieves all purchase orders.
     * @param owningCompanyId The ID of the owning company.
     * @param page The page number.
     * @param pageSize The number of items per page.
     * @returns An Observable of APIListWrapper<PurchaseOrder> object.
     */

    getAllPurchaseOrders(
        owningCompanyId: number,
        page: number,
        pageSize: number
    ): Observable<any> {
        const url = `${this.baseUrl}/purchase-orders`;
        const params = new HttpParams()
            .set('owningCompanyId', owningCompanyId.toString())
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
        return this.http.get<APIListWrapper<PurchaseOrder>>(url, { params });
    }

    /**
     * Retrieves a purchase order by its ID.
     * @param id The ID of the purchase order.
     * @returns An Observable of a PurchaseOrder object.
     */
    getPurchaseOrderById(id: number): Observable<PurchaseOrder> {
        const url = `${this.baseUrl}/purchase-orders/${id}`;
        return this.http.get<PurchaseOrder>(url);
    }

    /**
     * Creates a new purchase order.
     * @param purchaseOrder The PurchaseOrder object to create.
     * @returns An Observable of the created PurchaseOrder object.
     */
    createPurchaseOrder(purchaseOrder: PurchaseOrder): Observable<PurchaseOrder> {
        const url = `${this.baseUrl}/purchase-orders`;
        return this.http.post<PurchaseOrder>(url, purchaseOrder);
    }

    /**
     * Confirms a purchase order.
     * @param purchaseOrder The PurchaseOrder object to create.
     * @returns An Observable of the created PurchaseOrder object.
     */
    confirmPurchaseOrder(purchaseOrder: PurchaseOrder): Observable<Boolean> {
        const url = `${this.baseUrl}/purchase-orders/confirm`;
        return this.http.patch<Boolean>(url, purchaseOrder);
    }

    /**
     * Deletes a purchase order by its ID.
     * @param id The ID of the purchase order to delete.
     * @returns An Observable of void.
     */
    deletePurchaseOrder(id: number): Observable<void> {
        const url = `${this.baseUrl}/purchase-orders`;
        return this.http.delete<void>(`${url}/${id}`);
    }

    /**
     * Retrieves all payment terms.
     * @returns An Observable of a list of PaymentTerm objects.
     */
    getAllPaymentTerms(): Observable<PaymentTerm[]> {
        const url = `${this.baseUrl}/payment-terms`;
        return this.http.get<PaymentTerm[]>(url);
    }

    /**
     * Retrieves a payment term by its ID.
     * @param id The ID of the payment term.
     * @returns An Observable of a PaymentTerm object.
     */
    getPaymentTermById(id: number): Observable<PaymentTerm> {
        const url = `${this.baseUrl}/payment-terms/${id}`;
        return this.http.get<PaymentTerm>(url);
    }
}
