import { Component, OnInit } from '@angular/core';
import { DriverService } from './driver.service';
import { Driver } from './driver.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import {TableLazyLoadEvent} from "primeng/table";
import {Vehicle} from "../vehicle/vehicle.model";

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
    styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {

    isLoading: boolean = false;
    currentTab = '';
    page = 0;
    pageSize = 10;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    ownedDrivers: Driver[];
    totalOwnedRecords: number;
    ownedFirst = 0;
    ownedRows = 10;
    freelancerDrivers: Driver[];
    freelancerFirst = 0;
    totalFreelancerRecords: number;
    freelancerRows = 10;

    showAddFreelancerDriverDialog: boolean = false;
    driverPhoneNumber: string = '';
    searchDriverInProgress: boolean = false;

    constructor(
        private driverService: DriverService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' }, { label: 'Drivers' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    editDriver(driver: Driver) {
        this.router.navigate(['/fleet/driver/edit', driver.id]);
    }

    deleteDriver(driver: Driver) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this driver?',
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.driverService.deleteDriver(driver).subscribe(response => {
                    if (response.success) {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DriverModel deleted successfully.' });
                        if (this.currentTab === 'owned')
                            this.loadOwnedDrivers();
                        else this.loadFreelancerDrivers();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete driver.' });
                    }
                });
            }
        });
    }

    loadOwnedData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.ownedFirst = event.first ?? 0;
        this.ownedRows = event.rows ?? 10;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 10;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadOwnedDrivers();
    }

    loadOwnedDrivers() {
        const transportationAgencyId = this.sessionStorageService.getCompany()?.transportationAgencyId;
        if (transportationAgencyId) {
            this.driverService.findDriversByTransportationAgencyId(this.page, this.pageSize, transportationAgencyId)
                .subscribe(response => {
                    console.log(response);
                    this.ownedDrivers = response.items;
                    this.totalOwnedRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Transportation Agency ID not found.' });
        }
    }

    loadFreelancerData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.freelancerFirst = event.first ?? 0;
        this.freelancerRows = event.rows ?? 10;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 10;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadFreelancerDrivers();
    }

    loadFreelancerDrivers() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.driverService.getFreelanceDriversByCompanyId(this.page, this.pageSize, companyId)
                .subscribe(response => {
                    console.log(response);
                    this.freelancerDrivers = response.items;
                    this.totalFreelancerRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Company ID not found.' });
        }
    }

    tabChange($event: Event) {
        this.currentTab = ($event as CustomEvent).detail.index === 0 ? 'owned' : 'freelancer';
    }

    addFreelancerDriver() {
        this.showAddFreelancerDriverDialog = true;
    }

    searchDriver() {
        this.searchDriverInProgress = true;
        this.driverService.findDriverByFilter(undefined, undefined, undefined, this.driverPhoneNumber, false, 'en-US')
            .subscribe(driverWrapped => {
                console.log(driverWrapped);
                if (driverWrapped.success && driverWrapped.data) {
                    // Driver found, call addFreelanceDriver
                    this.addFreelanceDriver(driverWrapped.data);
                } else {
                    // Driver not found, redirect to edit-driver
                    this.searchDriverInProgress = false;
                    this.router.navigate(['/fleet/driver/create'], {
                        queryParams: {
                            phoneNumber: this.driverPhoneNumber,
                            isFreelancer: true
                        }
                    });
                }
            });
    }

    addFreelanceDriver(driver: Driver) {
        // Call your driverService to add the driver as a freelancer
        this.driverService.addFreelanceDriver(driver, this.sessionStorageService.getCompany()!.id) // Assuming you have an API for this
            .then(promiseResponse => {
                promiseResponse.subscribe(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Driver added as freelancer successfully.'
                    });
                    this.showAddFreelancerDriverDialog = false;
                    this.searchDriverInProgress = false;
                    this.loadFreelancerDrivers(); // Refresh the freelancer driver list
                }, error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to add driver as freelancer.'
                    });
                });
            });
    }

    addOwnDriver() {
        this.router.navigate(['/fleet/driver/create']);
    }
}
