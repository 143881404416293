import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {VehicleService} from "./vehicle/vehicle.service";
import {FreightForwarder} from "../company/freight-forwarder/freight-forwarder.model";
import {TransportationAgency} from "../company/transportation-agency/transportation-agency.model";
import {SessionStorageService} from "../services/session-storage.service";
import {ReportsService} from "../services/reports.service";
import {VehicleMin} from "./vehicle/vehicle-min.model";
import {forkJoin} from "rxjs";
import {TraccarFleetService} from "./traccar/traccar-fleet.service";
import {TraccarDevice} from "./traccar/traccar-device.model";

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.css']
})
export class FleetComponent implements OnInit {

    isLoading: boolean = false;
    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;
    companyId: number;
    vehicleList: VehicleMin[] = [];
    gpsDeviceList: TraccarDevice[] = [];

    currentTransportationAgency: TransportationAgency | null;
    currentFreightForwarder: FreightForwarder | null;

    zoom = 12
    vehicleMarkerImage = "https://storage.googleapis.com/prologix.appspot.com/basic_data/admin_files/TractorUnitBlack.png"
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        mapTypeId: 'roadmap',
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8
    }


    constructor(protected sessionStorageService: SessionStorageService,
                private vehicleService: VehicleService, private traccarFleetService: TraccarFleetService,
                private reportsService: ReportsService) {
        this.currentTransportationAgency = this.sessionStorageService.getTransportationAgency();
        this.currentFreightForwarder = this.sessionStorageService.getFreightForwarder();
        this.companyId = this.sessionStorageService.getCompany()!!.id;
    }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };

        this.getAllVehicles();
        navigator.geolocation.getCurrentPosition((position) => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }
            console.log(this.center);
        })
    }

    getAllVehicles() {
        this.isLoading = true;
        forkJoin([
            this.vehicleService.findVehicleMinsByCompanyId(this.companyId),
            this.vehicleService.getFreelanceVehicleMinsByCompanyId(this.companyId)
        ]).subscribe({
            next: ([vehicles, freelanceVehicles]) => {
                this.vehicleList.push(...vehicles.items, ...freelanceVehicles.items);
                this.vehicleList.forEach(vehicle => {
                    console.log(vehicle.plateNumber);
                    vehicle.currentLat = this.center.lat;
                    vehicle.currentLng = this.center.lng;
                    this.traccarFleetService.getGpsDevicesByName(vehicle.plateNumber).subscribe(gpsDevice => {
                        console.log(gpsDevice);
                        if (!this.gpsDeviceList.find(value => value.id == gpsDevice[0].id)) {
                            this.gpsDeviceList.push(...gpsDevice);
                            this.traccarFleetService.getGpsPositionByDeviceId(gpsDevice[0]?.id ?? 0).subscribe(gpsPosition => {
                                console.log(gpsPosition);
                                if (gpsPosition != null) {
                                    vehicle.currentLat = gpsPosition.latitude;
                                    vehicle.currentLng = gpsPosition.longitude;
                                    vehicle.motion = gpsPosition.attributes?.motion;
                                    vehicle.distance = gpsPosition.attributes?.distance;
                                    vehicle.speed = gpsPosition.speed;
                                }
                            });
                        }
                    })
                });
                this.isLoading = false;
            },
            error: error => {
                console.error("Error fetching vehicles:", error);
                this.isLoading = false;
            }
        });
    }

    showDate(longDate: number) {
        return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
    }

    openInfo(vehicle: VehicleMin) {
        throw new Error('Method not implemented.');
    }

    getVehiclesCountInMotion() {
        return this.vehicleList.filter(vehicle => vehicle.motion).length;
    }

    getTotalDistanceCovered() {
        return parseFloat((this.vehicleList.map(vehicle => (vehicle.distance ?? 0))
            .reduce((a, b) => a  + b, 0))
            .toFixed(2)) + ' km';
    }

    getTotalFuelConsumed() {
        return (parseFloat(this.getTotalDistanceCovered().replace(' km','')) * (1/11) * 1.66).toFixed(2) + ' SAR';
    }

    getVehiclesCountInStatic() {
        return this.vehicleList.filter(vehicle => !vehicle.motion).length;
    }

    getMaxVehicleSpeed() {
        return parseFloat(String(this.vehicleList.sort((a, b) => (b.speed ?? 0) - (a.speed ?? 0))
            [0]?.speed ?? 0)).toFixed(0) + ' km/h';
    }
}
