import { TripType } from "../../models/vehicle/trip-type";
import { Driver } from "../driver/driver.model";
import { VehicleType } from "./params/vehicle-type";
import { VehicleConfig } from "./params/vehicle-config";
import { VehicleCapacity } from "./params/vehicle-capacity";
import { VehicleYear } from "./params/vehicle-year";
import { VehicleMake } from "./params/vehicle-make";
import { PayloadType } from "../../models/order-params/payload-type";
import { VehicleModel } from "./params/vehicle-model";

/* eslint-disable */
export class Vehicle {

    vehicleId: number;
    plateNumber: string
    registrationNumber: string;
    engineNumber: string;
    make: VehicleMake;
    model: VehicleModel;
    year: VehicleYear;
    type: VehicleType;
    capacity: VehicleCapacity;
    config: VehicleConfig;
    registrationExpiry: number;
    registrationImage: string;
    vehicleImages?: string[];
    isActive: boolean;
    validated: boolean;
    rating: number;
    vehicleStatus: string;
    tripTypes: TripType[];
    payloadTypes: PayloadType[];
    isInsured: boolean;
    insuranceAmount: number;
    insuranceCompany: any;
    insuranceExpiry: Date;
    insurancePolicyNo: string;
    currentDriver: Driver;
    companyId: number

}
