/* eslint-disable */
import { BaseProfile } from "../../models/common/base-profile";
import { Country } from "../../models/common/country";
import { LatLng } from "../../models/common/lat-lng";
import { TransportationAgency } from "../../company/transportation-agency/transportation-agency.model";
import { Vehicle } from "../vehicle/vehicle.model";

export class Driver extends BaseProfile {
    legalName: string;
    fixedRate?: any;
    driverStatus: string;
    tripCount?: any;
    isIDVerified?: any;
    validated?: any;
    membershipDate?: any;
    preferredLocation: LatLng;
    preferredAddress: string;
    licenseNumber: string;
    residenceNumber: string;
    licenseExpiryDate: number;
    residenceExpiryDate: number;
    licenseImage: string;
    residenceImage: string;
    motLicenseNumber?: any;
    motLicenseImage?: any;
    legalAgreementAccepted: boolean;
    nationality: Country;
    residingCountry: Country;
    currentVehicle?: Vehicle;
    currentTransportationAgency?: TransportationAgency;
    subLocality?: any;
    agencyCrNumber?: any;
}
