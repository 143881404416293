import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIResponseWrapper } from "../../models/common/api-response-wrapper";
import { TransportationAgency } from "./transportation-agency.model";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TransportationAgencyService {

    private baseUrl = `${environment.restApiUrl}transportation-agency-endpoint`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    findTransportationAgencyByFilter(
        phoneNumber?: string,
        domainEmailAccount?: string,
        agencyId?: number,
        crNumber?: number,
        motLicenseNumber?: string
    ) {
        const params = {
            phoneNumber: phoneNumber || '',
            domainEmailAccount: domainEmailAccount || '',
            agencyId: agencyId ? agencyId.toString() : '', // Convert number to string
            crNumber: crNumber ? crNumber.toString() : '',
            motLicenseNumber: motLicenseNumber || ''
        };
        return this.http.get<APIResponseWrapper<TransportationAgency>>(`${this.baseUrl}/transportation-agency`, { params });
    }

    addTransportationAgency(transportationAgency: TransportationAgency) {
        return this.http.post<APIResponseWrapper<TransportationAgency>>(`${this.baseUrl}/transportation-agency`, transportationAgency);
    }

    updateTransportationAgency(transportationAgency: TransportationAgency)  {
        return this.http.put<APIResponseWrapper<TransportationAgency>>(`${this.baseUrl}/transportation-agency`, transportationAgency);
    }

    deleteTransportationAgency(transportationAgency: TransportationAgency): Observable<any> { // Adjust return type if needed
        return this.http.delete(`${this.baseUrl}/transportation-agency`, { body: transportationAgency });
    }

    findAllAgenciesByCountry(countryId: number): Observable<TransportationAgency[]> {
        const params = { countryId: countryId.toString() };
        return this.http.get<TransportationAgency[]>(`${this.baseUrl}/agency-by-country`, { params });
    }
}
