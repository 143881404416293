/* eslint-disable */
import { Country } from "../common/country";
import { GeoAddress } from "../common/geo-address.model";
import { LatLng } from "../common/lat-lng";
import { Customer } from "../../customer/customer.model";
import { Location } from "../../location/location.model";
import { PayloadItemView } from "../order-params/payload-item-view";
import { VehicleParaPackView } from "../../fleet/vehicle/params/vehicle-para-pack-view";
import { ChargeLine } from "./charge-line";
import { JobAttachment } from "./job-attachment";
import { NominationPack } from "./nomination-pack";
import { Waypoint } from "./waypoint";
import {Company} from "../../company/company.model";

export class OrderQueryView {
    queryId: string;
    customer: Customer;
    company: Company;
    owningCompany: Company;
    owningCompanyContact: Customer;
    requestedBookingOption: string;
    requestedBookingTime: number;
    nationalityPref: Country;
    countryOfOrder: Country | undefined;
    pickupAddress: GeoAddress;
    dropoffAddress: GeoAddress;
    orderType: string;
    searchAddress: GeoAddress;
    pickupLocation: LatLng;
    dropoffLocation: LatLng;
    searchLocation: LatLng;
    sourceLocation: Location;
    destinationLocation: Location;
    waypoints: Waypoint[] = [];

    distance: number;
    time: number;
    tripType: any;
    totalJobWorth: number;
    totalFareQuote: number;
    selectedPaymentMethod: any;
    vehicleParaPack: VehicleParaPackView = new VehicleParaPackView();
    insuredNeeded: boolean;
    fixedRate: boolean;
    customerVehicleSelect: boolean;
    singleDriverRepeat: boolean;
    maxDeliveryIndex: number;
    payloadItemList: PayloadItemView[] = [];
    nominationPack: NominationPack;
    deliveries: any;
    biddings: any;
    jobs: any;
    deliveryStatusMap: any;
    filesList: JobAttachment[];

    /* ----   Enterprise Fields   ---- */
    creationDate: number;
    quantityRequired: number;
    pickupCity: string;
    dropoffCity: string;
    orderCity: string;
    pickupCountry: Country;
    dropoffCountry: Country;
    sellingPrice: number;
    buyingPrice: number;
    orderStatus: string;
    chargeLines: ChargeLine[] = [];
}
