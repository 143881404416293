import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractsService } from '../contracts.service';
import { DeliveryRateCard } from '../models/delivery-rate-card.model';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {VehicleConfig} from "../../fleet/vehicle/params/vehicle-config";
import {SessionStorageService} from "../../services/session-storage.service";
import {VehicleCapacity} from "../../fleet/vehicle/params/vehicle-capacity";
import {VehicleType} from "../../fleet/vehicle/params/vehicle-type";
import {Contracts} from "../models/contracts.model";

@Component({
    selector: 'app-add-edit-rate-card',
    templateUrl: './add-edit-rate-card.component.html',
    styleUrls: ['./add-edit-rate-card.component.css']
})
export class AddEditRateCardComponent implements OnInit {

    contract: Contracts;
    contractId: number | null;
    contractRevisionId: number | null;
    createEditRateCardForm!: FormGroup;
    createEditRateCard: DeliveryRateCard = new DeliveryRateCard();
    rateCardCreationInProgress = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    vehicleTypes: VehicleType[] = [];
    vehicleConfigs: VehicleConfig[] = [];
    vehicleCapacities: VehicleCapacity[] = [];
    filteredVehicleConfigs: VehicleConfig[] = [];
    filteredVehicleCapacities: VehicleCapacity[] = [];

    countries: string[] = ['SA', 'AE', 'BH', 'QR', 'KW', 'OM'];
    cities: string[] = [
        // Saudi Arabia
        'Riyadh', 'Jeddah', 'Mecca', 'Medina', 'Dammam', 'Taif', 'Khobar', 'Abha', 'Tabuk', 'Buraydah',
        'Dhahran', 'Al-Ahsa', 'Jubail', 'Khamis Mushait', 'Najran', 'Hail', 'Qatif', 'Yanbu', 'Jazan', 'Arar',

        // UAE
        'Dubai', 'Abu Dhabi', 'Sharjah', 'Ajman', 'Umm Al Quwain', 'Ras Al Khaimah', 'Fujairah',

        // Oman
        'Muscat', 'Salalah', 'Sohar', 'Nizwa', 'Sur', 'Rustaq', 'Ibri', 'Barka',

        // Qatar
        'Doha', 'Al Wakrah', 'Al Khor', 'Al Rayyan', 'Dukhan', 'Mesaieed', 'Umm Salal',

        // Bahrain
        'Manama', 'Riffa', 'Muharraq', 'Hamad Town', 'Isa Town', 'Sitra', 'Jidhafs',

        // Kuwait
        'Kuwait City', 'Hawalli', 'Salmiya', 'Farwaniya', 'Jahra', 'Ahmadi', 'Fahaheel'
    ];

    constructor(
        private fb: FormBuilder,
        private contractsService: ContractsService,
        private route: ActivatedRoute,
        private location: Location,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        const vehicleTypes = this.sessionStorageService.getVehicleTypes();
        if (vehicleTypes) {
            this.vehicleTypes = vehicleTypes;
            this.vehicleConfigs = vehicleTypes.flatMap(value => value.vehicleConfigs);
            this.vehicleCapacities = vehicleTypes.flatMap(value => value.vehicleCapacities);
        }

        // Load existing rate card for editing
        const rateCardId = this.route.snapshot.paramMap.get('rateId');
        if (rateCardId) {
            this.loadRateCard(Number(rateCardId));
        }

        // Load existing rate card for editing
        this.contractId = Number(this.route.snapshot.paramMap.get('contractId') ?? 0);
        this.contractRevisionId = Number(this.route.snapshot.paramMap.get('contractRevisionId') ?? 0);

        this.contractsService.getContractById(this.contractId).subscribe(contract => {
            console.log(contract);
            this.contract = contract;
        });


        this.breadcrumbItems = [
            { label: 'Rate Cards', routerLink: '/contracts' }, // Adjust routerLink as needed
            { label: rateCardId ? 'Edit Rate Card' : 'Add Rate Card' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.createEditRateCardForm = this.fb.group({
            vehicleTypeDropdown: [''],
            vehicleConfigDropdown: [''],
            vehicleCapacityDropdown: [''],
            sourceCityInput: [''],
            sourceCountryInput: [''],
            destinationCityInput: [''],
            destinationCountryInput: [''],
            distanceInput: [''],
            amountInput: [''],
            goodsInsuranceInput: [''],
            portPermitInput: [''],
            waitingThresholdInput: [''],
            waitingChargeInput: [''],
            waitingLimitInput: ['']
        });
    }

    loadRateCard(rateCardId: number) {
        this.contractsService.getDeliveryRateCardByRateId(rateCardId).subscribe(rateCard => {
            this.createEditRateCard = rateCard;
            this.updateFormValues();
        });
    }

    updateFormValues() {
        this.createEditRateCardForm.patchValue({
            vehicleTypeDropdown: this.vehicleTypes.find(type => type.id === this.createEditRateCard.vehicleTypeId),
            vehicleConfigDropdown: this.vehicleConfigs.find(config => config.configId === this.createEditRateCard.vehicleConfigId),
            vehicleCapacityDropdown: this.vehicleCapacities.find(capacity => capacity.capacityId === this.createEditRateCard.vehicleCapacityId),
            sourceCityInput: this.createEditRateCard.sourceCity,
            sourceCountryInput: this.createEditRateCard.sourceCountry,
            destinationCityInput: this.createEditRateCard.destinationCity,
            destinationCountryInput: this.createEditRateCard.destinationCountry,
            distanceInput: this.createEditRateCard.distance,
            amountInput: this.createEditRateCard.amount,
            goodsInsuranceInput: this.createEditRateCard.goodsInsurance,
            portPermitInput: this.createEditRateCard.portPermit,
            waitingThresholdInput: this.createEditRateCard.waitingThreshold,
            waitingChargeInput: this.createEditRateCard.waitingCharge,
        });
    }

    updateRateCardObject() {
        this.createEditRateCard = {
            ...this.createEditRateCard, // Keep existing properties
            vehicleTypeId: this.createEditRateCardForm.get('vehicleTypeDropdown')?.value.id,
            vehicleConfigId: this.createEditRateCardForm.get('vehicleConfigDropdown')?.value.configId,
            vehicleCapacityId: this.createEditRateCardForm.get('vehicleCapacityDropdown')?.value.capacityId,
            sourceCity: this.createEditRateCardForm.get('sourceCityInput')?.value,
            sourceCountry: this.createEditRateCardForm.get('sourceCountryInput')?.value,
            destinationCity: this.createEditRateCardForm.get('destinationCityInput')?.value,
            destinationCountry: this.createEditRateCardForm.get('destinationCountryInput')?.value,
            distance: this.createEditRateCardForm.get('distanceInput')?.value,
            amount: this.createEditRateCardForm.get('amountInput')?.value,
            goodsInsurance: this.createEditRateCardForm.get('goodsInsuranceInput')?.value,
            portPermit: this.createEditRateCardForm.get('portPermitInput')?.value,
            waitingThreshold: this.createEditRateCardForm.get('waitingThresholdInput')?.value,
            waitingCharge: this.createEditRateCardForm.get('waitingChargeInput')?.value,
            contractRevisionId: this.contractRevisionId!,
            buyingCompanyId: this.contract.secondParty.id,
            sellingCompanyId: this.contract.firstParty.id
        };
    }

    createUpdateRateCard() {
        this.rateCardCreationInProgress = true;
        this.updateRateCardObject();
        const rateCardData: DeliveryRateCard = this.createEditRateCard;
        console.log(rateCardData);

        // Create new rate card
        this.contractsService.createDeliveryRateCardsForRevision(this.contractId!, this.contractRevisionId!, [rateCardData])
            .subscribe(() => {
                this.rateCardCreationInProgress = false;
                // Handle success, e.g., show a message, navigate back
                //this.location.back();
            });
    }

    onVehicleTypeChange() {
        const selectedVehicleType: VehicleType = this.createEditRateCardForm.get('vehicleTypeDropdown')?.value;
        console.log(selectedVehicleType);
        if (selectedVehicleType) {
            this.filteredVehicleConfigs = this.vehicleConfigs.filter(config => config.typeId === selectedVehicleType.id);
            this.filteredVehicleCapacities = this.vehicleCapacities.filter(capacity => capacity.typeId === selectedVehicleType.id);
        } else {
            this.filteredVehicleConfigs = [];
            this.filteredVehicleCapacities = [];
        }
    }
}
