import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChartModule} from "primeng/chart";

import {ReportsService} from "../services/reports.service";
import {DashboardComponent} from "./dashboard.component";
import {CalendarModule} from "primeng/calendar";




@NgModule({
    imports: [
        RouterModule,
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        ProgressSpinnerModule,
        ChartModule,
        CalendarModule
    ],
    providers: [
        ReportsService,
    ],
    declarations: [
      DashboardComponent
    ],
})
export class DashboardModule { }
