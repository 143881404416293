/* eslint-disable */
export class BaseProfile {
    id: number;
    firebaseId: string;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    imageUrl: string;
    dateOfBirth: Date;
    gender: boolean;
    totalRating: number;
    isPhoneVerified: boolean;
}
