import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from "@angular/router";
import { SessionStorageService } from "../../services/session-storage.service";
import { AccountsService } from "../accounts.service";
import {CreditNote} from "./credit-note.model";

@Component({
    selector: 'app-credit-note',
    templateUrl: './credit-note.component.html',
    styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent implements OnInit {
    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    creditNotes: CreditNote[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;

    constructor(
        private creditNoteService: AccountsService,
        private messageService: MessageService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Credit Note' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadData();
    }

    loadData(event?: any) {
        this.isLoading = true;
        this.first = event?.first ?? 0;
        this.rows = event?.rows ?? 20;
        let page = event?.first ?? 0;
        page = page / 20;
        let pageSize = event?.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.creditNoteService.getAllCreditNotes(this.sessionStorageService.getCompany()?.id ?? 0, page, pageSize).subscribe({
            next: (data) => {
                this.creditNotes = data.items;
                this.totalRecords = data.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading credit notes'
                });
                this.isLoading = false;
            }
        });
    }

    addCreditNote() {
        this.router.navigate(['/accounts/enterprise/payables/credit-note/add']);
    }

    editCreditNote(creditNote: CreditNote) {
        this.router.navigate(['/accounts/enterprise/payables/credit-note/edit', creditNote.creditNoteID]);
    }

    showDate(date: Date | undefined): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString();
    }

    getCreditNoteTotalAmount(creditNote: CreditNote) {
        return 'SAR ' + creditNote.creditNoteLines!.reduce((total, line) => total + line.amount!, 0);
    }
}
