import { OrderDeliveryView } from "./order-delivery";
import { OrderQueryView } from "./order-query-view";

export class DeliveryNoteRequest {

    orderQuery: OrderQueryView;
    orderDelivery: OrderDeliveryView;

    constructor(orderQuery: OrderQueryView, orderDelivery: OrderDeliveryView) {
        this.orderQuery = orderQuery;
        this.orderDelivery = orderDelivery;
    }
}