/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { OrderQueryView } from "../models/order-view/order-query-view";
import { SessionStorageService } from '../services/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import {Driver} from "../fleet/driver/driver.model";
import {OrderJobView} from "../models/order-view/order-job";

@Injectable()
export class ShipmentJobService {

    transactionApiUrl: string;

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.transactionApiUrl = `${environment.reactiveApiUrl}`;
    }

    createJobsFromDriverAndVehicle(orderId: string, drivers: Driver[], cultureCode: string) {
        return this.http.post<APIResponseWrapper<OrderJobView>>(`${this.transactionApiUrl}jobs/order/${orderId}/jobs`
            + "?cultureCode=" + cultureCode, JSON.stringify(drivers), {
            headers: {
                'Content-type': 'application/json'
            }
        });
    }

    graduateJobToDelivery(orderId: string, jobId: string, shipmentJob: OrderJobView) {
        return this.http.patch<APIResponseWrapper<void>>(`${this.transactionApiUrl}jobs/order/${orderId}/delivery/${jobId}`,
            JSON.stringify(shipmentJob), {
            headers: {
                'Content-type': 'application/json'
            }
        });
    }

    getJobByOrderAndJobNumber(queryId: string, jobId: string) {
        return this.http.get<OrderQueryView>(`${this.transactionApiUrl}jobs/order/${queryId}/job/${jobId}`
        + "?isDriverAndVehicleNeeded=" + "true"
        + "&isPayloadItemsNeeded=" + "true"
        + "&isnNominationsNeeded=" + "true"
        + "&isFilesNeeded=" + "true"
        + "&cultureCode=" + "en-US");
    }

}
