import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {map, Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {TraccarDevice} from "./traccar-device.model";
import {TraccarPosition} from "./traccar-position.model";

@Injectable({
    providedIn: 'root'
})
export class TraccarFleetService {

    private baseUrl = `${environment.traccarFleetApiUrl}`;

    constructor(private http: HttpClient, private firestore: AngularFirestore) { }

    getGpsDevicesByName(name: string): Observable<TraccarDevice[]> {
        return this.firestore.collection<TraccarDevice>('gpsDevice',
                ref => ref.where('name', '==', name))
            .snapshotChanges().pipe(
                map(actions =>
                    actions.map(a => {
                        const data = a.payload.doc.data() as TraccarDevice;
                        const id = a.payload.doc.id;
                        return data;
                    })
                )
            );
    }

    getGpsPositionByDeviceId(deviceId: number): Observable<TraccarPosition> {
        return this.firestore.collection<TraccarPosition>('gpsPosition').doc(deviceId.toString())
            .snapshotChanges().pipe(map(a => {
                        const data = a.payload.data() as TraccarPosition;
                        const id = a.payload.id;
                        return data;
                    }
                )
            );
    }

    findPositionsByDeviceId(deviceId: number, from: Date, to: Date): Observable<TraccarPosition[]> {
        const params = new HttpParams()
            .set('deviceId', deviceId.toString())
            .set('from', from.toISOString())
            .set('to', to.toISOString());

        const headers = {
            'Authorization': `Bearer ${environment.traccarFleetApiKey}`
        };

        return this.http.get<TraccarPosition[]>(`${this.baseUrl}positions`, { params, headers });
    }
}
