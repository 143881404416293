/* eslint-disable */
import { Length } from "../order-params/length";
import { PayloadType } from "../order-params/payload-type";
import { QuantityView } from "../order-params/quantity-view";
import { Weight } from "../order-params/weight";

export class PayloadItemView {

    currencyCode: string | undefined;
    itemBreadth: number | undefined;
    itemDesciption: string | undefined;
    itemHeight: number | undefined;
    itemLength: number | undefined;
    itemQuantity: QuantityView | undefined;
    itemType: PayloadType | undefined;
    itemWeight: number | undefined;
    lengthUnit: Length | undefined;
    packingListIndex: number;
    price: number | undefined;
    receivingQuantity: QuantityView | undefined;
    shippingQuantity: QuantityView | undefined;
    weightUnit: Weight | undefined;

}