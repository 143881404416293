/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { SessionStorageService } from './session-storage.service';
import { OrderQueryView } from '../models/order-view/order-query-view';
import { OrderDeliveryView } from '../models/order-view/order-delivery';
import { DeliveryNoteRequest } from '../models/order-view/delivery-note-request';

@Injectable()
export class ReportsService {

    reportsApiUrl: string;

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.reportsApiUrl = `${environment.reportsApiUrl}`;
    }

    generateWayBill(orderQuery: OrderQueryView, orderDelivery: OrderDeliveryView) {
        return this.http.post<String>(`${this.reportsApiUrl}waybill/download`, new DeliveryNoteRequest(orderQuery, orderDelivery), {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, OPTIONS', // Allow POST and OPTIONS
                'Access-Control-Allow-Headers': 'Content-Type' // Allow Content-Type header
            }
        });
    }



}
