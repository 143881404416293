<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
        <div class="mr-5 flex align-items-center mt-3">
            <i class="fa-solid fa-truck-fast mr-2" style="font-size: 1.5rem"></i>
            <div class="font-medium text-xl text-900">Generate Deliveries from {{ isFreelancerRequired ? 'Freelancer' : 'Own' }} Drivers & Vehicles</div>
        </div><br>

        <div class="flex flex-column lg:flex-row">
            <div class="flex col">
                <p-pickList [source]="availableDrivers" [target]="selectedDrivers" sourceHeader="Available Drivers"
                            targetHeader="Selected Drivers" [responsive]="true" filterBy="name" (onMoveToTarget)="assignRelatedVehicle($event)"
                            sourceFilterPlaceholder="Search by name" targetFilterPlaceholder="Search by name"
                            [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-driver pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <img *ngIf="driver.imageUrl" [src]="driver.imageUrl" alt="{{ driver.name }}" style="height: 28px" />
                            <div>{{ driver.name }}</div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
            <div class="flex col">
                <p-pickList [source]="availableVehicles" [target]="selectedVehicles" sourceHeader="Available Vehicles"
                            targetHeader="Selected Vehicles" [responsive]="true" filterBy="plateNumber"
                            sourceFilterPlaceholder="Search by plate number" targetFilterPlaceholder="Search by plate number"
                            [dragdrop]="true" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-vehicle pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <img *ngIf="vehicle.type.imageUrl" [src]="vehicle.type.imageUrl" alt="{{ vehicle.plateNumber }}" style="height: 28px" />
                            <div>{{ vehicle.plateNumber }}</div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>

        <div class="flex flex-column lg:flex-row align-items-end mt-4">
            <div class="flex col">
                <p-button label="Generate Deliveries" icon="fa-solid fa-plus" iconPos="left"
                          [loading]="deliveryGenerationInProgress" class="m-1 px-1 py-1" (onClick)="generateDeliveries()"></p-button>
                <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                          class="m-1 px-1 py-1 p-button-help"></p-button>
            </div>
        </div>
    </div>
</div>
