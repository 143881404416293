<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="products" dataKey="productID" [resizableColumns]="true"
         styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Products</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Product" icon="fa-solid fa-plus" (onClick)="addProduct()"
                          class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Product ID</th>
            <th>Product Name</th>
            <th>Product Code</th>
            <th>Product Type</th>
            <th>Tax Rate</th>
            <th>Description</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="product"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ product.productID }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.productCode }}</td>
            <td>{{ product.productType }}</td>
            <td>{{ product.taxRate }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.createdAt | date:'yyyy-MM-dd' }}</td>
            <td>{{ product.updatedAt | date:'yyyy-MM-dd' }}</td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editProduct(product)"
                        pTooltip="Edit Product"
                        class="p-button-rounded p-button-text p-button-info mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-product>
        <tr>
            <td colspan="10">
                <div class="p-3">
                    <p-table [value]="product.productVariants" dataKey="variantId" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-10">
                                    <h2>Product Variants</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Variant ID</th>
                                <th>Variant Name</th>
                                <th>Variant Code</th>
                                <th>Rate Adjustment</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-variant>
                            <tr>
                                <td>{{ variant.variantID }}</td>
                                <td>{{ variant.name }}</td>
                                <td>{{ variant.variantCode }}</td>
                                <td>{{ variant.rateAdjustment }}</td>
                                <td>{{ variant.createdAt | date:'yyyy-MM-dd' }}</td>
                                <td>{{ variant.updatedAt | date:'yyyy-MM-dd' }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} Products.
        </div>
    </ng-template>
</p-table>
