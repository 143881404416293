import {Component, OnInit} from '@angular/core';
import {ChartOfAccounts} from "./chart-of-accounts.model";
import {ChartOfAccountsService} from "./chart-of-accounts.service";
import {ConfirmationService, MenuItem, MessageService, SelectItem, TreeNode} from "primeng/api";
import {SessionStorageService} from "../services/session-storage.service";
import {Router} from "@angular/router";
import {COAAccountType} from "./coa-account-type.model";
import {tap} from "rxjs";

@Component({
    selector: 'app-chart-of-accounts',
    templateUrl: './chart-of-accounts.component.html',
    styleUrls: ['./chart-of-accounts.component.css']
})
export class ChartOfAccountsComponent implements OnInit {
    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    chartOfAccountsTree: TreeNode<ChartOfAccounts>[] = [];
    selectedChartOfAccounts: ChartOfAccounts[] = [];
    accountTypes: COAAccountType[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;

    constructor(
        private chartOfAccountsService: ChartOfAccountsService,
        private sessionStorageService: SessionStorageService,
        private messageService: MessageService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Chart Of Accounts' }
        ];

        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
        this.loadAccountTypes();
        this.loadData();
    }

    loadData() {
        this.isLoading = true;
        this.chartOfAccountsService.getAllChartOfAccountsByCompany(this.sessionStorageService.getCompany()?.id ?? 0, true).subscribe({
            next: (chartOfAccounts) => {
                console.log(chartOfAccounts);
                this.chartOfAccountsTree = chartOfAccounts.map(account => this.buildTreeNode(account));
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading chart of accounts'
                });
                this.isLoading = false;
            }
        });
    }

    private loadAccountTypes() {
        this.chartOfAccountsService.getAllAccountTypes().pipe(
            tap(value => {
                this.accountTypes = value;
            })
        );
    }

    buildTreeNode(chartOfAccounts: ChartOfAccounts) :TreeNode<ChartOfAccounts> {
        return {
            label: chartOfAccounts.accountName,
            data: chartOfAccounts,
            key: chartOfAccounts.accountId.toString(),
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            leaf: chartOfAccounts.childrenAccounts.length === 0,
            expanded: true,
            children: chartOfAccounts.childrenAccounts.map(child => this.buildTreeNode(child))
        };
    }

    editChartOfAccounts(chartOfAccounts: ChartOfAccounts) {
        this.router.navigate(['/chart-of-accounts/edit', chartOfAccounts.accountId]);
    }

    deleteChartOfAccounts(chartOfAccounts: ChartOfAccounts) {
        this.chartOfAccountsService.deleteChartOfAccounts(chartOfAccounts.accountId, chartOfAccounts.owningCompanyId!).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Chart of account deleted successfully.'
                });
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error deleting chart of account'
                });
            },
            complete: () => {
                this.loadData();
            }
        })
    }

    addChildChartOfAccounts(chartOfAccounts: ChartOfAccounts) {
        this.router.navigate(['/chart-of-accounts/add', chartOfAccounts.accountId]);
    }


}
