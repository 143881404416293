<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="debitNotes" dataKey="debitNoteID" [resizableColumns]="true"
         styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }" [paginator]="true" [rows]="20"
         [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRecords" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         (onLazyLoad)="loadData($event)" [rowsPerPageOptions]="[20, 50, 100]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Debit Notes</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Debit Note" icon="fa-solid fa-plus" (onClick)="addDebitNote()"
                          class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Debit Note #</th>
            <th>Customer</th>
            <th>Company</th>
            <th>Linked Invoice</th>
            <th>Linked Bill</th>
            <th>Debit Note Date</th>
            <th>Total Amount</th>
            <th>Reason</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-debitNote let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="debitNote"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ debitNote.debitNoteNumber }}</td>
            <td>{{ debitNote.customer?.name }}</td>
            <td>{{ debitNote.company?.name }}</td>
            <td>{{ debitNote.invoice?.odooInvoiceNumber }}</td>
            <td>{{ debitNote.bill?.odooBillNumber }}</td>
            <td>{{ debitNote.debitNoteDate | date:'yyyy-MM-dd' }}</td>
            <td>{{ getDebitNoteTotalAmount(debitNote) }}</td>
            <td>{{ debitNote.reason }}</td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editDebitNote(debitNote)"
                        pTooltip="Edit Debit Note"
                        class="p-button-rounded p-button-text p-button-info mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-debitNote>
        <tr>
            <td colspan="10">
                <div class="p-3">
                    <p-table [value]="debitNote.debitNoteLines" dataKey="debitNoteLineID" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-10">
                                    <h2>Debit Note Lines</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-line>
                            <tr>
                                <td>{{ line.description }}</td>
                                <td>SAR {{ line.amount }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} Debit Notes.
        </div>
    </ng-template>
</p-table>
