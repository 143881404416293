import { Component, OnInit } from '@angular/core';
import {MenuItem} from "primeng/api";
import {TableLazyLoadEvent} from "primeng/table";
import {Router} from "@angular/router";
import {JournalEntriesService} from "./journal-entries.service";
import {SessionStorageService} from "../../services/session-storage.service";
import {JournalEntry} from "./models/journal-entry-header.model";


@Component({
  selector: 'app-journal-entries',
  templateUrl: './journal-entries.component.html',
  styleUrls: ['./journal-entries.component.css']
})
export class JournalEntriesComponent implements OnInit {

    journalEntries: JournalEntry[] = [];
    first = 0;
    rows = 20;
    totalRequests = 0;
    isLoading = true;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    selectedEntry: JournalEntry | null = null;  // To store the selected entry
    displayDetails: boolean = false;  // To control the modal visibility

    constructor(private router: Router,
                private sessionStorageService: SessionStorageService,
                private journalEntriesService: JournalEntriesService) {
        this.journalEntries = [];
    }

    addJournalEntry() {
        this.router.navigate(['/accounts/enterprise/journal-entries/add']);
    }

    viewDetails(entry: JournalEntry) {
        this.selectedEntry = entry;
        this.displayDetails = true; // Show the modal
    }

    editJournalEntry(journalEntry: JournalEntry) {
        this.router.navigate(['/accounts/enterprise/journal-entries/edit', journalEntry.entryId]);
    }


    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Accounts', routerLink: '/accounts' },
            { label: 'Enterprise', routerLink: '/accounts/enterprise' },
            { label: 'Journal Entries', routerLink: '/accounts/enterprise/journal-entries' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }


    loadData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        let page = event.first ?? 0;
        page = page / 20;
        let pageSize = event.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.journalEntries = [];
        this.journalEntriesService.getAllJournalEntriesByCompany(this.sessionStorageService.getCompany()?.id ?? 0, page, pageSize).subscribe(ledgerListWrapper => {
            console.log(ledgerListWrapper);
            this.journalEntries = ledgerListWrapper.items;
            this.totalRequests = ledgerListWrapper.count;
            this.isLoading = false;
        });
    }
}
