import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { SalesOrder } from './sales-order.model';
import {SelectItem} from "primeng/api";
import {OrdersService} from "../order.service";
import {Router} from "@angular/router";
import {SessionStorageService} from "../../services/session-storage.service";

@Component({
    selector: 'app-sales-order',
    templateUrl: './sales-order.component.html',
    styleUrls: ['./sales-order.component.css']
})
export class SalesOrderComponent implements OnInit {
    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    salesOrders: SalesOrder[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;
    salespersonList: SelectItem<any>[] | undefined;

    constructor(
        private salesOrderService: OrdersService,
        private messageService: MessageService, private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Sales Order' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadData();
    }

    loadData(event?: any) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        let page = event.first ?? 0;
        page = page / 20;
        let pageSize = event.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.salesOrderService.getAllSalesOrdersByOwningCompany(this.sessionStorageService.getCompany()?.id ?? 0, page, pageSize).subscribe({
            next: (data) => {
                this.salesOrders = data.items;
                this.totalRecords = data.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading sales orders'
                });
                this.isLoading = false;
            }
        });
    }

    addSalesOrder() {
        this.router.navigate(['/order/sales-order/add']);
    }

    editSalesOrder(salesOrder: SalesOrder) {
        this.router.navigate(['/order/sales-order/edit', salesOrder.orderID]);
    }

    showDate(date: Date | undefined): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString(); // Example
    }

    getSalesOrderTotalAmount(salesOrder: SalesOrder) {
        return 'SAR ' + salesOrder.salesOrderLines.reduce((total, line) => total + line.totalLineAmount, 0);
    }

    confirmSalesOrder(salesOrder: SalesOrder) {
        this.isLoading = true;
        this.salesOrderService.confirmSalesOrder(salesOrder).subscribe(value => {
            console.log(value);
            this.isLoading = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order# ' + salesOrder.orderNumber + ' confirmed successfully' });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        });
    }
}
