<p-toast></p-toast>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createEditRateCardForm" novalidate autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="fa-solid fa-truck mr-2" style="font-size: 1.5rem"></i> <!— Truck icon -->
                <div class="font-medium text-xl text-900">Create/Update Rate Card</div>
            </div><br>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-truck-fast"></i></span> <!— VehicleModel Type icon -->
                        <p-dropdown id="vehicleType"
                                    [options]="vehicleTypes"
                                    [(ngModel)]="createEditRateCard.vehicleTypeId"
                                    optionLabel="name"
                                    placeholder="VehicleModel Type"
                                    [autoDisplayFirst]="false"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    formControlName="vehicleTypeDropdown"
                                    [style]="{'width': '100%'}"
                                    (onChange)="onVehicleTypeChange()">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <button pButton icon="pi pi-times" class="ml-3"></button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleType pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ vehicleType.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-cogs"></i></span> <!— VehicleModel Config icon -->
                        <p-dropdown id="vehicleConfig"
                                    [options]="filteredVehicleConfigs"
                                    [(ngModel)]="createEditRateCard.vehicleConfigId"
                                    optionLabel="name"
                                    placeholder="VehicleModel Configuration"
                                    [autoDisplayFirst]="false"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    formControlName="vehicleConfigDropdown"
                                    [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <button pButton icon="pi pi-times" class="ml-3"></button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleConfig pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ vehicleConfig.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-weight-hanging"></i></span> <!— VehicleModel Capacity icon -->
                        <p-dropdown id="vehicleCapacity"
                                    [options]="filteredVehicleCapacities"
                                    [(ngModel)]="createEditRateCard.vehicleCapacityId"
                                    optionLabel="name"
                                    placeholder="VehicleModel Capacity"
                                    [autoDisplayFirst]="false"
                                    [filter]="true"
                                    filterBy="name"
                                    [showClear]="true"
                                    formControlName="vehicleCapacityDropdown"
                                    [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <button pButton icon="pi pi-times" class="ml-3"></button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleCapacity pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ vehicleCapacity.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-earth-americas"></i></span>
                        <p-dropdown id="sourceCountry"
                                    [options]="countries"
                                    [(ngModel)]="createEditRateCard.sourceCountry"
                                    placeholder="Source Country"
                                    formControlName="sourceCountryInput"
                                    [style]="{'width': '100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-city"></i></span> <!— Source City icon -->
                        <p-dropdown id="sourceCity" [options]="cities" [(ngModel)]="createEditRateCard.sourceCity"
                                    placeholder="Source City" formControlName="sourceCityInput" [style]="{'width': '100%'}">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-earth-americas"></i></span> <!— Destination Country icon -->
                        <p-dropdown id="destinationCountry"
                                    [options]="countries"
                                    [(ngModel)]="createEditRateCard.destinationCountry"
                                    placeholder="Destination Country"
                                    formControlName="destinationCountryInput"
                                    [style]="{'width': '100%'}">
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-city"></i></span> <!— Destination City icon -->
                        <p-dropdown id="destinationCity"
                                    [options]="cities"
                                    [(ngModel)]="createEditRateCard.destinationCity"
                                    placeholder="Destination City"
                                    formControlName="destinationCityInput"
                                    [style]="{'width': '100%'}">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-ruler"></i></span> <!— Distance icon -->
                        <p-inputNumber [(ngModel)]="createEditRateCard.distance"
                                       placeholder="Distance" formControlName="distanceInput" buttonLayout="horizontal">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-money-bill-wave"></i></span> <!— Amount icon -->
                        <p-inputNumber [(ngModel)]="createEditRateCard.amount"
                                       placeholder="Amount" formControlName="amountInput" buttonLayout="horizontal">
                        </p-inputNumber>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center ml-3">
                        <i class="fa-solid fa-shield-halved" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="goodsInsuranceInput" style="font-size: 1.2rem">Goods Insurance?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center mr-3">
                        <p-inputSwitch [(ngModel)]="createEditRateCard.goodsInsurance" id="goodsInsuranceInput"
                                       formControlName="goodsInsuranceInput"></p-inputSwitch>
                    </div>
                </div>
                <div class="flex col overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center ml-3">
                        <i class="fa-solid fa-ship" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="portPermitInput" style="font-size: 1.2rem">Port Permit?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center mr-3">
                        <p-inputSwitch [(ngModel)]="createEditRateCard.portPermit" id="portPermitInput"
                                       formControlName="portPermitInput"></p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-clock"></i></span> <!— Waiting Threshold icon -->
                        <p-inputNumber [(ngModel)]="createEditRateCard.waitingThreshold"
                                       placeholder="Waiting Threshold" formControlName="waitingThresholdInput" buttonLayout="horizontal">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-money-bill-1-wave"></i></span> <!— Waiting Charge icon -->
                        <p-inputNumber [(ngModel)]="createEditRateCard.waitingCharge"
                                       placeholder="Waiting Charge" formControlName="waitingChargeInput" buttonLayout="horizontal">
                        </p-inputNumber>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="{{ createEditRateCard.rateId ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
                              [loading]="rateCardCreationInProgress" class="m-1 px-1 py-1" (onClick)="createUpdateRateCard()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                              class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>
