<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="flex justify-center items-center w-full h-full">
    <div class="card w-full">
        <div class="flex flex-column align-items-center justify-content-center w-full">
            <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div class="text-center mb-5">
                    <img src="../../assets/cargomate_logo.png" height="100" class="mb-3">
                    <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                </div>
                <div class="flex flex-column card-container align-items-center w-full">
                    <firebase-ui (signInSuccessWithAuthResult)="successCallback($event)"
                        (signInFailure)="errorCallback($event)" (uiShown)="uiShownCallback()"></firebase-ui>
                </div>
            </div>
        </div>
    </div>
</div>
