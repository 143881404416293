<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="journalEntries" dataKey="entryId" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRequests" [lazy]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
         [rowsPerPageOptions]="[20, 50, 100]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Journal Entries</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Journal Entry" icon="fa-solid fa-plus" (onClick)="addJournalEntry()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Date</th>
            <th>Number</th>
            <th>Partner</th>
            <th>Reference</th>
            <th>Journal</th>
            <th>Total</th>
            <th>Status</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-entry let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="entry"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ entry.entryDate | date:'mediumDate' }}</td>
            <td>{{ entry.entryNumber }}</td>
            <td>{{ entry.partnerName }}</td>
            <td>{{ entry.reference }}</td>
            <td>{{ entry.journal.journalName }}</td>
            <td *ngIf="entry.amount != '0'">{{ entry.currencyCode }}&nbsp;{{ entry.amount | number:'1.2-2' }}</td>
            <td *ngIf="entry.amount == '0'"></td>
            <td>
                <p-badge *ngIf="entry.state != 'posted'" severity="warning" [value]="'Draft'"></p-badge>
                &nbsp;&nbsp;<p-badge *ngIf="entry.state === 'posted'" severity="success" [value]="'Posted'"></p-badge>
            </td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editJournalEntry(entry)"
                        class="p-button-rounded p-button-text p-button-info mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-entry>
        <tr>
            <td colspan="9">
                <div class="p-3">
                    <p-table [value]="entry.items" dataKey="itemId" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="grid">
                                <div class="col-10">
                                    <h2>Journal Items</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>S#</th>
                                <th>Account</th>
                                <th>Partner</th>
                                <th>Label</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ entry.items.indexOf(item) + 1 }}</td>
                                <td>{{ item.account.accountCode }}&nbsp;{{ item.account.accountName }}</td>
                                <td>{{ item.partnerName }}</td>
                                <td>{{ item.label }}</td>
                                <td>{{ entry.currencyCode + ' ' + item.debit }}</td>
                                <td>{{ entry.currencyCode + ' ' + item.credit }}</td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRequests}} locations.
        </div>
    </ng-template>
</p-table>
