import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {MessageService} from "primeng/api";

import {locationRoutes} from "./location.routes";
import {LocationComponent} from "./location.component";
import {CreateLocationComponent} from "./create-location/create-location.component";
import {LocationService} from "./location.service";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {CustomerModule} from "../customer/customer.module";
import {CompanyModule} from "../company/company.module";
import {MessagesModule} from "primeng/messages";



@NgModule({
    imports: [
        RouterModule.forChild(locationRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        DropdownModule,
        AutoCompleteModule,
        CustomerModule,
        CompanyModule,
        MessagesModule
    ],
    providers: [
        MessageService,
        LocationService
    ],
    declarations: [
        LocationComponent,
        CreateLocationComponent
    ],
})
export class LocationModule { }
