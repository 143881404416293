<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-accordion *ngIf="generatedInvoicesSummaryList.length > 0">
    <p-accordionTab *ngFor="let generatedInvoicesSummary of generatedInvoicesSummaryList">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image src="{{ generatedInvoicesSummary?.client?.logo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <h4>{{ generatedInvoicesSummary.client.name }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ generatedInvoicesSummary.totalUnpaidAmount }}</p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Pending</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ generatedInvoicesSummary.totalPaidAmount }}</p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Invoiced</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="generatedInvoicesSummary.invoices" dataKey="invoiceId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="generatedInvoicesSummary.totalInvoices" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadData($event, generatedInvoicesSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="generatedInvoicesSummary.selectedInvoices">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!generatedInvoicesSummary.invoices"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Generated Invoices</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Generate Receipt" icon="fa-solid fa-file-invoice-dollar"
                                  (onClick)="generateReceipt(generatedInvoicesSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(generatedInvoicesSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>ID</th>
                    <th>Invoice#</th>
                    <th>Invoice Date</th>
                    <th>Contact</th>
                    <th>Amount</th>
                    <th>Applied Receipts</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="invoice"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="invoice"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ invoice.invoiceId }}</td>
                    <td>{{ invoice.odooInvoiceNumber }}</td>
                    <td>{{ invoice.invoiceCreationDate | date:'yyyy-MM-dd' }}</td>
                    <td>
                        <p-chip *ngIf="invoice.customer" [label]="invoice.customer.name">
                            <img src="{{ invoice.customer.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ invoice.customer.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>  <!-- Assuming receivable has a companyName property -->
                    <td>{{ invoice.country.currencyCode + ' ' + invoice.totalAmount }}</td> <!-- Assuming receivable has currencyCode and amount properties -->
                    <td>
                        <ul>
                            <li *ngFor="let receipt of invoice.receipts">{{ receipt.odooReceiptNumber }}: {{ invoice.country.currencyCode }} {{ receipt.amount }}</li>
                        </ul>
                    </td>
                    <td>
                        <p-tag *ngIf="invoice.receipts?.length > 0" icon="fa-solid fa-check" severity="success" value="PAID"></p-tag>
                        <p-tag *ngIf="invoice.receipts?.length  === 0" icon="fa-solid fa-circle-exclamation" severity="danger" value="UNPAID"></p-tag>
                    </td>
                    <td>
                <span class="p-buttonset">
            <p-button pTooltip="Download Invoice" icon="pi pi-download" (onClick)="downloadInvoice(invoice)" class="p-1" severity="info"></p-button>
        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-invoice>
                <tr>
                    <td colspan="12">
                        <div class="p-3">
                            <p-table [value]="invoice.invoiceLines" dataKey="invoiceLineId" [resizableColumns]="true"
                                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-10">
                                            <h2>Generated Invoice Lines</h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S#</th>
                                        <th>Header#</th>
                                        <th>Detail#</th>
                                        <th>Service Type</th>
                                        <th>Charge Amount</th>
                                        <th>Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-invoiceLine>
                                    <tr>
                                        <td>{{ invoiceLine.invoiceLineId }}</td>
                                        <td>{{ invoiceLine.headerReferenceNumber }}</td>
                                        <td>{{ invoiceLine.detailReferenceNumber }}</td>
                                        <td>{{ invoiceLine.itemNumber }}</td>
                                        <td>{{ invoice.country.currencyCode + ' ' + invoiceLine.chargeAmount }}</td>
                                        <td>
                  <span class="p-buttonset">
                      <button pButton type="button" icon="fa fa-pencil" (click)="editInvoiceLine(invoiceLine)"
                              class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                  </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{generatedInvoicesSummary.totalInvoices}} receivables.
                </div>
            </ng-template>

        </p-table>
    </p-accordionTab>
</p-accordion>
