import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { SessionStorageService } from "../../../services/session-storage.service";
import {tap, forkJoin, Subject, debounceTime, distinctUntilChanged, switchMap, of} from "rxjs";
import {Customer} from "../../../customer/customer.model";
import {CustomerService} from "../../../customer/customer.service";
import {Company} from "../../../company/company.model";
import {ContractsService} from "../../../contracts/contracts.service";
import {DebitNote} from "../debit-note.model";
import {AccountsService} from "../../accounts.service";
import {DebitNoteLine} from "../debit-note-line.model";
import {Invoice} from "../../invoice/invoice.model";
import { Bill } from '../../payables/generated-bills/bill.model';

@Component({
    selector: 'app-add-edit-debit-note',
    templateUrl: './add-edit-debit-note.component.html',
    styleUrls: ['./add-edit-debit-note.component.css']
})
export class AddEditDebitNoteComponent implements OnInit {

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};
    debitNote!: DebitNote;
    debitNoteForm!: FormGroup;
    editMode = false;
    debitNoteId!: number;
    isLoading: boolean = false;

    companies: SelectItem<Company>[] = [];
    customers: SelectItem<Customer>[] = [];
    bills: Bill[] = [];
    filteredBills: Bill[] = [];
    invoices: Invoice[] = [];
    filteredInvoices: Invoice[] = [];
    private billSearchTerms = new Subject<string>();
    private invoiceSearchTerms = new Subject<string>();


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private debitNoteService: AccountsService,
        private customerService: CustomerService,
        private contractService: ContractsService,
        private accountsService: AccountsService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Debit Note', routerLink: '/accounts/debit-note' },
            { label: this.editMode ? 'Edit Debit Note' : 'Add Debit Note' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.debitNoteId = params['id'];
            this.editMode = !!this.debitNoteId;
            this.loadData();
        });

        this.initForm();
        this.initFilterLogic();
    }

    loadData() {
        this.isLoading = true;

        const loadObservables = {
            companies: this.loadCompanies(),
        };

        forkJoin(loadObservables)
            .pipe(
                tap(() => {
                    if (this.editMode) {
                        this.loadDebitNote().subscribe();
                    } else {
                        if (this.companies.length > 0) {
                            this.onCompanyChange(this.companies[0].value);
                            this.debitNoteForm.patchValue({ company: this.companies[0].value });
                        }
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while loading data.' + error.message
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    loadCompanies() {
        return this.contractService.getContractRelatedCompanies(this.sessionStorageService.getCompany()!.id).pipe(
            tap(value => {
                this.companies = value.map(company => ({
                    value: company,
                    label: company.name
                }));
            }),
        );
    }

    onCompanyChange(company: Company) {
        if (company) {
            this.loadCustomersByCompany(company).subscribe();
            //this.billSearchTerms.next('');
            //this.invoiceSearchTerms.next('');
        } else {
            this.customers = [];
            this.debitNoteForm.patchValue({ customer: null });
            this.filteredBills = [];
            this.filteredInvoices = [];
            this.debitNoteForm.patchValue({ bill: null, invoice: null });
        }
    }

    loadCustomersByCompany(company: Company) {
        return this.customerService.getCustomersByCompany(company.id).pipe(
            tap(customers => {
                this.customers = customers.map(customer => ({
                    value: customer,
                    label: customer.name
                }));
            })
        );
    }

    loadDebitNote() {
        return this.debitNoteService.getDebitNoteById(this.debitNoteId).pipe(
            tap(debitNote => {
                this.debitNote = debitNote;
                this.debitNoteForm.patchValue(debitNote);
                this.onCompanyChange(debitNote.company!);
                this.debitNoteForm.setControl('debitNoteLines', this.fb.array(debitNote.debitNoteLines!
                    .map(line => this.createDebitNoteLineFormGroup(line))));
            })
        );
    }

    initForm() {
        this.debitNoteForm = this.fb.group({
            debitNoteID: [null],
            debitNoteNumber: [''],
            company: [null, Validators.required],
            customer: [null, Validators.required],
            bill: [null],
            invoice: [null],
            debitNoteDate: [new Date(), Validators.required],
            reason: [''],
            createdAt: [null],
            createdBy: [null],
            updatedAt: [null],
            updatedBy: [null],
            debitNoteLines: this.fb.array([this.createDebitNoteLineFormGroup()]),
        });
    }

    createDebitNoteLineFormGroup(debitNoteLine?: DebitNoteLine): FormGroup {
        return this.fb.group({
            debitNoteLineID: [debitNoteLine?.debitNoteLineID ?? null],
            description: [debitNoteLine?.description ?? ''],
            amount: [debitNoteLine?.amount ?? 0, [Validators.required, Validators.min(0)]],
        });
    }

    get getDebitNoteLineControls(): FormArray {
        return this.debitNoteForm.get('debitNoteLines') as FormArray;
    }

    addDebitNoteLine() {
        this.getDebitNoteLineControls.push(this.createDebitNoteLineFormGroup());
    }

    removeDebitNoteLine(index: number) {
        this.getDebitNoteLineControls.removeAt(index);
    }

    saveDebitNote() {
        if (this.debitNoteForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const modifiedDebitNote = this.debitNoteForm.value as DebitNote;
        modifiedDebitNote.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        const userId = this.sessionStorageService.getCustomer()!.id

        if (this.editMode) {
            modifiedDebitNote.createdAt = this.debitNote.createdAt;
            modifiedDebitNote.createdBy = this.debitNote.createdBy;
            modifiedDebitNote.updatedAt = new Date();
            modifiedDebitNote.updatedBy = userId;
        } else {
            modifiedDebitNote.createdAt = new Date();
            modifiedDebitNote.createdBy = userId;
            modifiedDebitNote.updatedAt = new Date();
            modifiedDebitNote.updatedBy = userId;
        }

        modifiedDebitNote.debitNoteLines?.forEach(line => {
            line.createdAt = this.editMode ? line.createdAt : new Date();
            line.createdBy = this.editMode ? line.createdBy : userId;
            line.updatedAt = new Date();
            line.updatedBy = userId;
            line.debitNoteID = modifiedDebitNote.debitNoteID;
        });

        this.debitNoteService.createDebitNote(modifiedDebitNote).subscribe({
            next: (newDebitNote: DebitNote) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Debit Note# ' + newDebitNote.debitNoteNumber + ' saved successfully.'
                });
                setTimeout(() => {
                    this.router.navigate(['/accounts/enterprise/receivables/debit-note']);
                }, 3000);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving debit note'
                });
            }
        });
    }

    cancel() {
        this.location.back();
    }

    filterBills(event: any) {
        this.billSearchTerms.next(event.query);
    }

    filterInvoices(event: any) {
        this.invoiceSearchTerms.next(event.query);
    }

    private initFilterLogic() {
        this.billSearchTerms
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((searchText: string) => {
                    const companyId = this.debitNoteForm.get('company')?.value?.id;
                    const owningCompanyId = this.sessionStorageService.getCompany()!!.id;
                    if(companyId) {
                        return this.accountsService.getBills(0, 5, companyId, undefined, owningCompanyId, searchText);
                    } else {
                        return of({items:[]});
                    }
                }),
                tap(res => this.filteredBills = res.items)
            )
            .subscribe();

        this.invoiceSearchTerms
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((searchText: string) => {
                    const companyId = this.debitNoteForm.get('company')?.value?.id;
                    const owningCompanyId = this.sessionStorageService.getCompany()!!.id;
                    if(companyId) {
                        return this.accountsService.getInvoices(0, 5, companyId, owningCompanyId, searchText);
                    } else {
                        return of({items: []});
                    }
                }),
                tap(res => this.filteredInvoices = res.items)
            )
            .subscribe();
    }
}
