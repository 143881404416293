import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {Company} from "../company.model";
import {CompanyService} from "../company.service";
import {Country} from "../../models/common/country";
import {SessionStorageService} from "../../services/session-storage.service";
import {AutoCompleteCompleteEvent, AutoCompleteOnSelectEvent} from "primeng/autocomplete";
import {GeoAddress} from "../../models/common/geo-address.model";
import {GeoElement} from "../../models/common/geo-element.model";
import {LatLng} from "../../models/common/lat-lng";
import {GoogleMapsLibrariesLoaderService} from "../../services/google/google-libraries-loader.service";


@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.css']
})
export class CompanyFormComponent implements OnInit, AfterViewInit {

    googleMap: any;
    companyForm!: FormGroup;
    @Input() displayModal: boolean = false;
    @Input() dialogHeader: string = '';
    @Input() submitButtonLabel: string = '';
    @Input() company: Company = new Company();
    @Output() modalClosed = new EventEmitter<boolean>();
    countries: Country[] = [];
    searchPlaceResults: string[];
    isCompanySaveInProgress: boolean = false;

    constructor(
        private fb: FormBuilder, private companyService: CompanyService,
        private sessionStorageService: SessionStorageService,
        private readonly googleMapsLibrariesLoaderService: GoogleMapsLibrariesLoaderService,
        private messageService: MessageService,
    ) {
    }

    ngOnInit(): void {
        this.loadCountries();
        this.initializeForm();
    }

    ngAfterViewInit(): void {
        this.googleMapsLibrariesLoaderService.lazyLoadMap().subscribe(_ =>
            this.googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement));
        if (!navigator.geolocation) {
            console.log('location is not supported');
        }
        navigator.geolocation.getCurrentPosition((position) => {
            //this.orderQuery.searchLocation = new LatLng(position.coords.latitude, position.coords.longitude);
        });
    }

    initializeForm() {
        this.companyForm = this.fb.group({
            searchLocation: [],
            name: [this.company.name, Validators.required],
            crNumber: [this.company.crNumber, Validators.required],
            taxRegNumber: [this.company.taxRegNumber],
            emailAddress: [this.company.emailAddress, Validators.email],
            phoneNumber: [this.company.phoneNumber, Validators.required]
        });
    }

    loadCountries() {
        this.countries = this.sessionStorageService.getCountryList()!!;
    }

    onSubmit() {
        if (this.company.id) {
            this.updateCompany(this.company);
        } else {
            this.createCompany(this.company);
        }
    }

    createCompany(companyData: Company) {
        this.isCompanySaveInProgress = true;
        this.companyService.addCompany(companyData).subscribe(apiResponse => {
            console.log(apiResponse.data);
            if (apiResponse.success) {
                this.company = apiResponse.data;
                console.log('Company created successfully! ');
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Company created successfully!'});
                this.closeModal();
            } else {
                console.error('Error creating company:', apiResponse.message);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to create company. Please try again later.'
                });
            }
            this.isCompanySaveInProgress = false;
        }, error => {
            console.error('Error creating company:', error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to create company. Please try again later.'
            });
            this.isCompanySaveInProgress = false;
        });
    }

    updateCompany(companyData: any) {
        this.isCompanySaveInProgress = true;
        this.companyService.updateCompany(companyData).subscribe(apiResponse => {
            console.log(apiResponse.data);
            if (apiResponse.success) {
                this.company = apiResponse.data;
                console.log('Company updated successfully! ');
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Company updated successfully!'});
                this.closeModal();
            } else {
                console.error('Error updating company:', apiResponse.message);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update company. Please try again later.'
                });
            }
            this.isCompanySaveInProgress = false;
        }, error => {
            console.error('Error updating company:', error);
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update company. Please try again later.'
            });
            this.isCompanySaveInProgress = false;
        });
    }

    onCancel() {
        this.closeModal();
    }

    closeModal() {
        this.displayModal = false;
        this.modalClosed.emit(true);
    }

    searchPlaces(event: AutoCompleteCompleteEvent) {
        const filtered: any[] = [];
        const displaySuggestions = (
            predictions: google.maps.places.QueryAutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
        ) => {
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                //alert(status);
                return;
            }
            predictions.forEach((prediction) => {
                filtered.push({ name: prediction.description, place_id: prediction.place_id });
            });
            this.searchPlaceResults = filtered;
        };
        // const autocompleteService = new google.maps.places.AutocompleteService();
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: event.query }, displaySuggestions);
    }

    placeSelect($event: AutoCompleteOnSelectEvent) {
        const place_id = $event.value.place_id;
        const placesService = new google.maps.places.PlacesService(this.googleMap);
        this.company.name = $event.value.name;

        placesService.getDetails({
            placeId: place_id,
            fields: ['name', 'formatted_address', 'place_id', 'geometry', 'adr_address', 'address_components', 'photos', 'website', 'international_phone_number']
        }, (result, status) => {
            if (status != google.maps.places.PlacesServiceStatus.OK) {
                alert(status);
                return;
            } else {
                const lat = result?.geometry?.location?.lat().valueOf()!!;
                const lng = result?.geometry?.location?.lng().valueOf()!!;
                let geoAddress: GeoAddress = new GeoAddress();
                result?.address_components?.forEach(addressComponent => {
                    let geoElement = new GeoElement();
                    geoElement.name = addressComponent.short_name;
                    geoElement.types = addressComponent.types;
                    geoAddress.addressElements.push(geoElement);
                })
                this.company.location = new LatLng(lat, lng);
                this.company.address = result?.formatted_address ?? '';
                this.company.phoneNumber = result?.international_phone_number?.replace(/\s/g, '') ?? null;
                this.company.websiteURL = result?.website ?? null;
                this.company.countryId = this.sessionStorageService.getCountryList()?.find(country => country.countryCode == 'SA')?.id ?? 2;
                this.company.placeId = result?.place_id ?? null;
                this.company.logo = result?.photos?.[0]?.getUrl() ?? null;
            }
        });
    }
}
