import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { SessionStorageService } from "../../../services/session-storage.service";
import { AccountsService } from "../../accounts.service";
import { GeneratedVouchersSummary } from "./generated-vouchers-summary.model";
import * as XLSX from "xlsx";
import {VoucherAmountDialogComponent} from "./voucher-amount-dialog/voucher-amount-dialog.component";
import {TableLazyLoadEvent} from "primeng/table";
import {PaymentTransactions} from "../../payment-transactions/payment-transactions.model";
import {PaymentService} from "../../../services/payment.service";

@Component({
    selector: 'app-generated-vouchers',
    templateUrl: './generated-vouchers.component.html',
    styleUrls: ['./generated-vouchers.component.css']
})
export class GeneratedVouchersComponent implements OnInit {
    generatedVouchersSummaryList: GeneratedVouchersSummary[] = [];
    isLoading: boolean = false;
    first: number = 0;
    rows: number = 20;
    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem;

    @ViewChild(VoucherAmountDialogComponent) voucherAmountDialogComponent: VoucherAmountDialogComponent;

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private sessionStorageService: SessionStorageService,
        private paymentService: PaymentService) {
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Payables' }, { label: 'Generated Vouchers' }];
        this.loadVouchersSummary();
    }

    loadVouchersSummary() {
        this.isLoading = true;
        this.accountsService.getGeneratedVouchersSummary(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (vouchersSummaryList) => {
                this.generatedVouchersSummaryList = vouchersSummaryList;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error loading vouchers summary' });
                this.isLoading = false;
            }
        });
    }

    loadVouchers(event: TableLazyLoadEvent, generatedVouchersSummary: GeneratedVouchersSummary) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        this.accountsService.getVouchers(this.first, this.rows, generatedVouchersSummary.clientId,
            generatedVouchersSummary.contactId, this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (vouchersWrapper) => {
                generatedVouchersSummary.vouchers = vouchersWrapper.items;
                generatedVouchersSummary.totalVouchers = vouchersWrapper.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error loading vouchers' });
                this.isLoading = false;
            }
        });
    }

    exportToExcel(generatedVouchersSummary: GeneratedVouchersSummary) {
        const worksheet = XLSX.utils.json_to_sheet(generatedVouchersSummary.vouchers);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'cargomate_generated_vouchers_export_' + new Date().toISOString() + '.xlsx';
        // Create a blob from the workbook
        const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target = '_blank';
        a.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
    }

    generatePaymentFromVouchers(generatedVouchersSummary: GeneratedVouchersSummary) {
        this.isLoading = true;
        if (generatedVouchersSummary.selectedVouchers == null || generatedVouchersSummary.selectedVouchers.length == 0) {
            this.messageService.add({severity: 'error', summary: 'Invalid selection', detail: 'No vouchers selected.'});
            this.isLoading = false;
            return;
        }
        if (generatedVouchersSummary.selectedVouchers.find(value => value.paymentTransactionNumber != null)) {
            this.messageService.add({severity: 'error', summary: 'Invalid selection', detail: 'Selected Vouchers contain already paid receipts.'});
            this.isLoading = false;
            return;
        }
        this.paymentService.generatePaymentFromVouchers(generatedVouchersSummary.selectedVouchers, this.sessionStorageService.getCustomer()?.firebaseId ?? '').subscribe(
            (paymentTransaction: PaymentTransactions) => {
                console.log('Payment Transaction from Vouchers:', paymentTransaction);
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Payment made successfully.'});
                this.isLoading = false;
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            (error) => {
                console.error('Error generating payment:', error);
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to generate payment.'});
                this.isLoading = false;
            }
        );
    }

    showVoucherAmountDialog(generatedVouchersSummary: GeneratedVouchersSummary) {
        this.voucherAmountDialogComponent.showDialog(generatedVouchersSummary);
    }
}
