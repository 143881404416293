import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { SelectItem } from "primeng/api";
import { OrdersService } from "../order.service";
import { Router } from "@angular/router";
import { SessionStorageService } from "../../services/session-storage.service";
import { PurchaseOrder } from "./purchase-order.model";

@Component({
    selector: 'app-purchase-order',
    templateUrl: './purchase-order.component.html',
    styleUrls: ['./purchase-order.component.css']
})
export class PurchaseOrderComponent implements OnInit {
    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    purchaseOrders: PurchaseOrder[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;
    salespersonList: SelectItem<any>[] | undefined;

    constructor(
        private purchaseOrderService: OrdersService,
        private messageService: MessageService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Purchase Order' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadData();
    }

    loadData(event?: any) {
        this.isLoading = true;
        this.first = event?.first ?? 0;
        this.rows = event?.rows ?? 20;
        let page = event?.first ?? 0;
        page = page / 20;
        let pageSize = event?.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.purchaseOrderService.getAllPurchaseOrders(this.sessionStorageService.getCompany()?.id ?? 0, page, pageSize).subscribe({
            next: (data) => {
                this.purchaseOrders = data.items;
                this.totalRecords = data.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading purchase orders'
                });
                this.isLoading = false;
            }
        });
    }

    addPurchaseOrder() {
        this.router.navigate(['/order/purchase-order/add']);
    }

    editPurchaseOrder(purchaseOrder: PurchaseOrder) {
        this.router.navigate(['/order/purchase-order/edit', purchaseOrder.purchaseOrderID]);
    }

    showDate(date: Date | undefined): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString();
    }

    getPurchaseOrderTotalAmount(purchaseOrder: PurchaseOrder) {
        return 'SAR ' + purchaseOrder.purchaseOrderLines.reduce((total, line) => total + line.totalLineAmount, 0);
    }

    confirmPurchaseOrder(purchaseOrder: PurchaseOrder) {
        this.isLoading = true;
        this.purchaseOrderService.confirmPurchaseOrder(purchaseOrder).subscribe(value => {
            this.isLoading = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Order# ' + purchaseOrder.purchaseOrderNumber + ' confirmed successfully' });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        });
    }
}
