import {AfterViewInit, Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { VehicleService } from '../../vehicle/vehicle.service';
import { Vehicle } from '../../vehicle/vehicle.model';
import { Driver } from '../driver.model';
import { DriverService } from '../driver.service';
import {SessionStorageService} from "../../../services/session-storage.service";
import {Country} from "../../../models/common/country";
import {FileSelectEvent, FileUploadEvent, FileUploadHandlerEvent} from "primeng/fileupload";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../../../environments/environment";
import {AutoCompleteCompleteEvent, AutoCompleteOnSelectEvent} from "primeng/autocomplete";
import {GeoAddress} from "../../../models/common/geo-address.model";
import {GeoElement} from "../../../models/common/geo-element.model";
import {LatLng} from "../../../models/common/lat-lng";
import {GoogleMapsLibrariesLoaderService} from "../../../services/google/google-libraries-loader.service";

@Component({
    selector: 'app-edit-driver',
    templateUrl: './edit-driver.component.html',
    styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit, AfterViewInit {

    createEditDriverForm: FormGroup<any>;
    nameInput: FormControl;
    phoneNumberInput: FormControl;
    emailAddressInput: FormControl;
    nationalityDropdown: FormControl;
    residingCountryDropdown: FormControl;
    preferredAddressInput: FormControl;
    residenceNumberInput: FormControl;
    residenceExpiryDateInput: FormControl;
    licenseNumberInput: FormControl;
    licenseExpiryDateInput: FormControl;
    currentVehicleDropdown: FormControl;

    driverId: number;
    isLoading: boolean = false;
    driver: Driver = new Driver();
    countries: Country[] = [];
    selectedCountry: Country | undefined;
    selectedResidingCountry: Country | undefined;
    availableVehicles: Vehicle[] = [];
    selectedVehicle: Vehicle | undefined;
    selectedResidenceExpiryDate: Date | undefined;
    selectedLicenseExpiryDate: Date | undefined;
    driverCreationInProgress: boolean = false;
    pdfUploadProgress: number = 0;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    googleMap: any;
    formTitle: string;
    isFreelancer: boolean = false;
    searchPlaceResults: string[];

    constructor(
        private sessionStorageService: SessionStorageService,
        private route: ActivatedRoute,
        private driverService: DriverService,
        private messageService: MessageService,
        private vehicleService: VehicleService,
        private router: Router,
        private readonly googleMapsLibrariesLoaderService: GoogleMapsLibrariesLoaderService,
    ) {
        this.loadCountries();
        this.loadVehicles();
    }

    ngOnInit(): void {
        this.createEditDriverForm = new FormGroup({
            nameInput: new FormControl('', Validators.required),
            phoneNumberInput: new FormControl('', Validators.required),
            emailAddressInput: new FormControl(''),
            nationalityDropdown: new FormControl('', Validators.required),
            residingCountryDropdown: new FormControl('', Validators.required),
            preferredAddressInput: new FormControl('', Validators.required),
            residenceNumberInput: new FormControl('', Validators.required),
            residenceExpiryDateInput: new FormControl('', Validators.required),
            licenseNumberInput: new FormControl('', Validators.required),
            licenseExpiryDateInput: new FormControl('', Validators.required),
            currentVehicleDropdown: new FormControl('', Validators.required)
        });

        // Get driver ID from route parameters
        const driverId = this.route.snapshot.paramMap.get('id');

        if (driverId) {
            this.formTitle = 'Update Driver';
            this.driverId = Number(driverId);
            this.loadDriver(); // Load driver if ID exists
        } else {
            this.formTitle = 'Create Driver';
            this.initializeForNewDriver(); // Initialize for new driver
        }

        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' },{ label: 'Drivers', routerLink: '/fleet/drivers' }, { label: driverId ? 'Update' : 'Create' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    ngAfterViewInit(): void {
        this.googleMapsLibrariesLoaderService.lazyLoadMap().subscribe(_ =>
            this.googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement));
        if (!navigator.geolocation) {
            console.log('location is not supported');
        }
        navigator.geolocation.getCurrentPosition((position) => {
            //this.orderQuery.searchLocation = new LatLng(position.coords.latitude, position.coords.longitude);
        });
    }

    initializeForNewDriver() {
        // Initialize for a new driver (if needed)
        this.driver = new Driver();
        if (this.route.snapshot.queryParamMap.get('phoneNumber')) {
            this.driver.phoneNumber = this.route.snapshot.queryParamMap.get('phoneNumber')!;
            //this.phoneNumberInput.setValue(this.driver.phoneNumber);
        }
        if (this.route.snapshot.queryParamMap.get('isFreelancer'))
            this.isFreelancer = this.route.snapshot.queryParamMap.get('isFreelancer')! as unknown as boolean;
    }

    loadDriver() {
        this.isLoading = true;
        this.driverService.findDriverByFilter(undefined, this.driverId, undefined, undefined, true, 'en-US').subscribe(driverWrapper => {
            console.log(driverWrapper);
            this.driver = driverWrapper.data;
            this.selectedResidenceExpiryDate = new Date(this.driver.residenceExpiryDate);
            this.selectedLicenseExpiryDate = new Date(this.driver.licenseExpiryDate);
            this.updateFormValues();
            this.isLoading = false;
        });
    }

    updateFormValues() {
        this.createEditDriverForm.patchValue({
            nameInput: this.driver.name,
            phoneNumberInput: this.driver.phoneNumber,
            emailAddressInput: this.driver.emailAddress,
            nationalityDropdown: this.driver.nationality,
            residingCountryDropdown: this.driver.residingCountry,
            preferredAddressInput: this.driver.preferredAddress,
            residenceNumberInput: this.driver.residenceNumber,
            residenceExpiryDateInput: this.selectedResidenceExpiryDate,
            licenseNumberInput: this.driver.licenseNumber,
            licenseExpiryDateInput: this.selectedLicenseExpiryDate,
            currentVehicleDropdown: this.driver.currentVehicle
        });

        this.selectedCountry = this.countries.find(c => c.id === this.driver.nationality?.id);
        this.selectedResidingCountry = this.countries.find(c => c.id === this.driver.residingCountry?.id);
        this.selectedVehicle = this.availableVehicles.find(v => v.vehicleId === this.driver.currentVehicle?.vehicleId);
    }

    loadCountries() {
        this.countries = this.sessionStorageService.getCountryList() || [];
    }

    loadVehicles() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.vehicleService.findVehiclesByCompanyId(0, 10, companyId)
                .subscribe(response => {
                    this.availableVehicles = response.items;
                });
        }
    }

    onCountryChange(event: any) {
        this.selectedCountry = event.value;
        if (this.selectedCountry)
            this.driver.nationality = this.selectedCountry;
    }

    onResidingCountryChange(event: any) {
        this.selectedResidingCountry = event.value;
        if (this.selectedResidingCountry)
            this.driver.residingCountry = this.selectedResidingCountry;
    }

    onVehicleChange(event: any) {
        this.selectedVehicle = event.value;
        this.driver.currentVehicle = this.selectedVehicle;
    }

    saveDriver() {
        this.driverCreationInProgress = true;
        this.driver.legalName = this.driver.name;
        this.driver.residenceExpiryDate = this.selectedResidenceExpiryDate?.getTime() ?? new Date().getTime();
        this.driver.licenseExpiryDate = this.selectedLicenseExpiryDate?.getTime() ?? new Date().getTime();
        if (this.isFreelancer) {
            this.driverService.addFreelanceDriver(this.driver, this.sessionStorageService.getCompany()!.id)
                .then(promiseResponse => {
                promiseResponse.subscribe(response => { if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Driver ' + this.driver.name + ' is updated',
                        detail: 'Driver ' + this.driver.name + ' has been successfully updated'
                    });
                    this.router.navigate(['/fleet/drivers']);
                }
                this.driverCreationInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add driver.' + error.message });
                this.driverCreationInProgress = false;
            });
                });
        }
        else if (this.driver.id) {
            this.driver.currentTransportationAgency = this.sessionStorageService.getTransportationAgency()!;
            this.driverService.updateDriver(this.driver).then(promiseResponse => {
                promiseResponse.subscribe(response => {
                    if (response.success) {
                        this.messageService.add({
                            severity: 'success', summary: 'Driver ' + response.data.name + ' is updated',
                            detail: 'Driver ' + response.data.name + ' has been successfully updated'
                        });
                        this.router.navigate(['/fleet/drivers']);
                    }
                    this.driverCreationInProgress = false;
                }, error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to add driver.' + error.message
                    });
                    this.driverCreationInProgress = false;
                });
            });
        } else {
            this.driver.currentTransportationAgency = this.sessionStorageService.getTransportationAgency()!;
            this.driverService.addDriver(this.driver).then(promiseResponse => {
                promiseResponse.subscribe(response => {
                    if (response.success) {
                        this.messageService.add({
                            severity: 'success', summary: 'Driver ' + response.data.name + ' is created',
                            detail: 'Driver ' + response.data.name + ' has been successfully created'
                        });
                        this.router.navigate(['/fleet/drivers']);
                    }
                    this.driverCreationInProgress = false;
                }, error => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to add driver.' + error.message
                    });
                    this.driverCreationInProgress = false;
                });
            });
        }
    }

    clearForm() {
        this.createEditDriverForm.reset();
        this.driver = new Driver();
        this.selectedCountry = undefined;
        this.selectedResidingCountry = undefined;
        this.selectedVehicle = undefined;
    }

    onResidenceCardUpload(event: FileSelectEvent) {
        console.log(event.files);
        this.uploadPDFFile(event.files[0], 'RESIDENCE');
    }

    onLicenseCardUpload(event: FileSelectEvent) {
        console.log(event.files);
        this.uploadPDFFile(event.files[0], 'LICENSE');
    }

    uploadPDFFile(file: File, fileType: string) {
        this.driverCreationInProgress = true;

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(ref(storage, (fileType === 'RESIDENCE' ? '/residence_scan/' : '/license_scan/') + file.name), file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                this.pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.pdfUploadProgress = Math.round(this.pdfUploadProgress);
                if (this.pdfUploadProgress > 10)
                    this.pdfUploadProgress = this.pdfUploadProgress - 5;
                console.log('Upload is ' + this.pdfUploadProgress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file:", error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (fileType === 'RESIDENCE')
                        this.driver.residenceImage = downloadURL;
                    else this.driver.licenseImage = downloadURL;
                    this.driverCreationInProgress = false;
                }).catch((error) => {
                    console.error("Error getting download URL:", error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
                });
            }
        );
    }

    searchPlaces(event: AutoCompleteCompleteEvent) {
        const filtered: any[] = [];
        const displaySuggestions = (
            predictions: google.maps.places.QueryAutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
        ) => {
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                //alert(status);
                return;
            }
            predictions.forEach((prediction) => {
                filtered.push({ name: prediction.description, place_id: prediction.place_id });
            });
            this.searchPlaceResults = filtered;
        };
        // const autocompleteService = new google.maps.places.AutocompleteService();
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: event.query }, displaySuggestions);
    }

    placeSelect($event: AutoCompleteOnSelectEvent) {
        const place_id = $event.value.place_id;
        const placesService = new google.maps.places.PlacesService(this.googleMap);

        placesService.getDetails({
            placeId: place_id,
            fields: ['name', 'formatted_address', 'place_id', 'geometry', 'adr_address', 'address_components']
        }, (result, status) => {
            console.log(result);
            if (status != google.maps.places.PlacesServiceStatus.OK) {
                alert(status);
                return;
            } else {
                const lat = result?.geometry?.location?.lat().valueOf()!!;
                const lng = result?.geometry?.location?.lng().valueOf()!!;
                let geoAddress: GeoAddress = new GeoAddress();
                result?.address_components?.forEach(addressComponent => {
                    let geoElement = new GeoElement();
                    geoElement.name = addressComponent.short_name;
                    geoElement.types = addressComponent.types;
                    geoAddress.addressElements.push(geoElement);
                })
                this.driver.preferredLocation = new LatLng(lat, lng);
                this.driver.preferredAddress = result?.formatted_address ?? '';
                console.log('Preferred address updated');
            }
        });
    }
}
