import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem, PrimeIcons} from "primeng/api";
import {ActivatedRoute} from "@angular/router";
import {formatDate} from "@angular/common";
import {VehicleService} from "../vehicle.service";
import {Vehicle} from "../vehicle.model";
import {TraccarFleetService} from "../../traccar/traccar-fleet.service";
import {TraccarPosition} from "../../traccar/traccar-position.model";
import {TraccarDevice} from "../../traccar/traccar-device.model";

@Component({
  selector: 'app-telematics',
  templateUrl: './telematics.component.html',
  styleUrls: ['./telematics.component.css']
})
export class TelematicsComponent implements OnInit, AfterViewInit {

    vehicleId: number;
    vehicle: Vehicle;
    isLoading: boolean = true;
    initialLoading: boolean = true;
    currentGpsDevice: TraccarDevice;
    currentGpsPosition: TraccarPosition;
    gpsPositionList: TraccarPosition[] = [];

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    @ViewChild(google.maps.Map)
    map!: google.maps.Map;

    zoom = 17
    vehicleMarkerImage = "https://storage.googleapis.com/prologix.appspot.com/basic_data/admin_files/TractorUnitBlack.png"
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        mapTypeId: 'roadmap',
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        maxZoom: 20,
        minZoom: 4
    }
    vehicleLocation: google.maps.LatLngLiteral;
    polyline: google.maps.Polyline | null = null;

    constructor(private route: ActivatedRoute,
                private vehicleService: VehicleService,
                private traccarFleetService: TraccarFleetService) {
        this.vehicleLocation = { lat: 21.485811, lng: 39.192505 };
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log(params); // { order: "popular" }
            this.vehicleId = params['vehicleId'];
            console.log(this.vehicleId);
            this.vehicleService.findVehicleByIds('en-US', [this.vehicleId]).subscribe(vehicleWrapper => {
                this.vehicle = vehicleWrapper.data[0];
                this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' },
                    { label: 'Vehicles', routerLink: '/fleet/vehicle' },
                    { label: this.vehicle.plateNumber }];

                this.traccarFleetService.getGpsDevicesByName(this.vehicle.plateNumber).subscribe(gpsDevice => {
                    console.log(gpsDevice);
                    this.currentGpsDevice = gpsDevice[0];
                    if (this.initialLoading && this.currentGpsDevice) {
                        this.getFleetPositionsFromDevice();
                        this.initialLoading = false;
                    }
                })
            });

            this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };
        });
    }

    ngAfterViewInit() {
        if (this.map) {
            // Create and set the polyline on the map
            this.createOrUpdatePolyline();
        }
    }

    getFleetPositionsFromDevice() {
        const toDate = new Date();
        const fromDate = new Date(toDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours in milliseconds
        this.traccarFleetService.findPositionsByDeviceId(this.currentGpsDevice?.id ?? 0, fromDate, toDate).subscribe(value => {
            console.log(value);
            this.gpsPositionList = value.sort((a, b) => b.id - a.id);
            this.currentGpsPosition = this.gpsPositionList[0];
            this.vehicleLocation = { lat: this.currentGpsPosition?.latitude ?? 0.0, lng: this.currentGpsPosition?.longitude ?? 0.0 };
            this.center = this.vehicleLocation;
            this.isLoading = false;
            this.createOrUpdatePolyline();
            this.traccarFleetService.getGpsPositionByDeviceId(this.currentGpsDevice?.id ?? 0).subscribe(gpsPosition => {
                console.log(gpsPosition);
                if (gpsPosition != null) {
                    if (!this.gpsPositionList.find(value => value.id == gpsPosition.id)) {
                        this.currentGpsPosition = gpsPosition;
                        this.vehicleLocation = { lat: gpsPosition.latitude ?? 0.0, lng: gpsPosition.longitude ?? 0.0 };
                        this.center = this.vehicleLocation;
                        this.gpsPositionList.push(gpsPosition);
                        this.gpsPositionList.sort((a, b) => b.id - a.id);
                        this.createOrUpdatePolyline();
                    }
                }
            });
        });
    }


    showDate(longDate: number | undefined) {
        return longDate != undefined ? formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US') : undefined;
    }

    openInfo(_t13: HTMLElement) {
        throw new Error('Method not implemented.');
    }

    tabChange($event: Event) {

    }

    createOrUpdatePolyline() {
        if (this.polyline) {
            this.polyline.setMap(null); // Clear the existing polyline
            this.polyline = null;
        }

        if (this.gpsPositionList.length < 2) {
            console.error('At least two positions are required to create a polyline.');
            return;
        }

        const coordinates = this.gpsPositionList.map(position => {
            return { lat: position.latitude ?? 0.0, lng: position.longitude ?? 0.0 };
        });

        this.polyline = new google.maps.Polyline({
            path: coordinates,
            geodesic: true, // Use geodesic curves for more accurate distance calculations
            strokeColor: '#0000FF', // Customize color
            strokeOpacity: 0.8,
            strokeWeight: 3
        });

        this.polyline.setMap(this.map);
    }


}
