import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentCardType } from "../models/payments/payment-card-type.model";
import { PersonalPaymentMethod } from "../models/payments/personal-payment-method.model";
import { APIResponseWrapper } from "../models/common/api-response-wrapper";
import {PaymentMethod} from "../models/payments/payment-method.model";


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    private baseUrl = `${environment.restApiUrl}payment-endpoint`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    findAllPaymentMethodsByCountry(countryId: number): Observable<PaymentMethod[]> {
        const params = { countryId: countryId.toString() };
        return this.http.get<PaymentMethod[]>(`${this.baseUrl}/available-payments`, { params });
    }

    findAllPaymentCardsByCountry(countryId: number): Observable<PaymentCardType[]> {
        const params = { countryId: countryId.toString() };
        return this.http.get<PaymentCardType[]>(`${this.baseUrl}/available-payment-cards`, { params });
    }

    getAllPersonalPaymentMethods(firebaseId: string): Observable<PersonalPaymentMethod[]> {
        const params = { firebaseId };
        return this.http.get<PersonalPaymentMethod[]>(`${this.baseUrl}/personal-payments`, { params });
    }

    getDefaultPersonalPaymentMethods(firebaseId: string): Observable<PersonalPaymentMethod> {
        const params = { firebaseId };
        return this.http.get<PersonalPaymentMethod>(`${this.baseUrl}/personal-default-payment`, { params });
    }

    getPersonalPaymentMethodById(personalPaymentMethodId: number): Observable<PersonalPaymentMethod> {
        return this.http.get<PersonalPaymentMethod>(`${this.baseUrl}/personal-payments/${personalPaymentMethodId}`);
    }

    addDriver(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        return this.http.post<APIResponseWrapper<PersonalPaymentMethod>>(`${this.baseUrl}/personal-payments`, personalPaymentMethod);
    }

    deletePersonalPaymentMethod(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        return this.http.delete<APIResponseWrapper<PersonalPaymentMethod>>(`${this.baseUrl}/personal-payments`, { body: personalPaymentMethod });
    }

    updateDefaultPaymentMethod(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        return this.http.put<APIResponseWrapper<PersonalPaymentMethod>>(`${this.baseUrl}/default-payment-method`, personalPaymentMethod);
    }
}
