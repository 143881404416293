import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { tap } from "rxjs";
import { PersonalPaymentMethod } from "../../../../models/payments/personal-payment-method.model";
import { GeneratedReceiptsSummary } from "../generated-receipts-summary.model";
import { PaymentService } from "../../../../services/payment.service";
import { SessionStorageService } from "../../../../services/session-storage.service";
import {CustomerService} from "../../../../customer/customer.service";
import {Customer} from "../../../../customer/customer.model";
import {RadioButtonClickEvent} from "primeng/radiobutton";
import {AccountsService} from "../../../accounts.service";
import {Receipt} from "../../../receipt/receipt.model";
import {BaseMasterDataService} from "../../../../services/base-master-data.service";

@Component({
    selector: 'app-receipt-amount-dialog',
    templateUrl: './receipt-amount-dialog.component.html',
})
export class ReceiptAmountDialogComponent implements OnInit {
    receiptAmountDialogVisible = false;
    receiptAmountDialogHeader = 'Create Receipt';
    receiptAmount: number;
    personalPaymentMethods: PersonalPaymentMethod[] = [];
    selectedPaymentMethod: PersonalPaymentMethod;
    createReceiptInProgress: boolean = false;
    currentGeneratedReceiptsSummary: GeneratedReceiptsSummary;
    customers: Customer[] = [];
    selectedCustomer: Customer;

    constructor(
        private paymentService: PaymentService,
        private sessionStorageService: SessionStorageService,
        private baseMasterService: BaseMasterDataService,
        private accountsService: AccountsService,
        private customerService: CustomerService,
        private messageService: MessageService,
    ) {
    }

    ngOnInit(): void {
    }

    showDialog(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.currentGeneratedReceiptsSummary = generatedReceiptsSummary;
        this.customerService.getCustomersByCompany(generatedReceiptsSummary.client.id).subscribe(value => {
            this.customers = value;
            this.receiptAmountDialogVisible = true;
        }); // Assign customers
        if (this.customers.length > 0) {
            this.selectedCustomer = this.customers[0]; // Select first customer by default
        }
    }

    loadCustomerPaymentMethods(event: RadioButtonClickEvent) {
        this.paymentService.getAllPersonalPaymentMethods(event.value.firebaseId).subscribe(personalPaymentMethods => {
                this.personalPaymentMethods = personalPaymentMethods;
                if (this.personalPaymentMethods.length > 0) {
                    this.selectedPaymentMethod = this.personalPaymentMethods[0];
                }
            }
        );
    }

    createReceipt() {
        if (!this.selectedPaymentMethod || !this.receiptAmount || this.receiptAmount <= 0 || !this.selectedCustomer) {
            this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Please fill in all required fields.' });
            return;
        }
        this.createReceiptInProgress = true;
        let receipt = {
            odooReceiptDate: new Date(),
            isDigitalPayment: this.selectedPaymentMethod.paymentMethod.paymentType.isDigitalPayment,
            amount: this.receiptAmount,
            personalPaymentMethod: this.selectedPaymentMethod,
            country: this.sessionStorageService.getCountryList()!
                .find(value => value.id == this.currentGeneratedReceiptsSummary.client.countryId),
            customer: this.selectedCustomer,
            company: this.currentGeneratedReceiptsSummary.client,
            owningCompany: this.sessionStorageService.getCompany(),
            createdAt: new Date(),
            updatedAt: new Date(),
        } as Receipt;
        this.accountsService.createReceipt(receipt).subscribe(value => {
            this.createReceiptInProgress = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Receipt created successfully' });
            setTimeout(() => {
                this.receiptAmountDialogVisible = false;
                window.location.reload();
            }, 3000);
        })
    }
}
