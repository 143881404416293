import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { tap } from "rxjs";
import { PersonalPaymentMethod } from "../../../../models/payments/personal-payment-method.model";
import { PaymentService } from "../../../../services/payment.service";
import { SessionStorageService } from "../../../../services/session-storage.service";
import {GeneratedVouchersSummary} from "../generated-vouchers-summary.model";
import {Customer} from "../../../../customer/customer.model";
import {Company} from "../../../../company/company.model";
import {CompanyService} from "../../../../company/company.service";
import {CustomerService} from "../../../../customer/customer.service";
import {Receipt} from "../../../receipt/receipt.model";
import {AccountsService} from "../../../accounts.service";
import {Voucher} from "../voucher.model";

@Component({
    selector: 'app-voucher-amount-dialog',
    templateUrl: './voucher-amount-dialog.component.html',
})
export class VoucherAmountDialogComponent implements OnInit {

    voucherAmountDialogVisible = false;
    voucherAmountDialogHeader = 'Create Voucher';
    voucherAmount: number;
    personalPaymentMethods: PersonalPaymentMethod[] = [];
    selectedPaymentMethod: PersonalPaymentMethod;
    createVoucherPaymentInProgress: boolean = false;
    currentGeneratedVouchersSummary: GeneratedVouchersSummary;
    customers: Customer[] = [];
    selectedCustomer: Customer;

    constructor(
        private paymentService: PaymentService,
        private accountsService: AccountsService,
        private sessionStorageService: SessionStorageService,
        private customerService: CustomerService,
        private messageService: MessageService,
    ) {
    }

    ngOnInit(): void {
    }

    showDialog(generatedVouchersSummary: GeneratedVouchersSummary) {
        this.currentGeneratedVouchersSummary = generatedVouchersSummary;
        this.customers = [];
        this.personalPaymentMethods = [];
        if (generatedVouchersSummary.clientId === 0) {
            this.customers.push({
                id: generatedVouchersSummary.contactId,
                name: generatedVouchersSummary.clientName
            } as Customer);
            this.personalPaymentMethods.push({
                personalPaymentId: 0,
                paymentCardName: 'Cash',
            } as PersonalPaymentMethod);
            if (generatedVouchersSummary.selectedVouchers)
                this.voucherAmount = generatedVouchersSummary.selectedVouchers
                .reduce((total, voucher) => total + (voucher?.amount ?? 0), 0);
            this.voucherAmountDialogVisible = true;
        }
        else this.customerService.getCustomersByCompany(generatedVouchersSummary.clientId).subscribe(value => {
            this.customers = value;
            if (this.customers.length > 0) {
                this.selectedCustomer = this.customers[0]; // Select first customer by default
            }
            this.loadCustomerPaymentMethods().subscribe(() => {
                this.voucherAmountDialogVisible = true;
            });
        });
    }

    loadCustomerPaymentMethods() {
        return this.paymentService.getAllPersonalPaymentMethods(this.sessionStorageService.getCustomer()?.firebaseId ?? '').pipe(
            tap(personalPaymentMethods => {
                this.personalPaymentMethods = personalPaymentMethods;
                if (this.personalPaymentMethods.length > 0) {
                    this.selectedPaymentMethod = this.personalPaymentMethods[0];
                }
            })
        );
    }

    createVoucherPayment() {
        if (!this.selectedPaymentMethod || !this.voucherAmount || this.voucherAmount <= 0 || !this.selectedCustomer) {
            this.messageService.add({ severity: 'error', summary: 'Validation Error', detail: 'Please fill in all required fields.' });
            return;
        }
        this.createVoucherPaymentInProgress = true;
        let voucher = {
            odooVoucherDate: new Date(),
            isDigitalPayment: this.selectedPaymentMethod.paymentCardName === 'Cash',
            amount: this.voucherAmount,
            personalPaymentMethod: this.selectedPaymentMethod,
            country: this.sessionStorageService.getCountryList()!
                .find(value => value.id === this.sessionStorageService.getCompany()?.countryId),
            customer: this.selectedCustomer,
            company: {
                id: this.currentGeneratedVouchersSummary.clientId,
                name: this.currentGeneratedVouchersSummary.clientName,
            } as Company,
            owningCompany: this.sessionStorageService.getCompany(),
            createdAt: new Date(),
            updatedAt: new Date(),
        } as Voucher;
        this.accountsService.createVoucher(voucher).subscribe(value => {
            this.createVoucherPaymentInProgress = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Voucher created successfully' });
            setTimeout(() => {
                this.voucherAmountDialogVisible = false;
                window.location.reload();
            }, 3000);
        })
    }
}
