import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { ProductVariant } from "../../models/product-variant.model";
import { Products } from "../../models/product.model";
import { ProductsService } from "../../product.service";
import { SessionStorageService } from "../../../services/session-storage.service";
import { ProductType } from "../../models/product-type.model";

@Component({
    selector: 'app-add-edit-product',
    templateUrl: './add-edit-product.component.html',
    styleUrls: ['./add-edit-product.component.css']
})
export class AddEditProductComponent implements OnInit {
    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};
    product!: Products;
    productForm!: FormGroup;
    editMode = false;
    productId!: number;
    isLoading: boolean = false;
    productTypes: SelectItem<string>[] = [];

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private productService: ProductsService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Products', routerLink: '/order/products' },
            { label: this.editMode ? 'Edit Product' : 'Add Product' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.productId = params['id'];
            this.editMode = !!this.productId;
            if (this.editMode) {
                this.loadProduct();
            } else {
                this.product = new Products();
            }
        });
        this.loadProductTypes();
        this.initForm();
    }

    loadProduct() {
        this.isLoading = true;
        this.productService.getProductById(this.productId).subscribe({
            next: (product: Products) => {
                this.product = product;
                // Patch values except for productType
                const { productType, ...productWithoutProductType } = product;
                this.productForm.patchValue(productWithoutProductType);

                // Manually set the selected ProductType in the dropdown
                this.productForm.get('productType')?.setValue(productType?.valueOf());

                this.productForm.setControl('productVariants', this.fb.array(product.productVariants
                    .map(variant => this.createProductVariantFormGroup(variant))));
                this.isLoading = false;
            },
            error: (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading product'
                });
                this.isLoading = false;
            }
        });
    }

    loadProductTypes() {
        this.productTypes = Object.values(ProductType).map((type) => ({
            value: type,
            label: type,
        }));
    }

    initForm() {
        this.productForm = this.fb.group({
            productID: [null],
            name: ['', Validators.required],
            productCode: ['', Validators.required],
            productType: [null, Validators.required],
            standardRate: [null, Validators.required],
            taxRate: [''],
            description: [''],
            createdAt: [null],
            createdBy: [null],
            updatedAt: [null],
            updatedBy: [null],
            productVariants: this.fb.array([this.createProductVariantFormGroup()])
        });
    }

    createProductVariantFormGroup(productVariant?: ProductVariant): FormGroup {
        return this.fb.group({
            variantID: [productVariant?.variantID ?? null],
            name: [productVariant?.name ?? ''],
            variantCode: [productVariant?.variantCode ?? ''],
            rateAdjustment: [productVariant?.rateAdjustment ?? 0],
            createdAt: [productVariant?.createdAt ?? null],
            createdBy: [productVariant?.createdBy ?? null],
            updatedAt: [productVariant?.updatedAt ?? null],
            updatedBy: [productVariant?.updatedBy ?? null]
        });
    }

    get getProductVariantControls(): FormArray {
        return this.productForm.get('productVariants') as FormArray;
    }

    addProductVariant() {
        this.getProductVariantControls.push(this.createProductVariantFormGroup());
    }

    removeProductVariant(index: number) {
        this.getProductVariantControls.removeAt(index);
    }

    saveProduct() {
        if (this.productForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const modifiedProduct = this.productForm.value as Products;
        modifiedProduct.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        let userId = this.sessionStorageService.getCustomer()!.id;
        modifiedProduct.updatedAt = new Date();
        modifiedProduct.updatedBy = userId;
        if (this.product.currencyCode)
            modifiedProduct.currencyCode = this.product.currencyCode;
        else modifiedProduct.currencyCode = this.sessionStorageService.getCountryList()?.
                    find(country => country.id === this.sessionStorageService.getCompany()?.countryId)?.currencyCode ?? 'SAR'
        if (this.editMode) {
            modifiedProduct.createdAt = this.product.createdAt;
            modifiedProduct.createdBy = this.product.createdBy;
        } else {
            modifiedProduct.createdAt = new Date();
            modifiedProduct.createdBy = userId;
        }
        modifiedProduct.productVariants.forEach(variant => {
            variant.owningCompanyId = this.sessionStorageService.getCompany()!.id;
            variant.updatedAt = new Date();
            variant.updatedBy = userId;
            if (variant.variantID) {
                const originalVariant = this.product.productVariants.find(v => v.variantID === variant.variantID);
                if (originalVariant) {
                    variant.createdAt = originalVariant.createdAt;
                    variant.createdBy = originalVariant.createdBy;
                }
            } else {
                variant.createdAt = new Date();
                variant.createdBy = userId;
            }
        });

        this.productService.createProduct(modifiedProduct).subscribe({
            next: (newProduct: Products) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Product saved successfully.'
                });
                this.router.navigate(['/order/products']);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving modifiedProduct'
                });
            }
        });
    }

    cancel() {
        this.location.back();
    }
}
