<p-toast></p-toast>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createEditContractForm" novalidate autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="fa-solid fa-file-contract mr-2" style="font-size: 1.5rem"></i> <!— Contract icon -->
                <div class="font-medium text-xl text-900">Create/Update Contract</div>
            </div><br>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-hashtag"></i></span>
                        <input type="text" pInputText [(ngModel)]="createEditContract.contractNumber"
                               placeholder="Contract Number" formControlName="contractNumberInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-user-group"></i></span> <!— First Party icon -->
                        <p-dropdown id="firstParty" [options]="parties" [(ngModel)]="createEditContract.firstParty"
                                    optionLabel="name" placeholder="First Party" [autoDisplayFirst]="false"
                                    [filter]="true" filterBy="name" [showClear]="true" (onChange)="onFirstPartyChanged()"
                                    formControlName="firstPartyDropdown" [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                    <p-button label="Add" icon="fa-solid fa-plus" iconPos="left" styleClass="p-button-success"
                                              class="ml-3" (onClick)="registerParty('secondParty')"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-party pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ party.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-user-tie"></i></span> <!— Rep icon -->
                        <p-dropdown id="firstPartyRep" [options]="firstPartyReps" [(ngModel)]="createEditContract.firstPartyRep"
                                    optionLabel="name" placeholder="First Party Rep" [autoDisplayFirst]="false"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="firstPartyRepDropdown" [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                    <p-button label="Add" icon="fa-solid fa-plus" iconPos="left" styleClass="p-button-success"
                                              class="ml-3" (onClick)="registerPartyRep('firstParty')"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-rep pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ rep.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-user-group"></i></span> <!— Second Party icon -->
                        <p-dropdown id="secondParty" [options]="parties" [(ngModel)]="createEditContract.secondParty"
                                    optionLabel="name" placeholder="Second Party" [autoDisplayFirst]="false"
                                    [filter]="true" filterBy="name" [showClear]="true" (onChange)="onSecondPartyChanged()"
                                    formControlName="secondPartyDropdown" [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                    <p-button label="Add" icon="fa-solid fa-plus" iconPos="left" styleClass="p-button-success"
                                              class="ml-3" (onClick)="registerParty('secondParty')"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-party pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ party.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-user-tie"></i></span> <!— Rep icon -->
                        <p-dropdown id="secondPartyRep" [options]="secondPartyReps" [(ngModel)]="createEditContract.secondPartyRep"
                                    optionLabel="name" placeholder="Second Party Rep" [autoDisplayFirst]="false"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="secondPartyRepDropdown" [style]="{'width': '100%'}">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                    <p-button label="Add" icon="fa-solid fa-plus" iconPos="left" styleClass="p-button-success"
                                              class="ml-3" (onClick)="registerPartyRep('secondParty')"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-rep pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ rep.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="{{ createEditContract.contractID ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
                              [loading]="contractCreationInProgress" class="m-1 px-1 py-1" (onClick)="createUpdateContract()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                              class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>

<app-customer-form
    [displayModal]="showCustomerForm"
    [dialogHeader]="customerFormHeader"
    [submitButtonLabel]="customerFormSubmitLabel"
    [company]="selectedCompany"
    [customer]="selectedCustomer"
    (modalClosed)="onCustomerFormClosed()">
</app-customer-form>

<app-company-form
    [displayModal]="showCompanyForm"
    [dialogHeader]="companyFormHeader"
    [submitButtonLabel]="companyFormSubmitLabel"
    [company]="selectedCompany"
    (modalClosed)="onCompanyFormClosed()">
</app-company-form>

