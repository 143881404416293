import {AfterViewInit, Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeoAddress } from '../../models/common/geo-address.model';
import { GeoElement } from '../../models/common/geo-element.model';
import { LatLng } from '../../models/common/lat-lng';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem, Message, MessageService} from 'primeng/api';
import { SessionStorageService } from '../../services/session-storage.service';
import { CompanyService } from '../../company/company.service';
import { CustomerService } from '../../customer/customer.service';
import { GoogleMapsLibrariesLoaderService } from '../../services/google/google-libraries-loader.service';
import { Location } from '../location.model';
import { LocationService } from '../location.service';
import { AutoCompleteOnSelectEvent } from 'primeng/autocomplete';
import { Customer } from '../../customer/customer.model';
import { Company } from '../../company/company.model';

@Component({
    selector: 'app-create-location',
    templateUrl: './create-location.component.html',
    styleUrls: ['./create-location.component.css']
})
export class CreateLocationComponent implements OnInit, AfterViewInit {

    googleMap: any;
    createEditLocationForm: FormGroup<any>;
    createContactForm: FormGroup<any>;
    searchLocation: FormControl;
    searchDefaultContact: FormControl;
    companyDropdown: FormControl;
    locationTypeDropdown: FormControl;
    externalLocationReferenceInput: FormControl;
    nameInput: FormControl;


    searchPlaceResults: string[];
    locationId: number;
    isLoading: boolean = false;
    createEditLocation: Location = new Location();
    customerList: Customer[];
    searchContactResults: Customer[];
    companyList: Company[];
    locationTypes = [{
        name: 'WAREHOUSE',
        value: 'WAREHOUSE'
    }, {
        name: 'OFFICE',
        value: 'OFFICE'
    }, {
        name: 'CUSTOMER',
        value: 'CUSTOMER'
    }]
    locationCreationInProgress: boolean = false;
    errorMessages: string = '';
    errorMessageList: Message[] = [];

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;
    companyFormHeader: string;
    companyFormSubmitLabel: string;
    selectedCustomer: Customer = new Customer();
    selectedCompany: Company = new Company();
    showCompanyForm: boolean = false;
    customerFormHeader: string;
    customerFormSubmitLabel: string;
    showCustomerForm: boolean = false;


    constructor(private sessionStorageService: SessionStorageService, private route: ActivatedRoute,
                private readonly googleMapsLibrariesLoaderService: GoogleMapsLibrariesLoaderService,
                private customerService: CustomerService, private router: Router,
                private messageService: MessageService, private companyService: CompanyService,
                private angularFireAuth: AngularFireAuth, private locationService: LocationService) {
    }

    ngOnInit(): void {
        this.createEditLocationForm = new FormGroup({
            searchLocation: new FormControl('', Validators.required),
            searchDefaultContact: new FormControl('', Validators.required),
            countryDropdown: new FormControl('', Validators.required),
            companyDropdown: new FormControl('', Validators.required),
            locationTypeDropdown: new FormControl('', Validators.required),
            externalLocationReferenceInput: new FormControl('', Validators.required),
            nameInput: new FormControl('', Validators.required),
        });
        this.createContactForm = new FormGroup({
            clientSelectDropdown: new FormControl('', Validators.required),
            contactNameInput: new FormControl('', Validators.required),
            contactPhoneInput: new FormControl('', Validators.required),
        });

        // Get location ID from route parameters
        const locationId = this.route.snapshot.paramMap.get('id');

        if (locationId) {
            this.locationId = Number(locationId);
            this.loadLocation(); // Load location if ID exists
        } else {
            this.initializeForNewLocation(); // Initialize for new location
        }

        this.breadcrumbItems = [{ label: 'Locations', routerLink: '/location' }, { label: locationId ? 'Update' : 'Create' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    ngAfterViewInit(): void {
        this.googleMapsLibrariesLoaderService.lazyLoadMap().subscribe(_ =>
            this.googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement));
        if (!navigator.geolocation) {
            console.log('location is not supported');
        }
        navigator.geolocation.getCurrentPosition((position) => {
            //this.orderQuery.searchLocation = new LatLng(position.coords.latitude, position.coords.longitude);
        });
    }

    initializeForNewLocation() {
        // Initialize for a new location (if needed)
        this.createEditLocation = new Location();
        this.createEditLocation.country = this.sessionStorageService.getCountryList()?.find(country => country.countryCode == 'SA')!!;
        this.companyService.getCompaniesByCountry(this.createEditLocation.country.id).subscribe(companies => {
            this.companyList = companies;
        });
    }

    loadLocation() {
        this.isLoading = true;
        this.locationService.getLocationById(this.locationId).subscribe(locationWrapper => {
            console.log(locationWrapper);
            this.createEditLocation = locationWrapper.data[0];
            this.updateFormValues();
            this.isLoading = false;
            if (this.createEditLocation.country)
                this.companyService.getCompaniesByCountry(this.createEditLocation.country.id).subscribe(companies => {
                    this.companyList = companies;
                });
        });
    }

    updateFormValues() {
        this.createEditLocationForm.patchValue({
            searchLocation: this.createEditLocation.name, // Assuming you want to display the name in the search field
            searchDefaultContact: this.createEditLocation.defaultContact,
            countryDropdown: this.createEditLocation.country,
            companyDropdown: this.createEditLocation.company,
            locationTypeDropdown: this.createEditLocation.locationType,
            externalLocationReferenceInput: this.createEditLocation.externalLocationReference,
            nameInput: this.createEditLocation.name
        });
    }

    searchPlaces(event: { query: any; }) {
        const filtered: any[] = [];
        const displaySuggestions = (
            predictions: google.maps.places.QueryAutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
        ) => {
            if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
                //alert(status);
                return;
            }
            predictions.forEach((prediction) => {
                filtered.push({ name: prediction.description, place_id: prediction.place_id });
            });
            this.searchPlaceResults = filtered;
        };

        // const autocompleteService = new google.maps.places.AutocompleteService();
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: event.query }, displaySuggestions);
    }

    placeSelect($event: any): void {
        const place_id = $event.value.place_id;
        const placesService = new google.maps.places.PlacesService(this.googleMap);
        this.createEditLocation.name = $event.value.name;

        placesService.getDetails({
            placeId: place_id,
            fields: ['name', 'formatted_address', 'place_id', 'geometry', 'adr_address', 'address_components', 'photos', 'international_phone_number']
        }, (result, status) => {
            if (status != google.maps.places.PlacesServiceStatus.OK) {
                alert(status);
                return;
            } else {
                const lat = result?.geometry?.location?.lat().valueOf()!!;
                const lng = result?.geometry?.location?.lng().valueOf()!!;
                let geoAddress: GeoAddress = new GeoAddress();
                result?.address_components?.forEach(addressComponent => {
                    let geoElement = new GeoElement();
                    geoElement.name = addressComponent.short_name;
                    geoElement.types = addressComponent.types;
                    geoAddress.addressElements.push(geoElement);
                })
                this.createEditLocation.location = new LatLng(lat, lng);
                this.createEditLocation.address = geoAddress;
                this.createEditLocation.telephoneNumber = result?.international_phone_number ?? null;
                this.createEditLocation.buildingImage = result?.photos?.[0]?.getUrl() ?? null;
                this.createEditLocation.helperImage1 = result?.photos?.[1]?.getUrl() ?? null;
                this.createEditLocation.helperImage2 = result?.photos?.[2]?.getUrl() ?? null;
                this.createEditLocation.helperImage3 = result?.photos?.[3]?.getUrl() ?? null;
            }
        });
    }

    async searchContacts(event: { query: string; }): Promise<void> {
        if (event.query.length < 13) return;
        if ((event.query.startsWith('+966') || event.query.startsWith('+971')) && event.query.length != 13) return;
        let contactWrapped = await this.customerService.getCustomerByMobileNumber(event.query).toPromise();
        console.log(JSON.stringify(contactWrapped));
        if (contactWrapped?.success)
            this.searchContactResults = [contactWrapped.data];
        else {
            this.messageService.add({
                severity: 'warn', summary: 'Contact not found',
                detail: 'The contact with the entered mobile number is not found in the system, please register the contact first!'
            });
            let countryId = this.sessionStorageService.getCountryList()!.find(country => country.countryCode == 'SA')!.id;
            this.companyList = (await this.companyService.getCompaniesByCountry(countryId).toPromise())!!;
            this.registerContact(event.query);
        }
    }

    contactSelect(event: AutoCompleteOnSelectEvent): void {
        this.createEditLocation.defaultContact = event.value;
    }

    createUpdateLocation() {
        this.locationCreationInProgress = true;
        this.validateLocationBeforeSave();
        if (this.errorMessages.length > 0) {
            this.locationCreationInProgress = false;
            console.log(this.errorMessages);
            this.messageService.add({
                severity: 'error', summary: 'Location ' + this.createEditLocation.name + ' is NOT created',
                detail: 'Location ' + this.createEditLocation.name + ' is not created: ' + this.errorMessages
            });
            return;
        }
        this.createEditLocation.managedBy = this.sessionStorageService.getCustomer()!!.company;
        if (this.createEditLocation.id) {
            this.locationService.updateLocation(this.createEditLocation).subscribe(createLocationResponse => {
                this.locationCreationInProgress = false;
                if (createLocationResponse.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Location ' + createLocationResponse.data.name + ' is updated',
                        detail: 'Location ' + createLocationResponse.data.name + ' has been successfully updated'
                    });
                    this.router.navigate(['/location']);
                }
            }, error => {
                this.locationCreationInProgress = false;
                this.messageService.add({
                    severity: 'error', summary: 'Location ' + this.createEditLocation.name + ' is NOT created',
                    detail: 'Location ' + this.createEditLocation.name + ' is not created: ' + JSON.stringify(error)
                });
            });
        } else {
            this.locationService.createLocation(this.createEditLocation).subscribe(createLocationResponse => {
                this.locationCreationInProgress = false;
                if (createLocationResponse.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Location ' + createLocationResponse.data.name + ' is created',
                        detail: 'Location ' + createLocationResponse.data.name + ' has been successfully created'
                    });
                    this.router.navigate(['/location']);
                }
            }, error => {
                this.locationCreationInProgress = false;
                this.messageService.add({
                    severity: 'error', summary: 'Location ' + this.createEditLocation.name + ' is NOT created',
                    detail: 'Location ' + this.createEditLocation.name + ' is not created: ' + JSON.stringify(error)
                });
            });
        }
    }

    registerCompany() {
        this.companyFormHeader = 'Create New Company';
        this.companyFormSubmitLabel = 'Create';
        this.selectedCustomer = new Customer(); // Clear for new customer
        this.selectedCompany = new Company(); // Clear for new company
        this.showCompanyForm = true;
    }

    registerContact(phoneNumber: string) {
        this.customerFormHeader = 'Create New Representative';
        this.customerFormSubmitLabel = 'Create';
        this.selectedCustomer = new Customer(); // Clear for new customer
        this.selectedCustomer.phoneNumber = phoneNumber;
        this.selectedCompany = this.createEditLocation.company; // Populate with customer data for editing
        this.showCustomerForm = true;
    }

    onCustomerFormClosed() {
        this.showCustomerForm = false;
        this.customerService.getCustomersByCompany(this.createEditLocation.company.id).subscribe(value => {
            this.customerList = value;
            this.createEditLocation.defaultContact = value.sort((a, b) => a.id - b.id).pop()!!;
            this.searchContactResults = [this.createEditLocation.defaultContact];
            this.searchDefaultContact.setValue(this.createEditLocation.defaultContact);
        });
    }

    onCompanyFormClosed() {
        this.showCompanyForm = false;
        let countryId = this.sessionStorageService.getCountryList()!.find(country => country.countryCode == 'SA')!.id;
        this.companyService.getCompaniesByCountry(countryId).subscribe(companies => {
            this.companyList = companies;
            this.createEditLocation.company = companies.sort((a, b) => a.id - b.id).pop()!!;
        });
    }

    validateLocationBeforeSave() {
        this.errorMessages = '';
        if (!this.createEditLocation.name)
            this.errorMessages += 'Location name is required. ';
        if (!this.createEditLocation.locationType)
            this.errorMessages += 'Location type is required. ';
        if (!this.createEditLocation.company)
            this.errorMessages += 'Parent company is required. ';
        if (!this.createEditLocation.country)
            this.errorMessages += 'Country is required. ';
        if (!this.createEditLocation.location)
            this.errorMessages += 'Location coordinates are required. ';
        if (!this.createEditLocation.externalLocationReference)
            this.errorMessages += 'External location reference is required. ';
        if (!this.createEditLocation.defaultContact)
            this.errorMessages += 'Default contact is required. ';
    }

}
