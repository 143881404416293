import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentCardType } from "../models/payments/payment-card-type.model";
import { PersonalPaymentMethod } from "../models/payments/personal-payment-method.model";
import { APIResponseWrapper } from "../models/common/api-response-wrapper";
import {PaymentMethod} from "../models/payments/payment-method.model";
import {PaymentTransactions} from "../accounts/payment-transactions/payment-transactions.model";
import {PaymentTransactionsView} from "../accounts/payment-transactions/payment-transactions-view.model";
import {Receipt} from "../accounts/receipt/receipt.model";
import {Voucher} from "../accounts/payables/generated-vouchers/voucher.model";


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    private baseUrl = `${environment.restApiUrl}payment-endpoint`; // Adjust the base URL if needed

    constructor(private http: HttpClient) { }

    /**
     * Retrieves all available payment methods by country.
     * @param countryId The ID of the country.
     * @returns An Observable of a list of PaymentMethod objects.
     */
    findAllPaymentMethodsByCountry(countryId: number): Observable<PaymentMethod[]> {
        const url = `${this.baseUrl}/available-payments`;
        let params = new HttpParams().set('countryId', countryId.toString());
        return this.http.get<PaymentMethod[]>(url, {params});
    }

    /**
     * Retrieves all available payment cards by country.
     * @param countryId The ID of the country.
     * @returns An Observable of a list of PaymentCardType objects.
     */
    findAllPaymentCardsByCountry(countryId: number): Observable<PaymentCardType[]> {
        const url = `${this.baseUrl}/available-payment-cards`;
        let params = new HttpParams().set('countryId', countryId.toString());
        return this.http.get<PaymentCardType[]>(url, {params});
    }

    /**
     * Retrieves all personal payment methods for a user.
     * @param firebaseId The firebase ID of the user.
     * @returns An Observable of a list of PersonalPaymentMethod objects.
     */
    getAllPersonalPaymentMethods(firebaseId: string): Observable<PersonalPaymentMethod[]> {
        const url = `${this.baseUrl}/personal-payments`;
        let params = new HttpParams().set('firebaseId', firebaseId);
        return this.http.get<PersonalPaymentMethod[]>(url, {params});
    }

    /**
     * Retrieves the default personal payment method for a user.
     * @param firebaseId The firebase ID of the user.
     * @returns An Observable of a PersonalPaymentMethod object.
     */
    getDefaultPersonalPaymentMethods(firebaseId: string): Observable<PersonalPaymentMethod> {
        const url = `${this.baseUrl}/personal-default-payment`;
        let params = new HttpParams().set('firebaseId', firebaseId);
        return this.http.get<PersonalPaymentMethod>(url, {params});
    }

    /**
     * Retrieves a personal payment method by its ID.
     * @param personalPaymentMethodId The ID of the personal payment method.
     * @returns An Observable of a PersonalPaymentMethod object.
     */
    getPersonalPaymentMethodById(personalPaymentMethodId: number): Observable<PersonalPaymentMethod> {
        const url = `${this.baseUrl}/personal-payments/${personalPaymentMethodId}`;
        return this.http.get<PersonalPaymentMethod>(url);
    }

    /**
     * Adds a new personal payment method.
     * @param personalPaymentMethod The PersonalPaymentMethod object to add.
     * @returns An Observable of APIResponseWrapper<PersonalPaymentMethod> object.
     */
    addPaymentMethod(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        const url = `${this.baseUrl}/personal-payments`;
        return this.http.post<APIResponseWrapper<PersonalPaymentMethod>>(url, personalPaymentMethod);
    }

    /**
     * Deletes a personal payment method.
     * @param personalPaymentMethod The PersonalPaymentMethod object to delete (typically only ID is required).
     * @returns An Observable of APIResponseWrapper<PersonalPaymentMethod> object.
     */
    deletePersonalPaymentMethod(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        const url = `${this.baseUrl}/personal-payments`;
        return this.http.delete<APIResponseWrapper<PersonalPaymentMethod>>(url, { body: personalPaymentMethod });
    }

    /**
     * Updates the default payment method.
     * @param personalPaymentMethod The PersonalPaymentMethod object to update.
     * @returns An Observable of APIResponseWrapper<PersonalPaymentMethod> object.
     */
    updateDefaultPaymentMethod(personalPaymentMethod: PersonalPaymentMethod): Observable<APIResponseWrapper<PersonalPaymentMethod>> {
        const url = `${this.baseUrl}/default-payment-method`;
        return this.http.put<APIResponseWrapper<PersonalPaymentMethod>>(url, personalPaymentMethod);
    }

    /**
     * Creates a new payment transaction.
     * @param paymentTransaction The PaymentTransactions object to create.
     * @param paymentType get or make payment
     * @returns An Observable of the created PaymentTransactions object.
     */
    createPaymentTransaction(paymentTransaction: PaymentTransactions, paymentType: string): Observable<PaymentTransactions> {
        const url = `${this.baseUrl}/transactions/${paymentType}`;
        return this.http.post<PaymentTransactions>(url, paymentTransaction);
    }

    /**
     * Retrieves all payment transactions for a specific company.
     * @param companyId The ID of the company.
     * @returns An Observable of a list of PaymentTransactions objects.
     */
    getPaymentTransactionsByCompany(companyId: number): Observable<PaymentTransactionsView[]> {
        const url = `${this.baseUrl}/transactions/company/${companyId}`;
        return this.http.get<PaymentTransactionsView[]>(url);
    }

    /**
     * Generates a payment transaction from a list of receipts.
     * @param receiptList An array of Receipt objects.
     * @param ownerFirebaseId
     * @returns An Observable of a PaymentTransactions object.
     */
    generatePaymentFromReceipts(receiptList: Receipt[], ownerFirebaseId: string): Observable<PaymentTransactions> {
        const url = `${this.baseUrl}/register-payment/enterprise/generate/${ownerFirebaseId}`;
        return this.http.post<PaymentTransactions>(url, receiptList);
    }

    /**
     * Generates a payment transaction from a list of vouchers.
     * @param voucherList An array of Voucher objects.
     * @param ownerFirebaseId
     * @returns An Observable of a PaymentTransactions object.
     */
    generatePaymentFromVouchers(voucherList: Voucher[], ownerFirebaseId: string): Observable<PaymentTransactions> {
        const url = `${this.baseUrl}/make-payment/enterprise/generate/${ownerFirebaseId}`;
        return this.http.post<PaymentTransactions>(url, voucherList);
    }

}
