<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-accordion *ngIf="generatedReceiptsSummaryList.length > 0">
    <p-accordionTab *ngFor="let generatedReceiptsSummary of generatedReceiptsSummaryList">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image src="{{ generatedReceiptsSummary?.client?.logo }}" alt="Image" height="30px"
                                 class="mr-2"></p-image>
                        <h4>{{ generatedReceiptsSummary.client.name }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ generatedReceiptsSummary.totalUnAppliedAmount }}
                            </p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Unapplied</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ generatedReceiptsSummary.totalAppliedAmount }}
                            </p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Applied</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="generatedReceiptsSummary.receipts" dataKey="receiptId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="rows" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="generatedReceiptsSummary.totalReceipts" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadData($event, generatedReceiptsSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="generatedReceiptsSummary.selectedReceipts">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!generatedReceiptsSummary.receipts"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Generated Receipts</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Apply Receipt" icon="fa-solid fa-sliders" (onClick)="applyReceiptOnInvoice(generatedReceiptsSummary)"
                                  class="p-2" severity="warning"></p-button>
                        <p-button label="Create Receipt" icon="fa-solid fa-wallet" (onClick)="showReceiptAmountDialog(generatedReceiptsSummary)"
                                  class="p-2" severity="info"></p-button>
                        <p-button label="Register Payment" icon="fa-solid fa-trowel-bricks" (onClick)="generatePaymentFromReceipts(generatedReceiptsSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(generatedReceiptsSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>Receipt#</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Applied</th>
                    <th>UnApplied</th>
                    <th>Linked Invoices</th>
                    <th>Contact</th>
                    <th>Payment Transaction#</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-receipt let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="receipt"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="receipt"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ receipt.odooReceiptNumber }}</td>
                    <td>{{ receipt.odooReceiptDate | date:'yyyy-MM-dd' }}</td>
                    <td>{{ receipt.country.currencyCode }} {{ receipt.amount }}</td>
                    <td>{{ receipt.country.currencyCode }} {{ getAppliedAmount(receipt) }}</td>
                    <td>{{ receipt.country.currencyCode }} {{ getUnAppliedAmount(receipt) }}</td>
                    <td>
                        <ul>
                            <li *ngFor="let invoice of receipt.invoices">{{ invoice.odooInvoiceNumber }}: {{ receipt.country.currencyCode }} {{ invoice.totalAmount }}</li>
                        </ul>
                    </td>
                    <td>
                        <p-chip *ngIf="receipt.customer" [label]="receipt.customer?.name">
                            <img src="{{ receipt.customer.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ receipt.customer.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>
                    <td>{{ receipt.paymentTransactionNumber }}</td>
                    <td>{{ receipt.personalPaymentMethod?.paymentCardName }}</td>
                    <td>
                        <p-tag *ngIf="receipt.paymentTransactionNumber" icon="fa-solid fa-check" severity="success" value="CLOSED"></p-tag>
                        <p-tag *ngIf="!receipt.paymentTransactionNumber" icon="fa-solid fa-close" severity="danger" value="PENDING"></p-tag>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{ generatedReceiptsSummary.totalReceipts}} receipts.
                </div>
            </ng-template>
        </p-table>
    </p-accordionTab>
</p-accordion>

<app-receipt-amount-dialog></app-receipt-amount-dialog>
