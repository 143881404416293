/* eslint-disable */
import { VehicleCapacity } from "./vehicle-capacity";
import { VehicleConfig } from "./vehicle-config";
import { VehicleType } from "./vehicle-type";

export class VehicleParaPackView {

    vehicleType: VehicleType | undefined;
    vehicleConfig: VehicleConfig | undefined;
    vehicleCapacity: VehicleCapacity | undefined;

}
