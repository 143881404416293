<p-toast [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="salesOrderForm" (ngSubmit)="saveSalesOrder()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Sales Order' : 'Add Sales Order' }}
            </div>

            <div class="p-fluid grid">
                <div *ngIf="editMode" class="col-12 md:col-6">
                    <label for="orderNumber">Order Number</label>
                    <input type="text" pInputText formControlName="orderNumber" id="orderNumber" />
                    <small class="p-error" *ngIf="salesOrderForm.get('orderNumber')?.hasError('required') && salesOrderForm.get('orderNumber')?.touched">Order Number is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="company">Company</label>
                    <p-dropdown id="company" [options]="companies" formControlName="company" placeholder="Select a Company" optionValue="value"
                                optionLabel="label" [required]="true" [filter]="true" (onChange)="loadCustomersByCompany($event)"></p-dropdown>
                    <small class="p-error" *ngIf="salesOrderForm.get('company')?.hasError('required') && salesOrderForm.get('company')?.touched">Company is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="customer">Customer</label>
                    <p-dropdown id="customer" [options]="customers" formControlName="customer" placeholder="Select a Customer" optionLabel="label" optionValue="value" [required]="true" [filter]="true"></p-dropdown>
                    <small class="p-error" *ngIf="salesOrderForm.get('customer')?.hasError('required') && salesOrderForm.get('customer')?.touched">Customer is required.</small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="salesperson">Salesperson</label>
                    <p-dropdown id="salesperson" [options]="salespersons" formControlName="salesperson" placeholder="Select a Salesperson" optionLabel="label" optionValue="value"></p-dropdown>
                </div>
                <div class="col-12 md:col-6">
                    <label for="paymentTerm">Payment Term</label>
                    <p-dropdown id="paymentTerm" [options]="paymentTerms" formControlName="paymentTerm" placeholder="Select a Payment Term" optionLabel="label" optionValue="value" [required]="true"></p-dropdown>
                    <small class="p-error" *ngIf="salesOrderForm.get('paymentTerm')?.hasError('required') && salesOrderForm.get('paymentTerm')?.touched">Payment term is required.</small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="orderDate">Order Date</label>
                    <p-calendar id="orderDate" inputId="orderDate" formControlName="orderDate" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error" *ngIf="salesOrderForm.get('orderDate')?.hasError('required') && salesOrderForm.get('orderDate')?.touched">Order date is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="expectedDate">Expected Date</label>
                    <p-calendar id="expectedDate" inputId="expectedDate" formControlName="expectedDate" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error" *ngIf="salesOrderForm.get('expectedDate')?.hasError('required') && salesOrderForm.get('expectedDate')?.touched">Expected date is required.</small>
                </div>
            </div>

            <p-divider />

            <div formArrayName="salesOrderLines" class="mt-6">
                <div class="text-xl font-semibold mb-4">
                    {{ editMode ? 'Edit Sales Order Lines' : 'Add Sales Order Lines' }}
                </div>
                <div class="mt-2" *ngFor="let lineGroup of getSalesOrderLineControls.controls; let i = index">
                    <div [formGroupName]="i" class="grid p-fluid">
                        <div class="col-12 md:col-3">
                            <label for="product{{ i }}">Product</label>
                            <p-dropdown id="product{{ i }}" [options]="products" formControlName="product" placeholder="Select a Product" optionLabel="label" optionValue="value"
                                        [required]="true" [filter]="true" (onChange)="onProductChange($event, i)"></p-dropdown>
                            <small class="p-error" *ngIf="lineGroup.get('product')?.hasError('required') && lineGroup.get('product')?.touched">Product is required.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="variant{{ i }}">Variant</label>
                            <p-dropdown id="variant{{ i }}" [options]="productVariants" formControlName="variant" placeholder="Select a Variant" optionLabel="label" optionValue="value"
                                        [required]="true" [filter]="true" (onChange)="onProductVariantChange($event, i)"></p-dropdown>
                            <small class="p-error" *ngIf="lineGroup.get('variant')?.hasError('required') && lineGroup.get('variant')?.touched">Product Variant is required.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="description{{ i }}">Description</label>
                            <input type="text" pInputText formControlName="description" id="description{{ i }}" />
                        </div>
                        <div class="col-12 md:col-1">
                            <label for="quantity{{ i }}">Quantity</label>
                            <p-inputNumber inputId="quantity{{ i }}" formControlName="quantity" mode="decimal" [min]="1"  locale="en-US"></p-inputNumber>
                            <small class="p-error" *ngIf="lineGroup.get('quantity')?.hasError('required') && lineGroup.get('quantity')?.touched">Quantity is required.</small>
                            <small class="p-error" *ngIf="lineGroup.get('quantity')?.hasError('min') && lineGroup.get('quantity')?.touched">Quantity must be greater than 0.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="unitPrice{{ i }}">Unit Price</label>
                            <p-inputNumber inputId="unitPrice{{ i }}" formControlName="unitPrice" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" locale="en-US">
                            </p-inputNumber>
                            <small class="p-error" *ngIf="lineGroup.get('unitPrice')?.hasError('required') && lineGroup.get('unitPrice')?.touched">Unit Price is required.</small>
                            <small class="p-error" *ngIf="lineGroup.get('unitPrice')?.hasError('min') && lineGroup.get('unitPrice')?.touched">Unit Price must be greater or equal to 0.</small>
                        </div>
                        <div class="col-12 md:col-1 flex align-items-center justify-content-center">
                            <button pButton type="button" icon="pi pi-trash" (click)="removeSalesOrderLine(i)"
                                    class="p-button-rounded p-button-danger p-button-sm" *ngIf="i > 0"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <button pButton type="button" label="Add Line" icon="pi pi-plus" (click)="addSalesOrderLine()"></button>
            </div>

            <div class="mt-4 flex justify-content-end">  <!-- Buttons -->
                <button pButton type="submit" label="Save" [disabled]="salesOrderForm.invalid" class="mr-2"></button>
                <button pButton type="button" label="Cancel" class="p-button-secondary" (click)="cancel()"></button>
            </div>


        </div>
    </form>
</div>
