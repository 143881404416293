import jsPDF from 'jspdf';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { JobAttachment } from 'src/app/models/order-view/job-attachment';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from 'src/environments/environment';
import { ShipmentDeliveryService } from '../../shipment/shipment-delivery.service';
import { ShipmentQueryService } from '../../shipment/shipment-query.service';



@Injectable({
    providedIn: 'root'
})
export class GoogleCloudStorageService {

    constructor(private messageService: MessageService,
        private orderDeliveryService: ShipmentDeliveryService,
        private orderQueryService: ShipmentQueryService) {
    }

    openCamera() {
        const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
        fileInput.click();
    }

    onFileSelected(event: any, selectedImage: any) {
        const file: File = event.target.files[0];
        if (file) {
            selectedImage = null;
            const reader = new FileReader();
            reader.onload = (e: any) => {
                selectedImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    uploadFile(selectedFile: any, fileName: string, fileType: string, pdfUploadOngoing: boolean | null, pdfUploadProgress: number | null,
        queryId: string, deliveryNumber: string | undefined, callback: DownloadUrlCallback) {
       if (!selectedFile) {
           this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please select a file first.' });
           return;
       }

       pdfUploadOngoing = true;

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(ref(storage, (deliveryNumber == null ? '/order/' : '/delivery/') + fileName), selectedFile);

       uploadTask.on('state_changed',
           (snapshot) => {
               // Observe state change events such as progress, pause, and resume
               // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
               pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
               pdfUploadProgress = Math.round(pdfUploadProgress);
               if (pdfUploadProgress > 10)
                   pdfUploadProgress = pdfUploadProgress - 5;
               console.log('Upload is ' + pdfUploadProgress + '% done');
               switch (snapshot.state) {
                   case 'paused':
                       console.log('Upload is paused');
                       break;
                   case 'running':
                       console.log('Upload is running');
                       break;
               }
           },
           (error) => {
               // Handle unsuccessful uploads
               console.error("Error uploading file:", error);
               this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
           },
           () => {
               // Handle successful uploads on complete
               getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                   console.log('File available at', downloadURL);
                   if (deliveryNumber == null)
                       this.addOrderAttachment(selectedFile, fileName, fileType, downloadURL, queryId, pdfUploadProgress ?? 100, pdfUploadOngoing ?? false, callback);
                   else this.addDeliveryAttachment(selectedFile, fileName, fileType, downloadURL, queryId, deliveryNumber, pdfUploadProgress ?? 100, pdfUploadOngoing ?? false, callback)
               }).catch((error) => {
                   console.error("Error getting download URL:", error);
                   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
               });
           }
       );
   }

    uploadImage(selectedImage: any, fileName: string, fileType: string, pdfUploadOngoing: boolean, pdfUploadProgress: number,
         queryId: string, deliveryNumber: string, callback: DownloadUrlCallback) {
        if (!selectedImage) {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Please capture an image first.' });
            return;
        }

        pdfUploadOngoing = true;

        // Convert image to PDF
        const doc = new jsPDF();
        doc.addImage(selectedImage, 'JPEG', 0, 0, selectedImage.width, selectedImage.height); // Adjust dimensions as needed

        // Get PDF data as a Blob
        const pdfBlob = doc.output('blob');

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(ref(storage, (deliveryNumber == null ? '/order/' : '/delivery/') + fileName), pdfBlob);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                pdfUploadProgress = Math.round(pdfUploadProgress);
                if (pdfUploadProgress > 10)
                    pdfUploadProgress = pdfUploadProgress - 5;
                console.log('Upload is ' + pdfUploadProgress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading image:", error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (deliveryNumber == null)
                        this.addOrderAttachment(selectedImage, fileName, fileType, downloadURL, queryId, pdfUploadProgress, pdfUploadOngoing, callback);
                    else this.addDeliveryAttachment(selectedImage, fileName, fileType, downloadURL, queryId, deliveryNumber, pdfUploadProgress, pdfUploadOngoing, callback)
                }).catch((error) => {
                    console.error("Error getting download URL:", error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
                });
            }
        );
    }

    addOrderAttachment(selectedImage:any, fileName: string, fileType: string, downloadURL: string,
        queryId: string, pdfUploadProgress: number, pdfUploadOngoing: boolean, callback: DownloadUrlCallback) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF uploaded successfully.' });
        //update firestore collection to add a new document
        let jobAttachment: JobAttachment = {
            fileName: fileName,
            fileUrl: downloadURL,
            fileSize: selectedImage.length,
            fileType: fileType,
            createdAt: new Date().toISOString()
        };
        this.orderQueryService.addShipmentAttachment(jobAttachment, queryId).subscribe(res => {
            console.log(res);
            if (res.success) {
                callback(jobAttachment);
                pdfUploadProgress = 100;
                pdfUploadOngoing = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF added successfully to delivery' });
            }
        });
    }

    addDeliveryAttachment(selectedImage:any, fileName: string, fileType: string, downloadURL: string,
        queryId: string, deliveryNumber: string, pdfUploadProgress: number, pdfUploadOngoing: boolean,
        callback: DownloadUrlCallback) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF uploaded successfully.' });
        //update firestore collection to add a new document
        let jobAttachment: JobAttachment = {
            fileName: fileName,
            fileUrl: downloadURL,
            fileSize: selectedImage.length,
            fileType: fileType,
            createdAt: new Date().toISOString()
        };
        this.orderDeliveryService.addDeliveryAttachment(jobAttachment, queryId, deliveryNumber).subscribe(res => {
            console.log(res);
            if (res.success) {
                callback(jobAttachment);
                pdfUploadProgress = 100;
                pdfUploadOngoing = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF added successfully to delivery' });
            }
        });
    }

}

export interface DownloadUrlCallback {
    (jobAttachment: JobAttachment): void;
}
