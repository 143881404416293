import { Component, OnInit } from '@angular/core';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Vehicle } from '../vehicle.model';
import { VehicleService } from '../vehicle.service';
import { SessionStorageService } from "../../../services/session-storage.service";
import { FileSelectEvent } from "primeng/fileupload";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../environments/environment";
import { VehicleType } from "../params/vehicle-type";
import { VehicleConfig } from "../params/vehicle-config";
import { VehicleCapacity } from "../params/vehicle-capacity";
import { VehicleYear } from "../params/vehicle-year";
import { VehicleMake } from "../params/vehicle-make";
import { VehicleModel } from "../params/vehicle-model";
import { PayloadType } from "../../../models/order-params/payload-type";
import { TripType } from "../../../models/vehicle/trip-type";


@Component({
    selector: 'app-edit-vehicle',
    templateUrl: './edit-vehicle.component.html',
    styleUrls: ['./edit-vehicle.component.css']
})
export class EditVehicleComponent implements OnInit {

    createEditVehicleForm: FormGroup<any>;
    plateNumberInput: FormControl;
    vehicleTypeDropdown: FormControl;
    vehicleConfigDropdown: FormControl;
    vehicleCapacityDropdown: FormControl;
    vehicleMakeDropdown: FormControl;
    vehicleModelDropdown: FormControl;
    vehicleYearDropdown: FormControl;
    payloadTypesMultiSelect: FormControl;
    tripTypesMultiSelect: FormControl;
    registrationNumberInput: FormControl;
    registrationDateInput: FormControl;

    vehicleId: number;
    isLoading: boolean = false;
    vehicle: Vehicle = new Vehicle();
    selectedVehicleType: VehicleType;
    selectedVehicleConfig: VehicleConfig;
    selectedVehicleCapacity: VehicleCapacity;
    selectedVehicleMake: VehicleMake;
    selectedVehicleModel: VehicleModel;
    selectedVehicleYear: VehicleYear;
    selectedPayloadTypes: PayloadType[] = [];
    selectedTripTypes: TripType[] = [];
    selectedRegistrationDate: Date | undefined;
    vehicleCreationInProgress: boolean = false;
    pdfUploadProgress: number = 0;

    vehicleTypes: VehicleType[] = [];
    vehicleConfigs: VehicleConfig[] = [];
    vehicleCapacities: VehicleCapacity[] = [];
    vehicleMakes: VehicleMake[] = [];
    vehicleModels: VehicleModel[] = [];
    vehicleYears: VehicleYear[] = [];
    payloadTypes: PayloadType[] = [];
    tripTypes: TripType[] = [];

    formTitle: string;
    isFreelancer: boolean = false;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private sessionStorageService: SessionStorageService,
        private route: ActivatedRoute,
        private vehicleService: VehicleService,
        private messageService: MessageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.createEditVehicleForm = new FormGroup({
            plateNumberInput: new FormControl('', Validators.required),
            engineNumberInput: new FormControl('', Validators.required),
            vehicleTypeDropdown: new FormControl('', Validators.required),
            vehicleConfigDropdown: new FormControl('', Validators.required),
            vehicleCapacityDropdown: new FormControl('', Validators.required),
            vehicleMakeDropdown: new FormControl('', Validators.required),
            vehicleModelDropdown: new FormControl('', Validators.required),
            vehicleYearDropdown: new FormControl('', Validators.required),
            payloadTypesMultiSelect: new FormControl('', Validators.required),
            tripTypesMultiSelect: new FormControl('', Validators.required),
            registrationNumberInput: new FormControl('', Validators.required),
            registrationDateInput: new FormControl('', Validators.required)
        });

        // Get vehicle ID from route parameters
        const vehicleId = this.route.snapshot.paramMap.get('id');

        if (vehicleId) {
            this.formTitle = 'Update Vehicle';
            this.vehicleId = Number(vehicleId);
            this.loadVehicle(); // Load vehicle if ID exists
        } else {
            this.formTitle = 'Create Vehicle';
            this.initializeForNewVehicle(); // Initialize for new vehicle
        }

        this.loadVehicleTypes();
        this.loadVehicleConfigs();
        this.loadVehicleCapacities();
        this.loadVehicleMakes();
        this.loadVehicleModels();
        this.loadVehicleYears();
        this.loadPayloadTypes();
        this.loadTripTypes();

        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' },{ label: 'Vehicles', routerLink: '/fleet/vehicles' }, { label: vehicleId ? 'Update' : 'Create' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };

    }

    initializeForNewVehicle() {
        // Initialize for a new vehicle (if needed)
        this.vehicle = new Vehicle();
        if (this.route.snapshot.queryParamMap.get('registrationNumber')) {
            this.vehicle.registrationNumber = this.route.snapshot.queryParamMap.get('registrationNumber')!;
            //this.plateNumberInput.setValue(this.vehicle.plateNumber);
        }
        if (this.route.snapshot.queryParamMap.get('isFreelancer'))
            this.isFreelancer = this.route.snapshot.queryParamMap.get('isFreelancer')! as unknown as boolean;
    }

    loadVehicle() {
        this.isLoading = true;
        this.vehicleService.findVehicleByIds('en-US', [this.vehicleId]).subscribe(vehicles => {
            this.vehicle = vehicles.data[0];
            this.selectedRegistrationDate = new Date(this.vehicle.registrationExpiry);
            this.updateFormValues();
            this.isLoading = false;
        });
    }

    updateFormValues() {
        this.createEditVehicleForm.patchValue({
            plateNumberInput: this.vehicle.plateNumber,
            engineNumberInput: this.vehicle.engineNumber,
            vehicleTypeDropdown: this.vehicle.type,
            vehicleConfigDropdown: this.vehicle.config,
            vehicleCapacityDropdown: this.vehicle.capacity,
            vehicleMakeDropdown: this.vehicle.make,
            vehicleModelDropdown: this.vehicle.model,
            vehicleYearDropdown: this.vehicle.year,
            payloadTypesMultiSelect: this.vehicle.payloadTypes,
            tripTypesMultiSelect: this.vehicle.tripTypes,
            registrationNumberInput: this.vehicle.registrationNumber,
            registrationDateInput: this.selectedRegistrationDate
        });

        this.selectedVehicleType = this.vehicleTypes.find(vt => vt.id === this.vehicle.type?.id)!;
        this.selectedVehicleConfig = this.vehicleConfigs.find(vc => vc.configId === this.vehicle.config?.configId)!;
        this.selectedVehicleCapacity = this.vehicleCapacities.find(vca => vca.capacityId === this.vehicle.capacity?.capacityId)!;
        this.selectedVehicleMake = this.vehicleMakes.find(vm => vm.makeId === this.vehicle.make?.makeId)!;
        this.selectedVehicleModel = this.vehicleModels.find(vmo => vmo.modelId === this.vehicle.model?.modelId)!;
        this.selectedVehicleYear = this.vehicleYears.find(vy => vy.yearId === this.vehicle.year?.yearId)!;
        this.selectedPayloadTypes = this.vehicle.payloadTypes;
        this.selectedTripTypes = this.vehicle.tripTypes;
    }

    loadVehicleTypes() {
        this.vehicleTypes = this.sessionStorageService.getVehicleTypes()!;
    }

    loadVehicleConfigs() {
        if (this.selectedVehicleType)
            this.vehicleConfigs = this.sessionStorageService.getVehicleTypes()?.
            filter(vehicleType => vehicleType.id === this.selectedVehicleType.id)
                .flatMap(vehicleType => vehicleType.vehicleConfigs)!;
        else this.vehicleConfigs = this.sessionStorageService.getVehicleTypes()?.
            flatMap(vehicleType => vehicleType.vehicleConfigs)!;
    }

    loadVehicleCapacities() {
        if (this.selectedVehicleType)
            this.vehicleCapacities = this.sessionStorageService.getVehicleTypes()?.
            filter(vehicleType => vehicleType.id === this.selectedVehicleType.id)
                .flatMap(vehicleType => vehicleType.vehicleCapacities)!;
        else this.vehicleCapacities = this.sessionStorageService.getVehicleTypes()?.
            flatMap(vehicleType => vehicleType.vehicleCapacities)!;
    }

    loadVehicleMakes() {
        this.vehicleMakes = this.sessionStorageService.getVehicleMakes()!;
    }

    loadVehicleModels() {
        if (this.selectedVehicleMake)
            this.vehicleModels = this.sessionStorageService.getVehicleMakes()?.
            filter(vehicleMake => vehicleMake.makeId === this.selectedVehicleMake.makeId)
                .flatMap(vehicleMake => vehicleMake.models)!;
        else this.vehicleModels = this.sessionStorageService.getVehicleMakes()?.
            flatMap(vehicleMake => vehicleMake.models)!;
    }

    loadVehicleYears() {
        if (this.selectedVehicleModel)
            this.vehicleYears = this.vehicleModels
                .filter(vehicleModel => vehicleModel.modelId === this.selectedVehicleModel.modelId)
                .flatMap(vehicleModel => vehicleModel.years)!;
        else this.vehicleYears = this.sessionStorageService.getVehicleMakes()?.
        flatMap(vehicleMake => vehicleMake.models).flatMap(vehicleModel => vehicleModel.years)!;
    }

    loadPayloadTypes() {
        this.payloadTypes = this.sessionStorageService.getPayloadTypes()!;
    }

    loadTripTypes() {
        this.tripTypes = this.sessionStorageService.getTripTypes()!;
    }

    onVehicleTypeChange(event: any) {
        this.selectedVehicleType = event.value;
        this.vehicle.type = this.selectedVehicleType;
        this.loadVehicleConfigs();
        this.loadVehicleCapacities();
    }

    onVehicleConfigChange(event: any) {
        this.selectedVehicleConfig = event.value;
        this.vehicle.config = this.selectedVehicleConfig;
    }

    onVehicleCapacityChange(event: any) {
        this.selectedVehicleCapacity = event.value;
        this.vehicle.capacity = this.selectedVehicleCapacity;
    }

    onVehicleMakeChange(event: any) {
        this.selectedVehicleMake = event.value;
        this.vehicle.make = this.selectedVehicleMake;
        this.loadVehicleModels();
        this.loadVehicleYears();
    }

    onVehicleModelChange(event: any) {
        this.selectedVehicleModel = event.value;
        this.vehicle.model = this.selectedVehicleModel;
        this.loadVehicleYears();
    }

    onVehicleYearChange(event: any) {
        this.selectedVehicleYear = event.value;
        this.vehicle.year = this.selectedVehicleYear;
    }

    onRegistrationCardUpload(event: FileSelectEvent) {
        console.log(event.files);
        this.uploadPDFFile(event.files[0], 'REGISTRATION');
    }

    uploadPDFFile(file: File, fileType: string) {
        this.vehicleCreationInProgress = true;
        const storageRef = ref(storage, file.name);

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(ref(storage, (fileType === 'REGISTRATION' ? '/registration_scan/' : '/other/') + file.name), file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                this.pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.pdfUploadProgress = Math.round(this.pdfUploadProgress);
                if (this.pdfUploadProgress > 10)
                    this.pdfUploadProgress = this.pdfUploadProgress - 5;
                console.log('Upload is ' + this.pdfUploadProgress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file:", error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (fileType === 'REGISTRATION')
                        this.vehicle.registrationImage = downloadURL;
                    this.vehicleCreationInProgress = false;
                }).catch((error) => {
                    console.error("Error getting download URL:", error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
                });
            }
        );
    }

    saveVehicle() {
        this.vehicleCreationInProgress = true;
        this.vehicle.registrationExpiry = this.selectedRegistrationDate?.getTime() ?? new Date().getTime();
        this.vehicle.payloadTypes = this.selectedPayloadTypes;
        this.vehicle.tripTypes = this.selectedTripTypes;
        this.vehicle.vehicleImages = [];
        if (this.isFreelancer) {
            this.vehicleService.addFreelancerVehicle(this.vehicle, this.sessionStorageService.getCompany()!.id).subscribe(response => {
                if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Vehicle ' + this.vehicle.plateNumber + ' is created',
                        detail: 'Vehicle ' + this.vehicle.plateNumber + ' has been successfully created'
                    });
                    this.router.navigate(['/fleet/vehicles']);
                }
                this.vehicleCreationInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add vehicle.' + error.message });
                this.vehicleCreationInProgress = false;
            });
        }
        else if (this.vehicle.vehicleId) {
            this.vehicle.companyId = this.sessionStorageService.getCompany()!.id;
            this.vehicleService.updateVehicle(this.vehicle).subscribe(response => {
                if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Vehicle ' + response.data.plateNumber + ' is updated',
                        detail: 'Vehicle ' + response.data.plateNumber + ' has been successfully updated'
                    });
                    this.router.navigate(['/fleet/vehicles']);
                }
                this.vehicleCreationInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update vehicle.' + error.message });
                this.vehicleCreationInProgress = false;
            });
        } else {
            this.vehicle.companyId = this.sessionStorageService.getCompany()!.id;
            this.vehicleService.addVehicle(this.vehicle).subscribe(response => {
                if (response.success) {
                    this.messageService.add({
                        severity: 'success', summary: 'Vehicle ' + response.data.plateNumber + ' is created',
                        detail: 'Vehicle ' + response.data.plateNumber + ' has been successfully created'
                    });
                    this.router.navigate(['/fleet/vehicles']);
                }
                this.vehicleCreationInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add vehicle.' + error.message });
                this.vehicleCreationInProgress = false;
            });
        }
    }

    clearForm() {
        this.createEditVehicleForm.reset();
        this.vehicle = new Vehicle();
        this.selectedRegistrationDate = undefined;
    }
}
