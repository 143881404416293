import {Company} from "../../company/company.model";
import {Customer} from "../../customer/customer.model";
import {ContractRevisions} from "./contract-revisions.model";


export class Contracts {
  contractID: number;
  firstParty: Company;
  firstPartyRep: Customer;
  firstPartyRepDesignation: string;
  secondParty: Company;
  secondPartyRep: Customer;
  secondPartyRepDesignation: string;
  contractNumber: string;
  firstPartyCRNumber: string;
  secondPartyCRNumber: string;
  firstPartyLicenseNumber: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  contractStatus: string;
  revisions: ContractRevisions[];
}
