<p-toast position="top-center" [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="creditNoteForm" (ngSubmit)="saveCreditNote()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Credit Note' : 'Add Credit Note' }}
            </div>
            <div class="p-fluid grid" *ngIf="editMode">
                <div class="col-12 md:col-3">
                    <label for="creditNoteID">Credit Note ID</label>
                    <input type="text" pInputText id="creditNoteID" formControlName="creditNoteID" [disabled]="true"/>
                </div>
                <div class="col-12 md:col-3">
                    <label for="createdAt">Created At</label>
                    <input type="text" pInputText id="createdAt" [value]="creditNoteForm.get('createdAt')?.value | date:'yyyy-MM-dd HH:mm:ss'" disabled/>
                </div>
                <div class="col-12 md:col-3">
                    <label for="createdBy">Created By</label>
                    <input type="text" pInputText id="createdBy" formControlName="createdBy" [disabled]="true"/>
                </div>
                <div class="col-12 md:col-3">
                    <label for="updatedAt">Updated At</label>
                    <input type="text" pInputText id="updatedAt" [value]="creditNoteForm.get('updatedAt')?.value | date:'yyyy-MM-dd HH:mm:ss'" disabled/>
                </div>
                <div class="col-12 md:col-3">
                    <label for="updatedBy">Updated By</label>
                    <input type="text" pInputText id="updatedBy" formControlName="updatedBy" [disabled]="true"/>
                </div>
            </div>

            <div class="p-fluid grid">
                <div class="col-12 md:col-6" *ngIf="editMode">
                    <label for="creditNoteNumber">Credit Note Number</label>
                    <input type="text" pInputText formControlName="creditNoteNumber" id="creditNoteNumber" [disabled]="true"/>
                </div>
                <div class="col-12 md:col-6">
                    <label for="company">Company</label>
                    <p-dropdown id="company" [options]="companies" formControlName="company" optionValue="value"
                                placeholder="Select a Company" optionLabel="label" [required]="true"
                                [filter]="true" (onChange)="onCompanyChange($event.value)"></p-dropdown>
                    <small class="p-error"
                           *ngIf="creditNoteForm.get('company')?.hasError('required') && creditNoteForm.get('company')?.touched">Company
                        is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="customer">Customer</label>
                    <p-dropdown id="customer" [options]="customers" formControlName="customer" optionValue="value"
                                placeholder="Select a Customer" optionLabel="label" [required]="true"
                                [filter]="true"></p-dropdown>
                    <small class="p-error"
                           *ngIf="creditNoteForm.get('customer')?.hasError('required') && creditNoteForm.get('customer')?.touched">Customer
                        is required.</small>
                </div>
            </div>

            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="bill">Bill</label>
                    <p-autoComplete id="bill" formControlName="bill" [suggestions]="filteredBills"
                                    (completeMethod)="filterBills($event)" [forceSelection]="true"
                                    placeholder="Select a Bill" [dropdown]="true" field="odooBillNumber">
                        <ng-template let-bill pTemplate="item">
                            <span>{{ bill.odooBillNumber }}</span>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="col-12 md:col-6">
                    <label for="invoice">Invoice</label>
                    <p-autoComplete id="invoice" formControlName="invoice" [suggestions]="filteredInvoices"
                                    (completeMethod)="filterInvoices($event)" [forceSelection]="true"
                                    placeholder="Select an Invoice" [dropdown]="true" field="odooInvoiceNumber">
                        <ng-template let-invoice pTemplate="item">
                            <span>{{ invoice.odooInvoiceNumber }}</span>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>



            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="creditNoteDate">Credit Note Date</label>
                    <p-calendar id="creditNoteDate" inputId="creditNoteDate" formControlName="creditNoteDate"
                                dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error"
                           *ngIf="creditNoteForm.get('creditNoteDate')?.hasError('required') && creditNoteForm.get('creditNoteDate')?.touched">Credit
                        date is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="reason">Reason</label>
                    <input type="text" pInputText formControlName="reason" id="reason"/>
                </div>
            </div>

            <p-divider/>

            <div formArrayName="creditNoteLines" class="mt-6">
                <div class="text-xl font-semibold mb-4">
                    {{ editMode ? 'Edit Credit Note Lines' : 'Add Credit Note Lines' }}
                </div>
                <div class="mt-2"
                     *ngFor="let lineGroup of getCreditNoteLineControls.controls; let i = index">
                    <div [formGroupName]="i" class="grid p-fluid">
                        <div class="col-12 md:col-9">
                            <label for="description{{ i }}">Description</label>
                            <input type="text" pInputText formControlName="description"
                                   id="description{{ i }}"/>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="amount{{ i }}">Amount</label>
                            <p-inputNumber inputId="amount{{ i }}" formControlName="amount"
                                           mode="decimal" [minFractionDigits]="2"
                                           [maxFractionDigits]="2" [min]="0" locale="en-US"></p-inputNumber>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('amount')?.hasError('required') && lineGroup.get('amount')?.touched">Amount
                                is required.</small>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('amount')?.hasError('min') && lineGroup.get('amount')?.touched">Amount
                                must be at least 0.</small>
                        </div>
                        <div class="col-12 md:col-1 flex justify-content-end">
                            <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-text p-button-danger"
                                    (click)="removeCreditNoteLine(i)"></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-12">
                    <button pButton pRipple type="button" label="Add Line" icon="fa-solid fa-add"
                            (click)="addCreditNoteLine()" class="p-button-rounded p-button-text p-button-info mt-2"></button>
                </div>
            </div>
            <p-divider/>
            <div class="col-12 flex justify-content-end">
                <button pButton pRipple type="button" label="Cancel" (click)="cancel()"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton pRipple type="submit" label="Save" class="p-button-rounded p-button-text p-button-info"></button>
            </div>
        </div>
    </form>
</div>
