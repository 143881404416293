<p-dialog [(visible)]="voucherAmountDialogVisible" [header]="voucherAmountDialogHeader" [modal]="true"
          [style]="{ width: '60vw' }" [draggable]="false" [resizable]="false">
    <div class="p-fluid card">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="flex flex-column lg:flex-row mb-2">
                <div class="flex flex-column col-12">
                    <h4 class="mb-2">Select Customer</h4>
                    <div *ngFor="let customer of customers" class="field-radiobutton mb-2">
                        <p-radioButton [inputId]="customer.id.toString()" name="customer" [value]="customer"
                                       [(ngModel)]="selectedCustomer"></p-radioButton>
                        <label [for]="customer.id">{{customer.name}}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row mb-2">
                <div class="flex flex-column col-12">
                    <h4 class="mb-2">Select Payment Method</h4>
                    <div *ngFor="let paymentMethod of personalPaymentMethods" class="field-radiobutton mb-2">
                        <p-radioButton [inputId]="paymentMethod.personalPaymentId.toString()" name="paymentMethod" [value]="paymentMethod"
                                       [(ngModel)]="selectedPaymentMethod"></p-radioButton>
                        <label [for]="paymentMethod.personalPaymentId">{{paymentMethod.paymentCardName}}</label>
                    </div>
                </div>
            </div>
            <div class="flex flex-column lg:flex-row">
                <div class="flex col-12">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-money-check"></i></span>
                        <input pInputText id="amount" type="number" [(ngModel)]="voucherAmount"
                               placeholder="Voucher Amount" required>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" icon="fa-solid fa-close"
                (click)="voucherAmountDialogVisible = false" class="p-button-secondary"></button>
        <button pButton type="submit" label="Create Voucher" icon="fa-solid fa-square-pen"
                (click)="createVoucherPayment()" [loading]="createVoucherPaymentInProgress"></button>
    </ng-template>
</p-dialog>
