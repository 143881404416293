import { formatDate } from '@angular/common';
import {Component, OnInit, SecurityContext} from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { JobAttachment } from '../../models/order-view/job-attachment';
import { OrderDeliveryView } from '../../models/order-view/order-delivery';
import { OrderQueryView } from '../../models/order-view/order-query-view';
import { ShipmentQueryService } from '../shipment-query.service';
import { SessionStorageService } from '../../services/session-storage.service';
import * as geoAddressHelpers from '../../helpers/geo-address.helper'
import { MenuItem, MessageService } from 'primeng/api';
import { OrderJobView } from '../../models/order-view/order-job';
import { OrderQueryStatus } from '../../models/order-view/order-query-status';
import { OrderJobStatus } from '../../models/order-view/order-job-status';
import { ShipmentJobService } from '../shipment-job.service';
import { ShipmentDeliveryService } from '../shipment-delivery.service';
import { OrderStatus } from '../../models/order-view/order-status';
import { synchronized } from 'synchronized-ts';
import { JobStatus } from '../../models/order-view/job-status';
import { ReportsService } from '../../services/reports.service';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadComponent } from 'src/app/helpers/file-upload/file-upload.component';
import { DownloadUrlCallback } from 'src/app/services/google/google-cloud-storage.service';
import {TransportationAgency} from "../../company/transportation-agency/transportation-agency.model";
import {AiSummaryService} from "../../services/ai-summary.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NominationEntityView} from "../../models/order-view/nomination-entity-view";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Component({
	selector: 'app-view-shipment',
	templateUrl: './view-shipment.component.html',
	styleUrls: ['./view-shipment.component.css']
})
export class ViewShipmentComponent implements OnInit {

	orderNumber: string;
	orderQuery: OrderQueryView;
	orderDeliveryMap: Map<String, OrderDeliveryView> = new Map();
	orderJobMap: Map<String, OrderJobView> = new Map();
	orderDeliveryList: OrderDeliveryView[] = [];
	orderJobList: OrderJobView[] = [];
	orderFilesList: JobAttachment[] = [];
    nominationEntities: NominationEntityView[] = [];
	tripsCount: number = 0;
	jobsCount: number = 0;
	orderFilesCount: number = 0;
	isLoading: boolean = true;
	pickupSubLocality: string | undefined;
	dropoffSubLocality: string | undefined;
	pickupLocality: string | undefined;
	dropoffLocality: string | undefined;

	breadcrumbItems: MenuItem[];
	homeBreadcrumb: MenuItem;

	orderQueryStatus: OrderQueryStatus = new OrderQueryStatus();
	invokeDriverInProgress: boolean = false;
    confirmDriverInProgress: boolean = false;
	showAddFileDialog: boolean = false;
    currentTransportationAgency: TransportationAgency | null;
    shipmentAiSummary: string | null = '';
    assignDriverMenu: MenuItem[];

    chargeTypeToBeUpdated: string = '';
    updateChargesDialogVisible: boolean = false;
    updatedCharge: number = 0;
    updateChargesDialogHeader: string = '';
    updateChargesInProgress: boolean = false;


	constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer,
                private router: Router, private aiSummaryService: AiSummaryService,
                private database: AngularFireDatabase,
                protected sessionStorageService: SessionStorageService,
                private messageService: MessageService,
                private shipmentQueryService: ShipmentQueryService,
                private shipmentJobService: ShipmentJobService,
                private shipmentDeliveryService: ShipmentDeliveryService,
                private dialogService: DialogService,
                private reportsService: ReportsService) {
        this.currentTransportationAgency = this.sessionStorageService.getTransportationAgency();
        this.assignDriverMenu = [
            {
                label: 'Own Driver/Vehicle',
                icon: 'fa-solid fa-arrows-to-eye',
                command: () => this.generateDeliveries(false)
            },
            {
                label: 'Freelance Driver/Vehicle',
                icon: 'fa-solid fa-person-running',
                command: () => this.generateDeliveries(true)
            }
        ];
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			this.orderNumber = params['orderNumber'];
			this.getOrderQuery();
		});
	}

	getOrderQuery() {
		this.isLoading = true;
		console.log("Order Number: " + this.orderNumber);
		this.shipmentQueryService.getShipmentByShipmentNumber(this.orderNumber).subscribe(order => {
			this.orderDeliveryList = Object.entries(order?.deliveries)
				.map(deliveryEntry => deliveryEntry[1]) as OrderDeliveryView[];
			this.orderDeliveryMap = new Map(this.orderDeliveryList.map(delivery => [delivery.deliveryText, delivery]));
			this.orderJobList = Object.entries(order?.jobs)
				.map(jobEntry => jobEntry[1]) as OrderJobView[];
			this.orderJobMap = new Map(this.orderJobList.map(job => [job.deliveryText, job]));
			this.tripsCount = this.orderDeliveryMap.size;
			this.jobsCount = this.orderJobMap.size
			this.orderFilesList = order?.filesList;
			this.orderFilesCount = order?.filesList.length;
			this.isLoading = false;

			this.pickupLocality = geoAddressHelpers.getLocality(order?.pickupAddress);
			this.pickupSubLocality = geoAddressHelpers.getSubLocality(order?.pickupAddress);
			this.dropoffLocality = geoAddressHelpers.getLocality(order?.dropoffAddress);
			this.dropoffSubLocality = geoAddressHelpers.getSubLocality(order?.dropoffAddress);

			this.breadcrumbItems = [{ label: 'Shipments', routerLink: '/shipment' }, { label: order?.queryId }];
			this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/dashboard' };

			this.orderQuery = order;

            this.orderQuery.chargeLines.push(...this.orderDeliveryList.flatMap(value => value.chargeLines));

            this.generateAllNominees();
			this.getLiveJobWithStatus(order);
            this.getAiSummary();
		});

	}

    getAiSummary() {
        this.aiSummaryService.getShipmentSummary(this.orderQuery)
            .subscribe(value => {
                // Remove code block markers and split into sentences
                const sentences = value.replace('```html', '')
                    .replace('```', '')
                    .replace('\n','<br>')
                    .split('. ');

                // Process each sentence to wrap bold text in <strong> tags
                const formattedSummary = sentences.map(sentence => {
                    return sentence.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') + '. ';
                }).join('');

                // Sanitize the formatted HTML
                this.shipmentAiSummary = this.sanitizer.sanitize(SecurityContext.HTML, formattedSummary);
            }, error => {
                this.shipmentAiSummary = 'Error in generating AI Summary, please try again later';
            });
    }

	showDate(longDate: number) {
		return formatDate(new Date(longDate), 'dd-MM-yyyy HH:mm', 'en-US');
	}

	viewDeliveryPage(orderDelivery: OrderDeliveryView): void {
		this.sessionStorageService.setCurrentOrder(this.orderQuery!!);
		this.sessionStorageService.setCurrentDelivery(orderDelivery);
		this.router.navigate(
			[`shipment/delivery/view`, orderDelivery.deliveryText]
		);
	}

	getLiveJobWithStatus(orderQuery: OrderQueryView) {
		const jobStatusRef = this.database.list(`orderJob/${orderQuery.queryId}`);
		jobStatusRef.stateChanges(['child_added']).subscribe(snapShot => {
			console.log("Job Status Added: " + JSON.stringify(snapShot));
			this.updateOrderStatus(snapShot, this.orderQueryStatus, this.orderQuery);

		});
		jobStatusRef.stateChanges(['child_changed']).subscribe(snapShot => {
			console.log("Job Status Change: " + JSON.stringify(snapShot));
			this.updateOrderStatus(snapShot, this.orderQueryStatus, this.orderQuery);
		});
	}

	@synchronized
	updateOrderStatus(snapShot: SnapshotAction<any>, orderQueryStatus: OrderQueryStatus, orderQuery: OrderQueryView) {
		if (snapShot.key?.includes('isJobsGenerated'))
			orderQueryStatus.isJobsGenerated = snapShot.payload.val() as boolean;
		else if (snapShot.key?.includes('orderStatus')) {
			orderQueryStatus.orderStatus = snapShot.payload.val() as OrderStatus;
			this.orderQuery.orderStatus = orderQueryStatus.orderStatus;
		} else {
			let deliveryText = snapShot.key!;
			const orderJobStatus = snapShot.payload.val() as OrderJobStatus;
			orderQueryStatus.orderJobStatus.set(deliveryText, orderJobStatus);

			if (Object.values(JobStatus).indexOf(orderJobStatus.jobStatus as JobStatus) < Object.values(JobStatus).indexOf(JobStatus.JOB_DRIVER_ACCEPTED)) {
				if (!this.orderJobMap.has(deliveryText)) {
					this.shipmentJobService.getJobByOrderAndJobNumber(orderQuery.queryId, deliveryText).subscribe(order => {
						if (order != null) {
							let orderJob = ((Object.entries(order?.jobs).map(jobEntry => jobEntry[1]) as OrderJobView[])[0]);
							orderJob.jobStatus = orderJobStatus.jobStatus;
							orderJob.jobStatusMap = orderJobStatus.jobStatusMap;
							this.messageService.add({ severity: "info", closable: true, summary: 'Status of Job# ' + deliveryText + ' is updated', detail: orderJob.jobStatus, life: 6000 });
							this.orderJobMap.set(deliveryText, orderJob);
							this.orderJobList = Array.from(this.orderJobMap.values());
							this.jobsCount = this.orderJobMap.size;
						}
					});
				} else {
					let orderJob = this.orderJobMap.get(deliveryText)!;
					orderJob.jobStatus = orderJobStatus.jobStatus;
					orderJob.jobStatusMap = orderJobStatus.jobStatusMap;
					this.messageService.add({ severity: "info", closable: true, summary: 'Status of Job# ' + deliveryText + ' is updated', detail: orderJob.jobStatus, life: 6000 });
				}
			}

			if (Object.values(JobStatus).indexOf(orderJobStatus.jobStatus as JobStatus) >= Object.values(JobStatus).indexOf(JobStatus.JOB_DRIVER_ACCEPTED)) {
				this.orderJobMap.delete(deliveryText);
				this.orderJobList = Array.from(this.orderJobMap.values());
				this.jobsCount = this.orderJobMap.size;
				if (!this.orderDeliveryMap.has(deliveryText)) {
					this.shipmentDeliveryService.getDeliveryByShipmentAndDeliveryNumber(orderQuery.queryId, deliveryText).subscribe(order => {
						if (order != null) {
							let orderDelivery = ((Object.entries(order?.deliveries).map(deliveryEntry => deliveryEntry[1]) as OrderDeliveryView[])[0]);
							orderDelivery.jobStatus = orderJobStatus.jobStatus;
							orderDelivery.jobStatusMap = orderJobStatus.jobStatusMap;
							this.messageService.add({ severity: orderJobStatus.jobStatus === JobStatus.JOB_DRIVER_ACCEPTED ? "success" : "info", closable: true, summary: 'Status of Delivery# ' + deliveryText + ' is updated', detail: orderDelivery.jobStatus, life: 6000 });
							this.orderDeliveryMap.set(deliveryText, orderDelivery);
							this.orderDeliveryList = Array.from(this.orderDeliveryMap.values());
							this.tripsCount = this.orderDeliveryMap.size;
							if (orderDelivery.filesList.filter(file => file.fileName.includes('waybill')).length == 0)
								this.generateWaybill(orderQuery, orderDelivery);
						}
					});
				} else {
					let orderDelivery = this.orderDeliveryMap.get(deliveryText)!;
					orderDelivery.jobStatus = orderJobStatus.jobStatus;
					orderDelivery.jobStatusMap = orderJobStatus.jobStatusMap;
					this.messageService.add({ severity: orderJobStatus.jobStatus === JobStatus.JOB_DRIVER_ACCEPTED ? "success" : "info", closable: true, summary: 'Status of Delivery# ' + deliveryText + ' is updated', detail: orderDelivery.jobStatus, life: 6000 });
					if (orderDelivery.filesList.filter(file => file.fileName.includes('waybill')).length == 0)
						this.generateWaybill(orderQuery, orderDelivery);
				}
			}
		}
	}

	invokeDriver(orderJob: OrderJobView) {
		this.invokeDriverInProgress = true;
		this.database.object(`orderJob/${this.orderQuery.queryId}/${orderJob.deliveryText}/jobStatus`).set(JobStatus.JOB_INVOKE_DRIVER);
		this.database.object(`orderJob/${this.orderQuery.queryId}/${orderJob.deliveryText}/jobStatusMap/JOB_INVOKE_DRIVER`).set(new Date().getTime());
	}

	findNewDrivers() {
		this.shipmentQueryService.findVehiclesNow(this.orderQuery, 'en-US')
			.subscribe(createdOrderResponse => {
				console.log('Order Query Response: ' + JSON.stringify(createdOrderResponse));
				if (createdOrderResponse.success) {
					this.messageService.add({
						severity: 'success', summary: 'Request submitted!',
						detail: 'Request for finding drivers has been submitted!'
					});
				} else {
					this.messageService.add({
						severity: 'error', summary: 'Error in finding drivers',
						detail: 'There was an error in finding drivers: ' + createdOrderResponse.message
					});
				}
			});
	}

	generateWaybill(orderQuery: OrderQueryView, orderDelivery: OrderDeliveryView) {
		console.log("Generating Waybill for Delivery# " + orderDelivery.deliveryText);
		this.reportsService.generateWayBill(orderQuery, orderDelivery).subscribe(response => {
			if (response != null)
				this.shipmentDeliveryService.getDeliveryByShipmentAndDeliveryNumber(orderQuery.queryId, orderDelivery.deliveryText).subscribe(order => {
					if (order != null) {
						let orderDelivery = ((Object.entries(order?.deliveries).map(deliveryEntry => deliveryEntry[1]) as OrderDeliveryView[])[0]);
						this.orderDeliveryMap.set(orderDelivery.deliveryText, orderDelivery);
						this.orderDeliveryList = Array.from(this.orderDeliveryMap.values());
					}
				});
		});
	}

	addNewFile() {
		this.sessionStorageService.setCurrentOrder(this.orderQuery!!);

		// Define the callback function
		const fileUploadCallback: DownloadUrlCallback = (file: JobAttachment) => {
			// Update the orderFilesList with the new file
			this.orderFilesList.push(file);
			this.orderFilesCount = this.orderFilesList.length;
			fileUploadDialog.close();
		};

		// Open the dialog with the callback
		let fileUploadDialog = this.dialogService.open(FileUploadComponent, {
			header: 'Create/Upload Order File',
			width: '50vw',
			data: { callback: fileUploadCallback },
		});
	}

    generateDeliveries(isFreelancerFlag: boolean) {
        this.router.navigate([`shipment/delivery`, this.orderQuery.queryId, 'generate'], {
            queryParams: { isFreelancer: isFreelancerFlag }
        })
    }

    tabChange($event: Event) {

    }

    confirmDriver(orderJob: OrderJobView) {
        this.confirmDriverInProgress = true;
        this.shipmentJobService.graduateJobToDelivery(this.orderQuery.queryId, orderJob.deliveryText, orderJob).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Delivery generated successfully.' });
            this.confirmDriverInProgress = false;
            window.location.reload();
        }, error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error confirming delivery.' });
            this.confirmDriverInProgress = false;
        });
    }

    addNewChargeLine() {

    }

    updateBuyingPrice() {
        this.chargeTypeToBeUpdated = 'buying';
        this.updateChargesDialogHeader = 'Update Buying Price';
        this.updatedCharge = this.orderQuery.buyingPrice;
        this.updateChargesDialogVisible = true;
    }

    updateSellingPrice() {
        this.chargeTypeToBeUpdated = 'selling';
        this.updateChargesDialogHeader = 'Update Selling Price';
        this.updatedCharge = this.orderQuery.sellingPrice;
        this.updateChargesDialogVisible = true;
    }

    updateCharges() {
        this.updateChargesInProgress = true;
        if(this.chargeTypeToBeUpdated === 'buying') {
            this.orderQuery.buyingPrice = this.updatedCharge;
            this.shipmentQueryService.updateBuyingPrice(this.orderQuery).subscribe(value => {
                if(value) {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Buying price updated successfully.' });
                    this.updateChargesDialogVisible = false;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating buying price.' });
                }
                this.updateChargesInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating buying price: ' + JSON.stringify(error) });
                this.updateChargesInProgress = false;
            });
        } else {
            this.orderQuery.sellingPrice = this.updatedCharge;
            this.shipmentQueryService.updateSellingPrice(this.orderQuery).subscribe(value => {
                if(value) {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Selling price updated successfully.' });
                    this.updateChargesDialogVisible = false;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating selling price.' });
                }
                this.updateChargesInProgress = false;
            }, error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating selling price: ' + JSON.stringify(error) });
                this.updateChargesInProgress = false;
            });
        }
    }

    addWatcher() {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not implemented yet!' });
    }

    generateAllNominees() {
        this.nominationEntities.push(this.orderQuery.nominationPack.sender);
        this.nominationEntities.push(this.orderQuery.nominationPack.receiver);
        this.nominationEntities.push(...this.orderQuery.nominationPack.watchers);
    }

    sendEmail(email: string) {
        if (email) {
            window.location.href = `mailto:${email}`;
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Customer has no email address.' });
        }
    }

    makePhoneCall(phoneNumber: string) {
        if (phoneNumber) {
            window.location.href = `tel:${phoneNumber}`;
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Customer has no phone number.' });
        }
    }



}
