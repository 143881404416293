import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import {MessageService} from "primeng/api"; // Adjust the path accordingly

@Injectable({
    providedIn: 'root'
})
export class AccountsGuard implements CanActivate {

    constructor(private router: Router, private sessionStorageService: SessionStorageService,
                private messageService: MessageService) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const company = this.sessionStorageService.getCompany();
        if (company && company.enableEnterpriseFeatures) { // Replace 'enableEnterpriseFeatures' with the actual flag name
            return true;
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'You are not subscribed to Enterprise Plan!'
            });
            this.router.navigate(['/accounts']); // Redirect to a different route if not an enterprise customer
            return false;
        }
    }
}
