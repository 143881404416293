import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MessageService} from "primeng/api";
import {FileUploadModule} from "primeng/fileupload";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";

import {accountsRoutes} from "./accounts.routes";
import {AccountsComponent} from "./accounts.component";
import {InvoiceComponent} from "./invoice/invoice.component";
import {ReceiptComponent} from "./receipt/receipt.component";
import {AccountsService} from "./accounts.service";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChartModule} from "primeng/chart";
import { ReceivablesComponent } from './receivables/receivables.component';
import { PayablesComponent } from './payables/payables.component';
import { BillComponent } from './bill/bill.component';
import { VoucherComponent } from './voucher/voucher.component';
import { PaymentTransactionsComponent } from './payment-transactions/payment-transactions.component';
import {AccountsGuard} from "./accounts.guard";




@NgModule({
    imports: [
        RouterModule.forChild(accountsRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

        FileUploadModule,
        BreadcrumbModule,
        TableModule,
        TagModule,
        ButtonModule,
        TooltipModule,
        ProgressSpinnerModule,
        ChartModule,
        BreadcrumbModule,

    ],
    providers: [
        MessageService,
        AccountsService,
        AccountsGuard
    ],
    declarations: [
        AccountsComponent,
        InvoiceComponent,
        ReceiptComponent,
        ReceivablesComponent,
        PayablesComponent,
        BillComponent,
        VoucherComponent,
        PaymentTransactionsComponent,
    ],
})
export class AccountsModule { }
