// add-edit-journal-entry.component.ts
import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms'; // Import FormBuilder, etc.
import { ActivatedRoute } from '@angular/router'; // For route parameters
import { Location } from '@angular/common';
import {JournalEntriesService} from "../journal-entries.service";
import {JournalEntry} from "../models/journal-entry-header.model";
import {JournalItem} from "../models/journal-entry-detail.model";
import {MenuItem} from "primeng/api";
import {ChartOfAccounts} from "../../../chart-of-accounts/chart-of-accounts.model";
import {ChartOfAccountsService} from "../../../chart-of-accounts/chart-of-accounts.service";
import {SessionStorageService} from "../../../services/session-storage.service";
import {AutoCompleteOnSelectEvent} from "primeng/autocomplete";
import {Journal} from "../models/journal.model";
import {CustomerService} from "../../../customer/customer.service";
import {CompanyService} from "../../../company/company.service";
import {DropdownChangeEvent} from "primeng/dropdown"; // For navigating back

@Component({
  selector: 'app-add-edit-journal-entry',
  templateUrl: './add-edit-journal-entry.component.html',
  styleUrls: ['./add-edit-journal-entry.component.css']
})
export class AddEditJournalEntryComponent implements OnInit {

    journalEntryForm!: FormGroup;
    journalEntry: JournalEntry = new JournalEntry();
    editMode: boolean = false;
    journalEntryId: number | null = null;

    journalList: Journal[] = [];
    chartOfAccounts: ChartOfAccounts[] = []; // Array to store chart of accounts
    filteredChartOfAccounts: ChartOfAccounts[] = []; // For autocomplete filtering

    isLoading = false;
    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    partnerTypes = [
        'CUSTOMER',
        'COMPANY'
    ]

    partners: { partnerId: number, partnerName: string } []


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
                private sessionStorageService: SessionStorageService,
                private location: Location, private chartOfAccountsService: ChartOfAccountsService,
                private customerService: CustomerService, private companyService: CompanyService,
                private journalEntriesService: JournalEntriesService) { } // Inject FormBuilder

    ngOnInit(): void {
        this.journalEntryForm = this.fb.group({
            entryNumber: ['', Validators.required],
            reference: [''],
            journal: [''],
            entryDate: [new Date(), Validators.required],  // Initialize with current date
            journalItems: this.fb.array([this.createJournalItemsFormGroup()])  // Start with one detail row
        });

        this.route.params.subscribe(params => {
            this.journalEntryId = +params['id'];  // + converts to number
            if (this.journalEntryId) {
                this.editMode = true;
                this.loadJournalEntry(this.journalEntryId);
            }

            this.breadcrumbItems = [{ label: 'Accounts', routerLink: '/accounts' },
                { label: 'Enterprise', routerLink: '/accounts/enterprise' },
                { label: 'Journal Entries', routerLink: '/accounts/enterprise/journal-entries' },
                { label: this.editMode ? 'Update' : 'Create' }];
            this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
        });

        this.chartOfAccountsService.getAllChartOfAccountsByCompany(this.sessionStorageService.getCompany()?.id ?? 0, false).subscribe(accounts => {
            this.chartOfAccounts = accounts;
        });

        this.journalEntriesService.getAllJournalsByCompany(this.sessionStorageService.getCompany()?.id ?? 0).subscribe(journals => {
            this.journalList = journals;
        });

    }

    createJournalItemsFormGroup(journalItem?: JournalItem): FormGroup {
        return this.fb.group({
            accountCode: [journalItem?.account.accountCode + ' ' +journalItem?.account.accountName ?? '', Validators.required],
            partnerType: [journalItem?.partnerType ?? ''],
            partnerName: [journalItem?.partnerName ?? ''],
            label: [journalItem?.name ?? ''],
            debitAmount: [journalItem?.debit ?? 0, Validators.min(0)],  // Initialize debit/credit to 0
            creditAmount: [journalItem?.credit ?? 0, Validators.min(0)],
        });
    }

    get getJournalEntryDetailsControls() {  // Getter for details controls
        const detailsArray = this.journalEntryForm.get('journalItems') as FormArray;
        return detailsArray ? detailsArray.controls : [];
    }

    addJournalEntryDetail(journalEntryDetail?: JournalItem) {
        (this.journalEntryForm.get('journalItems') as FormArray).push(this.createJournalItemsFormGroup(journalEntryDetail));
    }

    removeJournalEntryDetail(index: number) {
        (this.journalEntryForm.get('journalItems') as FormArray).removeAt(index);
    }

    loadJournalEntry(id: number) {
        this.isLoading = true;
        this.journalEntriesService.getJournalEntryById(id).subscribe(entry => {
            this.journalEntry = entry; // Assign the retrieved entry
            this.journalEntryForm.patchValue(this.journalEntry); // Update the form values
            this.removeJournalEntryDetail(0);
            this.journalEntry.items.forEach(detail => this.addJournalEntryDetail(detail));
            this.isLoading = false;
        });
    }

    filterChartOfAccounts(event: any) {
        let filtered: any[] = [];
        let query = event.query;
        for (let i = 0; i < this.chartOfAccounts.length; i++) {
            let account = this.chartOfAccounts[i];
            if (account.accountName.toLowerCase().indexOf(query.toLowerCase()) == 0
                    || account.accountCode.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(account);
            }
        }
        this.filteredChartOfAccounts = filtered;
    }

    saveJournalEntry() {
        if (this.journalEntryForm.invalid) {
            return;  // Don't submit if the form is invalid
        }

        const journalEntryData = this.journalEntryForm.value;

        if (this.editMode) {
            // Update an existing journal entry (Use your service method)
            // Example:
            this.journalEntriesService.updateJournalEntry(journalEntryData).subscribe(() => {  // ! non-null assertion operator
                // Handle success, e.g., show a success message and navigate back to the list view
                this.location.back(); // Go back to the previous page
            });



        } else {
            // Create a new journal entry (Use your service method)
            this.journalEntriesService.createJournalEntry(journalEntryData).subscribe(() => { // Use your service for creation
                // Handle success (show a toast message, navigate, etc.)
                this.location.back(); // Go back to the previous page
            });
        }
    }

    cancel() {
        this.location.back(); // Go back to the previous page
    }


    onAccountSelect($event: AutoCompleteOnSelectEvent, i: number) {
        const account = $event.value;
        const detailGroup = (this.journalEntryForm.get('journalItems') as FormArray).controls[i] as FormGroup;
        detailGroup.patchValue({ accountCode: account.accountCode + ' ' + account.accountName });
        this.filteredChartOfAccounts = [];
        this.journalEntryForm.markAsDirty();
        this.journalEntryForm.markAsTouched();
        this.journalEntryForm.updateValueAndValidity();
    }

    onPartnerSelected($event: DropdownChangeEvent, i: number) {
        const partnerType = $event.value;
        const detailGroup = (this.journalEntryForm.get('journalItems') as FormArray).controls[i] as FormGroup;
        detailGroup.patchValue({ partnerType: partnerType });
        this.journalEntryForm.markAsDirty();
        this.journalEntryForm.markAsTouched();
        this.journalEntryForm.updateValueAndValidity();
        if (partnerType === 'CUSTOMER') {
            this.customerService.getCustomersByCompany(this.sessionStorageService.getCompany()?.id ?? 0).subscribe(customers => {
                customers.forEach(value => this.partners.push({
                    partnerId: value.id, partnerName: value.name,
                }));
            });
        }
        else if (partnerType === 'COMPANY') {
            this.companyService.getCompaniesByCountry(2).subscribe(companies => {

            });
        }
    }
}

