import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterOutlet } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { ImageModule } from 'primeng/image';
import {LoginComponent} from "./login.component";
import {MessageService} from "primeng/api";
import * as firebaseui from "firebaseui";
import {firebase, FirebaseUIModule} from "firebaseui-angular";


const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            requireDisplayName: false,
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
        },
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        {
            scopes: [
                'public_profile',
                'email'
            ],
            customParameters: {
                'auth_type': 'reauthenticate'
            },
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
        },
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        'microsoft.com',
    ],
    tosUrl: 'https://www.cargomate.co/privacy-policy',
    privacyPolicyUrl: 'https://www.cargomate.co/privacy-policy',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule({
    imports: [
        FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        ImageModule
    ],
    providers: [
        MessageService
    ],
    declarations: [
        LoginComponent
    ],
})
export class LoginModule { }
