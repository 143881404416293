import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FontAwesomeModule, FaIconLibrary, } from '@fortawesome/angular-fontawesome';


import { authInterceptorProviders } from './helpers/auth.interceptor';
import { BaseMasterDataService } from './services/base-master-data.service';
import { MessageService } from 'primeng/api';
import { BigQueryService } from './services/bigquery-api.service';
import { FileUploadComponent } from './helpers/file-upload/file-upload.component';


import {FleetModule} from "./fleet/fleet.module";
import {ShipmentModule} from "./shipment/shipment.module";
import {ProgressBarModule} from "primeng/progressbar";
import {LoginModule} from "./login/login.module";
import {FileUploadModule} from "primeng/fileupload";
import {DropdownModule} from "primeng/dropdown";
import {LocationModule} from "./location/location.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {ContractsModule} from "./contracts/contracts.module";
import {AvatarModule} from "primeng/avatar";
import {MenuModule} from "primeng/menu";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ImageModule} from "primeng/image";
import {MenubarModule} from "primeng/menubar";
import {AccountsModule} from "./accounts/accounts.module";
import {CompanyModule} from "./company/company.module";
import {CustomerModule} from "./customer/customer.module";
import {PaymentService} from "./services/payment.service";
import { AiChatComponent } from './ai-chat/ai-chat.component';
import {AiChatModule} from "./ai-chat/ai-chat.module";
import {AiSummaryService} from "./services/ai-summary.service";




@NgModule({
    declarations: [
        AppComponent,
        FileUploadComponent,
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,

        FontAwesomeModule,
        DropdownModule,
        AvatarModule,
        MenuModule,
        ProgressBarModule,
        OverlayPanelModule,
        ImageModule,
        MenubarModule,
        FileUploadModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the service worker
            registrationStrategy: 'registerWhenStable:30000'
        }),

        LoginModule,
        ShipmentModule,
        LocationModule,
        FleetModule,
        ContractsModule,
        DashboardModule,
        AccountsModule,
        CompanyModule,
        CustomerModule,
        AiChatModule,
    ],
    providers: [
        authInterceptorProviders,
        AuthService,
        BaseMasterDataService,
        MessageService,
        BigQueryService,
        PaymentService,
        AiSummaryService

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) { }
}
