// generated-receipts.component.ts
import {Component, OnInit, ViewChild} from '@angular/core';
import { AccountsService } from '../../accounts.service';
import { GeneratedReceiptsSummary } from './generated-receipts-summary.model';
import {ConfirmationService, MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {SessionStorageService} from "../../../services/session-storage.service";
import {PaymentService} from "../../../services/payment.service";
import {PaymentTransactions} from "../../payment-transactions/payment-transactions.model";
import {ReceiptAmountDialogComponent} from "./receipt-amount-dialog/receipt-amount-dialog.component";
import {Receipt} from "../../receipt/receipt.model";


@Component({
    selector: 'app-generated-receipts',
    templateUrl: './generated-receipts.component.html',
    styleUrls: ['./generated-receipts.component.css']
})
export class GeneratedReceiptsComponent {

    generatedReceiptsSummaryList: GeneratedReceiptsSummary[] = [];
    homeBreadcrumb: MenuItem = {icon: 'pi pi-home', routerLink: '/'};
    breadcrumbItems: MenuItem[] = [];
    isLoading = false;
    first = 0;
    rows = 20;

    @ViewChild(ReceiptAmountDialogComponent)
    receiptAmountDialogComponent: ReceiptAmountDialogComponent;

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private paymentService: PaymentService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {}

    ngOnInit(): void {
        this.breadcrumbItems = [
            {label: 'Dashboard', routerLink: '/dashboard'},
            {label: 'Accounts', routerLink: '/accounts'},
            {label: 'Receivables'},
            {label: 'Generated Receipts'}
        ];
        this.loadGeneratedReceiptsSummary();
    }



    loadGeneratedReceiptsSummary() {
        this.isLoading = true;
        // Replace with the appropriate method from your service to fetch the data
        this.accountsService.getGeneratedReceiptsSummary(this.sessionStorageService.getCompany()!!.id).subscribe(
            data => {
                console.log(data);
                this.generatedReceiptsSummaryList = data;
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to load generated receipts summary.'});
            }
        );
    }

    loadData(event: any, generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.first = event.first;
        this.rows = event.rows;
        this.isLoading = true;
        this.accountsService.getReceipts(this.first, this.rows,
            generatedReceiptsSummary.client.id, this.sessionStorageService.getCompany()!!.id).subscribe((dataWrapper) => {
            console.log(dataWrapper);
            generatedReceiptsSummary.receipts = dataWrapper.items// Simulate fetching data
            generatedReceiptsSummary.totalReceipts = dataWrapper.count; // Set total records for pagination
            this.isLoading = false;
        });
    }

    exportToExcel(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        // Your export logic here
        console.log("Receipts to export:", generatedReceiptsSummary.selectedReceipts);
    }

    generatePaymentFromReceipts(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.isLoading = true;
        if (generatedReceiptsSummary.selectedReceipts == null || generatedReceiptsSummary.selectedReceipts.length == 0) {
            this.messageService.add({severity: 'error', summary: 'Invalid selection', detail: 'No receipts selected.'});
            this.isLoading = false;
            return;
        }
        if (generatedReceiptsSummary.selectedReceipts.find(value => value.paymentTransactionNumber != null)) {
            this.messageService.add({severity: 'error', summary: 'Invalid selection', detail: 'Selected Receipts contain already paid receipts.'});
            this.isLoading = false;
            return;
        }
        this.paymentService.generatePaymentFromReceipts(generatedReceiptsSummary.selectedReceipts, this.sessionStorageService.getCustomer()?.firebaseId ?? '').subscribe(
            (paymentTransaction: PaymentTransactions) => {
                console.log('Payment Transaction from Receipts:', paymentTransaction);
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Payment generated successfully.'});
                this.isLoading = false;
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            (error) => {
                console.error('Error generating payment:', error);
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to generate payment.'});
                this.isLoading = false;
            }
        );
    }

    showReceiptAmountDialog(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.receiptAmountDialogComponent.showDialog(generatedReceiptsSummary);
    }

    applyReceiptOnInvoice(generatedReceiptsSummary: GeneratedReceiptsSummary) {
        this.isLoading = true;
        if (generatedReceiptsSummary.selectedReceipts == null || generatedReceiptsSummary.selectedReceipts.length == 0)
            return;
        else if (generatedReceiptsSummary.selectedReceipts.length > 1) {
            console.error('Error applying receipt:');
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please select only 1 receipt'});
            this.isLoading = false;
            return;
        }
        else {
            this.router.navigate(['/accounts/enterprise/receivables/apply-receipts-invoice', generatedReceiptsSummary.selectedReceipts[0].receiptId]);
        }
    }

    getAppliedAmount(receipt: Receipt) {
        return receipt.invoices?.map(invoice => invoice.totalAmount)
            .reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0)
    }

    getUnAppliedAmount(receipt: Receipt) {
        let appliedAmount = this.getAppliedAmount(receipt);
        return (receipt.amount ? receipt.amount : 0) - (appliedAmount ? appliedAmount : 0);
    }
}
