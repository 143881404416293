import {Component, OnInit} from '@angular/core';
import {PartnerLedgerSummary} from "./partner-ledger-summary.model";
import {PartnerLedger} from "./partner-ledger.model";
import {AccountingReportsService} from "../accounting-reports.service";
import {MenuItem, MessageService, TreeNode} from "primeng/api";
import {SessionStorageService} from "../../services/session-storage.service";
import {TreeNodeSelectEvent, TreeNodeUnSelectEvent} from "primeng/tree";
import {Customer} from "../../customer/customer.model";
import {CustomerService} from "../../customer/customer.service";
import {Company} from "../../company/company.model";

@Component({
    selector: 'app-partner-ledger',
    templateUrl: './partner-ledger.component.html',
    styleUrls: ['./partner-ledger.component.css']
})
export class PartnerLedgerComponent implements OnInit {

    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    partnersTree: TreeNode[] = [];
    selectedPartners: TreeNode[] = [];
    fullPartnerLedgerSummaries: PartnerLedgerSummary[] = [];
    selectedPartnerLedgerSummaries: PartnerLedgerSummary[] = [];
    fullPartnerLedgers: PartnerLedger[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 10;

    companyTreeNode: TreeNode;
    customerTreeNode: TreeNode;
    driverTreeNode: TreeNode;
    agencyTreeNode: TreeNode;

    constructor(
        private accountingReportsService: AccountingReportsService,
        private sessionStorageService: SessionStorageService,
        private customerService: CustomerService,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Partner Ledger' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.companyTreeNode = this.generateRootNodes('Company');
        this.customerTreeNode = this.generateRootNodes('Customer');
        this.driverTreeNode = this.generateRootNodes('Driver');
        this.agencyTreeNode = this.generateRootNodes('Agency');


        this.loadPartnerLedgerSummary();
    }

    generateRootNodes(partnerType: string): TreeNode {
        return {
            label: partnerType,
            children: [],
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            leaf: false,
            expanded: true,
        };
    }

    buildTreeNode(partnerLedgerSummary: PartnerLedgerSummary): TreeNode {
        return {
            label: partnerLedgerSummary.partnerName,
            data: partnerLedgerSummary.partnerType,
            key: partnerLedgerSummary.partnerId.toString(),
            leaf: true,
            expanded: false,
        };
    }

    loadPartnerLedgerSummary() {
        this.isLoading = true;
        this.accountingReportsService.getPartnerLedgerSummary(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (data) => {
                console.log(data);
                this.fullPartnerLedgerSummaries = data;
                this.selectedPartnerLedgerSummaries = [...data];
                this.loadFullData();
                data.forEach(value => {
                   if (value.partnerType === 'CUSTOMER')
                       this.customerTreeNode.children?.push(this.buildTreeNode(value));
                   else if (value.partnerType === 'COMPANY')
                       this.companyTreeNode.children?.push(this.buildTreeNode(value));
                   else if (value.partnerType === 'AGENCY')
                       this.agencyTreeNode.children?.push(this.buildTreeNode(value));
                   else if (value.partnerType === 'DRIVER')
                       this.driverTreeNode.children?.push(this.buildTreeNode(value));
                });
                this.partnersTree.push(this.companyTreeNode);
                this.partnersTree.push(this.customerTreeNode);
                this.partnersTree.push(this.driverTreeNode);
                this.partnersTree.push(this.agencyTreeNode);
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading Partner Ledger Summary'
                });
                this.isLoading = false;
            }
        });
    }

    loadFullData() {
        this.accountingReportsService.getPartnerLedger(this.sessionStorageService.getCompany()?.id ?? 0).subscribe(data => {
            this.fullPartnerLedgers = data;
            this.fullPartnerLedgerSummaries.forEach(partnerLedgerSummary => {
                partnerLedgerSummary.partnerLedgers = this.fullPartnerLedgers
                    .filter(partnerLedger => partnerLedger.partnerId == partnerLedgerSummary.partnerId);
            });
            this.isLoading = false;
        }, error => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error loading Partner Ledger Details'
            });
            this.isLoading = false;
        });
    }

    updateSelectEvents(selectEvent: TreeNodeSelectEvent) {
        this.selectedPartners.push(selectEvent.node);
        this.filterPartners();
    }

    updateUnSelectEvents(unselectEvent: TreeNodeUnSelectEvent) {
        // Filter out the unselected node from selectedChartOfAccounts
        this.selectedPartners = this.selectedPartners.filter(node =>
            node.data?.id !== unselectEvent.node.data?.id
        );
        this.filterPartners();
    }

    filterPartners() {
        if (this.selectedPartners.length === 0) {
            this.selectedPartnerLedgerSummaries = [...this.fullPartnerLedgerSummaries]; // Show all if nothing selected
        } else {
            this.selectedPartnerLedgerSummaries = this.fullPartnerLedgerSummaries.filter(summary =>
                this.selectedPartners.some(node => this.checkRecursive(node, summary.partnerId, summary.partnerType))
            );
        }
    }

    checkRecursive(node: TreeNode, toCheckPartnerId: number, toCheckPartnerType: string): boolean | undefined {
        return node.key === toCheckPartnerId.toString() && node.data === toCheckPartnerType;
    }
}
