import { Routes } from '@angular/router';
import {ChartOfAccountsComponent} from "./chart-of-accounts.component";
import {AddEditAccountsComponent} from "./add-edit-accounts/add-edit-accounts.component";

export const chartOfAccountRoutes: Routes = [
    {
        path: 'chart-of-accounts',
        children: [
            {
                path: '',
                component: ChartOfAccountsComponent
            },
            {
                path: 'add/:id',
                component: AddEditAccountsComponent
            },
            {
                path: 'edit/:id',
                component: AddEditAccountsComponent
            }
        ]
    },
];
