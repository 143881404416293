import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { tap, forkJoin } from "rxjs";
import {ChartOfAccounts} from "../chart-of-accounts.model";
import {COAAccountType} from "../coa-account-type.model";
import {ChartOfAccountsService} from "../chart-of-accounts.service";
import {SessionStorageService} from "../../services/session-storage.service";
import {APIResponseWrapper} from "../../models/common/api-response-wrapper";

@Component({
    selector: 'app-add-edit-accounts',
    templateUrl: './add-edit-accounts.component.html',
    styleUrls: ['./add-edit-accounts.component.css']
})
export class AddEditAccountsComponent implements OnInit {

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};
    chartOfAccounts!: ChartOfAccounts;
    chartOfAccountsForm!: FormGroup;
    editMode = false;
    accountId!: number;
    isLoading: boolean = false;

    accountTypes: SelectItem<COAAccountType>[] = [];
    parentChartOfAccount: ChartOfAccounts | undefined;

    constructor(
        private fb: FormBuilder, private route: ActivatedRoute,
        private location: Location, private router: Router,
        private messageService: MessageService,
        private chartOfAccountsService: ChartOfAccountsService,
        private sessionStorageService: SessionStorageService) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Chart Of Accounts', routerLink: '/accounts/chart-of-accounts' },
            { label: this.editMode ? 'Edit Chart of Account' : 'Add Chart of Account' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.accountId = params['id'];
            this.editMode = this.route.snapshot.url[0].path.includes('edit');
            this.loadData();
        });

        this.initForm();
    }

    loadData() {
        this.isLoading = true;

        const loadObservables = {
            accountTypes: this.loadAccountTypes()
        };

        forkJoin(loadObservables)
            .pipe(
                tap(() => {
                    this.loadChartOfAccounts().subscribe();
                })
            )
            .subscribe({
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while loading data.' + error.message
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    loadAccountTypes() {
        return this.chartOfAccountsService.getAllAccountTypes().pipe(
            tap(value => {
                this.accountTypes = value.map(accountType => ({
                    value: accountType,
                    label: accountType.accountTypeName
                }));
            }),
        );
    }

    loadChartOfAccounts() {
        return this.chartOfAccountsService.getChartOfAccountsById(this.accountId).pipe(
            tap(chartOfAccounts => {
                this.chartOfAccounts = chartOfAccounts;
                if (this.editMode)
                    this.chartOfAccountsForm.patchValue(chartOfAccounts);
                else {
                    this.chartOfAccountsForm.reset();
                    this.parentChartOfAccount = chartOfAccounts;
                    this.chartOfAccountsForm.patchValue({ accountCode: chartOfAccounts.accountCode.substring(0, + chartOfAccounts.accountCode.length - 2) + '00' });
                    this.chartOfAccountsForm.patchValue({ parentAccount: chartOfAccounts });
                    this.chartOfAccountsForm.patchValue({ accountType: this.parentChartOfAccount?.accountType });
                }
            })
        );
    }

    initForm() {
        this.chartOfAccountsForm = this.fb.group({
            accountId: [null],
            accountCode: ['', Validators.required],
            accountName: ['', Validators.required],
            accountType: [null, Validators.required],
        });
    }

    saveChartOfAccounts() {
        if (this.chartOfAccountsForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const modifiedChartOfAccounts = this.chartOfAccountsForm.value as ChartOfAccounts;
        modifiedChartOfAccounts.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        modifiedChartOfAccounts.parentAccountId = this.parentChartOfAccount?.accountId ?? null;
        modifiedChartOfAccounts.isActive = true;
        modifiedChartOfAccounts.allowEditing = this.editMode;
        modifiedChartOfAccounts.deprecated = false;
        modifiedChartOfAccounts.allowReconciliation = false;
        modifiedChartOfAccounts.internalType = this.editMode
            ? modifiedChartOfAccounts.internalType : this.parentChartOfAccount?.internalType ?? '';

        this.chartOfAccountsService.createChartOfAccounts(modifiedChartOfAccounts).subscribe({
            next: (newChartOfAccounts: APIResponseWrapper<ChartOfAccounts>) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Chart of Account saved successfully.'
                });
                this.router.navigate(['/chart-of-accounts']);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving chart of account: '  + error.error.message
                });
            }
        });
    }

    cancel() {
        this.location.back();
    }
}
