<p-toast />
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<div *ngIf="!isLoading" class="col-12 flex overflow-hidden">
    <div class="flex-none flex align-items-center justify-content-center">
        <div class="pl-4">
            <p class="text-4xl font-bold text-blue-800 p-0 mb-1">Shipment# {{orderQuery.queryId}}</p>
            <p-tag icon="pi pi-user" value="{{orderQuery.orderStatus}}"></p-tag>
        </div>
    </div>
    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
    <div class="flex-none flex align-items-center">
        <div class="pr-4">
            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{orderQuery.countryOfOrder?.currencyCode}}
                {{orderQuery.buyingPrice! * orderQuery.quantityRequired!}}</p>
            <p class="text-l text-right text-red-200 p-0 m-0">{{orderQuery.quantityRequired}} x
                {{orderQuery.countryOfOrder?.currencyCode}} {{orderQuery.buyingPrice}}</p>
        </div>
    </div>
    <div class="flex-none flex align-items-center">
        <div class="pr-4">
            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{orderQuery.countryOfOrder?.currencyCode}}
                {{orderQuery.sellingPrice! * orderQuery.quantityRequired!}}</p>
            <p class="text-l text-right text-red-200 p-0 m-0">{{orderQuery.quantityRequired}} x
                {{orderQuery.countryOfOrder?.currencyCode}} {{orderQuery.sellingPrice}}</p>
        </div>
    </div>
</div>

<!-- AI Summary -->
<p-fieldset *ngIf="!isLoading" [toggleable]="true" class="p-4">
    <ng-template pTemplate="header">
        <div class="flex align-items-center text-primary">
            <span class="fa-solid fa-laptop-code"></span>&nbsp;&nbsp;
            <span class="font-bold text-lg">AI Summary</span>
        </div>
    </ng-template>
    <p-progressBar mode="indeterminate" class="m-2" *ngIf="shipmentAiSummary?.length == 0"></p-progressBar>
    <div [innerHTML]="shipmentAiSummary"></div>
</p-fieldset>

<p-tabView *ngIf="!isLoading" [scrollable]="true">

    <!-- Details -->
    <p-tabPanel (change)="tabChange($event)">
        <ng-template pTemplate="header">
            <i class="fa-solid fa-circle-info"></i>
            &nbsp;&nbsp;<span>Details</span>
            &nbsp;&nbsp;<p-badge *ngIf="false"></p-badge>
            &nbsp;&nbsp;<p-badge [value]="'INFO'" severity="info"></p-badge>
        </ng-template>
        <div class="grid mb-2">
            <div class="col-12 lg:col-6">
                <div class="grid bg-red-100 text-gray-900 border-round-md mt-2 ml-1 mr-1">
                    <div class="col-12">
                        <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                            <i class="fa-solid fa-flag-checkered"></i>&nbsp;&nbsp;Sender Info:
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="flex">
                            <div class="flex-none align-items-center align-content-center m-3 h-full">
                                <p-image src="{{ orderQuery.nominationPack.sender.client.logo != null ? orderQuery.nominationPack.sender.client.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                         height="50"></p-image><br>
                                <span></span>
                            </div>
                            <div class="flex-grow-1 grid w-full">
                                <div class="flex align-items-center col-12 p-0 pl-3">
                                    <p-avatar image="{{ orderQuery.nominationPack.sender.nominee.imageUrl != null ? orderQuery.nominationPack.sender.nominee.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                              shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                        orderQuery.nominationPack.sender.nominee.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                    <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                        orderQuery.nominationPack.sender.nominee.phoneNumber }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                                     *ngIf="orderQuery?.nominationPack?.sender?.nominee?.emailAddress?.search('@abcxyz.com') == 0">
                                    <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                        orderQuery.nominationPack.sender.nominee.emailAddress }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                    <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Site:&nbsp;&nbsp;<b>{{
                                        orderQuery.sourceLocation.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                                    <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                                    href="https://maps.google.com?q={{ orderQuery.nominationPack.sender.client.location.latitude }},{{ orderQuery.nominationPack.sender.client.location.longitude }}">{{pickupSubLocality}},
                                    {{pickupLocality}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6">
                <div class="grid bg-green-100 text-gray-900 border-round-md mt-2 ml-1 mr-1">
                    <div class="col-12">
                        <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                            <i class="fa-solid fa-flag"></i>&nbsp;&nbsp;Receiver Info:
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="flex">
                            <div class="flex-none align-items-center align-content-center m-3 h-full">
                                <p-image src="{{ orderQuery.nominationPack.receiver.client.logo != null ? orderQuery.nominationPack.receiver.client.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                         height="50"></p-image><br>
                                <span></span>
                            </div>
                            <div class="flex-grow-1 grid w-full">
                                <div class="flex align-items-center col-12 p-0 pl-3">
                                    <p-avatar image="{{ orderQuery.nominationPack.receiver.nominee.imageUrl != null ? orderQuery.nominationPack.receiver.nominee.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                              shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                        orderQuery.nominationPack.receiver.nominee.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                    <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                        orderQuery.nominationPack.receiver.nominee.phoneNumber }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                                     *ngIf="orderQuery?.nominationPack?.sender?.nominee?.emailAddress?.search('@abcxyz.com') == 0">
                                    <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                        orderQuery.nominationPack.receiver.nominee.emailAddress }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                    <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Site:&nbsp;&nbsp;<b>{{
                                        orderQuery.destinationLocation.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                                    <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                                    href="https://maps.google.com?q={{ orderQuery.nominationPack.receiver.client.location.latitude }},{{ orderQuery.nominationPack.receiver.client.location.longitude }}">{{dropoffSubLocality}},
                                    {{dropoffLocality}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid mb-2">
            <div class="col-12 lg:col-6">
                <div class="grid bg-blue-100 text-gray-900 border-round-md mt-2 ml-1 mr-1">
                    <div class="col-12">
                        <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                            <i class="fa-solid fa-truck"></i>&nbsp;&nbsp;Vehicle Type Requested:
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="flex">
                            <div class="flex-none align-items-center align-content-center m-3 h-full">
                                <p-image src="{{ orderQuery.vehicleParaPack.vehicleConfig?.image != null ? orderQuery.vehicleParaPack.vehicleConfig?.image
                                : 'https://cdn-icons-png.flaticon.com/512/870/870181.png' }}"
                                         height="50"></p-image><br>
                                <span></span>
                            </div>
                            <div class="flex-grow-1 grid w-full">
                                <div class="flex align-items-center col-12 p-0 pl-3 pt-2">
                                    <i class="fa-solid fa-wrench"></i>&nbsp;&nbsp;Type#:&nbsp;&nbsp;<b>{{
                                        orderQuery.vehicleParaPack.vehicleType?.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3">
                                    <i class="fa-solid fa-gear"></i>&nbsp;&nbsp;Config:&nbsp;&nbsp;<b>{{
                                        orderQuery.vehicleParaPack.vehicleConfig?.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                                    <i class="fa-solid fa-weight-hanging"></i>&nbsp;&nbsp;Capacity:&nbsp;&nbsp;<b>{{
                                        orderQuery.vehicleParaPack.vehicleCapacity?.name }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 lg:col-6">
                <div class="grid bg-purple-100 text-gray-900 border-round-md mt-2 ml-1 mr-1">
                    <div class="col-12">
                        <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                            <i class="fa-solid fa-truck-ramp-box"></i>&nbsp;&nbsp;Cargo Info:
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="flex">
                            <div class="flex-none align-items-center align-content-center m-3 h-full">
                                <p-image src="{{ orderQuery!!.payloadItemList[0]!!.itemType?.image ? orderQuery!!.payloadItemList[0]!!.itemType?.image
                                : 'https://cdn-icons-png.flaticon.com/512/3285/3285047.png' }}"
                                         height="50"></p-image><br>
                                <span></span>
                            </div>
                            <div class="flex-grow-1 grid w-full">
                                <div class="flex align-items-center col-12 p-0 pl-3 pt-2">
                                    <i class="fa-solid fa-wrench"></i>&nbsp;&nbsp;Type#:&nbsp;&nbsp;<b>{{
                                        orderQuery!!.payloadItemList[0]!!.itemType?.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3">
                                    <i class="fa-solid fa-gear"></i>&nbsp;&nbsp;Quantity:&nbsp;&nbsp;
                                    <b>{{ orderQuery!!.payloadItemList[0]!!.itemQuantity?.quantity }} {{
                                            orderQuery!!.payloadItemList[0]!!.itemQuantity?.uom?.name }}</b>
                                </div>
                                <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                                    <i class="fa-solid fa-weight-hanging"></i>&nbsp;&nbsp;Weight:&nbsp;&nbsp;<b>{{
                                        orderQuery!!.payloadItemList[0]!!.itemWeight }} {{
                                        orderQuery!!.payloadItemList[0]!!.weightUnit?.shortName }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>

    <!-- People -->
    <p-tabPanel (change)="tabChange($event)">
        <ng-template pTemplate="header">
            <i class="fa-solid fa-users"></i>
            &nbsp;&nbsp;<span>Nominees</span>
            &nbsp;&nbsp;<p-badge *ngIf="nominationEntities.length > 0" [value]="nominationEntities.length.toString()" severity="success"></p-badge>
        </ng-template>
        <ng-template pTemplate="content">
            <p-table [value]="nominationEntities" dataKey="id" [resizableColumns]="true"
                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                <ng-template pTemplate="caption">
                    <div class="col-12 flex overflow-hidden">
                        <div class="flex-none flex align-items-center justify-content-center">
                            Nominee Persons
                        </div>
                        <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                        <div class="flex-none flex align-items-center justify-content-center">
                            <p-button label="Add Watcher" severity="help" icon="fa-solid fa-add" (onClick)="addWatcher()"></p-button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Roles</th>
                        <th>Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-nominationEntity>
                    <tr>
                        <td>{{ nominationEntity.nominee.id }}</td>
                        <td>
                            <p-chip [label]="nominationEntity.nominee.name">
                                <img src="{{ nominationEntity.nominee.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                     alt="{{ nominationEntity.nominee.name }}"
                                     style="width: 32px; height: 32px; border-radius: 50%;">
                            </p-chip>
                        </td>
                        <td>{{ nominationEntity.client.name }}</td>
                        <td>{{ nominationEntity.nominee.emailAddress.includes('@abcxyz.com') ? '' : nominationEntity.nominee.emailAddress }}</td>
                        <td>{{ nominationEntity.nominee.phoneNumber }}</td>
                        <td>{{ nominationEntity.role }}</td>
                        <td>
                            <button pButton pTooltip="Send Email" type="button" icon="pi pi-envelope"
                                    (click)="sendEmail(nominationEntity.nominee.emailAddress)"
                                    [disabled]="nominationEntity.nominee.emailAddress.includes('@abcxyz.com')"
                                    class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                            <button pButton pTooltip="Call" type="button" icon="pi pi-phone"
                                    (click)="makePhoneCall(nominationEntity.nominee.phoneNumber)"
                                    class="p-button-rounded p-button-text p-button-success mr-2"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-tabPanel>

    <!-- Deliveries -->
    <p-tabPanel (change)="tabChange($event)">
        <ng-template pTemplate="header">
            <i class="fa-solid fa-truck-ramp-box"></i>
            &nbsp;&nbsp;<span>Deliveries</span>
            &nbsp;&nbsp;<p-badge *ngIf="(orderDeliveryList.length + orderJobList.length) === 0" severity="danger"
                                 [value]="(orderDeliveryList.length + orderJobList.length).toString()"></p-badge>
            &nbsp;&nbsp;<p-badge *ngIf="(orderDeliveryList.length + orderJobList.length) > 0" severity="success"
                                 [value]="(orderDeliveryList.length + orderJobList.length).toString()"></p-badge>
        </ng-template>
        <div *ngIf="tripsCount == 0 || orderQuery?.quantityRequired! > tripsCount" class="grid">
            <div class="col-12">
                <p-table [value]="orderJobList">
                    <ng-template pTemplate="caption">
                        <div class="grid">
                            <div class="col-12">
                                <p-progressBar *ngIf="!orderQueryStatus.isJobsGenerated" mode="indeterminate" class="m-1" [style]="{ height: '6px' }"></p-progressBar>
                            </div>
                            <div class="col-12 flex my-split-button-container">
                                <div class="flex-none flex align-items-center justify-content-center">
                                    Finding & Invoking drivers nearby the Pickup Location...
                                </div>
                                <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                                <div class="flex-none flex align-items-center justify-content-center">
                                    <p-button [outlined]="true" [loading]="!orderQueryStatus.isJobsGenerated" (onClick)="findNewDrivers()">
                                        <img alt="logo" src="/icons/android-chrome-192x192.png" style="width: 1.5rem" />
                                        <span class="ml-2 font-bold">Find Drivers from CargoMate</span>
                                    </p-button>
                                </div>
                                <div class="flex-none flex align-items-center justify-content-center ml-2">
                                    <p-splitButton label="Assign Driver/Vehicle" icon="fa-solid fa-people-arrows"
                                                   [model]="assignDriverMenu"></p-splitButton >
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Trip#</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orderJob>
                        <tr>
                            <td>{{orderJob.deliveryText}}</td>
                            <td>
                                <p-chip *ngIf="orderJob.selectedDriver" [label]="orderJob.selectedDriver.legalName">
                                    <img src="{{ orderJob.selectedDriver.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                         alt="{{ orderJob.selectedDriver.legalName }}"
                                         style="width: 32px; height: 32px; border-radius: 50%;">
                                </p-chip>
                            </td>
                            <td>
                                <p-chip *ngIf="orderJob.selectedVehicle" [label]="orderJob.selectedVehicle.plateNumber">
                                    <img src="{{ orderJob.selectedVehicle.make?.logo ?? 'https://cdn-icons-png.flaticon.com/512/4047/4047296.png'}}"
                                         alt="{{ orderJob.selectedVehicle.plateNumber }}"
                                         style="width: 32px; height: 32px; border-radius: 30%;">
                                </p-chip>
                            </td>
                            <td>
                                <p-tag icon="pi pi-user" severity="success" value="{{orderJob.jobStatus}}"></p-tag>
                            </td>
                            <td>
                                <p-button label="Invoke" severity="warning" [loading]="invokeDriverInProgress"
                                          icon="fa-solid fa-phone-volume" (onClick)="invokeDriver(orderJob)"></p-button>&nbsp;
                                <p-button label="Confirm" severity="help" [loading]="confirmDriverInProgress"
                                          icon="fa-solid fa-check" (onClick)="confirmDriver(orderJob)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            {{jobsCount}} jobs are currently available.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <div *ngIf="!isLoading && tripsCount != 0" class="grid">
            <div class="col-12">
                <p-table [value]="orderDeliveryList">
                    <ng-template pTemplate="caption">
                        <div class="flex align-items-center justify-content-between">
                            Deliveries
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Trip#</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orderDelivery>
                        <tr>
                            <td>{{orderDelivery.deliveryText}}</td>
                            <td>
                                <p-chip *ngIf="orderDelivery.deliveryDriver" [label]="orderDelivery.deliveryDriver.legalName">
                                    <img src="{{ orderDelivery.deliveryDriver.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                         alt="{{ orderDelivery.deliveryDriver.legalName }}"
                                         style="width: 32px; height: 32px; border-radius: 50%;">
                                </p-chip>
                            </td>
                            <td>
                                <p-chip *ngIf="orderDelivery.deliveryVehicle" [label]="orderDelivery.deliveryVehicle.plateNumber">
                                    <img src="{{ orderDelivery.deliveryVehicle.make?.logo ?? 'https://cdn-icons-png.flaticon.com/512/4047/4047296.png'}}"
                                         alt="{{ orderDelivery.deliveryVehicle.plateNumber }}"
                                         style="width: 32px; height: 32px; border-radius: 30%;">
                                </p-chip>
                            </td>
                            <td>
                                <p-tag icon="pi pi-user" severity="success" value="{{orderDelivery.jobStatus}}"></p-tag>
                            </td>
                            <td>
                                <p-button label="Track" severity="info" icon="pi pi-map" (onClick)="viewDeliveryPage(orderDelivery)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            In total there are {{tripsCount}} trips.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>

    <!-- Files -->
    <p-tabPanel (change)="tabChange($event)">
        <ng-template pTemplate="header">
            <i class="fa-solid fa-file-pdf"></i>
            &nbsp;&nbsp;<span>Files</span>
            &nbsp;&nbsp;<p-badge *ngIf="orderFilesCount === 0"  severity="info" [value]="orderFilesCount.toString()"></p-badge>
            &nbsp;&nbsp;<p-badge *ngIf="orderFilesCount > 0"  severity="success" [value]="orderFilesCount.toString()"></p-badge>
        </ng-template>
        <div *ngIf="!isLoading" class="grid">
            <div class="col-12">
                <p-table [value]="orderFilesList">
                    <ng-template pTemplate="caption">
                        <div class="col-12 flex overflow-hidden">
                            <div class="flex-none flex align-items-center justify-content-center">
                                Order Files
                            </div>
                            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                            <div class="flex-none flex align-items-center justify-content-center">
                                <p-button label="Add File" severity="help" icon="fa-solid fa-add" (onClick)="addNewFile()"></p-button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>File Name</th>
                            <th>File Type</th>
                            <th>Created At</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-orderFile>
                        <tr>
                            <td><a href="{{orderFile.fileUrl}}">{{orderFile.fileName}}</a></td>
                            <td>{{orderFile.fileType}}</td>
                            <td>{{showDate(orderFile.createdAt)}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            In total there are {{orderFilesCount}} files.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>

    <!-- Charges -->
    <p-tabPanel (change)="tabChange($event)">
        <ng-template pTemplate="header">
            <i class="fa-solid fa-money-bill-transfer"></i>
            &nbsp;&nbsp;<span>Charges</span>
            &nbsp;&nbsp;<p-badge *ngIf="orderQuery.chargeLines.length === 0" severity="danger"
                                 [value]="orderQuery.chargeLines.length.toString()"></p-badge>
            &nbsp;&nbsp;<p-badge *ngIf="orderQuery.chargeLines.length > 0" severity="info"
                                 [value]="orderQuery.chargeLines.length.toString()"></p-badge>
        </ng-template>
        <div class="grid p-4 ml-1 mr-1 mb-2 bg-yellow-100 text-gray-900 border-round-md">
            <div class="lg:col-6 sm:col-12">
                <span class="font-bold"><i class="fa-solid fa-angles-down"></i>&nbsp;&nbsp;Buying Charge:</span> {{ orderQuery.countryOfOrder?.currencyCode }} {{ orderQuery.buyingPrice }}
            </div>
            <div class="lg:col-6 sm:col-12">
                <span class="font-bold"><i class="fa-solid fa-angles-up"></i>&nbsp;&nbsp;Selling Charge:</span> {{ orderQuery.countryOfOrder?.currencyCode }} {{ orderQuery.sellingPrice }}
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <p-table [value]="orderQuery.chargeLines">
                    <ng-template pTemplate="caption">
                        <div class="col-12 flex overflow-hidden">
                            <div class="flex-none flex align-items-center justify-content-center">
                                Charge Lines
                            </div>
                            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                            <div class="flex-none flex align-items-center justify-content-center">
                                <p-button label="Update Buying Price" severity="warning" icon="fa-solid fa-angles-down"
                                          (onClick)="updateBuyingPrice()" class="pr-2"></p-button>
                                <p-button label="Update Selling Price" severity="warning" icon="fa-solid fa-angles-up"
                                          (onClick)="updateSellingPrice()" class="pr-2"></p-button>
                                <p-button label="Add Charge" severity="help" icon="fa-solid fa-add" (onClick)="addNewChargeLine()"></p-button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Line#</th>
                            <th>Delivery#</th>
                            <th>Type</th>
                            <th>Currency</th>
                            <th>Buying Amount</th>
                            <th>Selling Amount</th>
                            <th>Created At</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-chargeLine>
                        <tr>
                            <td>{{chargeLine.chargeLineNumber}}</td>
                            <td>{{chargeLine.deliveryText}}</td>
                            <td>{{chargeLine.chargeType}}</td>
                            <td>{{chargeLine.chargeCurrency}}</td>
                            <td>{{chargeLine.buyingChargeAmount}}</td>
                            <td>{{chargeLine.sellingChargeAmount}}</td>
                            <td>{{showDate(chargeLine.effectiveDate)}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            In total there are {{orderQuery.chargeLines.length}} lines.
                        </div>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-tabPanel>

</p-tabView>

<p-dialog [(visible)]="updateChargesDialogVisible" [header]="updateChargesDialogHeader" [modal]="true"
          [style]="{ width: '60vw' }" [draggable]="false" [resizable]="false">
    <div class="p-fluid card">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-money-check"></i></span>
                        <input pInputText id="name" type="number" [(ngModel)]="updatedCharge" placeholder="Driver Mobile Number" required>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" icon="fa-solid fa-close" (click)="updateChargesDialogVisible = false" class="p-button-secondary"></button>
        <button pButton type="submit" label="Update Charge" icon="fa-solid fa-square-pen" (click)="updateCharges()" [loading]="updateChargesInProgress"></button>
    </ng-template>
</p-dialog>
