import {Component, OnInit} from '@angular/core';
import {CustomerService} from './customer.service';
import {Customer} from './customer.model';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {SessionStorageService} from "../services/session-storage.service";
import {Role} from "./customer-role.model";
import {PaymentService} from "../services/payment.service";
import {PersonalPaymentMethod} from "../models/payments/personal-payment-method.model";

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

    customer!: Customer;
    isLoading = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    roles: Role[] = [];
    personalPaymentMethods: PersonalPaymentMethod[] = [];
    companySubLocality: string = '';
    companyLocality: string = '';

    constructor(
        private customerService: CustomerService,
        private messageService: MessageService,
        private paymentService: PaymentService,
        private sessionStorageService: SessionStorageService,
        private route: ActivatedRoute, private router: Router
    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.breadcrumbItems = [
            {label: 'Customer'}
        ];
        this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};

        let firebaseId = this.route.snapshot.paramMap.get('id');
        if (firebaseId) {
            this.loadCustomer(firebaseId);
        } else {
            firebaseId = this.sessionStorageService.getCustomer()!.firebaseId;
            if (firebaseId) {
                this.loadCustomer(firebaseId);
            }
        }
    }

    loadCustomer(customerId: string) {
        this.customerService.getCustomerById(customerId).subscribe(customerWrapper => {
            console.log(customerWrapper);
            this.customer = customerWrapper.data;
            this.customerService.getRolesByCustomerId(this.customer.id).subscribe(rolesData => {
                console.log(rolesData);
                this.roles = rolesData;
                this.isLoading = false;
            });
            this.paymentService.getAllPersonalPaymentMethods(this.customer.firebaseId).subscribe(personalPaymentMethodsData => {
                console.log(personalPaymentMethodsData);
                this.personalPaymentMethods = personalPaymentMethodsData;
                this.isLoading = false;
            });
        });
    }

    showDate(date: Date | undefined): string {
        if (date) {
            return formatDate(date, 'dd/MM/yyyy', 'en-US');
        }
        return '';
    }

    editRole(role: Role) {
        // Logic to handle role editing
        console.log('Edit role:', role);
    }

    deleteRole(role: Role) {
        // Logic to handle role deletion
        console.log('Delete role:', role);
    }

    createNewPaymentMethod() {
        // Logic to handle creating a new payment method (e.g., open a dialog)
        console.log('Create new payment method');
    }

    editPaymentMethod(paymentMethod: PersonalPaymentMethod) {
        // Logic to handle editing the payment method
        console.log('Edit payment method:', paymentMethod);
    }

    deletePaymentMethod(paymentMethod: PersonalPaymentMethod) {
        // Logic to handle deleting the payment method
        console.log('Delete payment method:', paymentMethod);
    }
}
