<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-tabView>
    <p-tabPanel header="Owned" (change)="tabChange($event)">
        <p-table [value]="ownedVehicles" dataKey="vehicleId" [resizableColumns]="true" styleClass="p-datatable-gridlines"
                 [tableStyle]="{ 'min-width': '80rem' }"
                 [paginator]="true" [rows]="10" [first]="ownedFirst" [showCurrentPageReport]="true" [totalRecords]="totalOwnedRecords"
                 [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadOwnedData($event)"
                 [rowsPerPageOptions]="[10, 20, 50]">
            <ng-template pTemplate="caption">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Owned Vehicles</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Add Vehicle" icon="pi pi-plus" (onClick)="addOwnVehicle()" class="p-2"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Plate Number</th>
                    <th>Type</th>
                    <th>Make</th>
                    <th>Registration</th>
                    <th>Payload</th>
                    <th>Trip</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vehicle>
                <tr>
                    <td>{{ vehicle.plateNumber }}</td>
                    <td>
                        <div class="flex align-items-center justify-content-center" *ngIf="vehicle.type">
                            <img src="{{ vehicle.type.imageUrl}}"
                                 alt="{{ vehicle.type.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                            {{ vehicle.config.name }} {{ vehicle.capacity.name }}
                        </div>
                    </td>
                    <td>
                        <div class="flex align-items-center justify-content-center" *ngIf="vehicle.make">
                            <img src="{{ vehicle.make.imageUrl}}"
                                 alt="{{ vehicle.make.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                            {{ vehicle.make.name }}
                        </div>
                    </td>
                    <td>{{ vehicle.registrationNumber }}<br>{{ vehicle.registrationDate | date:'dd/MM/yyyy' }}</td>
                    <td><span *ngIf="vehicle.payloadTypes">{{ getPayloadTypesString(vehicle) }}</span></td>
                    <td><span *ngIf="vehicle.tripTypes">{{ getTripTypesString(vehicle) }}</span></td>
                    <td>
                        <button pButton type="button" icon="fa-solid fa-maps-location-dot" (click)="trackVehicle(vehicle)"
                                class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                        <button pButton type="button" icon="pi pi-pencil" (click)="editVehicle(vehicle)"
                                class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                        <button pButton type="button" icon="pi pi-trash" (click)="deleteVehicle(vehicle)"
                                class="p-button-rounded p-button-text p-button-danger"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{totalOwnedRecords}} vehicles.
                </div>
            </ng-template>
        </p-table>
    </p-tabPanel>
    <p-tabPanel header="Freelancers">
        <p-table [value]="freelancerVehicles" dataKey="vehicleId" [resizableColumns]="true" styleClass="p-datatable-gridlines"
                 [tableStyle]="{ 'min-width': '80rem' }"
                 [paginator]="true" [rows]="10" [first]="freelancerFirst" [showCurrentPageReport]="true" [totalRecords]="totalFreelancerRecords"
                 [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadFreelancerData($event)"
                 [rowsPerPageOptions]="[10, 20, 50]">
            <ng-template pTemplate="caption">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Freelancer Vehicles</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Add Vehicle" icon="pi pi-plus" (onClick)="addFreelancerVehicle()" class="p-2"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Plate Number</th>
                    <th>Type</th>
                    <th>Make</th>
                    <th>Registration</th>
                    <th>Payload</th>
                    <th>Trip</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vehicle>
                <tr>
                    <td>{{ vehicle.plateNumber }}</td>
                    <td>
                        <div class="flex align-items-center justify-content-center" *ngIf="vehicle.type">
                            <img src="{{ vehicle.type.imageUrl}}"
                                 alt="{{ vehicle.type.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                            {{ vehicle.config.name }} {{ vehicle.capacity.name }}
                        </div>
                    </td>
                    <td>
                        <div class="flex align-items-center justify-content-center" *ngIf="vehicle.make">
                            <img src="{{ vehicle.make.imageUrl}}"
                                 alt="{{ vehicle.make.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                            {{ vehicle.make.name }}
                        </div>
                    </td>
                    <td>{{ vehicle.registrationNumber }}<br>{{ vehicle.registrationDate | date:'dd/MM/yyyy' }}</td>
                    <td><span *ngIf="vehicle.payloadTypes">{{ getPayloadTypesString(vehicle) }}</span></td>
                    <td><span *ngIf="vehicle.tripTypes">{{ getTripTypesString(vehicle) }}</span></td>
                    <td>
                        <button pButton type="button" icon="pi pi-pencil" (click)="editVehicle(vehicle)"
                                class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                        <button pButton type="button" icon="pi pi-trash" (click)="deleteVehicle(vehicle)"
                                class="p-button-rounded p-button-text p-button-danger"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{totalFreelancerRecords}} vehicles.
                </div>
            </ng-template>
        </p-table>
    </p-tabPanel>
</p-tabView>

<p-dialog [(visible)]="showAddFreelancerVehicleDialog" header="Add Freelancer Vehicle" [modal]="true"
          [style]="{ width: '60vw' }" [draggable]="false" [resizable]="false">
    <div class="p-fluid card">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-truck"></i></span>
                        <input pInputText id="name" type="text" [(ngModel)]="vehicleRegistrationNumber" placeholder="Vehicle Registration Number" required>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" icon="fa-solid fa-close" (click)="showAddFreelancerVehicleDialog = false" class="p-button-secondary"></button>
        <button pButton type="submit" label="Search Vehicle" icon="fa-solid fa-search" (click)="searchVehicle()" [loading]="searchVehicleInProgress"></button>
    </ng-template>
</p-dialog>
