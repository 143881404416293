import { Component, OnInit } from '@angular/core';
import { PaymentTransactions } from './payment-transactions.model';
import { PaymentService } from '../../services/payment.service';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { SessionStorageService } from '../../services/session-storage.service';
import {PaymentTransactionsView} from "./payment-transactions-view.model";

@Component({
    selector: 'app-payment-transactions',
    templateUrl: './payment-transactions.component.html',
    styleUrls: ['./payment-transactions.component.css']
})
export class PaymentTransactionsComponent implements OnInit {

    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    paymentTransactions: PaymentTransactionsView[] = [];
    selectedPaymentTransactions: PaymentTransactionsView[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;

    constructor(
        private paymentService: PaymentService,
        private sessionStorageService: SessionStorageService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Payment Transactions' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
        this.loadData();
    }

    loadData() {
        this.isLoading = true;
        this.paymentService.getPaymentTransactionsByCompany(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (data) => {
                this.paymentTransactions = data;
                this.totalRecords = data.length;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading payment transactions'
                });
                this.isLoading = false;
            }
        });
    }

    addPaymentTransactions() {
        //add implementation
    }

    editPaymentTransactions(paymentTransaction: PaymentTransactions) {
        //edit implementation
    }

    deletePaymentTransactions(paymentTransaction: PaymentTransactions) {
        //delete implementation
    }

}
