<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-6 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0">{{ customer?.name }}</p>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-12">
        <p-tabView>
            <p-tabPanel header="Details">
                <ng-template pTemplate="content">
                    <div class="grid">
                        <div class="col-12 lg:col-6">
                            <div class="grid bg-red-100 text-gray-900 border-round-md ml-3 mr-1">
                                <div class="col-12">
                                    <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                                        <i class="fa-solid fa-user"></i>&nbsp;&nbsp;Customer Info:
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="flex">
                                        <div class="flex-none align-items-center align-content-center m-3 h-full">
                                            <p-avatar image="{{ customer?.imageUrl != null ? customer?.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/149/149071.png' }}"
                                                      shape="circle" size="xlarge"></p-avatar><br>
                                            <span></span>
                                        </div>
                                        <div class="flex-grow-1 grid w-full">
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-user"></i>&nbsp;&nbsp;ID:&nbsp;&nbsp;<b>{{
                                                    customer?.id }}</b>
                                            </div>
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                                    customer?.emailAddress }}</b>
                                            </div>
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Phone:&nbsp;&nbsp;<b>{{
                                                    customer?.phoneNumber }}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6">
                            <div class="grid bg-blue-100 text-gray-900 border-round-md ml-1 mr-3">
                                <div class="col-12">
                                    <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                                        <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Company Info:
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="flex">
                                        <div class="flex-none align-items-center align-content-center m-3 h-full">
                                            <p-image [routerLink]="['/company', customer?.company?.id]"
                                                     src="{{ customer?.company?.logo != null ? customer?.company?.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                                     height="50"></p-image><br>
                                            <span></span>
                                        </div>
                                        <div class="flex-grow-1 grid w-full">
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Name:&nbsp;&nbsp;<b>{{
                                                    customer?.company?.name }}</b>
                                            </div>
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                                    customer?.company?.emailAddress }}</b>
                                            </div>
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                                                <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Phone:&nbsp;&nbsp;<b>{{
                                                    customer?.company?.phoneNumber }}</b>
                                            </div>
                                            <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                                                <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                                                href="https://maps.google.com?q={{ customer?.company?.location?.latitude }},{{ customer?.company?.location?.longitude }}">{{companySubLocality}},
                                                {{companyLocality}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Roles">
                <ng-template pTemplate="content">
                    <p-table [value]="roles" dataKey="roleId" [resizableColumns]="true" styleClass="p-datatable-gridlines"
                             [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="caption">
                            <div class="col-12 flex overflow-hidden">
                                <div class="flex-none flex align-items-center justify-content-center">
                                    <h2>Customer Roles</h2>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Role ID</th>
                                <th>Role Name</th>
                                <th>Description</th>
                                <th>Permissions</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-role>
                            <tr>
                                <td>{{ role.roleId }}</td>
                                <td>{{ role.roleName }}</td>
                                <td>{{ role.description }}</td>
                                <td>
                                    <ul>
                                        <li *ngFor="let permission of role.permissions">
                                            {{ permission.permissionName }} - {{ permission.description }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <button pButton type="button" icon="pi pi-pencil" (click)="editRole(role)" class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                                    <button pButton type="button" icon="pi pi-trash" (click)="deleteRole(role)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Payment Methods">
                <ng-template pTemplate="content">
                    <div class="mb-3">
                        <button pButton type="button" label="New Payment Method"
                                icon="pi pi-plus" (click)="createNewPaymentMethod()"></button>
                    </div>

                    <p-table [value]="personalPaymentMethods" dataKey="id" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>ID</th>
                                <th>Payment Type</th>
                                <th>Account Holder Name</th>
                                <th>Default</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-paymentMethod>
                            <tr>
                                <td>{{ paymentMethod.personalPaymentId }}</td>
                                <td>{{ paymentMethod.paymentCardName }}</td>
                                <td>{{ paymentMethod.paymentCardToken }}</td>
                                <td>
                                    <p-checkbox [(ngModel)]="paymentMethod.isDefault" [binary]="true" [disabled]="true"></p-checkbox>
                                </td>
                                <td>
                                    <button pButton type="button" icon="pi pi-pencil"
                                            (click)="editPaymentMethod(paymentMethod)"
                                            class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                                    <button pButton type="button" icon="pi pi-trash"
                                            (click)="deletePaymentMethod(paymentMethod)"
                                            class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
