import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {GeneralLedger} from "./general-ledger/general-ledger.model";
import {environment} from "../../environments/environment";
import {GeneralLedgerSummary} from "./general-ledger/general-ledger-summary.model";
import {PartnerLedger} from "./partner-ledger/partner-ledger.model";
import {PartnerLedgerSummary} from "./partner-ledger/partner-ledger-summary.model";

// Define your interfaces (or models) here based on the Java DTOs

export interface BalanceSheet {
    // Add the properties that match the BalanceSheet Java DTO
    account?: string;
    endingBalance?: number;
}

export interface ProfitAndLoss {
    // Add the properties that match the ProfitAndLoss Java DTO
    account?: string;
    balance?: number;
}

export interface AgedPartnerBalance {
    // Add the properties that match the AgedPartnerBalance Java DTO
    partner?: string;
    current?: number;
    days1_30?: number;
    days31_60?: number;
    days61_90?: number;
    over90?: number;
    total?: number;
}

export interface AgedReceivable {
    // Add the properties that match the AgedReceivable Java DTO
    partner?: string;
    current?: number;
    days1_30?: number;
    days31_60?: number;
    days61_90?: number;
    over90?: number;
    total?: number;
}

export interface AgedPayable {
    // Add the properties that match the AgedPayable Java DTO
    partner?: string;
    current?: number;
    days1_30?: number;
    days31_60?: number;
    days61_90?: number;
    over90?: number;
    total?: number;
}

export interface TrialBalance {
    // Add the properties that match the TrialBalance Java DTO
    account?: string;
    debit?: number;
    credit?: number;
}

export interface TaxReport {
    // Add the properties that match the TaxReport Java DTO
    taxCode?: string;
    taxAmount?: number;
    taxType?: string;
}

export interface CashBook {
    // Add the properties that match the CashBook Java DTO
    date?: Date;
    label?: string;
    income?: number;
    outcome?: number;
    balance?: number;
}

export interface DayBook {
    // Add the properties that match the DayBook Java DTO
    date?: Date;
    label?: string;
    amount?: number;
}

@Injectable({
    providedIn: 'root'
})
export class AccountingReportsService {
    private baseUrl = `${environment.restApiUrl}accounting/reports`; // Your base URL for the AccountingReportsEndpoint

    constructor(private http: HttpClient) { }

    /**
     * Retrieves the general ledger for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of GeneralLedger objects.
     */
    getGeneralLedger(owningCompanyId: number): Observable<GeneralLedger[]> {
        const url = `${this.baseUrl}/general-ledger/${owningCompanyId}`;
        return this.http.get<GeneralLedger[]>(url);
    }

    /**
     * Retrieves the partner ledger for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of PartnerLedger objects.
     */
    getPartnerLedger(owningCompanyId: number): Observable<PartnerLedger[]> {
        const url = `${this.baseUrl}/partner-ledger/${owningCompanyId}`;
        return this.http.get<PartnerLedger[]>(url);
    }

    /**
     * Retrieves the general ledger summary for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of GeneralLedgerSummary objects.
     */
    getGeneralLedgerSummary(owningCompanyId: number): Observable<GeneralLedgerSummary[]> {
        const url = `${this.baseUrl}/general-ledger-summary/${owningCompanyId}`;
        return this.http.get<GeneralLedgerSummary[]>(url);
    }

    /**
     * Retrieves the partner ledger summary for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of PartnerLedgerSummary objects.
     */
    getPartnerLedgerSummary(owningCompanyId: number): Observable<PartnerLedgerSummary[]> {
        const url = `${this.baseUrl}/partner-ledger-summary/${owningCompanyId}`;
        return this.http.get<PartnerLedgerSummary[]>(url);
    }

    /**
     * Retrieves the balance sheet for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of BalanceSheet objects.
     */
    getBalanceSheet(owningCompanyId: number): Observable<BalanceSheet[]> {
        const url = `${this.baseUrl}/balance-sheet/${owningCompanyId}`;
        return this.http.get<BalanceSheet[]>(url);
    }

    /**
     * Retrieves the profit and loss statement for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of ProfitAndLoss objects.
     */
    getProfitAndLoss(owningCompanyId: number): Observable<ProfitAndLoss[]> {
        const url = `${this.baseUrl}/profit-and-loss/${owningCompanyId}`;
        return this.http.get<ProfitAndLoss[]>(url);
    }

    /**
     * Retrieves the aged partner balance for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of AgedPartnerBalance objects.
     */
    getAgedPartnerBalance(owningCompanyId: number): Observable<AgedPartnerBalance[]> {
        const url = `${this.baseUrl}/aged-partner-balance/${owningCompanyId}`;
        return this.http.get<AgedPartnerBalance[]>(url);
    }

    /**
     * Retrieves the aged receivable report for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of AgedReceivable objects.
     */
    getAgedReceivable(owningCompanyId: number): Observable<AgedReceivable[]> {
        const url = `${this.baseUrl}/aged-receivable/${owningCompanyId}`;
        return this.http.get<AgedReceivable[]>(url);
    }

    /**
     * Retrieves the aged payable report for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of AgedPayable objects.
     */
    getAgedPayable(owningCompanyId: number): Observable<AgedPayable[]> {
        const url = `${this.baseUrl}/aged-payable/${owningCompanyId}`;
        return this.http.get<AgedPayable[]>(url);
    }

    /**
     * Retrieves the trial balance for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of TrialBalance objects.
     */
    getTrialBalance(owningCompanyId: number): Observable<TrialBalance[]> {
        const url = `${this.baseUrl}/trial-balance/${owningCompanyId}`;
        return this.http.get<TrialBalance[]>(url);
    }

    /**
     * Retrieves the tax report for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of TaxReport objects.
     */
    getTaxReport(owningCompanyId: number): Observable<TaxReport[]> {
        const url = `${this.baseUrl}/tax-report/${owningCompanyId}`;
        return this.http.get<TaxReport[]>(url);
    }

    /**
     * Retrieves the cash book for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of CashBook objects.
     */
    getCashBook(owningCompanyId: number): Observable<CashBook[]> {
        const url = `${this.baseUrl}/cash-book/${owningCompanyId}`;
        return this.http.get<CashBook[]>(url);
    }

    /**
     * Retrieves the day book for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of DayBook objects.
     */
    getDayBook(owningCompanyId: number): Observable<DayBook[]> {
        const url = `${this.baseUrl}/day-book/${owningCompanyId}`;
        return this.http.get<DayBook[]>(url);
    }
}
