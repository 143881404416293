<p-toast></p-toast>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createEditRevisionForm" novalidate autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="fa-solid fa-file-contract mr-2" style="font-size: 1.5rem"></i> <!— Contract Revision icon -->
                <div class="font-medium text-xl text-900">Create/Update Contract Revision# {{ createEditRevision.revisionNumber }}</div>
            </div><br>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span> <!— Calendar icon -->
                        <p-calendar [(ngModel)]="createEditRevision.effectiveStartDate" placeholder="Effective Start Date"  [style]="{'width': '100%'}"
                                    formControlName="effectiveStartDateInput" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span> <!— Calendar icon -->
                        <p-calendar [(ngModel)]="createEditRevision.endDate" placeholder="End Date"  [style]="{'width': '100%'}"
                                    formControlName="endDateInput" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-dollar"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.totalAmount"
                               placeholder="Total Amount" formControlName="totalAmountInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-percent"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.penaltyRate"
                               placeholder="Penalty Rate" formControlName="penaltyRateInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col overflow-hidden p-4">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <i class="fa-solid fa-handshake" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="firstPartyAgreedInput" style="font-size: 1.2rem">First Party Agreed?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-inputSwitch [(ngModel)]="createEditRevision.firstPartyAgreed" id="firstPartyAgreedInput"
                                       formControlName="firstPartyAgreedInput"></p-inputSwitch>
                    </div>
                </div>
                <div class="flex col overflow-hidden p-4">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <i class="fa-solid fa-handshake" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="secondPartyAgreedInput" style="font-size: 1.2rem">Second Party Agreed?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-inputSwitch [(ngModel)]="createEditRevision.secondPartyAgreed"
                                       id="secondPartyAgreedInput"
                                       formControlName="secondPartyAgreedInput">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-calendar-plus"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.agreementPeriod"
                               placeholder="Agreement Period" formControlName="agreementPeriodInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-calendar-times"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.nonRenewalDisclosureBeforeDays"
                               placeholder="Non-Renewal Disclosure Days" formControlName="nonRenewalDisclosureBeforeDaysInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col overflow-hidden p-4">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <i class="fa-solid fa-shield-check" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="goodsInsuranceByFirstPartyInput" style="font-size: 1.2rem">Goods Insurance By First Party?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-inputSwitch [(ngModel)]="createEditRevision.goodsInsuranceByFirstParty"
                                       id="goodsInsuranceByFirstPartyInput"
                                       formControlName="goodsInsuranceByFirstPartyInput">
                        </p-inputSwitch>
                    </div>
                </div>
                <div class="flex col overflow-hidden p-4">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <i class="fa-solid fa-shield-check" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="goodsInsuranceBySecondPartyInput" style="font-size: 1.2rem">Goods Insurance By Second Party?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-inputSwitch [(ngModel)]="createEditRevision.goodsInsuranceBySecondParty"
                                       id="goodsInsuranceBySecondPartyInput"
                                       formControlName="goodsInsuranceBySecondPartyInput">
                        </p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col overflow-hidden p-4">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <i class="fa-solid fa-file-invoice-dollar" style="font-size: 1.5rem; color: gray"></i>&nbsp;&nbsp;
                        <label for="vatAddedToInvoiceInput" style="font-size: 1.2rem">VAT Added To Invoice?</label>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-inputSwitch [(ngModel)]="createEditRevision.vatAddedToInvoice"
                                       id="vatAddedToInvoiceInput"
                                       formControlName="vatAddedToInvoiceInput">
                        </p-inputSwitch>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-money-bill"></i></span>
                        <input type="text" pInputText [(ngModel)]="createEditRevision.paymentTerms"
                               placeholder="Payment Terms" formControlName="paymentTermsInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-building"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.firstPartyBankAccountId"
                               placeholder="First Party Bank Account ID" formControlName="firstPartyBankAccountIdInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-credit-card"></i></span>
                        <input type="text" pInputText [(ngModel)]="createEditRevision.firstPartyBankAccountDetails"
                               placeholder="First Party Bank Account Details" formControlName="firstPartyBankAccountDetailsInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-clock"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.siteServiceTime"
                               placeholder="Site Service Time" formControlName="siteServiceTimeInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-exclamation-triangle"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.penaltyOnServiceTime"
                               placeholder="Penalty On Service Time" formControlName="penaltyOnServiceTimeInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-bell"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.noticePeriod"
                               placeholder="Notice Period" formControlName="noticePeriodInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-bolt"></i></span>
                        <input type="number" pInputText [(ngModel)]="createEditRevision.forceMajeureClausePeriod"
                               placeholder="Force Majeure Clause Period" formControlName="forceMajeureClausePeriodInput" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-gavel"></i></span>
                        <input type="text" pInputText [(ngModel)]="createEditRevision.disputeResolution"
                               placeholder="Dispute Resolution" formControlName="disputeResolutionInput" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-book"></i></span>
                        <input type="text" pInputText [(ngModel)]="createEditRevision.governingLaw"
                               placeholder="Governing Law" formControlName="governingLawInput" />
                    </div>
                </div>
            </div>

            <p-panel header="Contract Documents section">
                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="pi pi-file"></i></span> <!— File icon -->
                            <p-fileUpload name="originalAgreementFile" url="your-upload-url" accept=".pdf,.doc,.docx" mode="basic"
                                          (onUpload)="onOriginalAgreementFileUpload($event)" chooseLabel="Upload Original Agreement"></p-fileUpload>
                        </div>
                    </div>
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="pi pi-file-edit"></i></span> <!— File icon -->
                            <p-fileUpload name="signedAgreementFile" url="your-upload-url" accept=".pdf,.doc,.docx" mode="basic"
                                          (onUpload)="onSignedAgreementFileUpload($event)" chooseLabel="Upload Signed Agreement"></p-fileUpload>
                        </div>
                    </div>
                </div>
            </p-panel>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col w-full">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-pencil"></i></span>
                        <textarea pInputTextarea [(ngModel)]="createEditRevision.revisionNotes"
                                  placeholder="Revision Notes" formControlName="revisionNotesInput"
                                  [rows]="5" [cols]="30"></textarea>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="{{ createEditRevision.revisionID ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
                              [loading]="revisionCreationInProgress" class="m-1 px-1 py-1" (onClick)="createUpdateRevision()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                              class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>
