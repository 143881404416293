import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { SelectItem } from "primeng/api";
import { Router } from "@angular/router";
import { SessionStorageService } from "../../services/session-storage.service";
import {Products} from "../models/product.model";
import {ProductsService} from "../product.service";

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    products: Products[] = [];
    isLoading: boolean = false;
    totalRecords = 0;
    salespersonList: SelectItem<any>[] | undefined; // You might not need this for products

    constructor(
        private productsService: ProductsService,
        private messageService: MessageService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Product' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadData();
    }

    loadData(event?: any) {
        this.isLoading = true;
        this.productsService.getAllProductsByOwningCompany(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (data) => {
                this.products = data;
                this.totalRecords = data.length;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading products'
                });
                this.isLoading = false;
            }
        });
    }

    addProduct() {
        this.router.navigate(['/order/products/add']);
    }

    editProduct(product: Products) {
        this.router.navigate(['/order/products/edit', product.productID]);
    }

    // You might have other actions like "delete", etc.

    showDate(date: Date | undefined): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString();
    }
}
