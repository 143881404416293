<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="locations" dataKey="invoiceId" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
             [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="totalRequests" [lazy]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadData($event)"
             [rowsPerPageOptions]="[20, 50, 100]">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Locations</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Location" icon="pi pi-plus" (onClick)="addLocation()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Address</th>
            <th>Company</th>
            <th>Type</th>
            <th>Contact</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-location>
        <tr>
            <td>{{ location.externalLocationReference }}</td>
            <td><a href="https://maps.google.com/?q={{ location.location.latitude }},{{ location.location.longitude }}" target="_blank">{{ location.name }}</a></td>
            <td>{{ getLocationAddress(location) }}</td>
            <td>{{ location.company.name }}</td>
            <td>{{ location.locationType }}</td>
            <td>{{ location.defaultContact.name }}</td>
            <td>
                <button pButton type="button" icon="pi pi-eye" (click)="viewLocation(location)"
                        class="p-button-rounded p-button-text p-button-info mr-2"></button>
                <button pButton type="button" icon="pi pi-pencil" (click)="editLocation(location)"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deleteLocation(location)"
                        class="p-button-rounded p-button-text p-button-danger mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRequests}} locations.
        </div>
    </ng-template>
</p-table>
