import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from 'src/app/customer/customer.model';


@Injectable({
    providedIn: 'root'
})
export class ToolbarService {

    private isLoggedInBehaviour: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoggedInObservable: Observable<boolean> = this.isLoggedInBehaviour.asObservable();

    private customerBehaviour: BehaviorSubject<Customer | null> = new BehaviorSubject<Customer | null>(null);
    public customerObservable: Observable<Customer | null> = this.customerBehaviour.asObservable();

    updateLoggedInStatus(loggedInStatus: boolean) {
        this.isLoggedInBehaviour.next(loggedInStatus);
    }

    updateCustomer(customer: Customer | null) {
        this.customerBehaviour.next(customer);
    }

}
