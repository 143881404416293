import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GoogleMapsModule, MapDirectionsRenderer} from "@angular/google-maps";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {DialogService} from "primeng/dynamicdialog";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";
import {AvatarModule} from "primeng/avatar";
import {TagModule} from "primeng/tag";
import {TimelineModule} from "primeng/timeline";
import {AccordionModule} from "primeng/accordion";
import {GalleriaModule} from "primeng/galleria";

import { shipmentRoutes } from "./shipment.routes";
import { CreateShipmentComponent } from "./create-shipment/create-shipment.component";
import { ViewShipmentComponent } from "./view-shipment/view-shipment.component";
import { ShipmentComponent } from "./shipment.component";
import { DeliveryComponent } from "./delivery/delivery.component";
import { ShipmentCompanyComponent } from "./company/shipment-company.component";
import {GoogleMapsLibrariesLoaderService} from "../services/google/google-libraries-loader.service";
import {GoogleMapsService} from "../services/google/google-maps-loader.service";
import {ShipmentQueryService} from "./shipment-query.service";
import {ShipmentJobService} from "./shipment-job.service";
import {ShipmentDeliveryService} from "./shipment-delivery.service";
import {GoogleCloudStorageService} from "../services/google/google-cloud-storage.service";
import {InputNumberModule} from "primeng/inputnumber";
import {CalendarModule} from "primeng/calendar";
import {MessageService} from "primeng/api";
import { GenerateDeliveriesComponent } from './delivery/generate-deliveries/generate-deliveries.component';
import {PickListModule} from "primeng/picklist";
import {CompanyModule} from "../company/company.module";
import {CustomerModule} from "../customer/customer.module";
import {TabViewModule} from "primeng/tabview";
import {FieldsetModule} from "primeng/fieldset";
import {BadgeModule} from "primeng/badge";
import {SplitButtonModule} from "primeng/splitbutton";



@NgModule({
    imports: [
        RouterModule.forChild(shipmentRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,

        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        AvatarModule,
        TagModule,
        ButtonModule,
        TableModule,
        DropdownModule,
        AutoCompleteModule,
        TimelineModule,
        GoogleMapsModule,
        AccordionModule,
        GalleriaModule,
        InputTextModule,
        InputNumberModule,
        CalendarModule,
        PickListModule,
        CompanyModule,
        CustomerModule,
        TabViewModule,
        FieldsetModule,
        BadgeModule,
        SplitButtonModule,
    ],
    providers: [
        MessageService,
        DialogService,
        ShipmentQueryService,
        ShipmentJobService,
        ShipmentDeliveryService,
        GoogleCloudStorageService,
        GoogleMapsService,
        MapDirectionsRenderer,
        GoogleMapsLibrariesLoaderService,
    ],
    declarations: [
        CreateShipmentComponent,
        ViewShipmentComponent,
        ShipmentComponent,
        DeliveryComponent,
        ShipmentCompanyComponent,
        GenerateDeliveriesComponent
    ],
})
export class ShipmentModule { }
