import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIListWrapper } from "../../models/common/api-list-wrapper.model";
import { JournalEntry } from "./models/journal-entry-header.model";
import { environment } from "../../../environments/environment";
import {Journal} from "./models/journal.model";
import {JournalType} from "./models/journal-type.model";

@Injectable({
    providedIn: 'root'
})
export class JournalEntriesService {

    private baseUrl = `${environment.restApiUrl}`; // Update with your base API URL

    constructor(private http: HttpClient) { }

    /**
     * Get all journal entries for a specific company with pagination.
     * @param owningCompanyId The ID of the owning company.
     * @param page The page number (starts at 0).
     * @param pageSize The page size.
     * @returns An Observable of APIListWrapper<JournalEntry> containing the paginated data.
     */
    getAllJournalEntriesByCompany(owningCompanyId: number, page: number = 0, pageSize: number = 10): Observable<APIListWrapper<JournalEntry>> {
        const url = `${this.baseUrl}journal-entries/${owningCompanyId}`;
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        return this.http.get<APIListWrapper<JournalEntry>>(url, { params });
    }

    /**
     * Get a journal entry by ID for a specific company.
     * @param id The ID of the journal entry.
     * @returns An Observable of a JournalEntry object.
     */
    getJournalEntryById(id: number): Observable<JournalEntry> {
        const url = `${this.baseUrl}journal-entries/id/${id}`;
        return this.http.get<JournalEntry>(url);
    }

    /**
     * Create a new journal entry for a specific company.
     * @param journalEntry The JournalEntry object to create.
     * @returns An Observable of the created JournalEntry object.
     */
    createJournalEntry(journalEntry: JournalEntry): Observable<JournalEntry> {
        const url = `${this.baseUrl}journal-entries/${journalEntry.owningCompanyId}`;
        return this.http.post<JournalEntry>(url, journalEntry);
    }

    /**
     * Update an existing journal entry for a specific company.
     * @param journalEntry The updated JournalEntry object.
     * @returns An Observable of the updated JournalEntry object.
     */
    updateJournalEntry(journalEntry: JournalEntry): Observable<JournalEntry> {
        const url = `${this.baseUrl}journal-entries/${journalEntry.owningCompanyId}/${journalEntry.entryId}`;
        return this.http.put<JournalEntry>(url, journalEntry);
    }

    /**
     * Delete a journal entry for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the journal entry to delete.
     * @returns An Observable of void (or you can customize the response).
     */
    deleteJournalEntry(owningCompanyId: number, id: number): Observable<void> {
        const url = `${this.baseUrl}journal-entries/${owningCompanyId}/${id}`;
        return this.http.delete<void>(url);
    }

    /**
     * Get all journals for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @returns An Observable of a list of Journal objects.
     */
    getAllJournalsByCompany(owningCompanyId: number): Observable<Journal[]> {
        const url = `${this.baseUrl}journals/${owningCompanyId}`;
        return this.http.get<Journal[]>(url);
    }

    /**
     * Get a journal by ID for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the journal.
     * @returns An Observable of a Journal object.
     */
    getJournalByIdAndCompany(owningCompanyId: number, id: number): Observable<Journal> {
        const url = `${this.baseUrl}journals/${owningCompanyId}/${id}`;
        return this.http.get<Journal>(url);
    }

    /**
     * Create a new journal for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param journal The Journal object to create.
     * @returns An Observable of the created Journal object.
     */
    createJournal(owningCompanyId: number, journal: Journal): Observable<Journal> {
        const url = `${this.baseUrl}journals/${owningCompanyId}`;
        return this.http.post<Journal>(url, journal);
    }

    /**
     * Update an existing journal for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the journal to update.
     * @param journal The updated Journal object.
     * @returns An Observable of the updated Journal object.
     */
    updateJournal(owningCompanyId: number, id: number, journal: Journal): Observable<Journal> {
        const url = `${this.baseUrl}journals/${owningCompanyId}/${id}`;
        return this.http.put<Journal>(url, journal);
    }

    /**
     * Delete a journal for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the journal to delete.
     * @returns An Observable of void (or you can customize the response).
     */
    deleteJournal(owningCompanyId: number, id: number): Observable<void> {
        const url = `${this.baseUrl}journals/${owningCompanyId}/${id}`;
        return this.http.delete<void>(url);
    }

    /**
     * Get all journal types.
     * @returns An Observable of a list of JournalType objects.
     */
    getAllJournalTypes(): Observable<JournalType[]> {
        const url = `${this.baseUrl}journals/types`;
        return this.http.get<JournalType[]>(url);
    }

    /**
     * Get a journal type by ID.
     * @param id The ID of the journal type.
     * @returns An Observable of a JournalType object.
     */
    getJournalTypeById(id: number): Observable<JournalType> {
        const url = `${this.baseUrl}journals/type/${id}`;
        return this.http.get<JournalType>(url);
    }

}
