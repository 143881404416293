<p-toast [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="journalEntryForm" (ngSubmit)="saveJournalEntry()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Journal Entry' : 'Add Journal Entry' }}
            </div>

            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="entryNumber">Entry Number</label>
                    <input type="text" pInputText formControlName="entryNumber" id="entryNumber" />
                    <small class="p-error" *ngIf="journalEntryForm.get('entryNumber')?.hasError('required') && journalEntryForm.get('entryNumber')?.touched">Journal is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="reference">Reference</label>
                    <input type="text" pInputText formControlName="reference" id="reference" />
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="field col-12 md:col-6">
                    <label for="journal">Journal</label>
                    <p-dropdown id="journal" [options]="journalList" formControlName="journal" placeholder="Select a Journal"
                                optionLabel="journalName" [required]="true"></p-dropdown>
                    <small class="p-error" *ngIf="journalEntryForm.get('journal')?.hasError('required') && journalEntryForm.get('journal')?.touched">Journal is required.</small>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="entryDate">Entry Date</label>
                    <p-calendar id="entryDate" inputId="entryDate" formControlName="entryDate" dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error" *ngIf="journalEntryForm.get('entryDate')?.hasError('required') && journalEntryForm.get('entryDate')?.touched">Entry Date is required.</small>
                </div>
            </div>

            <p-divider />

            <div formArrayName="journalItems" class="mt-6">
                <div class="text-xl font-semibold mb-4">
                    {{ editMode ? 'Edit Journal Entry Lines' : 'Add Journal Entry Lines' }}
                </div>
                <div class="mt-2" *ngFor="let detailGroup of getJournalEntryDetailsControls; let i = index">
                    <div [formGroupName]="i" class="grid p-fluid">
                        <!-- add-edit-journal-entry.component.html -->
                        <div class="col-12 md:col-3">
                            <label for="accountCode{{ i }}">Account</label>
                            <p-autoComplete
                                [suggestions]="filteredChartOfAccounts"
                                (completeMethod)="filterChartOfAccounts($event)"
                                [dropdown]="true"
                                formControlName="accountCode"
                                id="accountCode{{ i }}"
                                placeholder="Search Account"
                                (onSelect)="onAccountSelect($event, i)">
                                <ng-template let-account pTemplate="item">
                                    <div>{{ account.accountCode }} {{ account.accountName }}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small class="p-error" *ngIf="detailGroup.get('accountCode')?.hasError('required') && detailGroup.get('accountCode')?.touched">Account Code is required.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="partnerType{{ i }}">Partner Type</label>
                            <p-dropdown id="partnerType{{ i }}" [options]="partnerTypes" formControlName="partnerType" [required]="true"></p-dropdown>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="partnerName{{ i }}">Journal</label>
                            <p-dropdown id="partnerName{{ i }}" [options]="partners" formControlName="partnerName" placeholder="Select a Partner"
                                        optionLabel="partnerName" (onChange)="onPartnerSelected($event, i)" []></p-dropdown>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="label{{ i }}">Label</label>
                            <input type="text" pInputText formControlName="label" id="label{{ i }}" />
                        </div>
                        <div class="col-6 md:col-2">
                            <label for="debitAmount{{ i }}">Debit</label>
                            <p-inputNumber inputId="debitAmount{{ i }}" formControlName="debitAmount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0"  locale="en-US">
                            </p-inputNumber>
                            <small class="p-error" *ngIf="detailGroup.get('debitAmount')?.hasError('min') && detailGroup.get('debitAmount')?.touched">Debit must be non-negative.</small>
                        </div>
                        <div class="col-6 md:col-2">
                            <label for="creditAmount{{ i }}">Credit</label>
                            <p-inputNumber inputId="creditAmount{{ i }}" formControlName="creditAmount" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0" locale="en-US">
                            </p-inputNumber>
                            <small class="p-error" *ngIf="detailGroup.get('creditAmount')?.hasError('min') && detailGroup.get('creditAmount')?.touched">Credit must be non-negative.</small>
                        </div>
                        <div class="col-12 md:col-1 flex align-items-center justify-content-center">
                            <button pButton type="button" icon="pi pi-trash" (click)="removeJournalEntryDetail(i)"
                                    class="p-button-rounded p-button-danger p-button-sm" *ngIf="i > 0"></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <button pButton type="button" label="Add Detail" icon="pi pi-plus" (click)="addJournalEntryDetail()"></button>
            </div>

            <div class="mt-4 flex justify-content-end">  <!-- Buttons -->
                <button pButton type="submit" label="Save" [disabled]="journalEntryForm.invalid" class="mr-2"></button>
                <button pButton type="button" label="Cancel" class="p-button-secondary" (click)="cancel()"></button>
            </div>


        </div>
    </form>
</div>
