<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<p-accordion *ngIf="generatedVouchersSummaryList.length > 0">
    <p-accordionTab *ngFor="let generatedVouchersSummary of generatedVouchersSummaryList">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image *ngIf="generatedVouchersSummary.clientLogo && generatedVouchersSummary.clientId === 0"
                                 src="{{ generatedVouchersSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!generatedVouchersSummary.clientLogo && generatedVouchersSummary.clientId === 0"
                                 src="assets/driver.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="generatedVouchersSummary.clientLogo && generatedVouchersSummary.clientId != 0"
                                 src="{{ generatedVouchersSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!generatedVouchersSummary.clientLogo && generatedVouchersSummary.clientId != 0"
                                 src="assets/transportation.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <h4>{{ generatedVouchersSummary.clientName }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ generatedVouchersSummary.totalUnAppliedAmount }}</p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Pending</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ generatedVouchersSummary.totalAppliedAmount }}</p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Vouchered</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="generatedVouchersSummary.vouchers" dataKey="voucherId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="rows" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="generatedVouchersSummary.totalVouchers" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadVouchers($event, generatedVouchersSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="generatedVouchersSummary.selectedVouchers">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!generatedVouchersSummary.vouchers"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Generated Vouchers</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Create Voucher" icon="fa-solid fa-wallet" (onClick)="showVoucherAmountDialog(generatedVouchersSummary)"
                                  class="p-2" severity="info"></p-button>
                        <p-button label="Make Payment" icon="fa-solid fa-trowel-bricks" (onClick)="generatePaymentFromVouchers(generatedVouchersSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(generatedVouchersSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>ID</th>
                    <th>Voucher#</th>
                    <th>Voucher Date</th>
                    <th>Amount</th>
                    <th>Payment Transaction#</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-voucher let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="voucher"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="voucher"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ voucher.voucherId }}</td>
                    <td>{{ voucher.odooVoucherNumber }}</td>
                    <td>{{ voucher.odooVoucherDate | date:'yyyy-MM-dd' }}</td>
                    <td>{{ voucher.country.currencyCode + ' ' + voucher.amount }}</td>
                    <td>{{ voucher.paymentTransactionNumber }}</td>
                    <td>{{ voucher.personalPaymentMethod?.paymentCardName }}</td>
                    <td>
                        <p-tag *ngIf="voucher.paymentTransactionNumber" icon="fa-solid fa-check" severity="success" value="CLOSED"></p-tag>
                        <p-tag *ngIf="!voucher.paymentTransactionNumber" icon="fa-solid fa-close" severity="danger" value="PENDING"></p-tag>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-voucher>
                <tr>
                    <td colspan="11">
                        <div class="p-3">
                            <p-table [value]="voucher.bills" dataKey="voucherLineId" [resizableColumns]="true"
                                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-10">
                                            <h2>Generated Voucher Lines</h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S#</th>
                                        <th>Date</th>
                                        <th>Bill#</th>
                                        <th>Charge Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-bill>
                                    <tr>
                                        <td>{{ bill.billId }}</td>
                                        <td>{{ bill.billCreationDate | date:'yyyy-MM-dd' }}</td>
                                        <td>{{ bill.odooBillNumber }}</td>
                                        <td>{{ voucher.country.currencyCode + ' ' + bill.totalAmount }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{generatedVouchersSummary.totalVouchers}} vouchers.
                </div>
            </ng-template>
        </p-table>
    </p-accordionTab>
    <app-voucher-amount-dialog></app-voucher-amount-dialog>
</p-accordion>
