import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { GeoAddress } from '../models/common/geo-address.model';
import { Location } from './location.model';
import { SessionStorageService } from '../services/session-storage.service';
import { LocationService } from './location.service';
import { MenuItem } from 'primeng/api';
import {TableLazyLoadEvent} from "primeng/table";

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

    locations: Location[];
    first = 0;
    rows = 20;
    totalRequests = 0;
    isLoading = true;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private database: AngularFireDatabase,
                private sessionStorageService: SessionStorageService,
                private locationService: LocationService) {
        this.locations = [];
    }

    addLocation() {
        this.router.navigate(['/location/create']);
    }

    viewLocation(location: Location) {
        throw new Error('Method not implemented.');
    }

    editLocation(location: Location) {
        this.router.navigate(['/location/edit', location.id]);
    }

    deleteLocation(location: Location) {
        throw new Error('Method not implemented.');
    }


    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Locations', routerLink: '/location' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    loadData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.first = event.first ?? 0;
        this.rows = event.rows ?? 20;
        let page = event.first ?? 0;
        page = page / 20;
        let pageSize = event.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.locationService.getLocationsByCompanyId(page, pageSize, this.sessionStorageService.getCompany()?.id ?? 0).subscribe(locationsListWrapper => {
            console.log(locationsListWrapper);
            this.locations = locationsListWrapper.items;
            this.totalRequests = locationsListWrapper.count;
            this.isLoading = false;
        });
    }

    getLocationAddress(location: Location) {
        let geoAddress: GeoAddress = new GeoAddress().getGeoAddressFromObject(location.address);
        return geoAddress.getSubLocality() + ', ' + geoAddress.getLocality();
    }

}
