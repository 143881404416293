import {Component, OnInit} from '@angular/core';
import {GeneralLedgerSummary} from "./general-ledger-summary.model";
import {GeneralLedger} from "./general-ledger.model";
import {AccountingReportsService} from "../accounting-reports.service";
import {MessageService, TreeNode} from "primeng/api";
import {MenuItem} from "primeng/api";
import {SessionStorageService} from "../../services/session-storage.service";
import {ChartOfAccountsService} from "../../chart-of-accounts/chart-of-accounts.service";
import {ChartOfAccounts} from "../../chart-of-accounts/chart-of-accounts.model";
import {Tree, TreeNodeSelectEvent, TreeNodeUnSelectEvent} from "primeng/tree";

@Component({
    selector: 'app-general-ledger',
    templateUrl: './general-ledger.component.html',
    styleUrls: ['./general-ledger.component.css']
})
export class GeneralLedgerComponent implements OnInit {

    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    chartOfAccountsTree: TreeNode<ChartOfAccounts>[] = [];
    selectedChartOfAccounts: TreeNode<ChartOfAccounts>[]  = [];
    fullGeneralLedgerSummaries: GeneralLedgerSummary[] = [];
    selectedGeneralLedgerSummaries: GeneralLedgerSummary[] = [];
    fullGeneralLedgers: GeneralLedger[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 10;

    constructor(private accountingReportsService: AccountingReportsService,
        private sessionStorageService: SessionStorageService,
        private chartOfAccountsService: ChartOfAccountsService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Enterprise', routerLink: '/accounts/enterprise' },
            { label: 'General Ledger' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadGeneralLedgerSummary();

        this.chartOfAccountsService.getAllChartOfAccountsByCompany(this.sessionStorageService.getCompany()?.id ?? 0, true).subscribe(accounts => {
            console.log(accounts);
            accounts.forEach(account => this.chartOfAccountsTree.push(this.buildTreeNode(account)));
        });
    }

    buildTreeNode(chartOfAccounts: ChartOfAccounts): TreeNode<ChartOfAccounts> {
        return {
            label: chartOfAccounts.accountName,
            data: chartOfAccounts,
            key: chartOfAccounts.accountId.toString(),
            expandedIcon: 'pi pi-folder-open',
            collapsedIcon: 'pi pi-folder',
            leaf: chartOfAccounts.childrenAccounts.length === 0,
            expanded: false,
            children: chartOfAccounts.childrenAccounts.map(child => this.buildTreeNode(child))
        };
    }

    loadGeneralLedgerSummary() {
        this.isLoading = true;
        this.accountingReportsService.getGeneralLedgerSummary(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (data) => {
                this.fullGeneralLedgerSummaries = data;
                this.selectedGeneralLedgerSummaries = data;
                this.loadFullData();
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading General Ledger Summary'
                });
                this.isLoading = false;
            }
        });
    }

    loadFullData() {
        this.accountingReportsService.getGeneralLedger(this.sessionStorageService.getCompany()?.id ?? 0).subscribe(data => {
            this.fullGeneralLedgers = data;
            this.fullGeneralLedgerSummaries.forEach(generalLedgerSummary => {
                generalLedgerSummary.generalLedgers = this.fullGeneralLedgers
                    .filter(generalLedger => generalLedger.accountId == generalLedgerSummary.accountId);
            });
            this.isLoading = false;
        }, error => {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error loading General Ledger Details'
            });
            this.isLoading = false;
        });
    }

    updateSelectEvents(selectEvent: TreeNodeSelectEvent) {
        this.selectedChartOfAccounts.push(selectEvent.node);
        this.filterChartOfAccounts();
    }

    updateUnSelectEvents(unselectEvent: TreeNodeUnSelectEvent) {
        this.selectedChartOfAccounts = this.selectedChartOfAccounts.filter(node =>
            node.data?.accountId !== unselectEvent.node.data?.accountId
        );
        this.filterChartOfAccounts();
    }

    filterChartOfAccounts() {
        if (this.selectedChartOfAccounts.length === 0) {
            this.selectedGeneralLedgerSummaries = [...this.fullGeneralLedgerSummaries]; // Show all if nothing selected
        } else {
            this.selectedGeneralLedgerSummaries = this.fullGeneralLedgerSummaries.filter(summary =>
                this.selectedChartOfAccounts.some(node => this.checkRecursive(node, summary.accountId))
            );
        }
    }

    checkRecursive(node: TreeNode<ChartOfAccounts>, toCheckAccountId: number): boolean | undefined {
        return node.data?.accountId === toCheckAccountId
            || node.children?.some(child => this.checkRecursive(child, toCheckAccountId));
    }
}
