import {ProductType} from "./product-type.model";
import {ProductVariant} from "./product-variant.model";

export class Products {

    productID: number;
    name: string;
    description: string;
    standardRate: number;
    productCode: string;
    productType: ProductType;
    owningCompanyId: number;
    taxRate: number;
    currencyCode: string;
    productVariants: ProductVariant[];

    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    createdBy: number;
    updatedBy: number;
    deletedBy: number;

}
