<p-dialog [(visible)]="displayModal" [header]="dialogHeader" [modal]="true" [style]="{width: '50vw'}"
          [draggable]="false" [resizable]="false">
    <div class="p-fluid">
        <p-table [value]="availableRoles" [(selection)]="selectedCustomerRoles" dataKey="roleId"
                 [tableStyle]="{ 'min-width': '30rem' }">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox #headerCheckbox
                            (click)="toggleAllRoles($event, headerCheckbox.checked)"></p-tableHeaderCheckbox>
                    </th>
                    <th>Role Name</th>
                    <th>Permissions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="role" [ngModel]="isRoleSelected(role)" (change)="onRoleChange($event, role)"></p-tableCheckbox>
                    </td>
                    <td>{{ role.roleName }}</td>
                    <td>
                        <ul>
                            <li *ngFor="let permission of role.permissions">{{ permission.permissionName }}</li>
                        </ul>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="onCancel()" class="p-button-secondary"></button>
        <button pButton type="button" label="Save" (click)="onSave()" [disabled]="!selectedCustomerRoles.length"></button>
    </ng-template>
</p-dialog>
