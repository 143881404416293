<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form class="overFlow" [formGroup]="createEditVehicleForm" novalidate autocomplete="off" autocorrect="off"
          autocapitalize="off" spellcheck="off">
        <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
            <div class="mr-5 flex align-items-center mt-3">
                <i class="fa-solid fa-truck mr-2" style="font-size: 1.5rem"></i>
                <div class="font-medium text-xl text-900">{{ formTitle }}</div>
            </div><br>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-hashtag"></i></span>
                        <input type="text" pInputText [(ngModel)]="vehicle.plateNumber" placeholder="Plate Number"
                               formControlName="plateNumberInput" tooltipPosition="top" pTooltip="Enter the vehicle's plate number" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-file-signature"></i></span>
                        <input type="text" pInputText [(ngModel)]="vehicle.registrationNumber" placeholder="Registration Number"
                               formControlName="registrationNumberInput" tooltipPosition="top" pTooltip="Enter the vehicle's registration number" />
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-file-signature"></i></span>
                        <input type="text" pInputText [(ngModel)]="vehicle.engineNumber" placeholder="Engine Number"
                               formControlName="engineNumberInput" tooltipPosition="top" pTooltip="Enter the vehicle's engine number" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-car"></i></span>
                        <p-dropdown id="vehicleType" [options]="vehicleTypes" [(ngModel)]="selectedVehicleType"
                                    optionLabel="name" placeholder="Select Vehicle Type"
                                    (onChange)="onVehicleTypeChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleTypeDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle type">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleType pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img *ngIf="vehicleType.imageUrl" [src]="vehicleType.imageUrl"
                                         alt="{{ vehicleType.name }}" style="height: 18px; margin-right: 5px;">
                                    <div>{{ vehicleType.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-cogs"></i></span>
                        <p-dropdown id="vehicleConfig" [options]="vehicleConfigs" [(ngModel)]="selectedVehicleConfig"
                                    optionLabel="name" placeholder="Select Vehicle Configuration"
                                    (onChange)="onVehicleConfigChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleConfigDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle configuration">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleConfig pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img *ngIf="vehicleConfig.imageUrl" [src]="vehicleConfig.imageUrl" alt="{{ vehicleConfig.name }}" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ vehicleConfig.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-weight-hanging"></i></span>
                        <p-dropdown id="vehicleCapacity" [options]="vehicleCapacities" [(ngModel)]="selectedVehicleCapacity"
                                    optionLabel="name" placeholder="Select Vehicle Capacity"
                                    (onChange)="onVehicleCapacityChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleCapacityDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle capacity">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleCapacity pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img *ngIf="vehicleCapacity.imageUrl" [src]="vehicleCapacity.imageUrl" alt="{{ vehicleCapacity.name }}" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ vehicleCapacity.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-car-side"></i></span>
                        <p-dropdown id="vehicleMake" [options]="vehicleMakes" [(ngModel)]="selectedVehicleMake"
                                    optionLabel="name" placeholder="Select Vehicle Make"
                                    (onChange)="onVehicleMakeChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleMakeDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle make">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleMake pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img *ngIf="vehicleMake.imageUrl" [src]="vehicleMake.imageUrl" alt="{{ vehicleMake.name }}" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ vehicleMake.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-car-rear"></i></span>
                        <p-dropdown id="vehicleModel" [options]="vehicleModels" [(ngModel)]="selectedVehicleModel"
                                    optionLabel="name" placeholder="Select Vehicle Model"
                                    (onChange)="onVehicleModelChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleModelDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle model">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleModel pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <img *ngIf="vehicleModel.imageUrl" [src]="vehicleModel.imageUrl" alt="{{ vehicleModel.name }}" style="width: 18px; height: 18px; margin-right: 5px;">
                                    <div>{{ vehicleModel.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-calendar"></i></span>
                        <p-dropdown id="vehicleYear" [options]="vehicleYears" [(ngModel)]="selectedVehicleYear"
                                    optionLabel="year" placeholder="Select Vehicle Year"
                                    (onChange)="onVehicleYearChange($event)"
                                    [filter]="true" filterBy="name" [showClear]="true"
                                    formControlName="vehicleYearDropdown" [style]="{'width': '100%'}"
                                    tooltipPosition="top" pTooltip="Select the vehicle year">
                            <ng-template pTemplate="filter" let-options="options">
                                <div class="flex p-3">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                                        <input type="text" pInputText placeholder="Filter" (keyup)="options.filter($event)">
                                    </div>
                                    <p-button icon="fa-solid fa-close" iconPos="left" styleClass="p-button-secondary"
                                              class="ml-3"></p-button>
                                </div>
                            </ng-template>
                            <ng-template let-vehicleYear pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                    <div>{{ vehicleYear.year }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>


            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-box"></i></span>
                        <p-multiSelect [options]="payloadTypes" [(ngModel)]="selectedPayloadTypes" optionLabel="name"
                                       placeholder="Select Payload Types"
                                       formControlName="payloadTypesMultiSelect" [style]="{'width': '100%'}"
                                       tooltipPosition="top" pTooltip="Select the payload types for this vehicle">
                        </p-multiSelect>
                    </div>
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-road"></i></span>
                        <p-multiSelect [options]="tripTypes" [(ngModel)]="selectedTripTypes" optionLabel="name"
                                       placeholder="Select Trip Types"
                                       formControlName="tripTypesMultiSelect" [style]="{'width': '100%'}"
                                       tooltipPosition="top" pTooltip="Select the trip types for this vehicle">
                        </p-multiSelect>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row">
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-id-card"></i></span> <!— File icon -->
                        <p-fileUpload name="registrationCardFile" accept=".pdf,.png,.jpg" mode="basic" [customUpload]="true"
                                      (onSelect)="onRegistrationCardUpload($event)" chooseLabel="Upload Registration Card"></p-fileUpload>
                    </div>
                </div>
                <div class="flex col">
                    <img *ngIf="vehicle.registrationImage" [src]="vehicle.registrationImage" alt="Vehicle Registration" style="max-height: 45px;">
                </div>
                <div class="flex col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="fa-solid fa-calendar-days"></i></span>
                        <p-calendar id="registrationDate" name="registrationDate" [(ngModel)]="selectedRegistrationDate"
                                    [showIcon]="true" dateFormat="dd/mm/yy" inputId="registrationDate" [style]="{'width': '100%'}"
                                    formControlName="registrationDateInput" tooltipPosition="top" pTooltip="Select the vehicle's registration date">
                        </p-calendar>
                    </div>
                </div>
            </div>

            <div class="flex flex-column lg:flex-row align-items-end">
                <div class="flex col">
                    <p-button label="{{ vehicle.vehicleId ? 'Update' : 'Create' }}" icon="fa-solid fa-plus" iconPos="left"
                              [loading]="vehicleCreationInProgress" class="m-1 px-1 py-1" (onClick)="saveVehicle()"></p-button>
                    <p-button label="Clear" icon="fa-solid fa-xmark" iconPos="left" styleClass="p-button-secondary"
                              class="m-1 px-1 py-1 p-button-help"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>
