import * as firebaseAuth from 'firebase/auth';
import * as angularAuth from "@angular/fire/compat/auth";
import { Injectable } from '@angular/core';
import {catchError, from, map, Observable, throwError} from 'rxjs';
import {HttpClient} from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    userData: Observable<firebaseAuth.User>;

    constructor(private angularFireAuth: angularAuth.AngularFireAuth,
                private http: HttpClient) {
        this.userData = angularFireAuth.authState as any;
    }



    login(emailAddress: string, password: string): Observable<any> {
        return new Observable((observer) => {
            this.angularFireAuth
                .signInWithEmailAndPassword(emailAddress, password)
                .then(res => {
                    console.log("You're in !");
                    observer.next(res.user);
                })
                .catch(err => {
                    console.log('Something went wrong:', err.message);
                    observer.error(err);
                });
        });
    }

    register(email: string, password: string): Observable<any> {
        return new Observable((observer) => {
            this.angularFireAuth
                .createUserWithEmailAndPassword(email, password)
                .then(res => {
                    console.log('You are Successfully signed up!', res);
                    observer.next(res.user);
                })
                .catch(error => {
                    console.log('Something is wrong:', error.message);
                    observer.error(error);
                });
        });
    }

    getCurrentUserAccessToken(): Observable<string> {
        return new Observable((observer) => {
            this.angularFireAuth.currentUser.then(async res => {
                let userAccessToken = await res?.getIdToken(true);
                console.log('Access Token: ', userAccessToken);
                if (userAccessToken) {
                    console.log('Access Token: ', userAccessToken);
                    observer.next(userAccessToken);
                } else {
                    console.log('Something is wrong with access token');
                    observer.error();
                }
            }).catch(error => {
                    console.log('Something is wrong:', error.message);
                    observer.error(error);
                });
        });
    }

    getCurrentUserRefreshToken(): Observable<string> {
        return new Observable((observer) => {
            this.angularFireAuth.currentUser.then(res => {
                console.log('Refresh Token: ', res?.getIdToken());
                let userRefreshToken = res?.refreshToken;
                if (userRefreshToken !== null) {
                    console.log('Refresh Token: ', userRefreshToken);
                    observer.next(userRefreshToken);
                } else {
                    console.log('Something is wrong:');
                    observer.error(new Error("refresh token is null"));
                }
            })
                .catch(error => {
                    console.log('Something is wrong:', error.message);
                    observer.error(error);
                });
        });
    }

    logout() {
        this.angularFireAuth.signOut();
    }

    async updateFirebaseUser(firebaseId: string, emailAddress: string, phone: string) {
        const url = 'https://us-central1-prologix-test.cloudfunctions.net/firebaseUpdateUserAPI';
        const headers = { 'Content-Type': 'application/json' };

        try {
            const response = await this.http.post(url, {
                'uid': firebaseId,
                'email': emailAddress,
                'phoneNumber': phone
            }, { headers }).toPromise();
            console.log('Firebase user updated:', response);
        } catch (error) {
            console.error('Error updating Firebase user:', error);
            throw error;
        }
    }

    async createFirebaseUser(email: string, phoneNumber: string): Promise<any> {
        if (!email)
            email = phoneNumber.replace('+','') + '@abcxyz.com';
        console.log('Generated Email: ' + email);
        let userCredential;
        try {
            userCredential = await this.angularFireAuth.signInWithEmailAndPassword(email, 'temporaryPassword');
        } catch (error) {
            userCredential = await this.angularFireAuth.createUserWithEmailAndPassword(email, 'temporaryPassword');
        }
        await this.updateFirebaseUser(userCredential.user!.uid, email, phoneNumber);
        return userCredential.user!.uid;
    }

}
