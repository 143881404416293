import { Injectable } from '@angular/core'; // Or other dependency injection library
import { HttpClient } from '@angular/common/http'; // For making HTTP requests
import { Observable } from 'rxjs';

// Import your DTO interfaces/classes
import {environment} from "../../environments/environment";
import {ChartOfAccounts} from "./chart-of-accounts.model";
import {COAAccountType} from "./coa-account-type.model";
import {APIResponseWrapper} from "../models/common/api-response-wrapper";


@Injectable({
    providedIn: 'root' // Or provide in the relevant module
})
export class ChartOfAccountsService {

    private baseUrl = `${environment.restApiUrl}chart-of-accounts`; // Base URL for your API

    constructor(private http: HttpClient) { }


    /**
     * Get all chart of accounts for a specific company.
     * @param owningCompanyId The ID of the owning company.
     * @param isTree Flag to indicate if the response should be a tree structure.
     * @returns An Observable of a list of ChartOfAccounts objects.
     */
    getAllChartOfAccountsByCompany(owningCompanyId: number, isTree: boolean): Observable<ChartOfAccounts[]> {
        const url = `${this.baseUrl}/${owningCompanyId}/isTree/${isTree}`;
        return this.http.get<ChartOfAccounts[]>(url);
    }

    /**
     * Get a chart of accounts by ID.
     * @param id The ID of the chart of accounts.
     * @returns An Observable of a ChartOfAccounts object.
     */
    getChartOfAccountsById(id: number): Observable<ChartOfAccounts> {
        const url = `${this.baseUrl}/id/${id}`;
        return this.http.get<ChartOfAccounts>(url);
    }
    /**
     * Get a chart of accounts by ID and company.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the chart of accounts.
     * @returns An Observable of a ChartOfAccounts object.
     */
    getChartOfAccountsByIdAndCompany(owningCompanyId: number, id: number): Observable<ChartOfAccounts> {
        const url = `${this.baseUrl}/owningCompanyId/${owningCompanyId}/id/${id}`;
        return this.http.get<ChartOfAccounts>(url);
    }

    /**
     * Create a new chart of accounts.
     * @param chartOfAccounts The ChartOfAccounts object to create.
     * @returns An Observable of the created ChartOfAccounts object.
     */
    createChartOfAccounts(chartOfAccounts: ChartOfAccounts): Observable<APIResponseWrapper<ChartOfAccounts>> {
        const url = `${this.baseUrl}`;
        return this.http.post<APIResponseWrapper<ChartOfAccounts>>(url, chartOfAccounts);
    }

    /**
     * Update an existing chart of accounts.
     * @param owningCompanyId The ID of the owning company.
     * @param id The ID of the chart of accounts to update.
     * @param chartOfAccounts The updated ChartOfAccounts object.
     * @returns An Observable of the updated ChartOfAccounts object.
     */
    updateChartOfAccounts(owningCompanyId: number, id: number, chartOfAccounts: ChartOfAccounts): Observable<ChartOfAccounts> {
        const url = `${this.baseUrl}/${id}?owningCompanyId=${owningCompanyId}`;
        return this.http.put<ChartOfAccounts>(url, chartOfAccounts);
    }

    /**
     * Delete a chart of accounts.
     * @param id The ID of the chart of accounts to delete.
     * @param owningCompanyId ID of the owning company
     * @returns An Observable of void.
     */
    deleteChartOfAccounts(id: number, owningCompanyId: number): Observable<void> {
        const url = `${this.baseUrl}/${id}?owningCompanyId=${owningCompanyId}`;
        return this.http.delete<void>(url);
    }

    /**
     * Get all account types.
     * @returns An Observable of a list of AccountType objects.
     */
    getAllAccountTypes(): Observable<COAAccountType[]> {
        const url = `${this.baseUrl}/account-types`;
        return this.http.get<COAAccountType[]>(url);
    }

    /**
     * Get an account type by ID.
     * @param id The ID of the account type.
     * @returns An Observable of an AccountType object.
     */
    getAccountTypeById(id: number): Observable<COAAccountType> {
        const url = `${this.baseUrl}/account-type/${id}`;
        return this.http.get<COAAccountType>(url);
    }



}
