import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnterpriseSubscription } from "./enterprise-subscription.model";
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class EnterpriseSubscriptionService {

    private apiUrl = `${environment.restApiUrl}enterprise-subscriptions`; // Base URL

    constructor(private http: HttpClient) { }

    createSubscription(subscription: EnterpriseSubscription): Observable<EnterpriseSubscription> {
        return this.http.post<EnterpriseSubscription>(this.apiUrl, subscription);
    }

    getSubscription(id: number): Observable<EnterpriseSubscription> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.get<EnterpriseSubscription>(url);
    }

    getSubscriptionsByCompanyId(companyId: number): Observable<EnterpriseSubscription[]> {
        const url = `${this.apiUrl}/company/${companyId}`;
        return this.http.get<EnterpriseSubscription[]>(url);
    }

    getAllSubscriptions(): Observable<EnterpriseSubscription[]> {
        return this.http.get<EnterpriseSubscription[]>(this.apiUrl);
    }

    updateSubscription(id: number, subscription: EnterpriseSubscription): Observable<EnterpriseSubscription> {
        const url = `${this.apiUrl}/${id}`;
        return this.http.put<EnterpriseSubscription>(url, subscription);
    }

    deleteSubscription(id: number): Observable<HttpResponse<any>> {  // Use HttpResponse<any>
        const url = `${this.apiUrl}/${id}`;
        return this.http.delete(url, { observe: 'response' }); // Observe the full response
    }
}
