/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Location } from './location.model';
import { SessionStorageService } from '../services/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import {APIListWrapper} from "../models/common/api-list-wrapper.model";
import {Observable} from "rxjs";

@Injectable()
export class LocationService {

    restApiUrl: string

    constructor(private http: HttpClient) {
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    getLocationById(locationId: number) {
        return this.http.get<APIResponseWrapper<Location[]>>(`${this.restApiUrl}location-endpoint/locations?locationId=${locationId}&cultureCode=en-US`);
    }

    getLocationsByCompanyId(page: number, pageSize: number, companyId: number) {
        return this.http.get<APIListWrapper<Location>>(`${this.restApiUrl}location-endpoint/locations-by-company?page=${page}&pageSize=${pageSize}&companyId=${companyId}&cultureCode=en-US`);
    }


    createLocation(location: Location) {
        return this.http.post<APIResponseWrapper<Location>>(`${this.restApiUrl}location-endpoint/location`, location)
    }

    updateLocation(location: Location) {
        return this.http.put<APIResponseWrapper<Location>>(`${this.restApiUrl}location-endpoint/location`, location);
    }

    getAllComboData(nameSearch: string, managedById: number, page: number, pageSize: number, cultureCode: string) {
        const params = { nameSearch, managedById, page, pageSize, cultureCode };
        return this.http.get<APIListWrapper<Location>>(
            `${this.restApiUrl}location-endpoint/locations-combo-data`,
            { params }
        );
    }

}
