import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractsService } from '../contracts.service';
import { ContractRevisions } from '../models/contract-revisions.model';
import {MenuItem, MessageService} from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {ContractStatus} from "../models/contract-status.model";
import {Contracts} from "../models/contracts.model";
import {GoogleCloudStorageService} from "../../services/google/google-cloud-storage.service";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import { storage } from 'src/environments/environment';

@Component({
    selector: 'app-add-edit-contract-revision',
    templateUrl: './add-edit-contract-revision.component.html',
    styleUrls: ['./add-edit-contract-revision.component.css']
})
export class AddEditContractRevisionComponent implements OnInit {

    contract: Contracts;
    contractId: number | null;
    contractRevisionId: number | null;
    createEditRevisionForm!: FormGroup;
    createEditRevision: ContractRevisions = new ContractRevisions();
    revisionCreationInProgress = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    pdfUploadProgress: number = 0
    contractStatuses: ContractStatus[] = []; // Replace with your actual data

    constructor(
        private fb: FormBuilder, private messageService: MessageService,
        private contractsService: ContractsService,
        private googleCloudStorageService: GoogleCloudStorageService,
        private route: ActivatedRoute,
        private location: Location
    ) { }

    ngOnInit(): void {
        this.createEditRevisionForm = this.fb.group({
            effectiveStartDateInput: [''],
            endDateInput: [''],
            totalAmountInput: [''],
            penaltyRateInput: [''],
            firstPartyAgreedInput: [false],
            secondPartyAgreedInput: [false],
            agreementPeriodInput: [''],
            nonRenewalDisclosureBeforeDaysInput: [''],
            goodsInsuranceByFirstPartyInput: [false],
            goodsInsuranceBySecondPartyInput: [false],
            vatAddedToInvoiceInput: [false],
            paymentTermsInput: [''],
            firstPartyBankAccountIdInput: [''],
            firstPartyBankAccountDetailsInput: [''],
            siteServiceTimeInput: [''],
            penaltyOnServiceTimeInput: [''],
            noticePeriodInput: [''],
            forceMajeureClausePeriodInput: [''],
            disputeResolutionInput: [''],
            governingLawInput: [''],
            revisionNotesInput: [''],
        });


        this.contractId = Number(this.route.snapshot.paramMap.get('contractId') ?? 0);
        console.log(this.contractId);
        this.contractsService.getContractById(this.contractId).subscribe(contract => {
            console.log(contract);
            this.contract = contract;
            if (!this.contractRevisionId)
                this.createEditRevision.revisionNumber = this.contract.revisions.length + 1;
        });

        this.contractRevisionId = Number(this.route.snapshot.paramMap.get('contractRevisionId') ?? 0);
        console.log(this.contractRevisionId);
        if (this.contractRevisionId != 0) {
            this.loadContractRevision();
        }

        this.loadContractRevision();

        this.breadcrumbItems = [
            { label: 'Contracts', routerLink: '/contracts' },
            { label: this.createEditRevision.revisionID ? 'Edit Contract Revision' : 'Add Contract Revision' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        //this.contract = this.sessionStorageService.getCurrentContract()!;

        // Load contract statuses (replace with your actual data fetching logic)
        this.loadContractStatuses();
    }

    loadContractRevision() {
        this.contractsService.getRevisionById(this.contractId!, this.contractRevisionId!).subscribe(revision => {
            console.log(revision);
            this.createEditRevision = revision;
            this.updateFormValues();
        });
    }

    loadContractStatuses() {
        this.contractStatuses = Object.values(ContractStatus);
    }

    updateFormValues() {
        this.createEditRevisionForm.patchValue({
            effectiveStartDateInput: this.createEditRevision.effectiveStartDate,
            endDateInput: this.createEditRevision.endDate,
            totalAmountInput: this.createEditRevision.totalAmount,
            penaltyRateInput: this.createEditRevision.penaltyRate,
            firstPartyAgreedInput: this.createEditRevision.firstPartyAgreed,
            secondPartyAgreedInput: this.createEditRevision.secondPartyAgreed,
            agreementPeriodInput: this.createEditRevision.agreementPeriod,
            nonRenewalDisclosureBeforeDaysInput: this.createEditRevision.nonRenewalDisclosureBeforeDays,
            goodsInsuranceByFirstPartyInput: this.createEditRevision.goodsInsuranceByFirstParty,
            goodsInsuranceBySecondPartyInput: this.createEditRevision.goodsInsuranceBySecondParty,
            vatAddedToInvoiceInput: this.createEditRevision.vatAddedToInvoice,
            paymentTermsInput: this.createEditRevision.paymentTerms,
            firstPartyBankAccountIdInput: this.createEditRevision.firstPartyBankAccountId,
            firstPartyBankAccountDetailsInput: this.createEditRevision.firstPartyBankAccountDetails,
            siteServiceTimeInput: this.createEditRevision.siteServiceTime,
            penaltyOnServiceTimeInput: this.createEditRevision.penaltyOnServiceTime,
            noticePeriodInput: this.createEditRevision.noticePeriod,
            forceMajeureClausePeriodInput: this.createEditRevision.forceMajeureClausePeriod,
            disputeResolutionInput: this.createEditRevision.disputeResolution,
            governingLawInput: this.createEditRevision.governingLaw,
            revisionNotesInput: this.createEditRevision.revisionNotes,
        });
    }

    updateRevisionObject() {
        this.createEditRevision = {
            ...this.createEditRevision, // Keep existing properties
            effectiveStartDate: this.createEditRevisionForm.get('effectiveStartDateInput')?.value,
            endDate: this.createEditRevisionForm.get('endDateInput')?.value,
            totalAmount: this.createEditRevisionForm.get('totalAmountInput')?.value,
            penaltyRate: this.createEditRevisionForm.get('penaltyRateInput')?.value,
            firstPartyAgreed: this.createEditRevisionForm.get('firstPartyAgreedInput')?.value,
            secondPartyAgreed: this.createEditRevisionForm.get('secondPartyAgreedInput')?.value,
            agreementPeriod: this.createEditRevisionForm.get('agreementPeriodInput')?.value,
            nonRenewalDisclosureBeforeDays: this.createEditRevisionForm.get('nonRenewalDisclosureBeforeDaysInput')?.value,
            goodsInsuranceByFirstParty: this.createEditRevisionForm.get('goodsInsuranceByFirstPartyInput')?.value,
            goodsInsuranceBySecondParty: this.createEditRevisionForm.get('goodsInsuranceBySecondPartyInput')?.value,
            vatAddedToInvoice: this.createEditRevisionForm.get('vatAddedToInvoiceInput')?.value,
            paymentTerms: this.createEditRevisionForm.get('paymentTermsInput')?.value,
            firstPartyBankAccountId: this.createEditRevisionForm.get('firstPartyBankAccountIdInput')?.value,
            firstPartyBankAccountDetails: this.createEditRevisionForm.get('firstPartyBankAccountDetailsInput')?.value,
            siteServiceTime: this.createEditRevisionForm.get('siteServiceTimeInput')?.value,
            penaltyOnServiceTime: this.createEditRevisionForm.get('penaltyOnServiceTimeInput')?.value,
            noticePeriod: this.createEditRevisionForm.get('noticePeriodInput')?.value,
            forceMajeureClausePeriod: this.createEditRevisionForm.get('forceMajeureClausePeriodInput')?.value,
            disputeResolution: this.createEditRevisionForm.get('disputeResolutionInput')?.value,
            governingLaw: this.createEditRevisionForm.get('governingLawInput')?.value,
            revisionNotes: this.createEditRevisionForm.get('revisionNotesInput')?.value,
        };
    }



    createUpdateRevision() {
        this.revisionCreationInProgress = true;
        this.updateRevisionObject();
        const revisionData: ContractRevisions = this.createEditRevision;

        if (this.createEditRevision.revisionID) {
            // Update existing contract revision
            this.contractsService.updateRevision(this.contract.contractID, this.createEditRevision.revisionID, revisionData)
                .subscribe(() => {
                    this.revisionCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                });
        } else {
            // Create new contract revision
            this.contractsService.createRevision(this.contract.contractID, revisionData)
                .subscribe(() => {
                    this.revisionCreationInProgress = false;
                    // Handle success, e.g., show a message, navigate back
                    this.location.back();
                });
        }
    }

    onOriginalAgreementFileUpload(event: any) {
        // Handle file upload logic here
        console.log(event.files);
        this.uploadPDFFile(event.files.at(0)!!, 'ORIGINAL');
    }

    onSignedAgreementFileUpload(event: any) {
        // Handle file upload logic here
        console.log(event.files);
        this.uploadPDFFile(event.files.at(0)!!, 'SIGNED');
    }

    uploadPDFFile(file: File, fileType: string) {
        // Create a unique file name
        const storageRef = ref(storage, file.name);

        // Upload the PDF file to Firebase Storage
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                this.pdfUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.pdfUploadProgress = Math.round(this.pdfUploadProgress);
                if (this.pdfUploadProgress > 10)
                    this.pdfUploadProgress = this.pdfUploadProgress - 5;
                console.log('Upload is ' + this.pdfUploadProgress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file:", error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'PDF upload failed.' });
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (fileType === 'ORIGINAL')
                        this.createEditRevision.originalAgreementDocumentUrl = downloadURL;
                    else this.createEditRevision.signedAgreementDocumentUrl = downloadURL;
                }).catch((error) => {
                    console.error("Error getting download URL:", error);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to get download URL.' });
                });
            }
        );
    }
}
