import { Routes } from '@angular/router';
import {CustomerComponent} from "./customer.component";

export const customerRoutes: Routes = [
    {
        path: 'customer',
        children: [
            {
                path: '',
                component: CustomerComponent,
            }
        ]
    },
];
