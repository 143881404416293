import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MessageService} from "primeng/api";
import {FileUploadModule} from "primeng/fileupload";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";

import {accountsRoutes} from "./accounts.routes";
import {AccountsComponent} from "./accounts.component";
import {InvoiceComponent} from "./invoice/invoice.component";
import {ReceiptComponent} from "./receipt/receipt.component";
import {AccountsService} from "./accounts.service";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ChartModule} from "primeng/chart";
import { ReceivablesComponent } from './receivables/receivables.component';
import { PayablesComponent } from './payables/payables.component';
import { PaymentTransactionsComponent } from './payment-transactions/payment-transactions.component';
import {AccountsGuard} from "./accounts.guard";
import {ChipModule} from "primeng/chip";
import {TabViewModule} from "primeng/tabview";
import {ImageModule} from "primeng/image";
import {AccordionModule} from "primeng/accordion";
import {RippleModule} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import { GeneratedInvoicesComponent } from './receivables/generated-invoices/generated-invoices.component';
import { GeneratedReceiptsComponent } from './receivables/generated-receipts/generated-receipts.component';
import { JournalEntriesComponent } from './journal-entries/journal-entries.component';
import {JournalEntriesService} from "./journal-entries/journal-entries.service";
import { AddEditJournalEntryComponent } from './journal-entries/add-edit-journal-entry/add-edit-journal-entry.component';
import {CalendarModule} from "primeng/calendar";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {InputTextareaModule} from "primeng/inputtextarea";
import {DividerModule} from "primeng/divider";
import {AutoCompleteModule} from "primeng/autocomplete";
import {BadgeModule} from "primeng/badge";
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';
import { PartnerLedgerComponent } from './partner-ledger/partner-ledger.component';
import {TreeModule} from "primeng/tree";
import {SplitterModule} from "primeng/splitter";
import {
    ReceiptAmountDialogComponent
} from "./receivables/generated-receipts/receipt-amount-dialog/receipt-amount-dialog.component";
import {DialogModule} from "primeng/dialog";
import {RadioButtonModule} from "primeng/radiobutton";
import { GeneratedBillsComponent } from './payables/generated-bills/generated-bills.component';
import { GeneratedVouchersComponent } from './payables/generated-vouchers/generated-vouchers.component';
import { VoucherAmountDialogComponent } from './payables/generated-vouchers/voucher-amount-dialog/voucher-amount-dialog.component';
import { AgedPayableComponent } from './reports/aged-payable/aged-payable.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { AddEditDebitNoteComponent } from './debit-note/add-edit-debit-note/add-edit-debit-note.component';
import { AddEditCreditNoteComponent } from './credit-note/add-edit-credit-note/add-edit-credit-note.component';
import { ApplyReceiptInvoiceComponent } from './receivables/apply-receipt-invoice/apply-receipt-invoice.component';
import {PickListModule} from "primeng/picklist";




@NgModule({
    imports: [
        RouterModule.forChild(accountsRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,

        FileUploadModule,
        BreadcrumbModule,
        TableModule,
        TagModule,
        ButtonModule,
        TooltipModule,
        ProgressSpinnerModule,
        ChartModule,
        BreadcrumbModule,
        ChipModule,
        TabViewModule,
        ImageModule,
        AccordionModule,
        RippleModule,
        ToastModule,
        CalendarModule,
        InputNumberModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        DividerModule,
        AutoCompleteModule,
        BadgeModule,
        TreeModule,
        SplitterModule,
        DialogModule,
        RadioButtonModule,
        PickListModule,

    ],
    providers: [
        MessageService,
        AccountsService,
        JournalEntriesService,
        AccountsGuard
    ],
    declarations: [
        AccountsComponent,
        InvoiceComponent,
        ReceiptComponent,
        ReceivablesComponent,
        PayablesComponent,
        PaymentTransactionsComponent,
        GeneratedInvoicesComponent,
        GeneratedReceiptsComponent,
        JournalEntriesComponent,
        AddEditJournalEntryComponent,
        GeneralLedgerComponent,
        PartnerLedgerComponent,
        ReceiptAmountDialogComponent,
        GeneratedBillsComponent,
        GeneratedVouchersComponent,
        VoucherAmountDialogComponent,
        AgedPayableComponent,
        CreditNoteComponent,
        DebitNoteComponent,
        AddEditDebitNoteComponent,
        AddEditCreditNoteComponent,
        ApplyReceiptInvoiceComponent
    ],
})
export class AccountsModule { }
