<p-toast position="bottom-center" [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="chartOfAccountsForm" (ngSubmit)="saveChartOfAccounts()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Chart of Account' : ('Add Sub-Account under ' + (parentChartOfAccount?.accountName ?? '') + ' (' + parentChartOfAccount?.accountCode + ')') }}
            </div>
            <div class="p-fluid grid" *ngIf="editMode">
                <div class="col-12 md:col-3">
                    <label for="accountId">Account ID</label>
                    <input type="text" pInputText id="accountId" formControlName="accountId" [disabled]="true"/>
                </div>
            </div>

            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="accountCode">Account Code</label>
                    <input type="text" pInputText formControlName="accountCode" id="accountCode" required/>
                    <small class="p-error"
                           *ngIf="chartOfAccountsForm.get('accountCode')?.hasError('required') && chartOfAccountsForm.get('accountCode')?.touched">Account
                        Code is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="accountName">Account Name</label>
                    <input type="text" pInputText formControlName="accountName" id="accountName" required/>
                    <small class="p-error"
                           *ngIf="chartOfAccountsForm.get('accountName')?.hasError('required') && chartOfAccountsForm.get('accountName')?.touched">Account
                        Name is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="accountType">Account Type</label>
                    <p-dropdown id="accountType" [options]="accountTypes" formControlName="accountType" optionValue="value"
                                placeholder="Select an Account Type" optionLabel="label" [required]="true"
                                [filter]="true"></p-dropdown>
                    <small class="p-error"
                           *ngIf="chartOfAccountsForm.get('accountType')?.hasError('required') && chartOfAccountsForm.get('accountType')?.touched">Account
                        Type is required.</small>
                </div>
            </div>

            <p-divider/>

            <div class="col-12 flex justify-content-end">
                <button pButton pRipple type="button" label="Cancel" (click)="cancel()"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton pRipple type="submit" label="Save" class="p-button-rounded p-button-text p-button-info"></button>
            </div>
        </div>
    </form>
</div>
