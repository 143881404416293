import { Component, OnInit } from '@angular/core';
import { ContractsService } from '../contracts.service';
import { Contracts } from '../models/contracts.model';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { ContractRevisions } from '../models/contract-revisions.model';
import { formatDate } from '@angular/common';
import {TabViewChangeEvent} from "primeng/tabview";
import {JobAttachment} from "../../models/order-view/job-attachment";
import {SessionStorageService} from "../../services/session-storage.service";
import {VehicleConfig} from "../../fleet/vehicle/params/vehicle-config";
import {ContractStatus} from "../models/contract-status.model";
import defaultCallbacks from "chart.js/dist/plugins/plugin.tooltip";


@Component({
    selector: 'app-view-contract',
    templateUrl: './view-contract.component.html',
    styleUrls: ['./view-contract.component.css']
})
export class ViewContractComponent implements OnInit {

    contract!: Contracts;
    isLoading = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    pickupSubLocality: string = '';
    pickupLocality: string = '';
    dropoffSubLocality: string = '';
    dropoffLocality: string = '';

    contractFilesList: JobAttachment[] = [];
    contractFilesCount = 0;
    currentRevisionNumber = 0;

    showStatusDialog: boolean = false;
    selectedStatus: ContractStatus | undefined;
    contractStatusList = Object.values(ContractStatus).map(status => ({
        key: status,
        name: status
    }));

    revisionFields:{ key: keyof ContractRevisions, label: string }[] = [
        { key: 'effectiveStartDate', label: 'Effective Start Date' },
        { key: 'endDate', label: 'End Date' },
        { key: 'firstPartyAgreed', label: 'First Party Agreed' },
        { key: 'secondPartyAgreed', label: 'Second Party Agreed' },
        { key: 'isRenewable', label: 'Is Renewable' },
        { key: 'agreementPeriod', label: 'Agreement Period' },
        { key: 'nonRenewalDisclosureBeforeDays', label: 'Non-Renewal Disclosure (Days)' },
        { key: 'goodsInsuranceByFirstParty', label: 'Goods Insured by First Party' },
        { key: 'goodsInsuranceBySecondParty', label: 'Goods Insured by Second Party' },
        { key: 'totalAmount', label: 'Total Amount' },
        { key: 'vatAddedToInvoice', label: 'VAT Added to Invoice' },
        { key: 'paymentTerms', label: 'Payment Terms' },
        { key: 'penaltyRate', label: 'Penalty Rate' },
        { key: 'firstPartyBankAccountId', label: 'First Party Bank Account ID' },
        { key: 'firstPartyBankAccountDetails', label: 'First Party Bank Account Details' },
        { key: 'siteServiceTime', label: 'Site Service Time' },
        { key: 'penaltyOnServiceTime', label: 'Penalty on Service Time' },
        { key: 'noticePeriod', label: 'Notice Period' },
        { key: 'forceMajeureClausePeriod', label: 'Force Majeure Clause Period' },
        { key: 'disputeResolution', label: 'Dispute Resolution' },
        { key: 'governingLaw', label: 'Governing Law' },
        { key: 'createdBy', label: 'Created By' },
        { key: 'createdDate', label: 'Created Date' },
        { key: 'revisedBy', label: 'Revised By' },
        { key: 'revisedDate', label: 'Revised Date' },
        { key: 'revisionNotes', label: 'Revision Notes' }
        // deliveryRateCards is already handled in a separate table
    ];

    constructor(
        private contractsService: ContractsService,
        private messageService: MessageService,
        private route: ActivatedRoute, private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.breadcrumbItems = [
            { label: 'Contracts', routerLink: '/contracts' },
            { label: 'View Contract' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        const contractId = this.route.snapshot.paramMap.get('id');
        if (contractId) {
            this.loadContract(Number(contractId));
        }
    }

    loadContract(contractId: number) {
        this.contractsService.getContractById(contractId).subscribe(contract => {
            console.log(contract);
            this.contract = contract;
            this.contract.contractStatus = this.contract.revisions
                .sort((a, b) => a.revisionNumber - b.revisionNumber)
                .map(value => value.status).pop() || ''
            this.isLoading = false;
            this.loadContractFiles(this.contract.revisions[0]);
            this.loadDeliveryRateCards(this.contract.revisions[0]);
        });
    }

    onRevisionTabChange(event: TabViewChangeEvent) {
        console.log(event.index);
        this.currentRevisionNumber = event.index;
        this.loadContractFiles(this.contract.revisions[event.index]);
        this.loadDeliveryRateCards(this.contract.revisions[event.index]);
    }

    addRateCard() {
        this.router.navigate([
            '/contracts/create-rate-card/contract',
            this.contract.contractID,
            'revision',
            this.contract.revisions[this.currentRevisionNumber].revisionID,
            'rate'
        ]);
    }

    loadContractFiles(contractRevision: ContractRevisions) {
        // Replace with your actual logic to fetch contract files
        if (contractRevision.originalAgreementDocumentUrl)
            this.contractFilesList.push({
                fileName: 'Original Agreement',
                fileType: 'Contract',
                fileUrl: contractRevision.originalAgreementDocumentUrl,
                createdAt: contractRevision.createdDate?.toISOString() ?? new Date().toISOString(),
            } as JobAttachment);
        if (contractRevision.signedAgreementDocumentUrl)
            this.contractFilesList.push({
                fileName: 'Signed Agreement',
                fileType: 'Contract',
                fileUrl: contractRevision.signedAgreementDocumentUrl,
                createdAt: contractRevision.createdDate?.toISOString() ?? new Date().toISOString(),
            } as JobAttachment);
        this.contractFilesCount = this.contractFilesList.length;
    }

    showDate(date: Date | undefined): string {
        if (date) {
            return formatDate(date, 'dd/MM/yyyy', 'en-US');
        }
        return '';
    }

    private loadDeliveryRateCards(contractRevision: ContractRevisions) {
        this.contractsService.getDeliveryRateCardsByRevisionId(this.contract.contractID, contractRevision.revisionID).subscribe(deliveryRateCards => {
            console.log(deliveryRateCards);
            contractRevision.deliveryRateCards = deliveryRateCards;
        });
    }

    getVehicleConfig(vehicleConfigId: number): VehicleConfig | undefined {
        return this.sessionStorageService.getVehicleTypes()?.flatMap(value => value.vehicleConfigs).filter(value => value.configId == vehicleConfigId).pop();
    }

    addRevision() {
        this.router.navigate(['/contracts/create-revision/contract', this.contract.contractID, 'revision']);
    }

    editRevision() {
        this.router.navigate(['/contracts/edit-revision/contract', this.contract.contractID, 'revision', this.contract.revisions[this.currentRevisionNumber].revisionID]);
    }

    showEditStatusDialog() {
        this.selectedStatus = this.contract.revisions[this.currentRevisionNumber].status; // Pre-select current status
        this.showStatusDialog = true;
    }

    updateRevisionStatus() {
        if (!this.selectedStatus) {
            return;
        }

        // Update the revision object with the new status
        this.contract.revisions[this.currentRevisionNumber].status = this.selectedStatus;

        // Call your service to update the revision on the backend
        this.contractsService.updateRevision(this.contract.contractID, this.contract.revisions[this.currentRevisionNumber].revisionID, this.contract.revisions[this.currentRevisionNumber])
            .subscribe(() => {
                    this.showStatusDialog = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Revision status updated!' });
                },
                (error) => {
                    // Handle error, e.g., show an error message
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update revision status.' });
                });
    }
}
