import { Routes } from '@angular/router';
import {SalesOrderComponent} from "./sales-order/sales-order.component";
import {AddEditSalesOrderComponent} from "./sales-order/add-edit-sales-order/add-edit-sales-order.component";
import {PurchaseOrderComponent} from "./purchase-order/purchase-order.component";
import {AddEditPurchOrderComponent} from "./purchase-order/add-edit-purch-order/add-edit-purch-order.component";
import {ProductComponent} from "./product/product.component";
import {AddEditProductComponent} from "./product/add-edit-product/add-edit-product.component";

export const orderRoutes: Routes = [
    {
        path: 'order/sales-order',
        children: [
            {
                path: '',
                component: SalesOrderComponent,
            },
            {
                path: 'add',
                component: AddEditSalesOrderComponent,
            },
            {
                path: 'edit/:id',
                component: AddEditSalesOrderComponent,
            }
        ]
    },
    {
        path: 'order/purchase-order',
        children: [
            {
                path: '',
                component: PurchaseOrderComponent,
            },
            {
                path: 'add',
                component: AddEditPurchOrderComponent,
            },
            {
                path: 'edit/:id',
                component: AddEditPurchOrderComponent,
            }
        ]
    },
    {
        path: 'order/products',
        children: [
            {
                path: '',
                component: ProductComponent,
            },
            {
                path: 'add',
                component: AddEditProductComponent,
            },
            {
                path: 'edit/:id',
                component: AddEditProductComponent,
            }
        ]
    },
];
