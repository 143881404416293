import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {MessageService} from "primeng/api";

import {companyRoutes} from "./company.routes";
import {CompanyComponent} from "./company.component";
import {CompanyService} from "./company.service";
import {TabViewModule} from "primeng/tabview";
import {CustomerModule} from "../customer/customer.module";
import {TransportationAgencyService} from "./transportation-agency/transportation-agency.service";
import {FreightForwarderService} from "./freight-forwarder/freight-forwarder.service";
import {CompanyFormComponent} from "./company-form/company-form.component";
import {DropdownModule} from "primeng/dropdown";
import {AutoCompleteModule} from "primeng/autocomplete";





@NgModule({
    imports: [
        RouterModule.forChild(companyRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        DropdownModule,
        ImageModule,
        TabViewModule,
        CustomerModule,
        ReactiveFormsModule,
        AutoCompleteModule
    ],
    providers: [
        MessageService,
        CompanyService,
        TransportationAgencyService,
        FreightForwarderService
    ],
    declarations: [
        CompanyComponent,
        CompanyFormComponent
    ],
    exports: [
        CompanyFormComponent
    ]
})
export class CompanyModule { }
