import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from "@angular/router";
import { SessionStorageService } from "../../services/session-storage.service";
import { AccountsService } from "../accounts.service";
import {DebitNote} from "./debit-note.model";

@Component({
    selector: 'app-debit-note',
    templateUrl: './debit-note.component.html',
    styleUrls: ['./debit-note.component.css']
})
export class DebitNoteComponent implements OnInit {
    breadcrumbItems: MenuItem[] | undefined;
    homeBreadcrumb: MenuItem | undefined;

    debitNotes: DebitNote[] = [];
    isLoading: boolean = false;
    first = 0;
    rows = 20;
    totalRecords = 0;

    constructor(
        private debitNoteService: AccountsService,
        private messageService: MessageService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Debit Note' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.loadData();
    }

    loadData(event?: any) {
        this.isLoading = true;
        this.first = event?.first ?? 0;
        this.rows = event?.rows ?? 20;
        let page = event?.first ?? 0;
        page = page / 20;
        let pageSize = event?.rows ?? 20;
        if (pageSize == 0)
            pageSize = 20;
        this.debitNoteService.getAllDebitNotes(this.sessionStorageService.getCompany()?.id ?? 0, page, pageSize).subscribe({
            next: (data) => {
                this.debitNotes = data.items;
                this.totalRecords = data.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading debit notes'
                });
                this.isLoading = false;
            }
        });
    }

    addDebitNote() {
        this.router.navigate(['/accounts/enterprise/receivables/debit-note/add']);
    }

    editDebitNote(debitNote: DebitNote) {
        this.router.navigate(['/accounts/enterprise/receivables/debit-note/edit', debitNote.debitNoteID]);
    }

    showDate(date: Date | undefined): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString();
    }

    getDebitNoteTotalAmount(debitNote: DebitNote) {
        return 'SAR ' + debitNote.debitNoteLines!.reduce((total, line) => total + line.amount!, 0);
    }
}
