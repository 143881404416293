<p-toast></p-toast>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card full-height-container">
    <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
        <div class="mr-5 flex align-items-center mt-3">
            <i class="fa-solid fa-building mr-2" style="font-size: 1.5rem"></i>
            <div class="font-medium text-xl text-900">AI Chat</div>
        </div>
        <br>
        <div class="flex flex-column lg:flex-row">
            <div class="flex lg:col-5 sm:col-12">
                <div class="ai-chat-promo p-grid p-justify-center p-align-center">
                    <div class="p-col-12 p-md-8 p-lg-6">
                        <img src="assets/ai-chat-graphic.jpg" alt="AI Chat" class="ai-chat-image" style="max-width: 50%;"><br>
                        <h2 class="p-text-center">Introducing Our AI Chat Assistant!</h2>
                        <p class="p-text-center">
                            Get instant support and streamline your logistics operations with our intelligent AI Chat.
                            Here's how it can help you:
                        </p>
                        <ul class="ai-skillset p-list-unstyled">
                            <li><i class="pi pi-check-circle"></i> Create Shipment Requests effortlessly</li>
                            <li><i class="pi pi-check-circle"></i> Obtain instant Price Quotations</li>
                            <li><i class="pi pi-check-circle"></i> Check Invoice and Receipt Balances</li>
                            <li><i class="pi pi-check-circle"></i> And much more! </li>
                        </ul><br>
                        <div class="p-text-center">
                            <p-button label="Chat Now" icon="pi pi-comments"></p-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex lg:col-7 sm:col-12 full-height-container">
                <div class="chat-messages bg-white border-round p-2 mb-2" #chatMessages>
                    <div *ngFor="let message of messages" [class.user-message]="message.sender === 'user'"
                         [class.other-message]="message.sender !== 'user'">
                        <div class="message-sender">{{ message.sender }}</div>
                        <div class="message-content">{{ message.content }}</div>
                        <div class="message-timestamp">{{ message.timestamp | date:'shortTime' }}</div>
                    </div>
                </div>
                <p-progressBar mode="indeterminate" class="mb-2" *ngIf="isLoading"></p-progressBar>
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="fa-regular fa-message"></i></span>
                    <input type="text" pInputText [(ngModel)]="newMessage"
                           placeholder="Type your message..." (keyup.enter)="sendMessage()" />
                    <button pButton type="button" label="Send" (click)="sendMessage()"></button>
                </div>
            </div>
        </div>
    </div>
</div>
