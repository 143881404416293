/* eslint-disable */

import { UserRole } from "../common/user-role";

export enum JobStatus {
  JOB_DETAILS_ENTRY = "JOB_DETAILS_ENTRY",
  SEARCHING_VEHICLE = "SEARCHING_VEHICLE",
  SELECTING_VEHICLE = "SELECTING_VEHICLE",
  JOB_INVOKE_DRIVER = "JOB_INVOKE_DRIVER",
  JOB_DRIVER_NEGOTIATING = "JOB_DRIVER_NEGOTIATING",
  JOB_DRIVER_ACCEPTED = "JOB_DRIVER_ACCEPTED",
  DRIVER_ORDER_PROCESSING = "DRIVER_ORDER_PROCESSING",
  DRIVER_REACHED_PICKUP = "DRIVER_REACHED_PICKUP",
  DRIVER_DOCUMENT_VERIFICATION = "DRIVER_DOCUMENT_VERIFICATION",
  JOB_PAYLOAD_LOADING = "JOB_PAYLOAD_LOADING",
  PAYLOAD_QUANTITY_VERIFICATION = "PAYLOAD_QUANTITY_VERIFICATION",
  PAYLOAD_CONDITION_CHECK = "PAYLOAD_CONDITION_CHECK",
  DELIVERY_DOCUMENTS_UPLOAD = "DELIVERY_DOCUMENTS_UPLOAD",
  PAYLOAD_SEALING_PROCESS = "PAYLOAD_SEALING_PROCESS",
  DRIVER_ORDER_SIGNOFF = "DRIVER_ORDER_SIGNOFF",
  DRIVER_STARTED_TRIP = "DRIVER_STARTED_TRIP",
  DRIVER_EN_ROUTE = "DRIVER_EN_ROUTE",
  DRIVER_DELAY_ON_TRIP = "DRIVER_DELAY_ON_TRIP",
  DRIVER_REACHED_DROPOFF = "DRIVER_REACHED_DROPOFF",
  RECIPIENT_VERIFICATION_PROCESS = "RECIPIENT_VERIFICATION_PROCESS",
  JOB_PAYLOAD_UNLOADING = "JOB_PAYLOAD_UNLOADING",
  PAYLOAD_SEALING_CHECK = "PAYLOAD_SEALING_CHECK",
  PAYLOAD_QUANTITY_CHECK = "PAYLOAD_QUANTITY_CHECK",
  PAYLOAD_CONDITION_RECHECK = "PAYLOAD_CONDITION_RECHECK",
  JOB_COMPLETION_ACKNOWLEDGE = "JOB_COMPLETION_ACKNOWLEDGE",
  DRIVER_JOB_COMPLETED = "DRIVER_JOB_COMPLETED",
  JOB_DRIVER_REJECTED = "JOB_DRIVER_REJECTED",
  JOB_DRIVER_CANCELLED = "JOB_DRIVER_CANCELLED",
  JOB_ADVERTISE_INVOKE = "JOB_ADVERTISE_INVOKE",
}

export interface JobStatusInfo {
  position: number;
  chargeableFor: UserRole;
  acceptableDelay: number;
}

export const jobStatusInfoMap: Record<JobStatus, JobStatusInfo> = {
  [JobStatus.JOB_DETAILS_ENTRY]: {
    position: 1,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.SEARCHING_VEHICLE]: {
    position: 2,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.SELECTING_VEHICLE]: {
    position: 3,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.JOB_INVOKE_DRIVER]: {
    position: 4,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.JOB_DRIVER_NEGOTIATING]: {
    position: 5,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.JOB_DRIVER_ACCEPTED]: {
    position: 6,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.DRIVER_ORDER_PROCESSING]: {
    position: 7,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.DRIVER_REACHED_PICKUP]: {
    position: 8,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
  [JobStatus.DRIVER_DOCUMENT_VERIFICATION]: {
    position: 9,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 5,
  },
  [JobStatus.JOB_PAYLOAD_LOADING]: {
    position: 10,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: Number.MAX_VALUE,
  },
  [JobStatus.PAYLOAD_QUANTITY_VERIFICATION]: {
    position: 11,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 10,
  },
  [JobStatus.PAYLOAD_CONDITION_CHECK]: {
    position: 12,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 10,
  },
  [JobStatus.DELIVERY_DOCUMENTS_UPLOAD]: {
    position: 13,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 5,
  },
  [JobStatus.PAYLOAD_SEALING_PROCESS]: {
    position: 14,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 10,
  },
  [JobStatus.DRIVER_ORDER_SIGNOFF]: {
    position: 15,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 5,
  },
  [JobStatus.DRIVER_STARTED_TRIP]: {
    position: 16,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 5,
  },
  [JobStatus.DRIVER_EN_ROUTE]: {
    position: 17,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.DRIVER_DELAY_ON_TRIP]: {
    position: 18,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 60,
  },
  [JobStatus.DRIVER_REACHED_DROPOFF]: {
    position: 19,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.RECIPIENT_VERIFICATION_PROCESS]: {
    position: 20,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 5,
  },
  [JobStatus.JOB_PAYLOAD_UNLOADING]: {
    position: 21,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: Number.MAX_VALUE,
  },
  [JobStatus.PAYLOAD_SEALING_CHECK]: {
    position: 22,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 10,
  },
  [JobStatus.PAYLOAD_QUANTITY_CHECK]: {
    position: 23,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 15,
  },
  [JobStatus.PAYLOAD_CONDITION_RECHECK]: {
    position: 24,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 10,
  },
  [JobStatus.JOB_COMPLETION_ACKNOWLEDGE]: {
    position: 25,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 5,
  },
  [JobStatus.DRIVER_JOB_COMPLETED]: {
    position: 26,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 5,
  },
  [JobStatus.JOB_DRIVER_REJECTED]: {
    position: 27,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.JOB_DRIVER_CANCELLED]: {
    position: 28,
    chargeableFor: UserRole.DRIVER,
    acceptableDelay: 0,
  },
  [JobStatus.JOB_ADVERTISE_INVOKE]: {
    position: 29,
    chargeableFor: UserRole.CUSTOMER,
    acceptableDelay: 0,
  },
};

export const jobStatusValues = Object.values(JobStatus);

export const jobStatusKeys = Object.keys(JobStatus);

export const jobStatusMap = jobStatusValues.reduce((acc, status) => {
  acc[status] = status;
  return acc;
}, {} as Record<JobStatus, JobStatus>);

export const isJobStatus = (status: string): status is JobStatus =>
  jobStatusValues.includes(status as JobStatus);

export const getJobStatusInfo = (status: JobStatus): JobStatusInfo =>
  jobStatusInfoMap[status];

export const getJobStatusPosition = (status: JobStatus): number =>
  getJobStatusInfo(status).position;

export const getJobStatusChargeableFor = (status: JobStatus): UserRole =>
  getJobStatusInfo(status).chargeableFor;

export const getJobStatusAcceptableDelay = (status: JobStatus): number =>
  getJobStatusInfo(status).acceptableDelay;

export const isJobStatusAtLeast = (
  status1: JobStatus,
  status2: JobStatus
): boolean => getJobStatusPosition(status1) >= getJobStatusPosition(status2);

export const isJobStatusAtMost = (
  status1: JobStatus,
  status2: JobStatus
): boolean => getJobStatusPosition(status1) <= getJobStatusPosition(status2);
