import * as XLSX from 'xlsx';
import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AccountsService } from "../accounts.service";
import { SessionStorageService } from "../../services/session-storage.service";
import {TabViewChangeEvent } from "primeng/tabview";
import {AccountPayables} from "./payables.model";
import {ClientPayablesSummary} from "./client-payables-summary.model";
import {AccountPayablesLine} from "./payables-line.model";

@Component({
    selector: 'app-payables',
    templateUrl: './payables.component.html',
    styleUrls: ['./payables.component.css']
})
export class PayablesComponent implements OnInit {

    clientPayablesSummaryList: ClientPayablesSummary[] = [];
    isLoading = false;
    first = 0;
    rows = 20;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private sessionStorageService: SessionStorageService
    ) { }


    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Accounts' }, { label: 'Enterprise' }, { label: 'Payables' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
        this.loadSummaryData(); // Initial load
    }

    loadSummaryData() {
        this.isLoading = true;
        this.accountsService.getClientPayablesSummary(this.sessionStorageService.getCompany()!!.id).subscribe(value => {
            this.clientPayablesSummaryList = value;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        })
    }


    loadData(event: any, clientPayablesSummary: ClientPayablesSummary) {
        this.isLoading = true;
        this.first = event.first;
        this.rows = event.rows;
        // Call your service to fetch payables data with pagination
        // Example (replace with your actual service call):
        this.accountsService.getAccountPayables(this.first, this.rows,
            this.sessionStorageService.getCompany()!!.id, clientPayablesSummary.clientId, clientPayablesSummary.contactId).subscribe((dataWrapper) => {
            console.log(dataWrapper);
            clientPayablesSummary.payables = dataWrapper.items// Simulate fetching data
            clientPayablesSummary.totalPayables = dataWrapper.count; // Set total records for pagination
            this.isLoading = false;
        });
    }



    showDate(date: Date): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString(); // Example
    }

    exportToExcel(clientPayablesSummary: ClientPayablesSummary) {
        const worksheet = XLSX.utils.json_to_sheet(clientPayablesSummary.payables);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'cargomate_payables_export_' + new Date().toISOString() + '.xlsx';
        // Create a blob from the workbook
        const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target = '_blank';
        a.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
    }



    viewDetails(payables: AccountPayables) {
        // Implement logic to navigate to payables details page or show a dialog, etc.
        console.log('View details for payables:', payables);
    }

    onClientPayablesTabChange($event: TabViewChangeEvent) {

    }

    editPayablesLine(payablesLine: AccountPayablesLine) {

    }

    generateBill(clientPayablesSummary: ClientPayablesSummary) {
        if (!clientPayablesSummary.selectedPayables || clientPayablesSummary.selectedPayables.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one payable' });
            return;
        }
        if (clientPayablesSummary.selectedPayables.some(value => value.status == 'BILLED')) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One or more payables are already billed' });
            return;
        }
        this.isLoading = true;
        this.accountsService.generateBillFromAccountPayables(clientPayablesSummary.selectedPayables).subscribe(bill => {
                console.log(bill);
                this.isLoading = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bill generated successfully' });
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                /*this.accountsService.generateBillPDF(bill.owningCompany!.id, bill.billId!, bill.odooBillNumber!,
                    bill.billLines.map(billLine => billLine.headerReferenceNumber!)).subscribe(success => {
                    clientPayablesSummary.selectedPayables.forEach(value => {
                        value.status = 'BILLED';
                        value.odooBillNumber = bill.odooBillNumber;
                        value.billingDate = bill.billCreationDate!;
                        value.odooBillId = bill.odooBillId;
                    })
                    this.isLoading = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bill generated successfully' });
                }, error => {
                    this.isLoading = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error generating bill PDF:' + JSON.stringify(error) });
                })*/
            },
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating bill:' + JSON.stringify(error) });
            });
    }
}
