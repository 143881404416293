<p-toast position='bottom-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="col-12 flex overflow-hidden">
    <div class="flex-none flex align-items-center justify-content-center">
        <h1>Partner Ledger</h1>
    </div>
</div>

<div class="col-12 flex overflow-hidden">
    <div class="flex-none flex">
        <div class="pl-2 pr-4">
            <p class="text-xl text-blue-600 pb-2 m-0">Partners</p>
            <p-tree [value]="partnersTree" selectionMode="checkbox" class="w-full"
                    [filter]="true" filterPlaceholder="Search Partners"
                    (onNodeSelect)="updateSelectEvents($event)"
                    (onNodeUnselect)="updateUnSelectEvents($event)"/>
        </div>
    </div>
    <div class="flex-grow-1 flex overflow-auto">
        <p-accordion *ngIf="selectedPartnerLedgerSummaries.length > 0" class="w-full">
            <p-accordionTab *ngFor="let partnerLedgerSummary of selectedPartnerLedgerSummaries">
                <ng-template pTemplate="header">
                    <div class="grid" style="width: 100%;">
                        <div class="col-12 flex overflow-hidden">
                            <div class="flex-none flex align-items-center justify-content-center pr-1">
                                <h4>{{ partnerLedgerSummary.partnerName }}</h4>
                                <h4>&nbsp;&nbsp;</h4>
                                <h4>({{ partnerLedgerSummary.totalRowCount }})</h4>
                            </div>
                            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                            <div class="flex-none flex align-items-center">
                                <div class="pr-4">
                                    <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                        class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ partnerLedgerSummary.totalCredit }}</p>
                                    <p class="text-l text-right text-green-200 p-0 m-0">Credit</p>
                                </div>
                            </div>
                            <div class="flex-none flex align-items-center">
                                <div class="pr-4">
                                    <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                        class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ partnerLedgerSummary.totalDebit }}</p>
                                    <p class="text-l text-right text-red-200 p-0 m-0">Debit</p>
                                </div>
                            </div>
                            <div class="flex-none flex align-items-center">
                                <div class="pr-4">
                                    <p class="text-2xl text-right text-blue-400 p-0 m-0"><i
                                        class="fa-solid fa-scale-balanced"></i>&nbsp;&nbsp;{{ partnerLedgerSummary.totalBalance }}</p>
                                    <p class="text-l text-right text-blue-200 p-0 m-0">Balance</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <p-table [value]="partnerLedgerSummary.partnerLedgers" dataKey="accountId" [resizableColumns]="true"
                         styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
                         [lazy]="true" [totalRecords]="partnerLedgerSummary.partnerLedgers.length"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                         [rowsPerPageOptions]="[20, 50, 100]">
                    <ng-template pTemplate="caption">
                        <div class="col-12 flex overflow-hidden">
                            <div class="flex-none flex align-items-center justify-content-center">
                                <h3>{{ partnerLedgerSummary.partnerName }}</h3>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Date</th>
                            <th>Transaction #</th>
                            <th>Journal</th>
                            <th>Account</th>
                            <th>Description</th>
                            <th>Reference</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-partnerLedger>
                        <tr>
                            <td>{{ partnerLedger.transactionDate | date:'yyyy-MM-dd' }}</td>
                            <td>{{ partnerLedger.transactionNumber }}</td>
                            <td>{{ partnerLedger.journal }}</td>
                            <td>{{ partnerLedger.accountName }}</td>
                            <td>{{ partnerLedger.description }}</td>
                            <td>{{ partnerLedger.reference }}</td>
                            <td>{{ partnerLedger.debit }}</td>
                            <td>{{ partnerLedger.credit }}</td>
                            <td>{{ partnerLedger.balance }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            In total there are {{partnerLedgerSummary.totalRowCount}} entries.
                        </div>
                    </ng-template>

                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
