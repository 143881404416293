import { Component } from '@angular/core';

@Component({
  selector: 'app-enterprise-subscription',
  templateUrl: './enterprise-subscription.component.html',
  styleUrls: ['./enterprise-subscription.component.css']
})
export class EnterpriseSubscriptionComponent {

}
