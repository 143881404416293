<p-toast position='bottom-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-accordion *ngIf="clientReceivablesSummaryList.length > 0">
    <p-accordionTab *ngFor="let clientReceivablesSummary of clientReceivablesSummaryList; let i = index">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image src="{{ clientReceivablesSummary?.client?.logo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <h4>{{ clientReceivablesSummary.client.name }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ clientReceivablesSummary.totalPendingAmount }}</p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Pending</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ clientReceivablesSummary.totalInvoicedAmount }}</p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Invoiced</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="clientReceivablesSummary.receivables" dataKey="receivableId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="clientReceivablesSummary.totalReceivables" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadData($event, clientReceivablesSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="clientReceivablesSummary.selectedReceivables">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!clientReceivablesSummary.receivables"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Receivables</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Generate Invoice" icon="fa-solid fa-file-invoice-dollar"
                                  (onClick)="generateInvoice(clientReceivablesSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(clientReceivablesSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Ref#</th>
                    <th>Contact</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Invoice#</th>
                    <th>Invoice Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-receivable let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="receivable"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="receivable"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ receivable.receivableId }}</td>
                    <td>{{ receivable.receivableTxnType }}</td>
                    <td>{{ receivable.receivableReferenceNumber }}</td>
                    <td>
                        <p-chip *ngIf="receivable.contact" [label]="receivable.contact.name">
                            <img src="{{ receivable.contact.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ receivable.contact.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>  <!-- Assuming receivable has a companyName property -->
                    <td>{{ receivable.createdAt | date }}</td> <!-- Assuming receivable has a date property -->
                    <td>{{ receivable.country.currencyCode + ' ' + receivable.totalAmount }}</td> <!-- Assuming receivable has currencyCode and amount properties -->
                    <td>{{ receivable.odooInvoiceNumber }}</td>
                    <td>{{ receivable.invoicingDate | date:'yyyy-MM-dd' }}</td>
                    <td>
                        <p-tag *ngIf="receivable.status === 'INVOICED'" icon="fa-solid fa-check" severity="success" value="INVOICED"></p-tag>
                        <p-tag *ngIf="receivable.status === 'NEW'" icon="fa-solid fa-circle-exclamation" severity="danger" value="NEW"></p-tag>
                    </td>
                    <td>
                <span class="p-buttonset">
                    <button pButton type="button" pTooltip="View Details" icon="pi pi-eye" (click)="viewDetails(receivable)"
                            class="p-button-rounded p-button-text p-button-help mr-2"></button>
                </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-receivable>
                <tr>
                    <td colspan="12">
                        <div class="p-3">
                            <p-table [value]="receivable.accountReceivablesLines" dataKey="receivableLineId" [resizableColumns]="true"
                                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-10">
                                            <h2>Receivable Lines</h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S#</th>
                                        <th>Header#</th>
                                        <th>Line#</th>
                                        <th>Service Type</th>
                                        <th>Charge Amount</th>
                                        <th>Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-receivableLine>
                                    <tr>
                                        <td>{{ receivableLine.receivableLineId }}</td>
                                        <td>{{ receivable.receivableReferenceNumber }}</td>
                                        <td>{{ receivableLine.receivableLineRefNumber }}</td>
                                        <td>{{ receivableLine.receivablesLineType }}</td>
                                        <td>{{ receivable.country.currencyCode + ' ' + receivableLine.amount }}</td>
                                        <td>
                  <span class="p-buttonset">
                      <button pButton type="button" icon="fa fa-pencil" (click)="editReceivableLine(receivableLine)"
                              class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                  </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{clientReceivablesSummary.totalReceivables}} receivables.
                </div>
            </ng-template>

        </p-table>
    </p-accordionTab>
</p-accordion>
