<p-toast position='bottom-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-treeTable [value]="chartOfAccountsTree" dataKey="accountId" styleClass="p-treetable-gridlines"
             [tableStyle]="{ 'min-width': '60rem' }">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Chart Of Accounts</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr >
            <th pFrozenColumn>Account #</th>
            <th pFrozenColumn>Account Name</th>
            <th>Account Type</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" >
        <tr [ttRow]="rowNode">
            <td pFrozenColumn>
                <p-treeTableToggler [rowNode]="rowNode" ></p-treeTableToggler>
                {{ rowData.accountCode }}
            </td>
            <td pFrozenColumn>{{ rowData.accountName }}</td>
            <td>{{ rowData.accountType.accountTypeName }}</td>
            <td>
                <button pButton type="button" icon="pi pi-plus" (click)="addChildChartOfAccounts(rowData)"
                        pTooltip="Add Sub-Account" tooltipPosition="top"
                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                <button *ngIf="rowData.allowEditing" [disabled]="!rowData.allowEditing" pButton type="button" icon="pi pi-pencil"
                        (click)="editChartOfAccounts(rowData)"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button *ngIf="rowData.allowEditing" [disabled]="!rowData.allowEditing" pButton type="button" icon="pi pi-trash"
                        (click)="deleteChartOfAccounts(rowData)"
                        class="p-button-rounded p-button-text p-button-danger mr-2"></button>
            </td>
        </tr>
    </ng-template>
</p-treeTable>
