import {JournalItem} from "./journal-entry-detail.model";
import {Journal} from "./journal.model";

export class JournalEntry {

    entryId: number;
    entryNumber: string;
    journal: Journal;
    entryDate: Date; // or Date if you prefer
    reference: string;
    partnerId: number;
    partnerName: string;
    owningCompanyId: number;
    currencyCode: string;
    amount: number;
    state: string;
    partnerType: string;
    items: JournalItem[];

}
