export class DeliveryRateCard {
  rateId: number;
  sourceCity: string;
  sourceCountry: string;
  destinationCity: string;
  destinationCountry: string;
  vehicleConfigId: number;
  vehicleTypeId: number;
  vehicleCapacityId: number;
  distance: number;
  amount: number;
  goodsInsurance: boolean;
  portPermit: boolean;
  waitingThreshold: number;
  waitingCharge: number;
  buyingCompanyId: number;
  sellingCompanyId: number;
  contractRevisionId: number;
}
