import { Component, OnInit } from '@angular/core';
import { VehicleService } from './vehicle.service';
import { Vehicle } from './vehicle.model';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import {TableLazyLoadEvent} from "primeng/table";
import {Driver} from "../driver/driver.model";

@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

    isLoading: boolean = false;
    currentTab = '';
    page = 0;
    pageSize = 10;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    ownedVehicles: Vehicle[];
    totalOwnedRecords: number;
    ownedFirst = 0;
    ownedRows = 10;
    freelancerVehicles: Vehicle[];
    freelancerFirst = 0;
    totalFreelancerRecords: number;
    freelancerRows = 10;

    showAddFreelancerVehicleDialog: boolean = false;
    vehicleRegistrationNumber: string = '';
    searchVehicleInProgress: boolean = false;


    constructor(
        private vehicleService: VehicleService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Fleet', routerLink: '/fleet' }, { label: 'Vehicles' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    editVehicle(vehicle: Vehicle) {
        this.router.navigate(['/fleet/vehicle/edit', vehicle.vehicleId]);
    }

    deleteVehicle(vehicle: Vehicle) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this vehicle?',
            header: 'Confirm Delete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.vehicleService.deleteVehicle(vehicle).subscribe(response => {
                    if (response) {
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Vehicle deleted successfully.' });
                        if (this.currentTab === 'owned')
                            this.loadOwnedVehicles();
                        else this.loadFreelancerVehicles();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete vehicle.' });
                    }
                });
            }
        });
    }

    addOwnVehicle() {
        this.router.navigate(['/fleet/vehicle/create']);
    }

    addFreelancerVehicle() {
        this.showAddFreelancerVehicleDialog = true;
    }

    getPayloadTypesString(vehicle: Vehicle) {
        return vehicle.payloadTypes.map(payloadType => payloadType.name).join(', ');
    }

    getTripTypesString(vehicle: Vehicle) {
        return vehicle.tripTypes.map(tripType => tripType.name).join(', ');
    }

    loadOwnedData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.ownedFirst = event.first ?? 0;
        this.ownedRows = event.rows ?? 10;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 10;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadOwnedVehicles();
    }

    loadOwnedVehicles() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.vehicleService.findVehiclesByCompanyId(this.page, this.pageSize, companyId)
                .subscribe(response => {
                    console.log(response);
                    this.ownedVehicles = response.items;
                    this.totalOwnedRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Company ID not found.' });
        }
    }

    loadFreelancerData(event: TableLazyLoadEvent) {
        this.isLoading = true;
        this.freelancerFirst = event.first ?? 0;
        this.freelancerRows = event.rows ?? 10;
        this.page = event.first ?? 0;
        this.page = this.page / 10;
        this.pageSize = event.rows ?? 10;
        if (this.pageSize == 0)
            this.pageSize = 10;
        this.loadFreelancerVehicles();
    }

    loadFreelancerVehicles() {
        const companyId = this.sessionStorageService.getCompany()?.id;
        if (companyId) {
            this.vehicleService.getFreelanceVehiclesByCompanyId(this.page, this.pageSize, companyId)
                .subscribe(response => {
                    console.log(response);
                    this.freelancerVehicles = response.items;
                    this.totalFreelancerRecords = response.count;
                    this.isLoading = false;
                });
        } else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Company ID not found.' });
        }
    }

    tabChange($event: Event) {
        this.currentTab = ($event as CustomEvent).detail.index === 0 ? 'owned' : 'freelancer';
    }

    searchVehicle() {
        this.searchVehicleInProgress = true;
        this.vehicleService.findVehicleByQuery('en-US', this.vehicleRegistrationNumber, undefined)
            .subscribe(vehicleWrapped => {
                console.log(vehicleWrapped);
                if (vehicleWrapped.success && vehicleWrapped.data) {
                    // Driver found, open vehicle creation with driver info
                    this.addFreelanceVehicle(vehicleWrapped.data);
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Vehicle not found.' });
                    this.searchVehicleInProgress = false;
                    this.showAddFreelancerVehicleDialog = false;
                    this.router.navigate(['/fleet/vehicle/create'], {
                        queryParams: {
                            registrationNumber: this.vehicleRegistrationNumber, // Pass driver phone number
                            isFreelancer: true // Indicate freelancer vehicle
                        }
                    });
                }
            });
    }

    addFreelanceVehicle(vehicle: Vehicle) {
        // Call your driverService to add the vehicle as a freelancer
        this.vehicleService.addFreelancerVehicle(vehicle, this.sessionStorageService.getCompany()!.id) // Assuming you have an API for this
            .subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Vehicle added as freelancer successfully.'
                });
                this.showAddFreelancerVehicleDialog = false;
                this.searchVehicleInProgress = false;
                this.loadFreelancerVehicles(); // Refresh the freelancer vehicle list
            }, error => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to add vehicle as freelancer.'
                });
            });
    }


}
