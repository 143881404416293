import { Component, OnInit } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { SessionStorageService } from "../../../services/session-storage.service";
import { Router } from "@angular/router";
import { Bill } from "./bill.model";
import { BillLine } from "./bill-line.model";
import { AccountsService } from "../../accounts.service";
import {GeneratedBillsSummary} from "./generated-bills-summary.model";
import {LazyLoadEvent} from "primeng/api";
import {TableLazyLoadEvent} from "primeng/table";
import {GeneratedInvoicesSummary} from "../../receivables/generated-invoices/generated-invoices-summary.model";
import * as XLSX from "xlsx";

@Component({
    selector: 'app-generated-bills',
    templateUrl: './generated-bills.component.html',
    styleUrls: ['./generated-bills.component.css']
})
export class GeneratedBillsComponent implements OnInit {

    generatedBillsSummaryList: GeneratedBillsSummary[] = [];


    isLoading: boolean = false;
    first = 0;
    rows = 20;
    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem;

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    }

    ngOnInit(): void {
        this.breadcrumbItems = [{ label: 'Payables' }, { label: 'Generated Bills' }];
        this.loadBillsSummary();
    }

    loadBillsSummary() {
        this.isLoading = true;
        this.accountsService.getGeneratedBillsSummary(this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (billsSummaryList) => {
                this.generatedBillsSummaryList = billsSummaryList;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error loading bills' });
                this.isLoading = false;
            }
        });
    }

    loadBills(event: any, generatedBillsSummary: GeneratedBillsSummary) {
        this.isLoading = true;
        this.first = event.first;
        this.rows = event.rows;
        this.accountsService.getBills(this.first, this.rows, generatedBillsSummary.clientId,
            generatedBillsSummary.contactId, this.sessionStorageService.getCompany()?.id ?? 0).subscribe({
            next: (billsWrapper) => {
                generatedBillsSummary.bills = billsWrapper.items;
                generatedBillsSummary.totalBills = billsWrapper.count;
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error loading bills' });
                this.isLoading = false;
            }
        });
    }

    viewBill(bill: Bill) {

    }

    generateVoucher(generatedBillsSummary: GeneratedBillsSummary) {
        if (!generatedBillsSummary.selectedBills || generatedBillsSummary.selectedBills.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one bill' });
            return;
        }
        if (generatedBillsSummary.selectedBills.some(value => value.vouchers.length > 0)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One or more bills are already paid' });
            return;
        }
        this.isLoading = true;
        this.accountsService.generateVoucherFromBills(generatedBillsSummary.selectedBills).subscribe(voucher => {
                console.log(voucher);
                this.isLoading = false;
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Voucher# ' + voucher.odooVoucherNumber + ' generated successfully' });
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                /* this.accountsService.generateInvoicePDF(voucher.owningCompany!.id, voucher.invoiceId!, voucher.odooInvoiceNumber!,
                     voucher.invoiceLines.map(invoiceLine => invoiceLine.headerReferenceNumber!)).subscribe(success => {
                     generatedInvoicesSummary.selectedInvoices.forEach(value => {
                         value.status = 'INVOICED';
                         value.odooInvoiceNumber = voucher.odooInvoiceNumber;
                         value.invoiceCreationDate = voucher.invoiceCreationDate!;
                         value.odooInvoiceId = voucher.odooInvoiceId;
                     })
                     this.isLoading = false;
                     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invoice generated successfully' });
                 }, error => {
                     this.isLoading = false;
                     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error generating voucher PDF:' + JSON.stringify(error) });
                 })*/
            },
            error => {
                console.log(error);
                this.isLoading = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating voucher:' + JSON.stringify(error) });
            });
    }

    editBillLine(billLine: BillLine) {

    }

    exportToExcel(generatedBillsSummary: GeneratedBillsSummary) {
        const worksheet = XLSX.utils.json_to_sheet(generatedBillsSummary.bills);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'cargomate_generated_bills_export_' + new Date().toISOString() + '.xlsx';
        // Create a blob from the workbook
        const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target = '_blank';
        a.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
    }

    downloadBill(bill: any) {

    }
}
