<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-12 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0">{{company.name}}</p>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-12 lg:col-12">
        <div class="grid bg-red-100 text-gray-900 border-round-md ml-3 mr-1">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Company Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ company?.logo != null ? company?.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                 height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;ID:&nbsp;&nbsp;<b>{{
                                company?.id }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;CR Number:&nbsp;&nbsp;<b>{{
                                company?.crNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Phone:&nbsp;&nbsp;<b>{{
                                company?.phoneNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                            href="https://maps.google.com?q={{ company?.location?.latitude }},{{ company?.location?.longitude }}">{{companySubLocality}},
                            {{companyLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-12">
        <p-tabView>
            <p-tabPanel header="Company Details">
            </p-tabPanel>
            <p-tabPanel header="Employees">
                <ng-template pTemplate="content">
                    <div class="mb-3">
                        <button pButton type="button" label="New Employee"
                                icon="pi pi-plus" (click)="createNewCustomer()"></button>
                    </div>
                    <p-table [value]="customers" dataKey="id" [resizableColumns]="true"
                             styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Roles</th>
                                <th>Actions</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-customer>
                            <tr>
                                <td>{{ customer.id }}</td>
                                <td>
                                    <p-chip [label]="customer.name">
                                        <img src="{{ customer.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                             alt="{{ customer.name }}"
                                             style="width: 32px; height: 32px; border-radius: 50%;">
                                    </p-chip>
                                </td>
                                <td>{{ customer.emailAddress.includes('@abcxyz.com') ? '' : customer.emailAddress }}</td>
                                <td>{{ customer.phoneNumber }}</td>
                                <td>
                                    <ul>
                                        <li *ngFor="let role of customer.roles">{{ role.roleName }}</li>
                                    </ul>
                                </td>
                                <td>
                                    <button pButton pTooltip="Send Email" type="button" icon="pi pi-envelope"
                                            (click)="sendEmail(customer.emailAddress)"
                                            [disabled]="customer.emailAddress.includes('@abcxyz.com')"
                                            class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                                    <button pButton pTooltip="Call" type="button" icon="pi pi-phone"
                                            (click)="makePhoneCall(customer.phoneNumber)"
                                            class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                    <button pButton pTooltip="Manage Roles" type="button" icon="fa-solid fa-hand-sparkles"
                                            (click)="manageRoles(customer)"
                                            class="p-button-rounded p-button-text p-button-help mr-2"></button>
                                    <button pButton pTooltip="Edit" type="button" icon="pi pi-pencil"
                                            (click)="editCustomer(customer)"
                                            class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                                    <button pButton pTooltip="Delete" type="button" icon="pi pi-trash"
                                            (click)="deleteCustomer(customer)"
                                            class="p-button-rounded p-button-text p-button-danger"></button>

                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Other Details">
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<app-customer-form
    [displayModal]="showCustomerForm"
    [dialogHeader]="customerFormHeader"
    [submitButtonLabel]="customerFormSubmitLabel"
    [customer]="selectedCustomer"
    [company]="selectedCompany"
    (modalClosed)="onCustomerFormClosed()">
</app-customer-form>

<app-customer-roles
    [displayModal]="showCustomerRolesDialog"
    [dialogHeader]="'Manage Roles for ' + selectedCustomer.name"
    [customerId]="selectedCustomer.id"
    (modalClosed)="onCustomerRolesDialogClosed()">
</app-customer-roles>
