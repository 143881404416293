import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItem, MessageService, SelectItem } from "primeng/api";
import { tap, forkJoin } from 'rxjs';
import {Company} from "../../../company/company.model";
import {Customer} from "../../../customer/customer.model";
import {PaymentTerm} from "../../models/payment-term.model";
import {Products} from "../../models/product.model";
import {OrdersService} from "../../order.service";
import {CustomerService} from "../../../customer/customer.service";
import {ContractsService} from "../../../contracts/contracts.service";
import {SessionStorageService} from "../../../services/session-storage.service";
import {PurchaseOrderLine} from "../purchase-order-line.model";
import {PurchaseOrder} from "../purchase-order.model";
import {ProductsService} from "../../product.service";


@Component({
    selector: 'app-add-edit-purch-order',
    templateUrl: './add-edit-purch-order.component.html',
    styleUrls: ['./add-edit-purch-order.component.css']
})
export class AddEditPurchOrderComponent implements OnInit {

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    purchaseOrderForm!: FormGroup;
    editMode = false;
    purchaseOrderId!: number;
    isLoading: boolean = false;

    companies: SelectItem<Company>[] = [];
    vendors: SelectItem<Customer>[] = [];
    paymentTerms: SelectItem<PaymentTerm>[] = [];
    products: SelectItem<Products>[] = [];


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private productsService: ProductsService,
        private purchaseOrderService: OrdersService,
        private customerService: CustomerService,
        private contractService: ContractsService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Orders', routerLink: '/orders' },
            { label: 'Purchase Order', routerLink: '/orders/purchase-orders' },
            { label: this.editMode ? 'Edit Purchase Order' : 'Add Purchase Order' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.purchaseOrderId = params['id'];
            this.editMode = !!this.purchaseOrderId;
            this.loadData();
        });

        this.initForm();
    }

    loadData() {
        this.isLoading = true;

        const loadObservables = {
            products: this.loadProducts(),
            paymentTerms: this.loadPaymentTerms(),
            companies: this.loadCompanies(),
        };

        forkJoin(loadObservables)
            .pipe(
                tap(() => {
                    if (this.editMode) {
                        this.loadPurchaseOrder().subscribe();
                    } else {
                        if (this.companies.length > 0) {
                            this.onCompanyChange(this.companies[0].value);
                            this.purchaseOrderForm.patchValue({ company: this.companies[0].value });
                        }
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while loading data.' + error.message
                    });
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    loadProducts() {
        return this.productsService.getAllProductsByOwningCompany(this.sessionStorageService.getCompany()!.id).pipe(
            tap(products => {
                this.products = products.map((product) => ({
                    value: product,
                    label: product.name
                }));
            })
        );
    }

    loadPaymentTerms() {
        return this.purchaseOrderService.getAllPaymentTerms().pipe(
            tap(paymentTerms => {
                this.paymentTerms = paymentTerms.map((paymentTerm) => ({
                    value: paymentTerm,
                    label: paymentTerm.name
                }));
            })
        );
    }

    loadCompanies() {
        return this.contractService.getContractRelatedCompanies(this.sessionStorageService.getCompany()!.id).pipe(
            tap(value => {
                this.companies = value.map(company => ({
                    value: company,
                    label: company.name
                }));
            }),
        );
    }

    onCompanyChange(company: Company) {
        if (company) {
            this.loadVendorsByCompany(company).subscribe();
        } else {
            this.vendors = [];
            this.purchaseOrderForm.patchValue({ vendor: null });
        }
    }

    loadVendorsByCompany(company: Company) {
        return this.customerService.getCustomersByCompany(company.id).pipe(
            tap(customers => {
                this.vendors = customers.map(customer => ({
                    value: customer,
                    label: customer.name
                }));
            })
        );
    }

    loadPurchaseOrder() {
        return this.purchaseOrderService.getPurchaseOrderById(this.purchaseOrderId).pipe(
            tap(purchaseOrder => {
                this.purchaseOrderForm.patchValue(purchaseOrder);
                //this.onCompanyChange(purchaseOrder.company);
                this.purchaseOrderForm.setControl('purchaseOrderLines', this.fb.array(purchaseOrder.purchaseOrderLines
                    .map(line => this.createPurchaseOrderLineFormGroup(line))));
            })
        );
    }

    initForm() {
        this.purchaseOrderForm = this.fb.group({
            orderNumber: [''],
            company: [null, Validators.required],
            vendor: [null, Validators.required],
            paymentTerm: [null, Validators.required],
            orderDate: [new Date(), Validators.required],
            expectedDate: [new Date(), Validators.required],
            purchaseOrderLines: this.fb.array([this.createPurchaseOrderLineFormGroup()]),
        });
    }

    createPurchaseOrderLineFormGroup(purchaseOrderLine?: PurchaseOrderLine): FormGroup {
        return this.fb.group({
            product: [purchaseOrderLine?.product ?? null, Validators.required],
            variant: [purchaseOrderLine?.variant ?? null],
            description: [purchaseOrderLine?.description ?? ''],
            quantity: [purchaseOrderLine?.quantity ?? 1, [Validators.required, Validators.min(1)]],
            unitPrice: [purchaseOrderLine?.unitPrice ?? 0, [Validators.required, Validators.min(0)]],
        });
    }

    get getPurchaseOrderLineControls(): FormArray {
        return this.purchaseOrderForm.get('purchaseOrderLines') as FormArray;
    }

    addPurchaseOrderLine() {
        this.getPurchaseOrderLineControls.push(this.createPurchaseOrderLineFormGroup());
    }

    removePurchaseOrderLine(index: number) {
        this.getPurchaseOrderLineControls.removeAt(index);
    }

    savePurchaseOrder() {
        if (this.purchaseOrderForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const purchaseOrder = this.purchaseOrderForm.value as PurchaseOrder;
        purchaseOrder.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        purchaseOrder.state = 'draft';
        if (this.editMode) {
            purchaseOrder.purchaseOrderID = this.purchaseOrderId;
        }
        this.purchaseOrderService.createPurchaseOrder(purchaseOrder).subscribe({
            next: (newPurchaseOrder) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Purchase order saved successfully.'
                });
                this.router.navigate(['/orders/purchase-orders']);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving purchase order'
                });
            }
        })
    }

    cancel() {
        this.location.back();
    }
}
