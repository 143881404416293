/* eslint-disable */
import { GeoElement } from "./geo-element.model";

export class GeoAddress {

    addressElements: GeoElement[] = [];

    public getLocality(): string | undefined {
        return this.getAddressComponent('locality');
    }

    public getSubLocality(): string | undefined {
        return this.getAddressComponent('sublocality');
    }

    public getAdminLevel1() {
        return this.getAddressComponent('administrative_area_level_1');
    }

    private getAddressComponent(component: string): string | undefined {
        let componentElement: GeoElement | undefined = this.addressElements
            ?.filter(geoElement => geoElement.types
                ?.find(addressComponentType => addressComponentType === component)).pop();
        return componentElement?.name;
    }

    public getGeoAddressFromObject(addressObject: any) {
        // Create a new GeoAddress instance
        const geoAddress = new GeoAddress();

        // Convert the object's addressElements to GeoElement instances
        geoAddress.addressElements = addressObject.addressElements.map((addressElement: { types: string[] | undefined; name: string | undefined; }) => {
            const geoElement = new GeoElement();
            geoElement.types = addressElement.types;
            geoElement.name = addressElement.name;
            return geoElement;
        });

        return geoAddress;
    }
}

