<p-toast></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-6 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0">Contract# {{contract.contractNumber}}</p>
        <p-tag icon="pi pi-file-contract" value="{{contract.contractStatus}}"></p-tag>
    </div>
    <div class="col-6 pt-4 pr-4">
        <p class="text-2xl text-right text-red-400 p-0 m-0"><i
            class="fa-solid fa-sack-dollar"></i>&nbsp;&nbsp;{{contract.revisions[0]?.totalAmount}}</p>
        <p class="text-l text-right text-red-200 p-0 m-0">Total Amount</p>
    </div>
</div>

<div *ngIf="!isLoading" class="grid mb-4">
    <div class="col-12 lg:col-6">
        <div class="grid bg-red-100 text-gray-900 border-round-md ml-3 mr-1">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-flag-checkered"></i>&nbsp;&nbsp;First Party Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ contract.firstParty?.logo != null ? contract.firstParty?.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                 height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ contract.firstPartyRep?.imageUrl != null ? contract.firstPartyRep?.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                      shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                contract.firstPartyRep?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                contract.firstPartyRep?.phoneNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                             *ngIf="contract?.firstPartyRep?.emailAddress?.search('@abcxyz.com') == 0">
                            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                contract.firstPartyRep?.emailAddress }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Company:&nbsp;&nbsp;<b>{{
                                contract.firstParty?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                            href="https://maps.google.com?q={{ contract.firstParty?.location?.latitude }},{{ contract.firstParty?.location?.longitude }}">{{pickupSubLocality}},
                            {{pickupLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6">
        <div class="grid bg-green-100 text-gray-900 border-round-md ml-1 mr-3">
            <div class="col-12">
                <div class="flex align-items-center font-bold text-xl pl-3 pt-2">
                    <i class="fa-solid fa-flag"></i>&nbsp;&nbsp;Second Party Info:
                </div>
            </div>
            <div class="col-12">
                <div class="flex">
                    <div class="flex-none align-items-center align-content-center m-3 h-full">
                        <p-image src="{{ contract.secondParty?.logo != null ? contract.secondParty?.logo
                                : 'https://cdn-icons-png.flaticon.com/512/4299/4299051.png' }}"
                                 height="50"></p-image><br>
                        <span></span>
                    </div>
                    <div class="flex-grow-1 grid w-full">
                        <div class="flex align-items-center col-12 p-0 pl-3">
                            <p-avatar image="{{ contract.secondPartyRep?.imageUrl != null ? contract.secondPartyRep?.imageUrl
                                : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png' }}" styleClass="mr-2"
                                      shape="circle" class="p-0 pt-2 pb-1"></p-avatar><b class="text-lg">{{
                                contract.secondPartyRep?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Mobile#:&nbsp;&nbsp;<b>{{
                                contract.secondPartyRep?.phoneNumber }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1"
                             *ngIf="contract?.secondPartyRep?.emailAddress?.search('@abcxyz.com') == 0">
                            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;Email:&nbsp;&nbsp;<b>{{
                                contract.secondPartyRep?.emailAddress }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-1">
                            <i class="fa-solid fa-building"></i>&nbsp;&nbsp;Company:&nbsp;&nbsp;<b>{{
                                contract.secondParty?.name }}</b>
                        </div>
                        <div class="flex align-items-center col-12 p-0 pl-3 pb-3">
                            <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;Address:&nbsp;&nbsp;<a
                            href="https://maps.google.com?q={{ contract.secondParty?.location?.latitude }},{{ contract.secondParty?.location?.longitude }}">{{dropoffSubLocality}},
                            {{dropoffLocality}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isLoading" class="grid bg-yellow-100 text-gray-900 border-round-md ml-3 mr-3 pt-2">
    <div class="col-12 flex overflow-hidden">
        <div class="flex-none align-items-center font-bold text-xl pl-3 pt-2">
            <i class="fa-solid fa-eye-low-vision"></i>&nbsp;&nbsp;Contract Revisions
        </div>
        <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
        <div class="flex-none flex align-items-center justify-content-center pr-3">
            <p-button size="small" label="Add Revision" severity="help" icon="fa-solid fa-add" (onClick)="addRevision()"></p-button>
        </div>
    </div>
    <div class="col-12 p-4 m-1">
        <p-tabView *ngIf="contract.revisions.length > 0" (onChange)="onRevisionTabChange($event)">
            <p-tabPanel *ngFor="let revision of contract.revisions; let i = index">
                <ng-template pTemplate="header">
                    <span>Revision# {{ revision.revisionNumber }}</span>&nbsp;&nbsp;&nbsp;
                    <p-tag *ngIf="revision.status === 'ACTIVE'" icon="pi pi-check" severity="success" value="ACTIVE"></p-tag>
                    <p-tag *ngIf="revision.status === 'DRAFT'" icon="pi pi-user" severity="info" value="DRAFT"></p-tag>
                </ng-template>
                <div class="grid">
                    <div class="col-12 flex overflow-hidden">
                        <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                        <div class="flex-none flex align-items-center justify-content-center pr-1">
                            <p-button size="small" label="Change Status" styleClass="p-button-help" icon="fa-solid fa-pencil" (click)="showEditStatusDialog()"></p-button>
                        </div>
                        <div class="flex-none flex align-items-center justify-content-center pr-2">
                            <p-button size="small" label="Edit Revision" severity="info" icon="fa-solid fa-pencil" (onClick)="editRevision()"></p-button>
                        </div>
                    </div>
                </div>
                <p-tabView>
                    <p-tabPanel header="Revision Details">
                        <div class="grid">
                            <div class="lg:col-6 sm:col-12" *ngFor="let field of revisionFields">
                                <span class="font-bold">{{field.label}}:</span> {{ revision[field.key] }}
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Delivery Rate Cards">
                        <p-table [value]="revision.deliveryRateCards">
                            <ng-template pTemplate="caption">
                                <div class="col-12 flex overflow-hidden">
                                    <div class="flex-none flex align-items-center justify-content-center">
                                        Delivery Rate Cards Table
                                    </div>
                                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                                    <div class="flex-none flex align-items-center justify-content-center">
                                        <p-button label="Add Rate" severity="help" icon="fa-solid fa-add" (onClick)="addRateCard()"></p-button>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>VehicleModel Type</th>
                                    <th>Source City</th>
                                    <th>Source Country</th>
                                    <th>Destination City</th>
                                    <th>Destination Country</th>
                                    <th>Distance</th>
                                    <th>Amount</th>
                                    <th>Goods Insurance</th>
                                    <th>Port Permit</th>
                                    <th>Waiting Charge</th>
                                    <th>Waiting Limit</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rateCard>
                                <tr>
                                    <td>{{ getVehicleConfig(rateCard.vehicleConfigId)?.name }}</td>
                                    <td>{{ rateCard.sourceCity }}</td>
                                    <td>{{ rateCard.sourceCountry }}</td>
                                    <td>{{ rateCard.destinationCity }}</td>
                                    <td>{{ rateCard.destinationCountry }}</td>
                                    <td>{{ rateCard.distance }}</td>
                                    <td>{{ rateCard.amount }}</td>
                                    <td>{{ rateCard.goodsInsurance }}</td>
                                    <td>{{ rateCard.portPermit }}</td>
                                    <td>{{ rateCard.waitingCharge }}</td>
                                    <td>{{ rateCard.waitingLimit }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Contract Files">
                        <p-table [value]="contractFilesList">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>File Name</th>
                                    <th>File Type</th>
                                    <th>Created At</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contractFile>
                                <tr>
                                    <td><a href="{{contractFile.fileUrl}}">{{contractFile.fileName}}</a></td>
                                    <td>{{contractFile.fileType}}</td>
                                    <td>{{showDate(contractFile.createdAt)}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div class="flex align-items-center justify-content-between">
                                    In total there are {{contractFilesCount}} files.
                                </div>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                </p-tabView>
            </p-tabPanel>
        </p-tabView>
        <p *ngIf="contract.revisions.length == 0">No revisions found.</p>
    </div>
</div>

<p-dialog header="Edit Revision Status" [(visible)]="showStatusDialog" [modal]="true" [style]="{ width: '50vw' }">
    <div class="p-field">
        <h4>Select Status:</h4>
        <div class="flex flex-column gap-3">
            <div *ngFor="let status of contractStatusList" class="field-checkbox">
                <p-radioButton [inputId]="status.key" name="category" [value]="status" [(ngModel)]="selectedStatus"></p-radioButton>
                <label [for]="status.key" class="ml-2">{{ status.name }}</label>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="showStatusDialog=false" label="Cancel"></p-button>
        <p-button icon="pi pi-check" (click)="updateRevisionStatus()" label="Save" [disabled]="!selectedStatus"></p-button>
    </ng-template>
</p-dialog>
