<p-toast position="top-center"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card m-2">
    <div class="card-container p-fluid grid bg-gray-100 border-round mt-2" style="padding: 16px;">

        <div class="flex flex-column lg:flex-row col-12">
            <div class="mr-5 flex align-items-center mt-3 mb-5">
                <i class="fa-solid fa-sliders mr-2" style="font-size: 1.5rem"></i>
                <div *ngIf="receipt" class="font-medium text-3xl text-900">Apply Receipt# {{ receipt?.odooReceiptNumber }} on Invoice</div>
                <div *ngIf="!receipt" class="font-medium text-3xl text-900">Apply Receipt on Invoice</div>
            </div>
        </div>

        <div class="flex flex-column lg:flex-row col-12">
            <p-pickList [source]="unpaidInvoices" [target]="appliedInvoices" sourceHeader="Available Invoices"
                        targetHeader="Invoices to Apply" [dragdrop]="true" [sourceStyle]="{'height':'300px'}"
                        [targetStyle]="{'height':'300px'}" filterBy="invoiceNumber,customer.name"
                        sourceFilterPlaceholder="Search by invoiceNumber" class="w-full"
                        targetFilterPlaceholder="Search by invoiceNumber"
                        [showSourceFilter]="true" [showTargetFilter]="true"
                        (onMoveToTarget)="calculateTotalInvoiceAmount()"
                        (onMoveToSource)="calculateTotalInvoiceAmount()"
                        (onMoveAllToSource)="calculateTotalInvoiceAmount()"
                        (onMoveAllToTarget)="calculateTotalInvoiceAmount()">
                <ng-template let-invoice pTemplate="item">
                    <div class="flex align-items-center">
                        <span class="ml-2">{{ invoice.odooInvoiceNumber }} - {{invoice.country.currencyCode}} {{invoice.totalAmount}}</span>
                    </div>
                </ng-template>
            </p-pickList>
        </div>

        <div class="flex flex-column lg:flex-row col-12 font-medium text-2xl text-900">
            <div class="flex col">
                <div class="flex align-items-center justify-content-between mt-3 col-12">
                    <label for="totalInvoiceAmount">Total Invoice Amount:</label>
                    <span id="totalInvoiceAmount">SAR {{ totalInvoiceAmount | number:'1.2-2' }}</span>
                </div>
            </div>
            <div class="flex col">
                <div class="flex align-items-center justify-content-between mt-3 col-12">
                    <label for="balanceReceiptAmount">Balance Receipt Amount:</label>
                    <span id="balanceReceiptAmount">SAR {{ balanceReceiptAmount | number:'1.2-2' }}</span>
                </div>
            </div>
        </div>

        <div class="flex flex-column lg:flex-row col-12">
            <div class="flex col">
                <div class="flex align-items-end justify-content-end col-12 mt-3 mr-3">
                    <p-button label="Apply Receipt on Invoices" iconPos="left" icon="pi pi-check"
                              (onClick)="applyReceipt()"></p-button>
                </div>
            </div>
        </div>

    </div>
</div>
