import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import { MessageService } from "primeng/api";

import { customerRoutes } from "./customer.routes";
import { CustomerComponent } from "./customer.component";
import { CustomerService } from "./customer.service";
import { TabViewModule } from "primeng/tabview";
import { AvatarModule } from "primeng/avatar";
import { CheckboxModule } from "primeng/checkbox";
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { DropdownModule } from "primeng/dropdown";
import { CustomerRolesComponent } from './customer-roles/customer-roles.component';





@NgModule({
    imports: [
        RouterModule.forChild(customerRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        TabViewModule,
        AvatarModule,
        CheckboxModule,
        DropdownModule,
        ReactiveFormsModule
    ],
    providers: [
        MessageService,
        CustomerService
    ],
    declarations: [
        CustomerComponent,
        CustomerFormComponent,
        CustomerRolesComponent,
    ],
    exports: [
        CustomerFormComponent,
        CustomerRolesComponent
    ]
})
export class CustomerModule { }
