import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {Driver} from "../../../fleet/driver/driver.model";
import {Vehicle} from "../../../fleet/vehicle/vehicle.model";
import {DriverService} from "../../../fleet/driver/driver.service";
import {VehicleService} from "../../../fleet/vehicle/vehicle.service";
import {SessionStorageService} from "../../../services/session-storage.service";
import {PickListMoveToTargetEvent} from "primeng/picklist";
import {ShipmentJobService} from "../../shipment-job.service";

@Component({
    selector: 'app-generate-deliveries',
    templateUrl: './generate-deliveries.component.html',
    styleUrls: ['./generate-deliveries.component.css']
})
export class GenerateDeliveriesComponent implements OnInit {

    availableDrivers: Driver[] = [];
    selectedDrivers: Driver[] = [];
    availableVehicles: Vehicle[] = [];
    selectedVehicles: Vehicle[] = [];
    isLoading: boolean = false;
    deliveryGenerationInProgress: boolean = false;
    isFreelancerRequired: boolean = false;
    queryId: string;

    breadcrumbItems: MenuItem[];
    homeBreadcrumb: MenuItem;

    constructor(
        private driverService: DriverService,
        private vehicleService: VehicleService,
        private messageService: MessageService,
        private shipmentJobService: ShipmentJobService,
        private router: Router, private route: ActivatedRoute,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        // Get driver ID from route parameters
        this.queryId = this.route.snapshot.paramMap.get('orderNumber')!;

        this.breadcrumbItems = [{ label: 'Shipments', routerLink: '/shipment' },
            { label: this.queryId, routerLink: ['/shipment/view', this.queryId] },
            { label: 'Generate Deliveries' }];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.isFreelancerRequired = this.route.snapshot.queryParamMap.get('isFreelancer') === 'true';
        this.loadDrivers();
        this.loadVehicles();
    }

    loadDrivers() {
        this.isLoading = true;
        if (this.isFreelancerRequired) {
            this.driverService.getFreelanceDriversByCompanyId(0, 1000, this.sessionStorageService.getCompany()!.id) // Adjust page size as needed
                .subscribe(response => {
                    console.log(response);
                    this.availableDrivers = response.items;
                    this.isLoading = false;
                });
        } else {
            const agencyId = this.sessionStorageService.getCompany()?.transportationAgencyId;
            if (agencyId) {
                this.driverService.findDriversByTransportationAgencyId(0, 1000, agencyId) // Adjust page size as needed
                    .subscribe(response => {
                        console.log(response);
                        this.availableDrivers = response.items;
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Agency ID not found.' });
            }
        }
    }

    loadVehicles() {
        this.isLoading = true;
        if (this.isFreelancerRequired) {
            this.vehicleService.getFreelanceVehiclesByCompanyId(0, 1000, this.sessionStorageService.getCompany()!.id) // Adjust page size as needed
                .subscribe(response => {
                    console.log(response);
                    this.availableVehicles = response.items;
                    this.isLoading = false;
                });
        } else {
            this.vehicleService.findVehiclesByCompanyId(0, 1000, this.sessionStorageService.getCompany()!.id) // Adjust page size as needed
                .subscribe(response => {
                    console.log(response);
                    this.availableVehicles = response.items;
                    this.isLoading = false;
                });
        }
    }

    generateDeliveries() {
        this.deliveryGenerationInProgress = true;
        for (let i = 0; i < this.selectedDrivers.length; i++) {
            this.selectedDrivers[i].currentVehicle = this.selectedVehicles[i];
        }
        this.shipmentJobService.createJobsFromDriverAndVehicle(this.queryId, this.selectedDrivers, 'en-US').subscribe(value => {
            console.log(value);
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deliveries generated successfully.' });
            this.deliveryGenerationInProgress = false;
            this.router.navigate([`/shipment/view/${this.queryId}`]);
        }, error => {
            console.log(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error generating deliveries.' });
            this.deliveryGenerationInProgress = false;
        });

    }

    clearSelections() {
        this.selectedDrivers = [];
        this.selectedVehicles = [];
    }

    assignRelatedVehicle(event: PickListMoveToTargetEvent) {
        let selectedDriver: Driver = event.items.pop();
        if (selectedDriver && selectedDriver.currentVehicle)
            this.selectedVehicles.push(this.availableVehicles.find(vehicle => vehicle.vehicleId === selectedDriver.currentVehicle!.vehicleId)!);
    }
}
