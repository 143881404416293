/* eslint-disable */
import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment'
import { SessionStorageService } from '../services/session-storage.service';
import {APIListWrapper} from "../models/common/api-list-wrapper.model";
import {AccountReceivables} from "./receivables/receivables.model";
import {Observable} from "rxjs";
import {AccountPayables} from "./payables/payables.model";
import {Bill} from "./bill/bill.model";
import {Voucher} from "./voucher/voucher.model";

@Injectable()
export class AccountsService {

    private apiUrl = ''; // Adjust if your API base URL is different

    constructor(private http: HttpClient) {
        this.apiUrl = `${environment.restApiUrl}` + 'accounts-endpoint';
    }

    // Invoices
    getInvoices(page: number, pageSize: number, companyId?: number, owningCompanyId?: number): Observable<any> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (companyId) {
            params = params.set('companyId', companyId.toString());
        }
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get(this.apiUrl + '/invoices', { params });
    }

    // Receipts
    getReceipts(page: number, pageSize: number, companyId?: number, owningCompanyId?: number): Observable<any> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (companyId) {
            params = params.set('companyId', companyId.toString());
        }
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get(this.apiUrl + '/receipts', { params });
    }

    // Account Receivables
    getAccountReceivables(page: number, pageSize: number, owningCompanyId?: number): Observable<APIListWrapper<AccountReceivables>> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get<APIListWrapper<AccountReceivables>>(this.apiUrl + '/account-receivables', { params });
    }

    createAccountReceivables(accountReceivables: AccountReceivables): Observable<AccountReceivables> {
        return this.http.post<AccountReceivables>(this.apiUrl + '/account-receivables', accountReceivables);
    }

    updateAccountReceivables(receivableId: number, accountReceivables: AccountReceivables): Observable<AccountReceivables> {
        return this.http.put<AccountReceivables>(`${this.apiUrl}/account-receivables/${receivableId}`, accountReceivables);
    }

    // Account Payables
    getAccountPayables(page: number, pageSize: number, owningCompanyId?: number): Observable<APIListWrapper<AccountPayables>> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get<APIListWrapper<AccountPayables>>(this.apiUrl + '/account-payables', { params });
    }

    createAccountPayables(accountPayables: AccountPayables): Observable<AccountPayables> {
        return this.http.post<AccountPayables>(this.apiUrl + '/account-payables', accountPayables);
    }

    updateAccountPayables(payableId: number, accountPayables: AccountPayables): Observable<AccountPayables> {
        return this.http.put<AccountPayables>(`${this.apiUrl}/account-payables/${payableId}`, accountPayables);
    }

    // Bills
    getBills(page: number, pageSize: number, companyId?: number, owningCompanyId?: number): Observable<any> { // Adjust the return type based on your API response
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (companyId) {
            params = params.set('companyId', companyId.toString());
        }
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get(this.apiUrl + '/bills', { params });
    }

    createBill(bill: Bill): Observable<Bill> {
        return this.http.post<Bill>(this.apiUrl + '/bills', bill);
    }

    updateBill(billId: number, bill: Bill): Observable<Bill> {
        return this.http.put<Bill>(`${this.apiUrl}/bills/${billId}`, bill);
    }

    // Vouchers
    getVouchers(page: number, pageSize: number, companyId?: number, owningCompanyId?: number): Observable<any> { // Adjust the return type based on your API response
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString());
        if (companyId) {
            params = params.set('companyId', companyId.toString());
        }
        if (owningCompanyId) {
            params = params.set('owningCompanyId', owningCompanyId.toString());
        }
        return this.http.get(this.apiUrl + '/vouchers', { params });
    }

    createVoucher(voucher: Voucher): Observable<Voucher> {
        return this.http.post<Voucher>(this.apiUrl + '/vouchers', voucher);
    }

    updateVoucher(voucherId: number, voucher: Voucher): Observable<Voucher> {
        return this.http.put<Voucher>(`${this.apiUrl}/vouchers/${voucherId}`, voucher);
    }

}
