/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable, switchMap, throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment'
import { OrderQueryView } from "../models/order-view/order-query-view";
import { SessionStorageService } from '../services/session-storage.service';
import { APIResponseWrapper } from '../models/common/api-response-wrapper';
import { JobAttachment } from '../models/order-view/job-attachment';

@Injectable()
export class ShipmentQueryService {

    transactionApiUrl: string;
    restApiUrl: string;

    constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {
        this.transactionApiUrl = `${environment.reactiveApiUrl}`;
        this.restApiUrl = `${environment.restApiUrl}`;
    }

    createShipment(orderQuery: OrderQueryView, cultureCode: string) {
        return this.http.post<APIResponseWrapper<OrderQueryView>>(`${this.transactionApiUrl}order`
        + "?cultureCode=" + cultureCode, JSON.stringify(orderQuery), {
            headers: {
                'Content-type': 'application/json'
            }
        });
    }

    findVehiclesNow(orderQuery: OrderQueryView, cultureCode: string) {
        return this.http.post<APIResponseWrapper<Boolean>>(`${this.transactionApiUrl}order/find-vehicles`
        + "?cultureCode=" + cultureCode, JSON.stringify(orderQuery), {
            headers: {
                'Content-type': 'application/json'
            }
        });
    }

    getShipmentsByCustomerPaginated(page: number, pageSize: number, customerId: string) {
        return this.http.get<OrderQueryView[]>(`${this.transactionApiUrl}order/query/customer/${customerId}/paginated`
        + "?page=" + page
        + "&pageSize=" + pageSize
        + "&isPayloadItemsNeeded=" + "true"
        + "&isDeliveriesNeeded=" + "false"
        + "&isBiddingsNeeded=" + "false"
        + "&isNominationsNeeded=" + "true"
        + "&isFilesListNeeded=" + "false"
        + "&cultureCode=" + "en-US");
    }

    getShipmentsByCustomerCount(customerId: string) {
        return this.http.get<number>(`${this.transactionApiUrl}order/query/customer/${customerId}/count`);
    }

    getShipmentsByCompanyPaginated(page: number, pageSize: number, companyId: number) {
        return this.http.get<OrderQueryView[]>(`${this.transactionApiUrl}order/query/company/${companyId}/paginated`
        + "?page=" + page
        + "&pageSize=" + pageSize
        + "&isPayloadItemsNeeded=" + "true"
        + "&isDeliveriesNeeded=" + "false"
        + "&isBiddingsNeeded=" + "false"
        + "&isNominationsNeeded=" + "true"
        + "&isFilesListNeeded=" + "false"
        + "&cultureCode=" + "en-US");
    }

    getShipmentsByCompanyCount(companyId: number) {
        return this.http.get<number>(`${this.transactionApiUrl}order/query/company/${companyId}/count`);
    }

    getShipmentByShipmentNumber(queryId: string) {
        return this.http.get<OrderQueryView>(`${this.transactionApiUrl}order/query/order-number/${queryId}`
        + "?isCustomerNeeded=" + "true"
        + "&isPayloadItemsNeeded=" + "true"
        + "&isDeliveriesNeeded=" + "true"
        + "&isBiddingsNeeded=" + "false"
        + "&isNominationsNeeded=" + "true"
        + "&isFilesListNeeded=" + "true"
        + "&cultureCode=" + "en-US");
    }

    addShipmentAttachment(jobAttachment: JobAttachment, queryId: string) {
        return this.http.put<APIResponseWrapper<Boolean>>(`${this.transactionApiUrl}order/attachment/${queryId}`, jobAttachment);
    }

    updateBuyingPrice(orderQuery: OrderQueryView) {
        const trxnUrl = `${this.transactionApiUrl}order/buying-price`;
        const restUrl = `${this.restApiUrl}order-info-endpoint/update-buying-price`;

        // Make the first request to trxnUrl
        return this.http.put<APIResponseWrapper<void>>(trxnUrl, orderQuery).pipe(
            switchMap((trxnResponse) => {
                // After the first request is successful, make the second request to restUrl
                if (trxnResponse.success) {
                    return this.http.put<APIResponseWrapper<boolean>>(restUrl, orderQuery).pipe(
                        map(restResponse => restResponse.success) // Extract and return the success value
                    );
                } else {
                    // Handle the case where the first request fails
                    return throwError('Error updating buying price (transaction API)');
                }
            })
        );
    }

    updateSellingPrice(orderQuery: OrderQueryView): Observable<boolean> {
        const trxnUrl = `${this.transactionApiUrl}order/selling-price`;
        const restUrl = `${this.restApiUrl}order-info-endpoint/update-selling-price`;

        // Make the first request to trxnUrl
        return this.http.put<APIResponseWrapper<void>>(trxnUrl, orderQuery).pipe(
            switchMap((trxnResponse) => {
                // After the first request is successful, make the second request to restUrl
                if (trxnResponse.success) {
                    return this.http.put<APIResponseWrapper<boolean>>(restUrl, orderQuery).pipe(
                        map(restResponse => restResponse.success) // Extract and return the success value
                    );
                } else {
                    // Handle the case where the first request fails
                    return throwError('Error updating selling price (transaction API)');
                }
            })
        );
    }

}
