import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLibrariesLoaderService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  constructor(@Inject(DOCUMENT) private readonly document: any) { }

  lazyLoadMap(): Observable<any> {
    return forkJoin([
      this.loadScript(`https://maps.googleapis.com/maps/api/js?sensor=false&libraries=places&key=${environment.googleApiKey}`),
    ]);
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next(null);
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }
}
