import { Routes } from '@angular/router';
import { ShipmentComponent } from './shipment.component';
import { CreateShipmentComponent } from './create-shipment/create-shipment.component';
import { ViewShipmentComponent } from './view-shipment/view-shipment.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { ShipmentCompanyComponent } from './company/shipment-company.component';
import {GenerateDeliveriesComponent} from "./delivery/generate-deliveries/generate-deliveries.component";

export const shipmentRoutes: Routes = [
    {
        path: 'shipment',
        children: [
            {
                path: '',
                component: ShipmentComponent,
            },
            {
                path: 'company',
                component: ShipmentCompanyComponent,
            },
            {
                path: 'create',
                component: CreateShipmentComponent
            },
            {
                path: 'view/:orderNumber',
                component: ViewShipmentComponent,
            },
            {
                path: 'delivery/view/:deliveryNumber', // Direct child of 'order' route
                component: DeliveryComponent
            },
            {
                path: 'delivery/:orderNumber/generate', // Direct child of 'order' route
                component: GenerateDeliveriesComponent
            },
        ]
    },
];
