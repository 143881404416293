import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageService } from "primeng/api";
import { EnterpriseFeatureService } from "../services/enterprise-features.service"; // Adjust the path accordingly

@Injectable({
    providedIn: 'root'
})
export class AccountsGuard implements CanActivate {

    constructor(private router: Router, private messageService: MessageService,
                private enterpriseFeatureService: EnterpriseFeatureService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.enterpriseFeatureService.getEnableEnterpriseAccounts()) {
            return true;
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'You are not subscribed to Enterprise Plan!'
            });
            this.router.navigate(['/accounts']);
            return false;
        }
    }
}
