import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Invoice } from '../../invoice/invoice.model';
import { SessionStorageService } from '../../../services/session-storage.service';
import {tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountsService} from "../../accounts.service";
import {Receipt} from "../../receipt/receipt.model";

@Component({
    selector: 'app-apply-receipt-invoice',
    templateUrl: './apply-receipt-invoice.component.html',
    styleUrls: ['./apply-receipt-invoice.component.css']
})
export class ApplyReceiptInvoiceComponent implements OnInit {

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    receiptId!: number;
    editMode = false;
    receipt!: Receipt;

    unpaidInvoices: Invoice[] = [];
    appliedInvoices: Invoice[] = [];
    totalInvoiceAmount: number = 0;
    balanceReceiptAmount: number = 0;
    receiptAmount: number = 0;
    isLoading: boolean = true;

    constructor(
        private accountsService: AccountsService,
        private messageService: MessageService,
        private route: ActivatedRoute, private router: Router,
        private sessionStorageService: SessionStorageService
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            { label: 'Accounts', routerLink: '/accounts' },
            { label: 'Receivables', routerLink: '/accounts/enterprise/receivables' },
            { label: 'Generated Receipts', routerLink: '/accounts/enterprise/receivables/generated-receipts' },
            { label: 'Apply Receipt to Invoice' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        this.route.params.subscribe(params => {
            this.receiptId = params['receiptId'];
            this.editMode = !!this.receiptId;
            this.loadReceiptData();
        });
    }

    private loadReceiptData() {
        this.accountsService.getReceiptById(this.receiptId).subscribe(value => {
            this.receipt = value;
            this.loadUnpaidInvoices();
            this.receiptAmount = value.amount!;
            this.balanceReceiptAmount = value.amount!;
        });
    }

    loadUnpaidInvoices() {
        this.isLoading = true;
        const companyId = this.sessionStorageService.getCompany()?.id ?? 0;
        this.accountsService.getInvoices(0, 25, this.receipt.company?.id, companyId, undefined, true).pipe(
            tap(res => {
                console.log(res);
                this.unpaidInvoices = res.items;
            })
        ).subscribe({
            error: (err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error loading unpaid invoices'
                });
                this.isLoading = false;
            },
            complete: () => {
                this.isLoading = false;
            }
        });
    }


    calculateTotalInvoiceAmount() {
        this.totalInvoiceAmount = this.appliedInvoices.reduce((sum, invoice) => sum + invoice.totalAmount!, 0);
        this.balanceReceiptAmount = this.receiptAmount - this.totalInvoiceAmount;
        if (this.balanceReceiptAmount < 0) {
            this.balanceReceiptAmount = 0;
            this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'The applied invoices amount are greater than the amount.'
            });
        }
    }


    applyReceipt() {
        if(this.appliedInvoices.length == 0) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please, select at least one invoice.'
            });
            return;
        }
        this.isLoading = true;
        this.accountsService.applyReceiptOnInvoices(this.receiptId, this.appliedInvoices).subscribe(value => {
            this.isLoading = false;
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'The invoices has been applied successfully.'
            });
            setTimeout(() => {
                this.router.navigate(['/accounts/enterprise/receivables/generated-receipts']);
            }, 3000);
        });
    }
}
