<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-accordion *ngIf="generatedBillsSummaryList.length > 0">
    <p-accordionTab *ngFor="let generatedBillsSummary of generatedBillsSummaryList">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image *ngIf="generatedBillsSummary.clientLogo && generatedBillsSummary.clientId === 0"
                                 src="{{ generatedBillsSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!generatedBillsSummary.clientLogo && generatedBillsSummary.clientId === 0"
                                 src="assets/driver.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="generatedBillsSummary.clientLogo && generatedBillsSummary.clientId != 0"
                                 src="{{ generatedBillsSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!generatedBillsSummary.clientLogo && generatedBillsSummary.clientId != 0"
                                 src="assets/transportation.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <h4>{{ generatedBillsSummary.clientName }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ generatedBillsSummary.totalUnpaidAmount }}</p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Pending</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ generatedBillsSummary.totalPaidAmount }}</p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Billed</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="generatedBillsSummary.bills" dataKey="billId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="generatedBillsSummary.totalBills" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadBills($event, generatedBillsSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="generatedBillsSummary.selectedBills">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!generatedBillsSummary.bills"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Generated Bills</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Generate Voucher" icon="fa-solid fa-file-invoice-dollar"
                                  (onClick)="generateVoucher(generatedBillsSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(generatedBillsSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>ID</th>
                    <th>Bill#</th>
                    <th>Bill Date</th>
                    <th>Contact</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bill let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="bill"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="bill"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ bill.billId }}</td>
                    <td>{{ bill.odooBillNumber }}</td>
                    <td>{{ bill.billCreationDate | date:'yyyy-MM-dd' }}</td>
                    <td>
                        <p-chip *ngIf="bill.customer" [label]="bill.customer.name">
                            <img src="{{ bill.customer.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ bill.customer.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>
                    <td>{{ bill.country.currencyCode + ' ' + bill.totalAmount }}</td>
                    <td>
                        <p-tag *ngIf="bill.vouchers?.length > 0" icon="fa-solid fa-check" severity="success" value="PAID"></p-tag>
                        <p-tag *ngIf="bill.vouchers?.length  === 0" icon="fa-solid fa-circle-exclamation" severity="danger" value="UNPAID"></p-tag>
                    </td>
                    <td>
                        <span class="p-buttonset">
                            <p-button pTooltip="Download Bill" icon="pi pi-download" (onClick)="downloadBill(bill)" class="p-1" severity="info"></p-button>
                            <p-button pTooltip="View Bill" icon="pi pi-eye" (onClick)="viewBill(bill)" class="p-1" severity="info"></p-button>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-bill>
                <tr>
                    <td colspan="11">
                        <div class="p-3">
                            <p-table [value]="bill.billLines" dataKey="billLineId" [resizableColumns]="true"
                                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-10">
                                            <h2>Generated Bill Lines</h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S#</th>
                                        <th>Item#</th>
                                        <th>Detail#</th>
                                        <th>Service Type</th>
                                        <th>Charge Amount</th>
                                        <th>Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-billLine>
                                    <tr>
                                        <td>{{ billLine.billLineId }}</td>
                                        <td>{{ billLine.headerReferenceNumber }}</td>
                                        <td>{{ billLine.detailReferenceNumber }}</td>
                                        <td>{{ billLine.itemNumber }}</td>
                                        <td>{{ bill.country.currencyCode + ' ' + billLine.chargeAmount }}</td>
                                        <td>
                                            <span class="p-buttonset">
                                                <button pButton type="button" icon="fa fa-pencil" (click)="editBillLine(billLine)"
                                                        class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{generatedBillsSummary.totalBills}} bills.
                </div>
            </ng-template>

        </p-table>
    </p-accordionTab>
</p-accordion>
