<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
  <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-table [value]="contracts" dataKey="invoiceId" [resizableColumns]="true" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
           [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true" [totalRecords]="contractsCount" [lazy]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onLazyLoad)="loadContracts($event)"
           [rowsPerPageOptions]="[20, 50, 100]">
  <ng-template pTemplate="caption">
    <div class="col-12 flex overflow-hidden">
      <div class="flex-none flex align-items-center justify-content-center">
        <h2>Contracts</h2>
      </div>
      <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
      <div class="flex-none flex align-items-center justify-content-center">
        <p-button label="Add Contract" icon="pi pi-plus" (onClick)="addContract()" class="p-2"></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Contract Number</th>
      <th>First Party</th>
      <th>First Party Rep</th>
      <th>Second Party</th>
      <th>Second Party Rep</th>
      <th>Status</th>
      <th>Created Date</th>
      <th>Updated Date</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contract>
    <tr>
      <td>{{ contract.contractNumber }}</td>
      <td>{{ contract.firstParty?.name }}</td>
      <td>
          <p-chip *ngIf="contract.firstPartyRep" [label]="contract.firstPartyRep.name">
              <img src="{{ contract.firstPartyRep.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                   alt="{{ contract.firstPartyRep.name }}"
                   style="width: 32px; height: 32px; border-radius: 50%;">
          </p-chip>
      </td>
      <td>{{ contract.secondParty?.name }}</td>
      <td>
          <p-chip *ngIf="contract.secondPartyRep" [label]="contract.secondPartyRep.name">
              <img src="{{ contract.secondPartyRep.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                   alt="{{ contract.secondPartyRep.name }}"
                   style="width: 32px; height: 32px; border-radius: 50%;">
          </p-chip>
      </td>
        <td>
            <p-tag [value]="contract.contractStatus"
                   [severity]="getContractSeverity(contract.contractStatus)">
            </p-tag>
        </td>
      <td>{{ contract.createdDate | date }}</td>
      <td>{{ contract.updatedDate | date }}</td>
      <td>
        <p-button icon="pi pi-eye" (onClick)="viewContract(contract)" class="pr-1 pt-1" severity="info"></p-button>
        <p-button icon="pi pi-pencil" (onClick)="editContract(contract)" class="pr-1 pt-1" severity="secondary"></p-button>
        <p-button icon="pi pi-trash" (onClick)="deleteContract(contract)" class="pr-1 pt-1" severity="danger"></p-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      In total there are {{contractsCount}} contracts.
    </div>
  </ng-template>
</p-table>
