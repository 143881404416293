<p-toast position='bottom-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<p-table [value]="paymentTransactions" dataKey="transactionId" [resizableColumns]="true"
         styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
         [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [rowsPerPageOptions]="[20, 50, 100]"
         [(selection)]="selectedPaymentTransactions">
    <ng-template pTemplate="caption">
        <div class="col-12 flex overflow-hidden">
            <div class="flex-none flex align-items-center justify-content-center">
                <h2>Payment Transactions</h2>
            </div>
            <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
            <div class="flex-none flex align-items-center justify-content-center">
                <p-button label="Add Transaction" icon="pi pi-plus" (click)="addPaymentTransactions()" class="p-2"></p-button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Date</th>
            <th>Transaction #</th>
            <th>Reference</th>
            <th>Amount</th>
            <th>Status</th>
            <th>From</th>
            <th>To</th>
            <th>Receipt #</th>
            <th>Voucher #</th>
            <th>Actions</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-paymentTransaction>
        <tr>
            <td>
                <p-tableCheckbox [value]="paymentTransaction"></p-tableCheckbox>
            </td>
            <td>{{ paymentTransaction.createdAt | date:'yyyy-MM-dd' }}</td>
            <td>{{ paymentTransaction.transactionNumber }}</td>
            <td>{{ paymentTransaction.transactionReference }}</td>
            <td>{{ paymentTransaction.transactionCountry.currencyCode }} {{ paymentTransaction.transactionAmount }}</td>
            <td>{{ paymentTransaction.transactionStatus }}</td>
            <td>
                <h4 *ngIf="paymentTransaction.transactionFromClient">{{ paymentTransaction.transactionFromClient.name }}</h4>
                <p-chip *ngIf="paymentTransaction.transactionFromContact" [label]="paymentTransaction.transactionFromContact.name">
                    <img src="{{ paymentTransaction.transactionFromContact.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                         alt="{{ paymentTransaction.transactionFromContact.name }}"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                </p-chip>
            </td>
            <td>
                <h4 *ngIf="paymentTransaction.transactionToClient">{{ paymentTransaction.transactionToClient.name }}</h4>
                <p-chip *ngIf="paymentTransaction.transactionToContact" [label]="paymentTransaction.transactionToContact.name">
                    <img src="{{ paymentTransaction.transactionToContact.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                         alt="{{ paymentTransaction.transactionToContact.name }}"
                         style="width: 32px; height: 32px; border-radius: 50%;">
                </p-chip>
            </td>
            <td>{{ paymentTransaction.receiptNumber }}</td>
            <td>{{ paymentTransaction.voucherNumber }}</td>
            <td>
                <button pButton type="button" icon="pi pi-pencil" (click)="editPaymentTransactions(paymentTransaction)"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton type="button" icon="pi pi-trash" (click)="deletePaymentTransactions(paymentTransaction)"
                        class="p-button-rounded p-button-text p-button-danger mr-2"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
            In total there are {{totalRecords}} payment transactions.
        </div>
    </ng-template>
</p-table>
