import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {ChatMessage} from "./ai-chat.model";

@Injectable({
    providedIn: 'root'
})
export class AiChatService {

    private apiUrl = `${environment.aiServiceApiUrl}chat`;

    constructor(private http: HttpClient) {
    }

    sendMessage(message: ChatMessage): Observable<ChatMessage> {
        return this.http.post<ChatMessage>(this.apiUrl, message);
    }
}
