/* eslint-disable */
import { LatLng } from "../models/common/lat-lng";

export class Company {

    id: number;
    placeId: string | null;
    name: string;
    location: LatLng;
    phoneNumber: string | null;
    crNumber: number;
    taxRegNumber: string | null;
    logo: string | null;
    poBox: number | null;
    websiteURL: string | null;
    address: string;
    countryId: number;
    companyStatus: string;
    emailAddress: string | null;
    defaultContactId: number | null;
    transportationAgencyId: number | null;
    freightForwarderId: number | null;
    enableEnterpriseFeatures: boolean = false;

}
