import * as XLSX from 'xlsx';
import {Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {AccountsService} from "../accounts.service";
import {SessionStorageService} from "../../services/session-storage.service";
import {AccountReceivables} from "./receivables.model";
import {ClientReceivablesSummary} from "./client-receivables-summary.model";
import {TabViewChangeEvent} from "primeng/tabview";
import {AccountReceivablesLine} from "./receivables-line.model";

@Component({
    selector: 'app-receivables',
    templateUrl: './receivables.component.html',
    styleUrls: ['./receivables.component.css']
})
export class ReceivablesComponent implements OnInit {

    clientReceivablesSummaryList: ClientReceivablesSummary[] =[];
    isLoading = false;
    first = 0;
    rows = 20;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    constructor(private accountsService: AccountsService,
                private messageService: MessageService,
                private sessionStorageService: SessionStorageService) {
    }


    ngOnInit(): void {
        this.breadcrumbItems = [{label: 'Accounts'}, {label: 'Enterprise'}, {label: 'Receivables'}];
        this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};
        this.loadSummaryData() // Initial load
    }

    loadSummaryData() {
        this.isLoading = true;
        this.accountsService.getClientReceivablesSummary(this.sessionStorageService.getCompany()!!.id).subscribe(value => {
            console.log(value);
            this.clientReceivablesSummaryList = value;
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        })
    }


    loadData(event: any, clientReceivablesSummary: ClientReceivablesSummary) {
        this.isLoading = true;
        this.first = event.first;
        this.rows = event.rows;
        // Call your service to fetch receivables data with pagination
        // Example (replace with your actual service call):
        this.accountsService.getAccountReceivables(this.first, this.rows,
            this.sessionStorageService.getCompany()!!.id, clientReceivablesSummary.client.id).subscribe((dataWrapper) => {
            console.log(dataWrapper);
            clientReceivablesSummary.receivables = dataWrapper.items// Simulate fetching data
            clientReceivablesSummary.totalReceivables = dataWrapper.count; // Set total records for pagination
            this.isLoading = false;
        });
    }



    showDate(date: Date): string {
        if (date === null || date === undefined) return '';
        return date.toLocaleDateString(); // Example
    }

    exportToExcel(clientReceivablesSummary: ClientReceivablesSummary) {
        const worksheet = XLSX.utils.json_to_sheet(clientReceivablesSummary.receivables);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const fileName = 'cargomate_receivables_export_' + new Date().toISOString() + '.xlsx';
        // Create a blob from the workbook
        const blob = new Blob([XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.target = '_blank';
        a.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
    }



    viewDetails(receivable: AccountReceivables) {
        // Implement logic to navigate to receivable details page or show a dialog, etc.
        console.log('View details for receivable:', receivable);
    }

    onClientReceivablesTabChange($event: TabViewChangeEvent) {

    }

    editReceivableLine(receivableLine: AccountReceivablesLine) {

    }

    generateInvoice(clientReceivablesSummary: ClientReceivablesSummary) {
        if (!clientReceivablesSummary.selectedReceivables || clientReceivablesSummary.selectedReceivables.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one receivable' });
            return;
        }
        if (clientReceivablesSummary.selectedReceivables.some(value => value.status == 'INVOICED')) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One or more receivables are already invoiced' });
            return;
        }
        this.isLoading = true;
        this.accountsService.generateInvoiceFromAccountReceivables(clientReceivablesSummary.selectedReceivables).subscribe(invoice => {
                console.log(invoice);
                this.accountsService.generateInvoicePDF(invoice.owningCompany!.id, invoice.invoiceId!, invoice.odooInvoiceNumber!,
                    invoice.invoiceLines.map(invoiceLine => invoiceLine.headerReferenceNumber!)).subscribe(success => {
                    clientReceivablesSummary.selectedReceivables.forEach(value => {
                        value.status = 'INVOICED';
                        value.odooInvoiceNumber = invoice.odooInvoiceNumber;
                        value.invoicingDate = invoice.invoiceCreationDate!;
                        value.odooInvoiceId = invoice.odooInvoiceId;
                    })
                    this.isLoading = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Invoice generated successfully' });
                }, error => {
                    this.isLoading = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error generating invoice PDF:' + JSON.stringify(error) });
                })
            },
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error creating invoice:' + JSON.stringify(error) });
            });
    }
}
