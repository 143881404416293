<div class="card pt-2" *ngIf="!isLoading">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>

<div class="grid mb-4">
    <div class="col-8 pt-4 pl-4">
        <p class="text-4xl font-bold text-blue-800 p-0 m-0 mb-1">Vehicle# {{vehicle?.plateNumber}}</p>
        <p-tag icon="fa-solid fa-road" value="{{ currentGpsPosition?.attributes?.motion ?? 'Static' }}"></p-tag>
    </div>
    <div class="col-4 pt-4 pr-4">
        <p class="text-2xl text-right text-red-400 p-0 m-0"><i
            class="fa-solid fa-compass-drafting"></i>&nbsp;&nbsp;{{ currentGpsDevice?.model?.includes('OsmAnd') ? 'Mobile' : currentGpsDevice?.model }}</p>
    </div>
</div>

<div class="col-12 card pr-2 pl-2 mb-4">
    <div class="card-container bg-gray-100 border-round-md p-4">
        <p-tabView *ngIf="!isLoading" [scrollable]="true">

            <!-- Tracking Info -->
            <p-tabPanel (change)="tabChange($event)">
                <ng-template pTemplate="header">
                    <i class="fa-solid fa-map-location-dot"></i>
                    &nbsp;&nbsp;<span>Tracking</span>
                    &nbsp;&nbsp;<p-badge [value]="'LIVE'" severity="danger"></p-badge>
                </ng-template>
                <div class="col-12">
                    <div class="card border-round-md">
                        <google-map #map id="map-canvas" height="480px" width="100%" [zoom]="zoom" [center]="center"
                                [options]="options">
                            <map-marker #vehicleMarker [position]="vehicleLocation" [title]="'Vehicle Location'"
                                        [icon]="vehicleMarkerImage" [options]="options" (mapClick)="openInfo(vehicleMarker)">
                                <map-info-window>{{ vehicleLocation?.lat }}, {{ vehicleLocation?.lng }}</map-info-window>
                            </map-marker>
                        </google-map>
                    </div>
                </div>
            </p-tabPanel>

            <!-- Telematics Data -->
            <p-tabPanel (change)="tabChange($event)">
                <ng-template pTemplate="header">
                    <i class="fa-solid fa-money-bill-transfer"></i>
                    &nbsp;&nbsp;<span>Telematics Data</span>
                    <p-badge *ngIf="gpsPositionList.length == 0" severity="warning"
                             [value]="'INACTIVE'"></p-badge>
                    &nbsp;&nbsp;<p-badge *ngIf="gpsPositionList.length > 0" severity="success"
                                         [value]="gpsPositionList.length.toString()"></p-badge>
                </ng-template>
                <div class="grid">
                    <div class="col-12">
                        <p-table [value]="gpsPositionList">
                            <ng-template pTemplate="caption">
                                <div class="col-12 flex overflow-hidden">
                                    <div class="flex-none flex align-items-center justify-content-center">
                                        Charge Lines
                                    </div>
                                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                                    <div class="flex-none flex align-items-center justify-content-center">

                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>ID</th>
                                    <th>Ignition</th>
                                    <th>Moving</th>
                                    <th>Odometer</th>
                                    <th>Speed</th>
                                    <th>GPS Coordinates</th>
                                    <th>Date</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-gpsPosition>
                                <tr>
                                    <td>{{ gpsPosition.id }}</td>
                                    <td>{{ (gpsPosition.attributes?.ignition ?? 'No') ? 'Yes' : 'No' }}</td>
                                    <td>{{ (gpsPosition.attributes?.motion ?? 'No') ? 'Yes' : 'No' }}</td>
                                    <td>{{ gpsPosition.attributes?.odometer ?? 0 }}</td>
                                    <td>{{ gpsPosition.speed ?? 0 }}</td>
                                    <td>{{ gpsPosition.latitude ?? 0 }}, {{ gpsPosition.longitude ?? 0 }}</td>
                                    <td>{{ showDate(gpsPosition.deviceTime) }}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="summary">
                                <div class="flex align-items-center justify-content-between">
                                    In total there are {{gpsPositionList.length}} lines.
                                </div>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
