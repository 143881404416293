import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataViewModule } from 'primeng/dataview';
import { CardModule } from 'primeng/card';
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from 'primeng/progressbar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { InputTextModule } from "primeng/inputtext";
import { DialogModule } from "primeng/dialog";
import { ChipModule } from 'primeng/chip';
import { ImageModule } from 'primeng/image';
import {MessageService} from "primeng/api";
import {TabViewModule} from "primeng/tabview";

import {orderRoutes} from "./order.routes";
import {AutoCompleteModule} from "primeng/autocomplete";
import {OrdersService} from "./order.service";
import {SalesOrderComponent} from "./sales-order/sales-order.component";
import {BadgeModule} from "primeng/badge";
import {RippleModule} from "primeng/ripple";
import { AddEditSalesOrderComponent } from './sales-order/add-edit-sales-order/add-edit-sales-order.component';
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import {DividerModule} from "primeng/divider";
import {InputNumberModule} from "primeng/inputnumber";
import { AddEditPurchOrderComponent } from './purchase-order/add-edit-purch-order/add-edit-purch-order.component';
import {PurchaseOrderComponent} from "./purchase-order/purchase-order.component";
import {TagModule} from "primeng/tag";
import { ProductComponent } from './product/product.component';
import { AddEditProductComponent } from './product/add-edit-product/add-edit-product.component';
import {ProductsService} from "./product.service";
import {InputTextareaModule} from "primeng/inputtextarea";





@NgModule({
    imports: [
        RouterModule.forChild(orderRoutes),
        RouterOutlet,
        FontAwesomeModule,
        FormsModule,
        ButtonModule,
        DataViewModule,
        CardModule,
        CommonModule,
        ToastModule,
        ButtonModule,
        ProgressBarModule,
        BreadcrumbModule,
        TableModule,
        InputTextModule,
        DialogModule,
        ChipModule,
        ImageModule,
        TabViewModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        BadgeModule,
        RippleModule,
        DropdownModule,
        CalendarModule,
        DividerModule,
        InputNumberModule,
        TagModule,
        InputTextareaModule,
    ],
    providers: [
        MessageService,
        OrdersService,
        ProductsService
    ],
    declarations: [
        SalesOrderComponent,
        AddEditSalesOrderComponent,
        PurchaseOrderComponent,
        AddEditPurchOrderComponent,
        ProductComponent,
        AddEditProductComponent
    ],
})
export class OrderModule { }
