import {ContractStatus} from "./contract-status.model";
import {DeliveryRateCard} from "./delivery-rate-card.model";


export class ContractRevisions {
  revisionID: number;
  revisionNumber: number;
  firstPartyAgreed: boolean;
  secondPartyAgreed: boolean;
  effectiveStartDate: Date;
  endDate: Date;
  isRenewable: boolean;
  agreementPeriod: number;
  nonRenewalDisclosureBeforeDays: number;
  goodsInsuranceByFirstParty: boolean;
  goodsInsuranceBySecondParty: boolean;
  status: ContractStatus;
  totalAmount: number;
  vatAddedToInvoice: boolean;
  paymentTerms: string;
  penaltyRate: number;
  firstPartyBankAccountId: number;
  firstPartyBankAccountDetails: string;
  siteServiceTime: number | null;
  penaltyOnServiceTime: number;
  noticePeriod: number;
  forceMajeureClausePeriod: number | null;
  disputeResolution: string;
  governingLaw: string;
  createdBy: string;
  createdDate: Date;
  revisedBy: string;
  revisedDate: Date;
  originalAgreementDocumentUrl: string;
  signedAgreementDocumentUrl: string;
  revisionNotes: string;
  deliveryRateCards: DeliveryRateCard[] = [];
}
