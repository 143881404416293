import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {OrderQueryView} from "../models/order-view/order-query-view";

@Injectable({
    providedIn: 'root'
})
export class AiSummaryService {

    private apiUrl = `${environment.aiServiceApiUrl}summary`;

    constructor(private http: HttpClient) {
    }

    getShipmentSummary(orderQuery: OrderQueryView): Observable<string> {
        return this.http.post<string>(this.apiUrl + '/shipment', orderQuery, {
            responseType: 'text' as 'json'
        });
    }
}
