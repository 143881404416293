import { Component, OnInit } from '@angular/core';
import { CompanyService } from './company.service';
import { Company } from './company.model';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import { formatDate } from '@angular/common';
import {SessionStorageService} from "../services/session-storage.service";
import {CustomerService} from "../customer/customer.service";
import {Customer} from "../customer/customer.model";

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

    company!: Company;
    customers: Customer[] = [];
    isLoading = false;

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    companySubLocality: string = '';
    companyLocality: string = '';

    showCustomerForm: boolean = false;
    showCustomerRolesDialog: boolean = false;
    customerFormHeader: string = '';
    customerFormSubmitLabel: string = '';
    selectedCustomer: Customer = new Customer();
    selectedCompany: Company = new Company(); // Populate with customer data for editing

    constructor(
        private companyService: CompanyService,
        private customerService: CustomerService,
        private messageService: MessageService,
        private route: ActivatedRoute, private router: Router,
        private sessionStorageService: SessionStorageService
    ) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.breadcrumbItems = [
            { label: 'Company' }
        ];
        this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };

        let companyId = this.route.snapshot.paramMap.get('id');
        if (companyId) {
            this.loadCompany(Number(companyId));
            this.loadCustomers(Number(companyId));
        } else {
            companyId = this.sessionStorageService.getCompany()!!.id.toString();
            if (companyId) {
                this.loadCompany(Number(companyId));
                this.loadCustomers(Number(companyId));
            }
        }

    }

    loadCompany(companyId: number) {
        this.companyService.getCompanyById(companyId).subscribe(companyWrapper => {
            console.log(companyWrapper);
            this.company = companyWrapper.data;
            this.isLoading = false;
        });
    }

    showDate(date: Date | undefined): string {
        if (date) {
            return formatDate(date, 'dd/MM/yyyy', 'en-US');
        }
        return '';
    }

    loadCustomers(companyId: number) {
        this.customerService.getCustomersByCompany(companyId).subscribe(customers => {
            this.customers = customers;
            this.customers.forEach(value => {
               this.customerService.getRolesByCustomerId(value.id).subscribe(rolesData => {
                  value.roles = rolesData;
               });
            });
        });
    }

    createNewCustomer() {
        this.customerFormHeader = 'Create New Employee';
        this.customerFormSubmitLabel = 'Create';
        this.selectedCustomer = new Customer(); // Clear for new customer
        this.selectedCompany = this.company; // Populate with customer data for editing
        this.showCustomerForm = true;
    }

    editCustomer(customer: Customer) {
        this.customerFormHeader = 'Edit Employee';
        this.customerFormSubmitLabel = 'Update';
        this.selectedCustomer = customer; // Populate with customer data
        this.selectedCompany = this.company; // Populate with customer data for editing
        this.showCustomerForm = true;
    }

    onCustomerFormClosed() {
        this.showCustomerForm = false;
        // Optionally refresh your customer list here
    }

    deleteCustomer(customer: Customer) {
        // Logic to handle deleting the customer
        console.log('Delete customer:', customer);
    }

    sendEmail(email: string) {
        if (email) {
            window.location.href = `mailto:${email}`;
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Customer has no email address.' });
        }
    }

    makePhoneCall(phoneNumber: string) {
        if (phoneNumber) {
            window.location.href = `tel:${phoneNumber}`;
        } else {
            this.messageService.add({ severity: 'warn', summary: 'Warning', detail: 'Customer has no phone number.' });
        }
    }

    manageRoles(customer: Customer) {
        this.selectedCustomer = customer;
        this.showCustomerRolesDialog = true;
    }

    onCustomerRolesDialogClosed() {
        this.showCustomerRolesDialog = false;
        // Optionally refresh the customer data here
    }
}
