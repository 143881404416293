<div class="card">
    <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">
        <div class="flex flex-column lg:flex-row">
            <div class="flex col-12">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-th-large"></i></span>
                    <p-dropdown id="location-type" [options]="fileTypes" [(ngModel)]="selectedFileType"
                        placeholder="File Type" [autoDisplayFirst]="false" [style]="{'width': '100%'}">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="flex flex-column lg:flex-row">
            <div class="flex col-12">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="fa-solid fa-upload"></i></span>
                    <p-fileUpload name="jobAttachmentUpload" (uploadHandler)="onUpload($event)" (onSelect)="onSelect($event)"
                         [multiple]="true" accept="application/pdf" [customUpload]="true"
                        [maxFileSize]="1000000" class="max-w-full">
                        <ng-template pTemplate="content">
                            <span *ngIf="!filesSelected">Drag and drop PDf files here</span>
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">
                                    {{ file.name }} - {{ file.size }} bytes
                                </li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </div>
    </div>
</div>