<p-toast position='top-center'></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2 pb-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<p-accordion *ngIf="clientPayablesSummaryList.length > 0">
    <p-accordionTab *ngFor="let clientPayablesSummary of clientPayablesSummaryList; let i = index">
        <ng-template pTemplate="header">
            <div class="grid" style="width: 100%;">
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center pr-1">
                        <p-image *ngIf="clientPayablesSummary.clientLogo && clientPayablesSummary.clientId === 0"
                                 src="{{ clientPayablesSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!clientPayablesSummary.clientLogo && clientPayablesSummary.clientId === 0"
                                 src="assets/driver.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="clientPayablesSummary.clientLogo && clientPayablesSummary.clientId != 0"
                                 src="{{ clientPayablesSummary?.clientLogo }}" alt="Image" height="30px" class="mr-2"></p-image>
                        <p-image *ngIf="!clientPayablesSummary.clientLogo && clientPayablesSummary.clientId != 0"
                                 src="assets/transportation.png" alt="Image" height="30px" class="mr-2"></p-image>
                        <h4>{{ clientPayablesSummary.clientName }}</h4>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-red-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-up"></i>&nbsp;&nbsp;{{ clientPayablesSummary.pendingAmount }}</p>
                            <p class="text-l text-right text-red-200 p-0 m-0">Pending</p>
                        </div>
                    </div>
                    <div class="flex-none flex align-items-center">
                        <div class="pr-4">
                            <p class="text-2xl text-right text-green-400 p-0 m-0"><i
                                class="fa-solid fa-circle-arrow-down"></i>&nbsp;&nbsp;{{ clientPayablesSummary.billedAmount }}</p>
                            <p class="text-l text-right text-green-200 p-0 m-0">Billed</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-table [value]="clientPayablesSummary.payables" dataKey="payableId" [resizableColumns]="true"
                 styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }"
                 [paginator]="true" [rows]="20" [first]="first" [showCurrentPageReport]="true"
                 [totalRecords]="clientPayablesSummary.totalPayables" [lazy]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 (onLazyLoad)="loadData($event, clientPayablesSummary)" [rowsPerPageOptions]="[20, 50, 100]"
                 [(selection)]="clientPayablesSummary.selectedPayables">
            <ng-template pTemplate="caption">
                <p-progressBar mode="indeterminate" class="m-2" *ngIf="!clientPayablesSummary.payables"></p-progressBar>
                <div class="col-12 flex overflow-hidden">
                    <div class="flex-none flex align-items-center justify-content-center">
                        <h2>Payables</h2>
                    </div>
                    <div class="flex-grow-1 flex align-items-center justify-content-center"></div>
                    <div class="flex-none flex align-items-center justify-content-center">
                        <p-button label="Generate Bill" icon="fa-solid fa-file-invoice-dollar"
                                  (onClick)="generateBill(clientPayablesSummary)"
                                  class="p-2" severity="help"></p-button>
                        <p-button label="Export to Excel" icon="pi pi-file-excel" (onClick)="exportToExcel(clientPayablesSummary)"
                                  class="p-2" severity="secondary"></p-button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 4rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th style="width: 5rem"></th>
                    <th>ID</th>
                    <th>Ref#</th>
                    <th>Contact</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Bill#</th>
                    <th>Bill Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payable let-expandedHeader="expanded">
                <tr>
                    <td>
                        <p-tableCheckbox [value]="payable"></p-tableCheckbox>
                    </td>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="payable"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expandedHeader ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{ payable.payableId }}</td>
                    <td>{{ payable.payableReferenceNumber }}</td>
                    <td>
                        <p-chip *ngIf="payable.contact" [label]="payable.contact.name">
                            <img src="{{ payable.contact.imageUrl ?? 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'}}"
                                 alt="{{ payable.contact.name }}"
                                 style="width: 32px; height: 32px; border-radius: 50%;">
                        </p-chip>
                    </td>  <!-- Assuming payable has a companyName property -->
                    <td>{{ showDate(payable.expectedPayingDate) }}</td> <!-- Assuming payable has a date property -->
                    <td>{{ payable.country.currencyCode + ' ' + payable.amount }}</td> <!-- Assuming payable has currencyCode and amount properties -->
                    <td>{{ payable.odooBillNumber }}</td>
                    <td>{{ showDate(payable.billCreationDate) }}</td>
                    <td>
                        <p-tag *ngIf="payable.status === 'BILLED'" icon="fa-solid fa-check" severity="success" value="BILLED"></p-tag>
                        <p-tag *ngIf="payable.status === 'NEW'" icon="fa-solid fa-circle-exclamation" severity="danger" value="NEW"></p-tag>
                    </td>
                    <td>
                <span class="p-buttonset">
                    <button pButton type="button" pTooltip="View Details" icon="pi pi-eye" (click)="viewDetails(payable)"
                            class="p-button-rounded p-button-text p-button-help mr-2"></button>
                </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-payable>
                <tr>
                    <td colspan="11">
                        <div class="p-3">
                            <p-table [value]="payable.accountPayablesLines" dataKey="payableLineId" [resizableColumns]="true"
                                     styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '60rem' }">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-10">
                                            <h2>Payable Lines</h2>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S#</th>
                                        <th>Order#</th>
                                        <th>Delivery#</th>
                                        <th>Service Type</th>
                                        <th>Charge Amount</th>
                                        <th>Actions</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-payableLine>
                                    <tr>
                                        <td>{{ payableLine.payableLineId }}</td>
                                        <td>{{ payableLine.payableLineRefNumber }}</td>
                                        <td>{{ payable.country.currencyCode + ' ' + payableLine.amountExclTax }}</td>
                                        <td>{{ payable.country.currencyCode + ' ' + payableLine.taxAmount }}</td>
                                        <td>{{ payable.country.currencyCode + ' ' + payableLine.amount }}</td>
                                        <td>
                  <span class="p-buttonset">
                      <button pButton type="button" icon="fa fa-pencil" (click)="editPayablesLine(payableLine)"
                              class="p-button-rounded p-button-text p-button-warning mr-2"></button>
                  </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                    In total there are {{clientPayablesSummary.totalPayables}} payables.
                </div>
            </ng-template>

        </p-table>
    </p-accordionTab>
</p-accordion>
