<p-toast [life]="10000"></p-toast>
<p-progressBar mode="indeterminate" class="m-2" *ngIf="isLoading"></p-progressBar>
<div class="card pt-2">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbItems" [home]="homeBreadcrumb"></p-breadcrumb>
</div>
<div class="card">
    <form [formGroup]="purchaseOrderForm" (ngSubmit)="savePurchaseOrder()">
        <div class="card-container bg-gray-100 border-round mt-2 p-4">
            <div class="text-2xl font-semibold mb-4">
                {{ editMode ? 'Edit Purchase Order' : 'Add Purchase Order' }}
            </div>

            <div class="p-fluid grid">
                <div *ngIf="editMode" class="col-12 md:col-6">
                    <label for="orderNumber">Order Number</label>
                    <input type="text" pInputText formControlName="orderNumber" id="orderNumber"/>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('orderNumber')?.hasError('required') && purchaseOrderForm.get('orderNumber')?.touched">Order
                        Number is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="company">Company</label>
                    <p-dropdown id="company" [options]="companies" formControlName="company"
                                placeholder="Select a Company" optionLabel="label" [required]="true"
                                [filter]="true" (onChange)="onCompanyChange($event.value)"></p-dropdown>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('company')?.hasError('required') && purchaseOrderForm.get('company')?.touched">Company
                        is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="vendor">Vendor</label>
                    <p-dropdown id="vendor" [options]="vendors" formControlName="vendor"
                                placeholder="Select a Vendor" optionLabel="label" [required]="true"
                                [filter]="true"></p-dropdown>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('vendor')?.hasError('required') && purchaseOrderForm.get('vendor')?.touched">Vendor
                        is required.</small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="paymentTerm">Payment Term</label>
                    <p-dropdown id="paymentTerm" [options]="paymentTerms" formControlName="paymentTerm"
                                placeholder="Select a Payment Term" optionLabel="label"
                                [required]="true"></p-dropdown>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('paymentTerm')?.hasError('required') && purchaseOrderForm.get('paymentTerm')?.touched">Payment
                        term is required.</small>
                </div>
            </div>
            <div class="p-fluid grid">
                <div class="col-12 md:col-6">
                    <label for="orderDate">Order Date</label>
                    <p-calendar id="orderDate" inputId="orderDate" formControlName="orderDate"
                                dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('orderDate')?.hasError('required') && purchaseOrderForm.get('orderDate')?.touched">Order
                        date is required.</small>
                </div>
                <div class="col-12 md:col-6">
                    <label for="expectedDate">Expected Date</label>
                    <p-calendar id="expectedDate" inputId="expectedDate" formControlName="expectedDate"
                                dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                    <small class="p-error"
                           *ngIf="purchaseOrderForm.get('expectedDate')?.hasError('required') && purchaseOrderForm.get('expectedDate')?.touched">Expected
                        date is required.</small>
                </div>
            </div>

            <p-divider/>

            <div formArrayName="purchaseOrderLines" class="mt-6">
                <div class="text-xl font-semibold mb-4">
                    {{ editMode ? 'Edit Purchase Order Lines' : 'Add Purchase Order Lines' }}
                </div>
                <div class="mt-2"
                     *ngFor="let lineGroup of getPurchaseOrderLineControls.controls; let i = index">
                    <div [formGroupName]="i" class="grid p-fluid">
                        <div class="col-12 md:col-3">
                            <label for="product{{ i }}">Product</label>
                            <p-dropdown id="product{{ i }}" [options]="products" formControlName="product"
                                        placeholder="Select a Product" optionLabel="label" [required]="true"
                                        [filter]="true"></p-dropdown>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('product')?.hasError('required') && lineGroup.get('product')?.touched">Product
                                is required.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="variant{{ i }}">Variant</label>
                            <input type="text" pInputText formControlName="variant"
                                   id="variant{{ i }}"/>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="description{{ i }}">Description</label>
                            <input type="text" pInputText formControlName="description"
                                   id="description{{ i }}"/>
                        </div>
                        <div class="col-12 md:col-1">
                            <label for="quantity{{ i }}">Quantity</label>
                            <p-inputNumber inputId="quantity{{ i }}" formControlName="quantity"
                                           mode="decimal" [minFractionDigits]="2"
                                           [maxFractionDigits]="2" [min]="1" locale="en-US"></p-inputNumber>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('quantity')?.hasError('required') && lineGroup.get('quantity')?.touched">Quantity
                                is required.</small>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('quantity')?.hasError('min') && lineGroup.get('quantity')?.touched">Quantity
                                must be at least 1.</small>
                        </div>
                        <div class="col-12 md:col-2">
                            <label for="unitPrice{{ i }}">Unit Price</label>
                            <p-inputNumber inputId="unitPrice{{ i }}" formControlName="unitPrice"
                                           mode="decimal" [minFractionDigits]="2"
                                           [maxFractionDigits]="2" [min]="0" locale="en-US"></p-inputNumber>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('unitPrice')?.hasError('required') && lineGroup.get('unitPrice')?.touched">Unit
                                Price is required.</small>
                            <small class="p-error"
                                   *ngIf="lineGroup.get('unitPrice')?.hasError('min') && lineGroup.get('unitPrice')?.touched">Unit
                                Price must be at least 0.</small>
                        </div>
                        <div class="col-12 md:col-2 flex justify-content-end">
                            <button pButton pRipple type="button" icon="pi pi-times"
                                    class="p-button-rounded p-button-text p-button-danger"
                                    (click)="removePurchaseOrderLine(i)"></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-12">
                    <button pButton pRipple type="button" label="Add Line" icon="fa-solid fa-add"
                            (click)="addPurchaseOrderLine()" class="p-button-rounded p-button-text p-button-info mt-2"></button>
                </div>
            </div>
            <p-divider/>
            <div class="col-12 flex justify-content-end">
                <button pButton pRipple type="button" label="Cancel" (click)="cancel()"
                        class="p-button-rounded p-button-text p-button-secondary mr-2"></button>
                <button pButton pRipple type="submit" label="Save" class="p-button-rounded p-button-text p-button-info"></button>
            </div>
        </div>
    </form>
</div>
