import { Injectable } from '@angular/core';
import { EnterpriseSubscriptionService } from "../company/enterprise-subscription/enterprise-subscription.service";
import { SessionStorageService } from "./session-storage.service";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {EnterpriseSubscription} from "../company/enterprise-subscription/enterprise-subscription.model";


@Injectable({
    providedIn: 'root'
})
export class EnterpriseFeatureService {

    private enterpriseSubscriptionList: EnterpriseSubscription[] = [];
    private enableEnterpriseAccounts: boolean = false;
    private isFreightForwarderSubscribed: boolean = false;
    private isTransportationCompanySubscribed: boolean = false;
    private isFleetSubscribed: boolean = false;
    private isGPSTrackingSubscribed: boolean = false;

    constructor(private enterpriseSubscriptionService: EnterpriseSubscriptionService,
                private sessionStorageService: SessionStorageService) {
    }


    public getAllSubscriptions() {
        this.enterpriseSubscriptionService.getSubscriptionsByCompanyId(this.sessionStorageService.getCompany()!!.id).subscribe(
            ((value) => {
                this.enterpriseSubscriptionList = value;
                 this.setIsFreightForwarderSubscribed(this.enterpriseSubscriptionList
                    .filter(value1 => value1.module === 'FREIGHT_FORWARDER').pop() !== undefined);
                this.setIsTransportationCompanySubscribed(this.enterpriseSubscriptionList
                    .filter(value1 => value1.module === 'TRANSPORTATION_COMPANY').pop() !== undefined);
                this.setIsFleetSubscribed(this.enterpriseSubscriptionList
                    .filter(value1 => value1.module === 'FLEET').pop() !== undefined);
                this.setIsGPSTrackingSubscribed(this.enterpriseSubscriptionList
                    .filter(value1 => value1.module === 'GPS_TRACKING').pop() !== undefined);

                if (this.getIsFreightForwarderSubscribed() || this.getIsTransportationCompanySubscribed())
                    this.setEnableEnterpriseAccounts(true);
            }),
            error => {
                console.log(error);
            }
        );
    }




    getIsFreightForwarderSubscribed(): boolean {
        this.isFreightForwarderSubscribed = sessionStorage.getItem('isFreightForwarderSubscribed') === 'true';
        return this.isFreightForwarderSubscribed;
    }

    setIsFreightForwarderSubscribed(value: boolean): void {
        sessionStorage.setItem('isFreightForwarderSubscribed', value.toString());
        this.isFreightForwarderSubscribed = value;
    }

    getIsTransportationCompanySubscribed(): boolean {
        this.isTransportationCompanySubscribed = sessionStorage.getItem('isTransportationCompanySubscribed') === 'true';
        return this.isTransportationCompanySubscribed;
    }

    setIsTransportationCompanySubscribed(value: boolean): void {
        sessionStorage.setItem('isTransportationCompanySubscribed', value.toString());
        this.isTransportationCompanySubscribed = value;
    }

    getIsFleetSubscribed(): boolean {
        this.isFleetSubscribed = sessionStorage.getItem('isFleetSubscribed') === 'true';
        return this.isFleetSubscribed;
    }

    setIsFleetSubscribed(value: boolean): void {
        sessionStorage.setItem('isFleetSubscribed', value.toString());
        this.isFleetSubscribed = value;
    }

    getIsGPSTrackingSubscribed(): boolean {
        this.isGPSTrackingSubscribed = sessionStorage.getItem('isGPSTrackingSubscribed') === 'true';
        return this.isGPSTrackingSubscribed;
    }

    setIsGPSTrackingSubscribed(value: boolean): void {
        sessionStorage.setItem('isGPSTrackingSubscribed', value.toString());
        this.isGPSTrackingSubscribed = value;
    }

    getEnableEnterpriseAccounts(): boolean {
        this.enableEnterpriseAccounts = sessionStorage.getItem('enableEnterpriseAccounts') === 'true';
        return this.enableEnterpriseAccounts;
    }

    setEnableEnterpriseAccounts(value: boolean): void {
        sessionStorage.setItem('enableEnterpriseAccounts', value.toString());
        this.enableEnterpriseAccounts = value;
    }

}
