/* eslint-disable */
import { BaseProfile } from "../models/common/base-profile";
import { Company } from "../company/company.model";
import {Role} from "./customer-role.model";

export class Customer extends BaseProfile {
    corporateRole: string;
    customerStatus: string;
    address: string;
    company: Company;
    countryId: number;
    roles: Role[]
}
