<p-toast></p-toast>
<p-dialog [header]="dialogHeader" [(visible)]="displayModal" [modal]="true" [style]="{ width: '50vw' }"
          [draggable]="false" [resizable]="false">
    <div class="p-fluid card">
        <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">
            <div class="card-container bg-gray-100 border-round mt-2" style="padding: 16px;">

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-map-location-dot"></i></span>
                            <p-autoComplete formControlName="searchLocation"
                                            [ngClass]="{'p-invalid': ((companyForm.get('search')?.touched || companyForm.get('search')?.dirty) )}"
                                            [autofocus]="true" [forceSelection]="true" placeholder="Location Search" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" [suggestions]="searchPlaceResults"
                                            (completeMethod)="searchPlaces($event)" (onSelect)="placeSelect($event)" [minLength]="1" field="name"
                                            [style]="{'width': '100%'}"></p-autoComplete>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-user"></i></span>
                            <input pInputText id="name" type="text" formControlName="name" [(ngModel)]="company.name" placeholder="Company Name" required>
                            <small class="p-error" *ngIf="companyForm.get('name')?.invalid && (companyForm.get('name')?.dirty || companyForm.get('name')?.touched)">Company Name is required.</small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-briefcase"></i></span>
                            <input pInputText id="crNumber" type="number" formControlName="crNumber" [(ngModel)]="company.crNumber" placeholder="CR Number" required>
                            <small class="p-error" *ngIf="companyForm.get('crNumber')?.invalid && (companyForm.get('crNumber')?.dirty || companyForm.get('crNumber')?.touched)">CR Number is required.</small>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-briefcase"></i></span>
                            <input pInputText id="taxRegNumber" type="number" formControlName="taxRegNumber" [(ngModel)]="company.taxRegNumber" placeholder="VAT Number">
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-envelope"></i></span>
                            <input pInputText id="emailAddress" type="email" formControlName="emailAddress" [(ngModel)]="company.emailAddress" placeholder="Email Address">
                        </div>
                    </div>
                </div>

                <div class="flex flex-column lg:flex-row">
                    <div class="flex col">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon"><i class="fa-solid fa-phone"></i></span>
                            <input pInputText id="phoneNumber" type="tel" formControlName="phoneNumber" [(ngModel)]="company.phoneNumber" placeholder="Mobile Number">
                            <small class="p-error" *ngIf="companyForm.get('phoneNumber')?.invalid && (companyForm.get('phoneNumber')?.dirty || companyForm.get('phoneNumber')?.touched)">Phone number is required.</small>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" icon="fa-solid fa-close" (click)="onCancel()" class="p-button-secondary"></button>
        <button pButton type="submit" [label]="submitButtonLabel" icon="fa-solid fa-plus" (click)="onSubmit()" [loading]="isCompanySaveInProgress"></button>
    </ng-template>
</p-dialog>

<div id="map"></div>
