import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Role} from "../customer-role.model";
import {CustomerService} from "../customer.service";
import {MessageService} from "primeng/api";
import {TableSelectAllChangeEvent} from "primeng/table";

@Component({
    selector: 'app-customer-roles',
    templateUrl: './customer-roles.component.html',
    styleUrls: ['./customer-roles.component.css']
})
export class CustomerRolesComponent implements OnInit {
    @Input() displayModal: boolean = false;
    @Input() dialogHeader: string = '';
    @Input() customerId: number = 0;
    @Output() modalClosed = new EventEmitter<boolean>();

    availableRoles: Role[] = [];
    selectedCustomerRoles: Role[] = [];

    constructor(
        private customerService: CustomerService,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.loadAvailableRoles();
    }

    loadAvailableRoles() {
        this.customerService.getAllRoles().subscribe(roles => {
            this.availableRoles = roles;
            this.loadCustomerRoles();
        });
    }

    loadCustomerRoles() {
        if (this.customerId) {
            this.customerService.getRolesByCustomerId(this.customerId).subscribe(roles => {
                console.log(roles);
                this.selectedCustomerRoles = roles;
            });
        }
    }

    isRoleSelected(role: Role): boolean {
        return this.selectedCustomerRoles.some(r => r.roleId === role.roleId);
    }

    toggleAllRoles(event: MouseEvent, isChecked: boolean | undefined) {
        if (isChecked) {
            this.selectedCustomerRoles = [...this.availableRoles];
        } else {
            this.selectedCustomerRoles = [];
        }
    }

    onSave() {
        // Send the updated roles to your backend
        this.customerService.modifyRolesForCustomer(this.customerId, this.selectedCustomerRoles)
            .subscribe(() => {
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Roles updated successfully!'});
                this.closeModal();
            }, error => {
                console.error('Error updating roles:', error);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update roles. Please try again later.'
                });
            });
    }

    onCancel() {
        this.closeModal();
    }

    closeModal() {
        this.displayModal = false;
        this.modalClosed.emit(true);
    }

    onRoleChange($event: any, role: Role) {
        if ($event.checked) {
            this.selectedCustomerRoles.push(role);
        } else {
            this.selectedCustomerRoles = this.selectedCustomerRoles.filter(r => r.roleId !== role.roleId);
        }
    }
}
