import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import * as firebaseAuth from 'firebase/auth';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';

import { AuthService } from '../services/auth.service';
import { CustomerService } from '../customer/customer.service';
import { SessionStorageService } from '../services/session-storage.service';
import { ToolbarService } from '../services/toolbar.service';
import { BaseMasterDataService } from '../services/base-master-data.service';
import { LocationService } from '../location/location.service';
import { MessageService } from 'primeng/api';
import {TransportationAgencyService} from "../company/transportation-agency/transportation-agency.service";
import {EnterpriseSubscriptionService} from "../company/enterprise-subscription/enterprise-subscription.service";
import {EnterpriseFeatureService} from "../services/enterprise-features.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    isLoading = false;
    isLoggedIn = false;
    isLoginFailed = false;
    returnUrl: string = '';
    errorMessage = '';
    roles: string[] = [];

    constructor(private authService: AuthService,
        private sessionStorageService: SessionStorageService,
        private angularFireAuth: AngularFireAuth,
        private router: Router, private route: ActivatedRoute,
        private customerService: CustomerService,
        private baseMasterDataService: BaseMasterDataService,
        private transportationAgencyService: TransportationAgencyService,
        private enterpriseFeatureService: EnterpriseFeatureService,
        private toasterService: MessageService,
        private toolbarService: ToolbarService) {
    }

    ngOnInit(): void {
        if (this.sessionStorageService.getAccessToken()) {
            this.isLoggedIn = true;
            this.roles = this.sessionStorageService.getUser().roles;
        }
        this.angularFireAuth.authState.subscribe(response => {
            if (response) {
                console.log('Logged in :)');
                this.loginToBackend(response);
            } else {
                console.log('Logged out :(');
            }
        });
        this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'];
            if (this.returnUrl != undefined)
                this.returnUrl = this.returnUrl.substring(this.findThirdOccurrence(this.returnUrl, '/'), this.returnUrl.length)
		});
    }

    successCallback(firebaseUISignInSuccessWithAuthResult: FirebaseUISignInSuccessWithAuthResult) {
        this.loginToBackend(firebaseUISignInSuccessWithAuthResult.authResult.user);
    }

    errorCallback(firebaseUISignInFailure: FirebaseUISignInFailure) {
        this.toasterService.add({
            severity: 'error',
            summary: 'FirebaseUI Error',
            detail: firebaseUISignInFailure.code
        });
    }

    uiShownCallback() {
        //throw new Error('Method not implemented.');
        console.log('Firebase Auth UI is shown');
    }

    private loginToBackend(user: unknown) {
        let firebaseUser = user as firebaseAuth.User;

        this.authService.getCurrentUserAccessToken().subscribe(
            token => {
                this.sessionStorageService.saveAccessToken(token, new Date().getTime());
                this.customerService.getCustomerById(firebaseUser.uid).subscribe(
                    response => {
                        let customer = response.data;
                        this.sessionStorageService.saveCustomer(customer);
                        this.isLoginFailed = false;
                        this.isLoggedIn = true;
                        this.customerService.getRolesByCustomerId(customer.id).subscribe(rolesData => {
                            customer.roles = rolesData;
                            this.sessionStorageService.saveCustomer(customer);
                        });
                        if (customer.company.transportationAgencyId) {
                            this.transportationAgencyService.findTransportationAgencyByFilter(undefined, undefined,
                                customer.company.transportationAgencyId, undefined, undefined).subscribe(agencyWrapperData => {
                                    console.log(agencyWrapperData);
                                    this.sessionStorageService.saveTransportationAgency(agencyWrapperData.data);
                            })
                        }
                        this.roles = this.sessionStorageService.getUser().roles;
                        this.baseMasterDataService.getBaseMasterData("en-US")
                            .subscribe(baseMasterData => {
                                console.log(baseMasterData);
                                this.sessionStorageService.saveBaseMasterData(baseMasterData.data);
                                this.isLoading = false;
                                this.toolbarService.updateLoggedInStatus(true);
                                this.toolbarService.updateCustomer(customer);
                                this.router.navigate([this.returnUrl == undefined || this.returnUrl == '/' ? '/dashboard' : this.returnUrl], { relativeTo: null });
                            });
                        this.enterpriseFeatureService.getAllSubscriptions();
                    },
                    err => {
                        this.isLoginFailed = true;
                        this.isLoggedIn = false;
                        this.isLoading = false;
                        this.toolbarService.updateLoggedInStatus(false);
                        this.toolbarService.updateCustomer(null);
                    }
                );
            }
        );
        this.authService.getCurrentUserRefreshToken().subscribe(
            response => {
                this.sessionStorageService.saveRefreshToken(response);
            }
        )
        this.sessionStorageService.saveUser(firebaseUser);

    }

    reloadPage(): void {
        window.location.reload();
    }

    findThirdOccurrence(str: string, char: string): number {
        let count = 0;
        let index = -1;

        for (let i = 0; i < str.length; i++) {
          if (str[i] === char) {
            count++;
            if (count === 3) {
              index = i;
              break;
            }
          }
        }

        return index;
      }

}
