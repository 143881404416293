import { Routes } from '@angular/router';
import { AccountsComponent } from './accounts.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ReceiptComponent } from './receipt/receipt.component';
import {ReceivablesComponent} from "./receivables/receivables.component";
import {PayablesComponent} from "./payables/payables.component";
import {AccountsGuard} from "./accounts.guard";
import {GeneratedInvoicesComponent} from "./receivables/generated-invoices/generated-invoices.component";
import {GeneratedReceiptsComponent} from "./receivables/generated-receipts/generated-receipts.component";
import {AddEditJournalEntryComponent} from "./journal-entries/add-edit-journal-entry/add-edit-journal-entry.component";
import {JournalEntriesComponent} from "./journal-entries/journal-entries.component";
import {GeneralLedgerComponent} from "./general-ledger/general-ledger.component";
import {PartnerLedgerComponent} from "./partner-ledger/partner-ledger.component";
import {PaymentTransactionsComponent} from "./payment-transactions/payment-transactions.component";
import {GeneratedBillsComponent} from "./payables/generated-bills/generated-bills.component";
import {GeneratedVouchersComponent} from "./payables/generated-vouchers/generated-vouchers.component";
import {DebitNoteComponent} from "./debit-note/debit-note.component";
import {AddEditDebitNoteComponent} from "./debit-note/add-edit-debit-note/add-edit-debit-note.component";
import {CreditNoteComponent} from "./credit-note/credit-note.component";
import {AddEditCreditNoteComponent} from "./credit-note/add-edit-credit-note/add-edit-credit-note.component";
import {ApplyReceiptInvoiceComponent} from "./receivables/apply-receipt-invoice/apply-receipt-invoice.component";

export const accountsRoutes: Routes = [
    {
        path: 'accounts',
        children: [
            {
                path: '',
                component: AccountsComponent,
            },
            {
                path: 'invoice',
                component: InvoiceComponent,
            },
            {
                path: 'receipt',
                component: ReceiptComponent
            },
            {
                path: 'enterprise',
                canActivate: [AccountsGuard],
                children: [
                    {
                        path: 'journal-entries',
                        children: [
                            {
                                path: '',
                                component: JournalEntriesComponent,
                            },
                            {
                                path: 'add',
                                component: AddEditJournalEntryComponent,
                            },
                            {
                                path: 'edit/:id',
                                component: AddEditJournalEntryComponent,
                            }
                        ]
                    },
                    {
                        path: 'general-ledger',
                        component: GeneralLedgerComponent,
                    },
                    {
                        path: 'partner-ledger',
                        component: PartnerLedgerComponent,
                    },
                    {
                        path: 'payment-transactions',
                        component: PaymentTransactionsComponent,
                    },
                    {
                        path: 'receivables',
                        children: [
                            {
                                path: '',
                                component: ReceivablesComponent,
                            },
                            {
                                path: 'generated-invoices',
                                component: GeneratedInvoicesComponent,
                            },
                            {
                                path: 'generated-receipts',
                                component: GeneratedReceiptsComponent,
                            },
                            {
                                path: 'apply-receipts-invoice/:receiptId',
                                component: ApplyReceiptInvoiceComponent,
                            },
                            {
                                path: 'debit-note',
                                children: [
                                    {
                                        path: '',
                                        component: DebitNoteComponent,
                                    },
                                    {
                                        path: 'add',
                                        component: AddEditDebitNoteComponent,
                                    },
                                    {
                                        path: 'edit/:id',
                                        component: AddEditDebitNoteComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'payables',
                        children: [
                            {
                                path: '',
                                component: PayablesComponent,
                            },
                            {
                                path: 'generated-bills',
                                component: GeneratedBillsComponent,
                            },
                            {
                                path: 'generated-vouchers',
                                component: GeneratedVouchersComponent,
                            },
                            {
                                path: 'credit-note',
                                children: [
                                    {
                                        path: '',
                                        component: CreditNoteComponent,
                                    },
                                    {
                                        path: 'add',
                                        component: AddEditCreditNoteComponent,
                                    },
                                    {
                                        path: 'edit/:id',
                                        component: AddEditCreditNoteComponent,
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
        ]
    },
];
