import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MenuItem, MessageService, SelectItem} from "primeng/api";
import {OrdersService} from "../../order.service";
import {SalesOrder} from "../sales-order.model";
import {PaymentTerm} from "../../models/payment-term.model";
import {SalesOrderLine} from "../sales-order-line.model";
import {Products} from "../../models/product.model";
import {forkJoin, of, tap} from "rxjs";
import {DropdownChangeEvent} from "primeng/dropdown";
import {ProductVariant} from "../../models/product-variant.model";
import {Company} from "../../../company/company.model";
import {Customer} from "../../../customer/customer.model";
import {CustomerService} from "../../../customer/customer.service";
import {ContractsService} from "../../../contracts/contracts.service";
import {SessionStorageService} from "../../../services/session-storage.service";
import {ProductsService} from "../../product.service";

@Component({
    selector: 'app-add-edit-sales-order',
    templateUrl: './add-edit-sales-order.component.html',
    styleUrls: ['./add-edit-sales-order.component.css']
})
export class AddEditSalesOrderComponent implements OnInit {

    breadcrumbItems: MenuItem[] = [];
    homeBreadcrumb: MenuItem = {};

    salesOrderForm!: FormGroup;
    editMode = false;
    salesOrderId!: number;
    isLoading: boolean = false;

    companies: SelectItem<Company>[] = [];
    customers: SelectItem<Customer>[] = [];
    paymentTerms: SelectItem<PaymentTerm>[] = [];
    products: SelectItem<Products>[] = [];
    productVariants: SelectItem<ProductVariant>[] = [];
    salespersons: SelectItem<Customer>[] = [];


    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        private salesOrderService: OrdersService,
        private productsService: ProductsService,
        private customerService: CustomerService,
        private contractService: ContractsService,
        private sessionStorageService: SessionStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.breadcrumbItems = [
            {label: 'Sales Order', routerLink: '/sales-orders'},
            {label: this.editMode ? 'Edit Sales Order' : 'Add Sales Order'}
        ];
        this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};

        this.route.params.subscribe(params => {
            this.salesOrderId = params['id'];
            this.editMode = !!this.salesOrderId;
            this.loadData();
        });

        this.initForm();
    }

    loadData() {
        this.isLoading = true;

        const loadObservables = {
            products: this.loadProducts(),
            paymentTerms: this.loadPaymentTerms(),
            companies: this.loadCompanies(),
            salesPersons: this.loadSalesPersons(),
        };

        forkJoin(loadObservables)
            .pipe(
                tap(() => {
                    if (this.editMode) {
                        this.loadSalesOrder().subscribe();
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'An error occurred while loading data.' + error.message
                    });
                    //this.cancel();
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    loadProducts() {
        return this.productsService.getAllProductsByOwningCompany(this.sessionStorageService.getCompany()!.id).pipe(
            tap(products => {
                this.products = products.map((product) => ({
                    value: product,
                    label: product.name
                }));
            })
        );
    }

    loadPaymentTerms() {
        return this.salesOrderService.getAllPaymentTerms().pipe(
            tap(paymentTerms => {
                this.paymentTerms = paymentTerms.map((paymentTerm) => ({
                    value: paymentTerm,
                    label: paymentTerm.name
                }));
            })
        );
    }

    loadCompanies() {
        return this.contractService.getContractRelatedCompanies(this.sessionStorageService.getCompany()!.id).pipe(
            tap(value => {
                this.companies = value.map(company => ({
                    value: company,
                    label: company.name
                }));
            })
        );
    }

    loadCustomersByCompany(event: DropdownChangeEvent) {
        console.log('onChange called');
        let company = event.value;
        console.log(company);
        this.customerService.getCustomersByCompany(company.id).subscribe(customers => {
                this.customers = customers.map(customer => ({
                    value: customer,
                    label: customer.name
                }));
            }
        );
    }

    loadSalesPersons() {
        return this.customerService.getCustomersByCompany(this.sessionStorageService.getCompany()?.id ?? 0).pipe(
            tap(customers => {
                this.salespersons = customers.map(customer => ({
                    value: customer,
                    label: customer.name
                }));
            })
        );
    }

    onProductChange(event: DropdownChangeEvent, index: number) {
        console.log(event);
        if(event.value) {
            const selectedProduct = event.value as Products;
            const lineGroup = this.getSalesOrderLineControls.at(index);
            this.loadProductVariants(selectedProduct).subscribe(
                () => {
                    lineGroup.patchValue({ description: selectedProduct.description, variant: null });
                }
            );
        }
    }

    loadProductVariants(product: Products){
        return of(product.productVariants).pipe(
            tap(productVariants => {
                this.productVariants = productVariants.map(variant => ({
                    value: variant,
                    label: variant.name
                }));
            })
        )
    }

    onProductVariantChange(event: DropdownChangeEvent, index: number) {
        if (event.value) {
            const selectedProductVariant = event.value as ProductVariant;
            const lineGroup = this.getSalesOrderLineControls.at(index);
            lineGroup.patchValue({ unitPrice: selectedProductVariant.rateAdjustment });
        }
    }

    loadSalesOrder() {
        return this.salesOrderService.getSalesOrderById(this.salesOrderId).pipe(
            tap(salesOrder => {
                this.salesOrderForm.patchValue(salesOrder);
                this.salesOrderForm.setControl('salesOrderLines', this.fb.array(salesOrder.salesOrderLines
                    .map(line => this.createSalesOrderLineFormGroup(line))));
            })
        );
    }

    initForm() {
        this.salesOrderForm = this.fb.group({
            orderNumber: [''],
            company: [null, Validators.required],
            customer: [null, Validators.required],
            salesperson: [null, Validators.required],
            paymentTerm: [null, Validators.required],
            orderDate: [new Date(), Validators.required],
            expectedDate: [new Date(), Validators.required],
            salesOrderLines: this.fb.array([this.createSalesOrderLineFormGroup()]),
        });
    }

    createSalesOrderLineFormGroup(salesOrderLine?: SalesOrderLine): FormGroup {
        return this.fb.group({
            product: [salesOrderLine?.product ?? null, Validators.required],
            variant: [salesOrderLine?.variant ?? null],
            description: [salesOrderLine?.description ?? ''],
            quantity: [salesOrderLine?.quantity ?? 1, [Validators.required, Validators.min(1)]],
            unitPrice: [salesOrderLine?.unitPrice ?? 0, [Validators.required, Validators.min(0)]],
        });
    }

    get getSalesOrderLineControls(): FormArray {
        return this.salesOrderForm.get('salesOrderLines') as FormArray;
    }

    addSalesOrderLine() {
        this.getSalesOrderLineControls.push(this.createSalesOrderLineFormGroup());
    }

    removeSalesOrderLine(index: number) {
        this.getSalesOrderLineControls.removeAt(index);
    }

    saveSalesOrder() {
        if (this.salesOrderForm.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please correct all form errors.'
            });
            return;
        }
        this.isLoading = true;
        const salesOrder = this.salesOrderForm.value as SalesOrder;
        salesOrder.owningCompanyId = this.sessionStorageService.getCompany()!.id;
        salesOrder.state = 'draft';
        let userId = this.sessionStorageService.getCustomer()!.id;
        if (this.editMode) {
            salesOrder.orderID = this.salesOrderId;
            salesOrder.updatedAt = new Date();
            salesOrder.updatedBy = userId;
            salesOrder.salesOrderLines.forEach(line => {
                line.orderID = this.salesOrderId;
                line.updatedAt = new Date();
                line.updatedBy = userId;
                if (!line.lineID) {
                    line.lineNumber = (salesOrder.salesOrderLines.indexOf(line) + 1).toString();
                    line.owningCompanyId = salesOrder.owningCompanyId;
                    line.createdAt = new Date();
                    line.createdBy = userId;
                    line.unitTaxAmount = line.unitPrice * (line.product.taxRate/100);
                    line.unitLineAmount = line.quantity * line.unitPrice;
                    line.totalTax = line.unitTaxAmount * line.quantity;
                    line.totalLineAmount = line.unitLineAmount + line.totalTax;
                }
            });
        } else {
            salesOrder.createdAt = new Date();
            salesOrder.createdBy = userId;
            salesOrder.updatedAt = new Date();
            salesOrder.updatedBy = userId;
            salesOrder.salesOrderLines.forEach(line => {
                line.lineNumber = (salesOrder.salesOrderLines.indexOf(line) + 1).toString();
                line.owningCompanyId = salesOrder.owningCompanyId;
                line.createdAt = new Date();
                line.createdBy = userId;
                line.updatedAt = new Date();
                line.updatedBy = userId;
                line.unitTaxAmount = line.unitPrice * (line.product.taxRate/100);
                line.unitLineAmount = line.quantity * line.unitPrice;
                line.totalTax = line.unitTaxAmount * line.quantity;
                line.totalLineAmount = line.unitLineAmount + line.totalTax;
            });
        }
        this.salesOrderService.createSalesOrder(salesOrder).subscribe({
            next: (newSalesOrder) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Sales order saved successfully.'
                });
                this.router.navigate(['/sales-orders']);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error saving sales order'
                });
            }
        })
    }

    cancel() {
        this.location.back();
    }
}
