import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FreightForwarder } from "./freight-forwarder.model";

@Injectable({
    providedIn: 'root'
})
export class FreightForwarderService {

    private baseUrl = environment.restApiUrl + 'freight-forwarder-endpoint'; // Adjust the base URL as needed

    constructor(private http: HttpClient) { }

    getFreightForwarder(
        countryId?: number,
        freightForwarderId?: number,
        crNumber?: number,
        phoneNumber?: string,
        emailAddress?: string,
        cultureCode = 'en-US'
    ): Observable<FreightForwarder> {
        const headers = new HttpHeaders({ 'Accept-Language': cultureCode });
        let url = `${this.baseUrl}?`;

        if (countryId) {
            url += `countryId=${countryId}&`;
        }
        if (freightForwarderId) {
            url += `freightForwarderId=${freightForwarderId}&`;
        }
        if (crNumber) {
            url += `crNumber=${crNumber}&`;
        }
        if (phoneNumber) {
            url += `phoneNumber=${phoneNumber}&`;
        }
        if (emailAddress) {
            url += `emailAddress=${emailAddress}&`;
        }

        // Remove trailing '&' if present
        url = url.slice(-1) === '&' ? url.slice(0, -1) : url;

        return this.http.get<FreightForwarder>(url, { headers });
    }

    addFreightForwarder(freightForwarder: FreightForwarder): Observable<FreightForwarder> {
        return this.http.post<FreightForwarder>(this.baseUrl, freightForwarder);
    }

    updateFreightForwarder(freightForwarder: FreightForwarder): Observable<FreightForwarder> {
        return this.http.put<FreightForwarder>(this.baseUrl, freightForwarder);
    }

    deleteFreightForwarder(freightForwarder: FreightForwarder): Observable<String> {
        // Assuming you have an ID property in your FreightForwarder object
        const url = `${this.baseUrl}/${freightForwarder.id}`;
        return this.http.delete<String>(url);
    }
}
